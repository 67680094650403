import React, { useCallback, useEffect, useState } from "react";
import { TrashIcon, PlusIcon, CheckIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import { RadioGroup } from "@headlessui/react";
import {
  classNames,
  currency_types,
  validateForm,
  yes_no,
} from "../../../utils/common";
import config from "../../../../src/utils/config";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from "react-date-picker";
import Cookies from "js-cookie";

const steps1 = [
  { id: "01", name: "Informação Geral", href: "#", status: "current" },
  { id: "02", name: "Modalidade de Contrato", href: "#", status: "upcoming" },
  { id: "03", name: "Contrato", href: "#", status: "upcoming" },
  { id: "04", name: "Condições de pagamento", href: "#", status: "upcoming" },
];

function Tab1({ currency, setTab = () => {} }) {
  const history = useHistory();
  const { tripID } = useParams();
  const [selectedPDID, setSelectedPDID] = useState(null);
  const [fields, setFields] = useState({
    start_date: new Date(),
    end_date: new Date(),
  });
  const [selectedOptionprovider, setSelectedOptionprovider] = useState(null);
  const [selectedservicecatogary, setSelectedservicecatogary] = useState(null);
  const [selectedradio4, setSelectedRadio4] = useState();
  const [selectedradio1, setSelectedRadio1] = useState();
  const [error, setError] = useState({});
  const [producer, setProducer] = useState([]);
  const [provider, setProvider] = useState([]);
  const [selectedradio, setSelectedRadio] = useState(yes_no[0]);
  const [selectedradio5, setSelectedRadio5] = useState(currency_types[0]);
  const [selectedradio6, setSelectedRadio6] = useState(yes_no[0]);
  const [paymentmode, setPaymentMode] = useState([]);
  const [serviceCategory, setServiceCategory] = useState([]);
  const [department, setDepartment] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const getcontractInfo = useCallback(() => {
    config
      .getAPI({ url: "contract/getdatabyId", params: { id: tripID } })
      .then((data) => {
        if (data.payload) {
          const {
            physical_code,
            object_of_the_contract,
            observation,
            contract_value,
            department_id,
            payment_id,
            producer_id,
            start_date,
            end_date,
            status,
            contest_reference,
          } = data.payload;
          setFields({
            physical_code,
            object_of_the_contract,
            observation,
            contract_value,
            department_id,
            payment_id,
            producer_id,
            status,
            start_date: new Date(start_date),
            end_date: new Date(end_date),
            contest_reference,
          });
          setSelectedservicecatogary({
            value: data.payload.service_category_id,
            label: data.payload.service_category.name,
          });
          setSelectedOptionprovider({
            value: data?.payload?.provider_id,
            label: data?.payload?.supplier?.provider_name,
          });
          setSelectedRadio4(data.payload.currency_id);
          setSelectedRadio1(data.payload.recurring_service);
          setSelectedPDID({
            value: data.payload.producer_id,
            label: data.payload.procedure_type.name,
          });
          setShowLoader(false)
        }
        //   if (data?.error?.message) {
        //     histroy.push('/')
        //     // return toast.error(data?.error?.message)
        //   }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }, [tripID]);

  const handleChangeEMR = (e, field) => {
    if (field === "provider_id") {
      setSelectedOptionprovider(e);
      setFields({ ...fields, [field]: e.value });
    } else if (field === "service_category_id") {
      setSelectedservicecatogary(e);
      setFields({ ...fields, [field]: e.value });
    } else if (field === "producer_id") {
      setFields({ ...fields, [field]: e.value });
      setSelectedPDID(e);
    } else {
      setFields({ ...fields, [field]: e.target.value });
    }
  };

  const handleGeneralInfoChange = (e, field) => {
    if (field === "start_date" || field === "end_date") {
      const setData = {
        ...fields,
        [field]: new Date(e),
      };
      setFields(setData);
    } else {
      setFields({ ...fields, [field]: e.target.value });
    }
  };

  function getpaymentmodeList(params = "") {
    config
      .getAPI({ url: "paymentMode/list", params: {} })
      .then((data) => {
        if (data.payload) {
          setPaymentMode(data.payload.rows);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getServiceCategoryList(params = "") {
    config
      .getAPI({ url: "serviceCategory/list", params: {} })
      .then((data) => {
        if (data.payload) {
          let optionsArr = [];
          data?.payload?.rows.map(async (row) => {
            optionsArr.push({ value: row.id, label: row.name });
          });
          setServiceCategory(optionsArr);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getdepartmentList(params = "") {
    config
      .getAPI({ url: "department/list", params: {} })
      .then((data) => {
        if (data.payload) {
          setDepartment(data.payload.rows);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  const handleSubmitGeneralInfo = async (type) => {
    let resId = fields?.id;
    const includedFields = [
      "physical_code",
      "object_of_the_contract",
      "contract_value",
      "producer_id",
      "payment_id",
      "department_id",
    ];
    const validationResponse = validateForm(fields, includedFields);
    setError(validationResponse?.errors);
    if (resId) {
      setTab(2);
    } else {
      setShowLoader(true);
      if (validationResponse?.isValid) {
        const insertResponse = await config.postAPI({
          url: "contract/update",
          params: {
            ...fields,
            id: tripID,
            currency_id: selectedradio4,
            recurring_service: selectedradio1,
            user_id:Cookies.get('user_id')
          },
        });
        if (insertResponse?.error?.message) {
          setShowLoader(false)
          return toast.error(insertResponse?.error?.message);
        }
        if (tripID)
          if (type === "next") {
            setTab(2);
            return toast.success("Registo criado com sucesso");
          } else {
            setTimeout(() => {
              return toast.success("Registo criado com sucesso");
            }, 200);
            history.push("/contracts/contracts_view");
          }
      }
    }
  };

  const getEvaluationProvider = useCallback(() => {
    config
      .getAPI({ url: "supplier/list", params: {} })
      .then((data) => {
        if (data.payload) {
          let optionsArr = [];
          data?.payload?.rows.forEach((row) => {
            optionsArr.push({ value: row.id, label: row.provider_name });
          });
          setProvider(optionsArr);
        }
        if (data?.error?.message) {
          history.push("/");
          // return toast.error(data?.error?.message)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }, [history]);
  const getproducerList = useCallback(
    (params = "") => {
      config
        .getAPI({ url: "procedureType/list", params: {} })
        .then((data) => {
          if (data.payload) {
            data.payload.rows = data.payload.rows.map((element) => {
              element.value = element.id;
              element.label = element.name;
              return element;
            });
            setProducer(data.payload.rows);
          }
          if (data?.error?.message) {
            history.push("/");
            // return toast.error(data?.error?.message)
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    },
    [history]
  );

  useEffect(() => {
    setShowLoader(true);
    getcontractInfo();
    getpaymentmodeList();
    getServiceCategoryList();
    getdepartmentList();
    getEvaluationProvider();
    getproducerList();
  }, [getEvaluationProvider, getcontractInfo, getproducerList]);

  return (
    <div className="w-full">
      <div
        className="loader-img"
        style={{
          display: showLoader ? "block" : "none",
        }}
      ></div>
      <div className="mb-6">
        <nav aria-label="Progress">
          <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
            {steps1.map((step, stepIdx) => (
              <li key={step.name} className="relative md:flex md:flex-1">
                {step.status === "complete" ? (
                  <a
                    href={step.href}
                    className="group flex w-full items-center"
                  >
                    <span className="flex items-center px-6 py-4 text-base font-medium">
                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-600">
                        <CheckIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                      <span className="ml-4 text-base font-medium text-gray-900">
                        {step.name}
                      </span>
                    </span>
                  </a>
                ) : step.status === "current" ? (
                  <a
                    href={step.href}
                    className="flex items-center px-6 py-4 text-base font-medium"
                    aria-current="step"
                  >
                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-red-600">
                      <span className="text-red-600">{step.id}</span>
                    </span>
                    <span className="ml-4 text-base font-medium text-red-600">
                      {step.name}
                    </span>
                  </a>
                ) : (
                  <a href={step.href} className="group flex items-center">
                    <span className="flex items-center px-6 py-4 text-base font-medium">
                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                        <span className="text-gray-500 group-hover:text-gray-900">
                          {step.id}
                        </span>
                      </span>
                      <span className="ml-4 text-base font-medium text-gray-500 group-hover:text-gray-900">
                        {step.name}
                      </span>
                    </span>
                  </a>
                )}

                {stepIdx !== steps1.length - 1 ? (
                  <>
                    {/* Arrow separator for lg screens and up */}
                    <div
                      className="absolute top-0 right-0 hidden h-full w-5 md:block"
                      aria-hidden="true"
                    >
                      <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 22 80"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0 -2L20 40L0 82"
                          vectorEffect="non-scaling-stroke"
                          stroke="currentcolor"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </>
                ) : null}
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="pt-1">
        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label
              htmlFor="physical_code"
              className="block text-base font-medium text-gray-700"
            >
              Código Físico <span className="mandatory">*</span>
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="physical_code"
                id="physical_code"
                autoComplete="given-name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                onChange={(e) => handleGeneralInfoChange(e, "physical_code")}
                value={fields?.physical_code}
              />
              {error?.physical_code && (
                <span className="text-xs text-red-600 capitalize">
                  {error?.physical_code}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="object_of_the_contract"
              className="block text-base font-medium text-gray-700"
            >
              Objecto do Contrato <span className="mandatory">*</span>
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="object_of_the_contract"
                id="object_of_the_contract"
                autoComplete="given-name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                onChange={(e) =>
                  handleGeneralInfoChange(e, "object_of_the_contract")
                }
                value={fields?.object_of_the_contract}
              />
              {error?.object_of_the_contract && (
                <span className="text-xs text-red-600 capitalize">
                  {error?.object_of_the_contract}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="last_name"
              className="block text-base font-medium text-gray-700"
            >
              Tipo do Procedimento <span className="mandatory">*</span>
            </label>
            <div className="mt-1">
              <Select
                value={selectedPDID}
                placeholder="Selecionar Tipo do Procedimento"
                onChange={(e) => {
                  handleChangeEMR(e, "producer_id");
                }}
                options={producer}
                isSearchable={true}
              />

              {error?.producer_id && (
                <span className="text-xs text-red-600 capitalize">
                  {error?.producer_id}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first_name"
              className="block text-base font-medium text-gray-700"
            >
              Nome do Fornecedor <span className="mandatory">*</span>
            </label>
            <div className="mt-1">
              <Select
                value={selectedOptionprovider}
                placeholder="Selecionar Nome do Fornecedor"
                onChange={(e) => handleChangeEMR(e, "provider_id")}
                options={provider}
              />
              {error?.provider_id && (
                <span className="text-xs text-red-600 capitalize">
                  {error?.provider_id}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="contract_value"
              className="block text-base font-medium text-gray-700"
            >
              Valor do Contrato <span className="mandatory">*</span>
            </label>
            <div className="mt-1">
              <input
                type="number"
                name="contract_value"
                id="contract_value"
                autoComplete="given-name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                onChange={(e) => handleGeneralInfoChange(e, "contract_value")}
                value={fields?.contract_value}
              />
              {error?.contract_value && (
                <span className="text-xs text-red-600 capitalize">
                  {error?.contract_value}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="last_name"
              className="block text-base font-medium text-gray-700 mb-2"
            >
              Tipo de Moeda
            </label>
            <div className="mt-1">
              <RadioGroup value={selectedradio4} onChange={setSelectedRadio4}>
                <RadioGroup.Label className="sr-only">
                  {" "}
                  Privacy setting{" "}
                </RadioGroup.Label>
                <div className="-space-y-px sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                  {currency.map((setting, settingIdx) => (
                    <RadioGroup.Option
                      key={setting.currency_symbol}
                      value={setting.id}
                      className={({ checked }) =>
                        classNames(
                          settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                          settingIdx === currency.length - 1
                            ? "rounded-bl-md rounded-br-md"
                            : "",
                          checked
                            ? "bg-white border-white z-0"
                            : "border-white",
                          "relative border flex cursor-pointer focus:outline-none"
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span
                            className={classNames(
                              checked
                                ? "bg-red-700 border-transparent"
                                : "bg-white border-gray-300",
                              active ? "ring-2 ring-offset-2 ring-red-700" : "",
                              "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                            )}
                            aria-hidden="true"
                          >
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <span className="ml-3 flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className={classNames(
                                checked ? "text-red-700" : "text-gray-900",
                                "block text-sm font-medium"
                              )}
                            >
                              {setting.currency_symbol}
                            </RadioGroup.Label>
                          </span>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
              {error?.selectedradio4 && (
                <span className="text-xs text-red-600 capitalize">
                  {error?.selectedradio4}
                </span>
              )}
            </div>
          </div>

          {selectedradio.name === "SIM" ? (
            <>
              <div className="sm:col-span-6">
                <div className="rounded-lg border border-gray-200 bg-gray-50 p-6">
                  <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="last_name"
                        className="block text-base font-medium text-gray-700"
                      >
                        Nome do Fornecedor
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="first_name"
                          id="first_name"
                          autoComplete="given-name"
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="last_name"
                        className="block text-base font-medium text-gray-700"
                      >
                        Valor do Contrato
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="first_name"
                          id="first_name"
                          autoComplete="given-name"
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="last_name"
                        className="block text-base font-medium text-gray-700 mb-2"
                      >
                        Tipo de Moeda
                      </label>
                      <div className="mt-1">
                        <RadioGroup
                          value={selectedradio5}
                          onChange={setSelectedRadio5}
                        >
                          <RadioGroup.Label className="sr-only">
                            {" "}
                            Privacy setting{" "}
                          </RadioGroup.Label>
                          <div className="-space-y-px sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            {currency_types.map((setting, settingIdx) => (
                              <RadioGroup.Option
                                key={setting.name}
                                value={setting}
                                className={({ checked }) =>
                                  classNames(
                                    settingIdx === 0
                                      ? "rounded-tl-md rounded-tr-md"
                                      : "",
                                    settingIdx === currency_types.length - 1
                                      ? "rounded-bl-md rounded-br-md"
                                      : "",
                                    checked
                                      ? "bg-transparent z-0"
                                      : "border-none",
                                    "relative flex cursor-pointer focus:outline-none"
                                  )
                                }
                              >
                                {({ active, checked }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        checked
                                          ? "bg-red-700 border-transparent"
                                          : "bg-transparent border-gray-300",
                                        active
                                          ? "ring-2 ring-offset-2 ring-red-700"
                                          : "",
                                        "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                      )}
                                      aria-hidden="true"
                                    >
                                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                                    </span>
                                    <span className="ml-3 flex flex-col">
                                      <RadioGroup.Label
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? "text-red-700"
                                            : "text-gray-900",
                                          "block text-sm font-medium"
                                        )}
                                      >
                                        {setting.name}
                                      </RadioGroup.Label>
                                    </span>
                                  </>
                                )}
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      </div>
                    </div>
                    <div className="sm:col-span-6 flex justify-end">
                      <button
                        type="button"
                        className="ml-3 mt-0 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-skin-primary hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 xl-admin-btn"
                      >
                        <TrashIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                      <button
                        type="button"
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-skin-primary hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 xl-admin-btn"
                      >
                        <PlusIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {selectedradio6.name === "SIM" ? (
            <>
              <div className="sm:col-span-6">
                <div className="rounded-lg border border-gray-200 bg-gray-50 p-6">
                  <div className="mx-auto lg:flex lg:items-center lg:justify-between">
                    <div className="tracking-tight text-gray-900 w-full">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first_name"
                            className="block text-base font-medium text-gray-700"
                          >
                            Nome do Fornecedor
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-5">
                      <div className="ml-3 h-4 inline-flex justify-center px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn invisible">
                        Add
                      </div>
                    </div>
                  </div>
                  <div className="mx-auto lg:flex lg:items-center lg:justify-between">
                    <div className="tracking-tight text-gray-900 w-full">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-6">
                          <div className="mt-1">
                            <select
                              id="country"
                              name="country"
                              autoComplete="country-name"
                              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base"
                            >
                              {/* <option> -- Selecionar Membros da Comissão -- </option> */}
                              <option>Fornecedor 1</option>
                              <option>Fornecedor 2</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-5">
                      <div className="ml-3 mt-0 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-skin-primary hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 xl-admin-btn">
                        <PlusIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mx-auto lg:flex lg:items-center lg:justify-between mt-3">
                    <div className="tracking-tight text-gray-900 w-full">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-6">
                          <div className="mt-1">
                            <select
                              id="country"
                              name="country"
                              autoComplete="country-name"
                              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base"
                            >
                              {/* <option> -- Selecionar Membros da Comissão -- </option> */}
                              <option>Fornecedor 1</option>
                              <option>Fornecedor 2</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-5">
                      <div className="ml-3 mt-0 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-skin-primary hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 xl-admin-btn">
                        <TrashIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div className="sm:col-span-3">
            <label
              htmlFor="first_name"
              className="block text-base font-medium text-gray-700"
            >
              Formas de Pagamento <span className="mandatory">*</span>
            </label>
            <div className="mt-1">
              <select
                id="country"
                name="country"
                autoComplete="country"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                onChange={(e) => {
                  handleChangeEMR(e, "payment_id");
                }}
                value={fields?.payment_id}
              >
                <option>--- Selecionar Formas de Pagamento ---</option>
                {paymentmode?.map((roleData) => {
                  return (
                    <option value={roleData?.id} key={roleData?.id}>
                      {roleData?.name}
                    </option>
                  );
                })}
              </select>
              {error?.payment_id && (
                <span className="text-xs text-red-600 capitalize">
                  {error?.payment_id}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="last_name"
              className="block text-base font-medium text-gray-700"
            >
              Categoria do serviço <span className="mandatory">*</span>
            </label>
            <div className="mt-1">
              <Select
                value={selectedservicecatogary}
                placeholder="Selecionar Categoria do serviço"
                onChange={(e) => handleChangeEMR(e, "service_category_id")}
                options={serviceCategory}
              />
              {error?.service_category_id && (
                <span className="text-xs text-red-600 capitalize">
                  {error?.service_category_id}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first_name"
              className="block text-base font-medium text-gray-700"
            >
              Área Beneficiária <span className="mandatory">*</span>
            </label>
            <div className="mt-1">
              <select
                id="country"
                name="country"
                autoComplete="country"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                onChange={(e) => {
                  handleChangeEMR(e, "department_id");
                }}
                value={fields?.department_id}
              >
                <option>--- Selecionar Área Beneficiária ---</option>
                {department?.map((roleData) => {
                  return (
                    <option value={roleData?.id} key={roleData?.id}>
                      {roleData?.name}
                    </option>
                  );
                })}
              </select>
              {error?.department_id && (
                <span className="text-xs text-red-600 capitalize">
                  {error?.department_id}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first_name"
              className="block text-base font-medium text-gray-700"
            >
              Data de início <span className="mandatory">*</span>
            </label>
            <div className="mt-1 relative">
              <DatePicker
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onChange={(e) => {
                  handleGeneralInfoChange(e, "start_date");
                }}
                value={fields?.start_date}
                format={"dd-MM-yy"}
              />
              {error?.start_date && (
                <span className="text-xs text-red-600 capitalize">
                  {error?.start_date}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first_name"
              className="block text-base font-medium text-gray-700"
            >
              Data de Expiração <span className="mandatory">*</span>
            </label>
            <div className="mt-1 relative">
              <DatePicker
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onChange={(e) => {
                  handleGeneralInfoChange(e, "end_date");
                }}
                value={fields?.end_date}
                format={"dd-MM-yy"}
              />
              {error?.end_date && (
                <span className="text-xs text-red-600 capitalize">
                  {error?.end_date}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="contest_reference"
              className="block text-base font-medium text-gray-700"
            >
              Referência do Concurso
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="contest_reference"
                id="contest_reference"
                autoComplete="given-name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                onChange={(e) =>
                  handleGeneralInfoChange(e, "contest_reference")
                }
                value={fields?.contest_reference}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first_name"
              className="block text-base font-medium text-gray-700"
            >
              Observação
            </label>
            <div className="mt-1">
              <textarea
                id="about"
                name="about"
                rows={3}
                className="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                onChange={(e) => handleGeneralInfoChange(e, "observation")}
                value={fields?.observation}
              />
              {error?.observation && (
                <span className="text-xs text-red-600 capitalize">
                  {error?.observation}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
            onClick={() => history.push("/contracts/contracts_view")}
          >
            Cancelar
          </button>

          <button
            type="button"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
            onClick={() => handleSubmitGeneralInfo("")}
          >
            Guardar e Sair
          </button>
          <button
            type="button"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
            onClick={() => handleSubmitGeneralInfo("next")}
          >
            Próximo
          </button>
        </div>
      </div>
    </div>
  );
}

export default Tab1;
