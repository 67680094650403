import React, { useState } from "react";
import MaterialTable from "material-table";
import { TablePagination } from '@material-ui/core';
import tableIcons from "./miui-icons";


function PatchedPagination(props) {
  const {
    ActionsComponent,
    onChangePage,
    onChangeRowsPerPage,
    ...tablePaginationProps
  } = props;

  return (
    <TablePagination
      {...tablePaginationProps}
      // @ts-expect-error onChangePage was renamed to onPageChange
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={(subprops) => {
        const { onPageChange, ...actionsComponentProps } = subprops;
        return (
          // @ts-expect-error ActionsComponent is provided by material-table
          <ActionsComponent
            {...actionsComponentProps}
            onChangePage={onPageChange}
          />
        );
      }}
    />
  );
}

// Table
function DataTable({
  columns,
  data,
  onSelectionChange,
  tableMsg,
  disabledCheckbox,
  showLoader,
}) {
  const [selectedRow] = useState(null);

  let pageSizeOptions = [];
  addPush(50);
  addPush(100);
  addPush(200);
  addPush(500);
  addPush(1000);
  function addPush(number) {
    if (data?.length > number) {
      pageSizeOptions.push(number);
    }
  }
  pageSizeOptions.push(data.length);
  return (
    <MaterialTable
      icons={tableIcons}
      columns={columns}
      data={data}
      className="datatable"
      title={""}
      pagination
      components={{
        Pagination: PatchedPagination,
      }}
      paginationMode="server"
      isLoading={showLoader}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <div className="block mx-auto py-10">
              <img
                className="norecord mx-auto py-5 h-[100px]"
                src="/img/no-record.svg"
                alt="no-record"
              />{" "}
              Nenhum registo encontrado
            </div>
          ),
        },
        toolbar: {
          nRowsSelected: "{0} registo (s) seleccionados",
        },
      }}
      options={{
        emptyRowsWhenPaging: false,
        search: true,
        selection: disabledCheckbox ? false : true,
        sorting: true,
        pageSize: 10,
        pageSizeOptions: pageSizeOptions,
        rowStyle: (rowData) => ({
          backgroundColor:
            selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
        }),
      }}
      onSelectionChange={onSelectionChange}
    />
  );
}

export default DataTable;
