import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dialog, Menu, Transition, Tab } from "@headlessui/react";
import LeftNavigation from "../../components/LeftNavigation/leftNavigation.js";
import Header from "../../components/Header/Header";
import ActionButton from "../../components/Other/ActionButton";
import { toast } from "react-toastify";
import { pluck, find } from "underscore";
import DeleteModel from "../../components/Other/DeleteModel";
import DataTable from "../../components/Table/MDataTable";
import { ClockIcon, CogIcon, CreditCardIcon, DocumentChartBarIcon, HomeIcon, QuestionMarkCircleIcon, ScaleIcon, ShieldCheckIcon, UserGroupIcon, XMarkIcon, PencilIcon, EyeIcon, DocumentTextIcon, PrinterIcon, CheckIcon } from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import config from "../../utils/config.js";
import { validateForm } from "../../utils/common.js";
import Cookies from "js-cookie";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import MultiSelect from "@kenshooui/react-multi-select";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];

const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Roles() {
  let history = useHistory();
  let [tripID, setTripID] = useState();
  const printInvoiceRef = useRef(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [details, setDetails] = useState();
  const [reason, setReason] = useState();
  const [error, setError] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [ids, setIds] = useState([]);
  const [docsName, setDocsName] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [showModal1, setShowModal1] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [PDFData, setPDFData] = useState({});
  const [open2, setOpen2] = useState(false);
  const [tripRequestedDocument, setTripRequestedDocument] = useState([]);
  const [tabEvaluationData, setTabEvaluationData] = useState([]);
  const [qualificationCandidateData, setQualificationCandidateData] = useState([]);
  const [competitorData, setCompetitorData] = useState([]);
  const [documentPDF, setDocumentPDF] = useState("false");
  const [selectedQC, setSelectedQC] = useState([]);

  const btnGroups = [
    { title: "Novo", type: "addNew", path: "/requests/NewRequest" },
    { title: "Eliminar", type: "delete", path: "/RequestsReceived" },
  ];

  async function handleAction(type, id = "") {
    if (ids.length < 1 && id === "") {
      return toast.error("Selecione pelo menos um registro");
    } else if (type === "delete") {
      setShowModal(true);
      return;
    }
    const sendData = {
      id: ids,
      fields: {
        status:
          type === "makeActive"
            ? "Active"
            : type === "makeInactive"
              ? "Inactive"
              : type,
      },
    };

    const response = await config.postAPI({
      url: "tripRequest/update",
      params: sendData,
    });
    if (!response?.payload?.success) {
      return toast.error("Falha na inserção do registro");
    } else {
      getList();
    }
    setIds([]);
    setDocsName([]);
    return toast.success("Registo actualizado com sucesso");
  }

  async function handleDelete() {
    try {
      const sendData = {
        ids,
        docsName,
        type: "delete",
      };
      const response = await config.deleteAPI({
        url: "tripRequest/delete",
        params: { id: sendData?.ids, docsName: docsName?.docsName },
      });

      if (!response?.payload?.success) {
        return toast.error("Falha na inserção do registro");
      }
      setShowModal(false);
      getList();
      setIds([]);
      setDocsName([]);
      return toast.success("Registo removido com sucesso");
    } catch (error) {
      console.log("error", error);
    }
  }

  function handleCancel() {
    setShowModal(false);
  }

  const onSelectionChange = (e) => {
    let idArr = pluck(e, "id");
    let docsArr = pluck(e, "internal_communication_document");
    setIds(idArr);
    setDocsName(docsArr);
  };

  function getList(params = "") {
    config
      .getAPI({ url: "tripRequest/list", params: { area_id: parseInt(Cookies.get("areaID")), recipient: "DAF" } })
      .then((data) => {
        if (data.payload) {
          setData(data.payload.rows);
          setShowLoader(false);
        }
        if (data?.error?.message) {
          history.push("/");
          // return toast.error(data?.error?.message)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  useEffect(() => {
    setShowLoader(true);
    getList();
  }, []);

  const columns = [
    { title: "Número da CI", field: "ci_number" },
    {
      title: "Área solicitante",
      field: "requester_area",
    },
    {
      title: "Assunto",
      field: "subject_matter",
      // render: (rowData) => <span>{rowData?.recipient?.recipient}</span>,
    },
    { title: "Tipo de procedimento", field: "procedure_type.name" },
    {
      title: "Data da solicitação",
      field: "contract_start_date",
      render: (rowData) => (
        <span>{new Date(rowData?.createdAt).toLocaleDateString()}</span>
      ),
    },
    {
      title: "Estado",
      field: "status",
      render: (rowData) => (
        <span className={getClassName(rowData.status)}>
          {" "}
          {changeinPortuguese(rowData.status)}
        </span>
      ),
    },
    {
      title: "Acções",
      render: (rowData) => (
        <div className="flex">
          {
            rowData.status !== "Approved" ?
              <Link
                className="text-indigo-600 hover:text-indigo-900 mr-4 mt-1"
                to={`/requests/RequestEdit/${rowData?.id}`}
              >
                <PencilIcon
                  className="flex-shrink-0 h-5 w-5 red-link"
                  aria-hidden="true"
                />
              </Link> : ""}
          <button
            className="text-indigo-600 hover:text-indigo-900 focus:outline-none mr-4"
            onClick={() => info(rowData.id, rowData.ci_number)}
          >
            <EyeIcon
              className="flex-shrink-0 h-5 w-5 red-link"
              aria-hidden="true"
            />
          </button>

          <button className="text-indigo-600 hover:text-indigo-900 focus:outline-none mr-4">
            <PrinterIcon
              className="flex-shrink-0 h-5 w-5 red-link"
              aria-hidden="true"
              onClick={() => downloadPdf(rowData.id)}
            />
          </button>

          <div className="inline-block self-center relative top-1">
            {
              rowData.status == "Pending" || rowData.status == "In_Preparation_Of_Parts" ?
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon
                        className="h-6 w-6 red-link"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex px-4 py-2 text-sm"
                              )}
                              onClick={() =>
                                handleStatusUpdate(rowData?.id, "Approved")
                              }
                            >
                              <CheckIcon
                                className="mr-3 h-5 w-5 red-link"
                                aria-hidden="true"
                              />
                              <span>Aprovar</span>
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                setTripID(rowData?.id);
                                setShowModal1(true);
                              }}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex px-4 py-2 text-sm"
                              )}
                            >
                              <XMarkIcon
                                className="mr-3 h-5 w-5 red-link"
                                aria-hidden="true"
                              />
                              <span>Rejeitar</span>
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                : ""}
          </div>
        </div>
      ),
    },
  ];

  const columns4 = [
    {
      title: "Documentos de Qualificação",
      field: "qualification_documents.name",
    },
    {
      title: "Estado",
      field: "status",
      render: (rowData) => (
        <span className={getClassName(rowData.status)}>
          {changeinPortuguese(rowData.status)}
        </span>
      ),
    },
  ];

  const documentList = async (trip_request_id) => {
    config
      .getAPI({ url: "tripRequestDocument/list", params: { trip_request_id } })
      .then((data) => {
        if (data.payload) {
          setTripRequestedDocument(data.payload.rows);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const getTabEvaluationData = async (trip_request_id) => {
    try {
      const response = await config.getAPI({
        url: "contest/list-evaluation-proposal-data",
        params: { trip_id: trip_request_id }
      });
      setTabEvaluationData(response.payload);
    } catch (error) {
      console.log("error", error);
    }
  }

  const getQualificationCandidateData = async (ci_number) => {
    try {
      const response = await config.getAPI({
        url: "contest/list-qualification-candidate-docs",
        params: { ci_number }
      });
      setQualificationCandidateData(response.payload?.tripQCIDs);
    } catch (error) {
      console.log("error", error);
    }
  }

  const getCompetitorData = async (id) => {
    try {
      const response = await config.getAPI({
        url: "tripCompetitor/getdatabyId",
        params: { trip_request_id: id }
      });
      setCompetitorData(response.payload?.response?.rows);
    } catch (error) {
      console.log("error", error);
    }
  }

  function info(id, ci_number) {
    const selectedData = find(data, function (num) {
      return num.id === id;
    });
    setDetails(selectedData);
    documentList(id)
    setOpen(true);
    getTabEvaluationData(id);
    getQualificationCandidateData(ci_number);
    getCompetitorData(id);
  }

  const getClassName = (status) => {
    if (status === "execution") {
      return "inline-flex items-center w-max px-3 py-0.5 rounded-full text-base font-normal bg-indigo-200 text-indigo-800";
    } else if (
      status === "delayed" ||
      status === "expired" ||
      status === "Rejected"
    ) {
      return "inline-flex items-center w-max px-3 py-0.5 rounded-full text-base font-normal bg-red-200 text-red-800";
    } else if (status === "Approved") {
      return "inline-flex items-center w-max px-3 py-0.5 rounded-full text-base font-normal bg-lime-200 text-lime-800";
    } else {
      return "inline-flex items-center w-max px-3 py-0.5 rounded-full text-base font-normal bg-yellow-200 text-yellow-800";
    }
  };

  const changeinPortuguese = (status) => {
    if (status === "Active") {
      return (status = "Ativo");
    } else if (status === "Approved") {
      return (status = "Aprovado");
    } else if (status === "Rejected") {
      return (status = "Rejeitada");
    } else if (status === "Inactive") {
      return (status = "Inativa");
    } else if (status === "Pending") {
      return (status = "Pendente");
    } else if (status === "In_Preparation_Of_Parts") {
      return (status = "Em Prep. Peças");
    }
  };

  function updateStatus(params) {
    config
      .postAPI({ url: "tripRequest/updateStatus", params })
      .then((data) => {
        getList();
        return toast.success("Atualização de status com sucesso");
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  const handleStatusUpdate = async (id, status) => {
    let params = { trip_request_id: id, status };
    if (status === "Rejected") {
      params = { ...params, reason };
      const includedFields = ["reason"];
      const validationResponse = validateForm({ reason }, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        setShowModal1(false);
        updateStatus(params);
      }
    } else {
      updateStatus(params);
    }
  };

  const downloadPdf = async (id) => {
    try {
      const pdf_data = find(data, function (num) { return num.id == id });
      const areaData = await config.getAPI({
        url: "department/getdatabyId",
        params: { id: Cookies.get('areaID') }
      });
      const cat_administrator = await config.getAPI({
        url: "categoryAdministrator/getdatabyId",
        params: { id: areaData?.payload?.person?.category_administrator_id }
      });
      setPDFData({ ...pdf_data, manager_name: areaData?.payload?.manager, manager_area_description: areaData?.payload?.description, administrator: areaData?.payload?.person?.name, administrator_category: cat_administrator?.payload?.name });

      setTimeout(() => {
        printInvoice();
      }, 100);
    } catch (error) {
      console.log("error", error);
    }
  }

  const printInvoice = useReactToPrint({
    content: () => printInvoiceRef.current,
  });

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div
          div
          className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col"
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <LeftNavigation />
        </div>
        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1 pb-8">
            <div className="mt-8">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="dark:bg-black">
                  <div className="py-5 flex items-center justify-between">
                    <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
                      Solicitações
                    </h1>
                    <div className="flex items-center gap-3">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="flex items-center rounded-full border-2 py-2 px-2 dark:border-[#00FF00] border-[#EA042B] outline-none">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon
                              className="h-5 w-5 dark:text-white red-text"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-30 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              {btnGroups.map((btn) => {
                                return (
                                  <ActionButton
                                    key={btn.id}
                                    btnTitle={btn.title}
                                    btnType={btn.type}
                                    btnPath={btn.path}
                                    handleAction={handleAction}
                                  />
                                );
                              })}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <DeleteModel
                    mode={showModal}
                    handleDelete={handleDelete}
                    handleCancel={handleCancel}
                  />
                  <div className="dark:bg-black">
                    <div className="flex flex-col">
                      <div className="datatable view_request_wrap">
                        <DataTable
                          columns={columns}
                          data={data}
                          disabledCheckbox={false}
                          onSelectionChange={onSelectionChange}
                          showLoader={showLoader}
                        />
                      </div>
                      {showModal1 ? (
                        <>
                          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative my-6 mx-auto max-w-4xl w-96">
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between pt-5 rounded-t px-3">
                                  <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-medium focus:outline-none"
                                    onClick={() => setShowModal1(false)}
                                  >
                                    <span className="bg-transparent text-gray-400 hover:text-gray-500 h-8 w-8 text-3xl block outline-none focus:outline-none">
                                      ×
                                    </span>
                                  </button>
                                </div>
                                {/*body*/}
                                <div className="relative px-6 flex-auto">
                                  <div className="sm:col-span-6">
                                    <label
                                      htmlFor="about"
                                      className="block text-base font-bold text-gray-900"
                                    >
                                      Número da CI
                                    </label>
                                    <div className="mt-1">
                                      <textarea
                                        id="about"
                                        name="about"
                                        rows={5}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        // defaultValue={reason ? reason : ""}
                                        value={reason ? reason : ""}
                                        onChange={(e) =>
                                          setReason(e.target.value)
                                        }
                                      />
                                    </div>
                                    {error?.reason && (
                                      <span className="text-xs text-red-600 capitalize">
                                        {error?.reason}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 rounded-b">
                                  <button
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                    type="button"
                                    onClick={() => setShowModal1(false)}
                                  >
                                    Cancelar
                                  </button>
                                  <button
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                    type="button"
                                    onClick={() =>
                                      handleStatusUpdate(tripID, "Rejected")
                                    }
                                  >
                                    Submeter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}

                      {showModal2 ? (
                        <>
                          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative my-6 mx-auto max-w-4xl w-96">
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between pt-5 rounded-t px-3">
                                  <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-medium focus:outline-none"
                                    onClick={() => setShowModal2(false)}
                                  >
                                    <span className="bg-transparent text-gray-400 hover:text-gray-500 h-8 w-8 text-3xl block outline-none focus:outline-none">
                                      ×
                                    </span>
                                  </button>
                                </div>
                                <div className="relative px-6 flex-auto">
                                  <div className="sm:col-span-6">
                                    <label
                                      htmlFor="about"
                                      className="block text-base font-bold text-gray-900"
                                    >
                                      Motivo
                                    </label>
                                    <div className="mt-1">
                                      <textarea
                                        id="about"
                                        name="about"
                                        rows={5}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        defaultValue={""}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex items-center justify-end p-6 rounded-b">
                                  <button
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                    type="button"
                                    onClick={() => setShowModal2(false)}
                                  >
                                    Cancelar
                                  </button>
                                  <button
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                    type="button"
                                  >
                                    Submeter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 font-medium text-gray-900 mb-5"
                    >
                      Detalhes da solicitação
                    </Dialog.Title>
                    <div className="pb-3">
                      <Tab.Group as="div">
                        <div className="border-b border-gray-200">
                          <Tab.List className="-mb-px flex space-x-8">
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? "border-red-600 text-red-600"
                                    : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                  "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                )
                              }
                            >
                              Informações gerais
                            </Tab>
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? "border-red-600 text-red-600"
                                    : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                  "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                )
                              }
                            >
                              Suporte da CI
                            </Tab>
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? "border-red-600 text-red-600"
                                    : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                  "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                )
                              }
                            >
                              Critérios de avaliação
                            </Tab>
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? "border-red-600 text-red-600"
                                    : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                  "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                )
                              }
                            >
                              Concorrentes
                            </Tab>
                          </Tab.List>
                        </div>
                        <Tab.Panels as={Fragment}>
                          <Tab.Panel className="mt-6">
                            <h3 className="sr-only">Informações gerais</h3>
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  CI Number
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details && details.ci_number}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  NIC
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details && details.nic_name}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  Área solicitante
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details && details.requester_area}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  Destinatário
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details && details.recipient?.recipient}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  Assunto
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details?.subject_matter}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  Tipo de procedimento
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details?.procedure_type?.name}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  Data da solicitação
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details && details
                                    ? moment(details.request_date).format(
                                      "DD-MM-YYYY"
                                    )
                                    : ""}
                                </dd>
                              </div>
                            </dl>
                          </Tab.Panel>
                          <Tab.Panel className="mt-6">
                            <div className="py-5">
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-2">
                                  {/* <h2 className="text-base font-bold text-gray-500">
                                    Passageiros seleccionados
                                  </h2> */}
                                  <div className="datatable mt-3 datatable_bg_gray">
                                    <div className="px-4 sm:px-6 lg:px-8">
                                      <div className="flex flex-col">
                                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                          <div className="inline-block min-w-full align-middle">
                                            <table className="min-w-full divide-y divide-gray-300">
                                              <thead>
                                                <tr>
                                                  <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-6 md:pl-0"
                                                  >
                                                    Documento
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="py-3.5 px-3 text-left text-base font-semibold text-gray-900"
                                                  >
                                                    Ficheiro
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="py-3.5 px-3 text-left text-base font-semibold text-gray-900"
                                                  >
                                                    Data de registo
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="py-3.5 px-3 text-left text-base font-semibold text-gray-900"
                                                  >
                                                    Acções
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody className="divide-y divide-gray-200">
                                                {
                                                  tripRequestedDocument && tripRequestedDocument.length > 0 ?
                                                    tripRequestedDocument.map((singleElement) => {
                                                      return (
                                                        <>
                                                          <tr key={1}>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-normal text-gray-900 sm:pl-6 md:pl-0">
                                                              {singleElement?.internal_communication?.name}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              {singleElement?.file_name}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              {new Date(singleElement?.createdAt).toLocaleDateString()}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                                <button
                                                                >
                                                                  <DocumentTextIcon className="red-link h-5 w-5" aria-hidden="true" onClick={() => (setDocumentPDF(config.staticPath + "tripRequest/" + singleElement?.trip_request_id + "/" + singleElement?.file_name), setOpen2(true))} />
                                                                </button>
                                                              </td>
                                                            </td>
                                                          </tr>
                                                        </>
                                                      )
                                                    })
                                                    :
                                                    <>
                                                      <tr
                                                        align="center"
                                                      >
                                                        <td
                                                          colspan="4"
                                                          className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-normal text-gray-900 sm:pl-6 md:pl-0">
                                                          {"Nenhum registo encontrado"}
                                                        </td>
                                                      </tr>
                                                    </>
                                                }

                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </dl>
                            </div>
                          </Tab.Panel>

                          <Tab.Panel className="mt-6">
                            <h3 className="sr-only">Critérios de avaliação</h3>
                            {
                              <Tab.Group as="div">
                                <div className="border-b border-gray-200">
                                  <Tab.List className="-mb-px flex space-x-8">
                                    <Tab className={({ selected }) =>
                                      classNames(
                                        selected
                                          ? "border-red-600 text-red-600"
                                          : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                        "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                      )
                                    }
                                    >
                                      Qualificação de Candidatos
                                    </Tab>
                                    <Tab className={({ selected }) =>
                                      classNames(
                                        selected
                                          ? "border-red-600 text-red-600"
                                          : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                        "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                      )
                                    }
                                    >
                                      Avaliação de propostas
                                    </Tab>
                                  </Tab.List>
                                </div>
                                <Tab.Panels as={Fragment}>
                                  <Tab.Panel className="pt-3">
                                    <h3 className="sr-only">
                                      Habilitação de Concorrentes
                                    </h3>
                                    <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none bg-gray-100 px-4 py-4">
                                      <div className="check_none">
                                        <DataTable
                                          columns={columns4}
                                          data={qualificationCandidateData}
                                          disabledCheckbox={false}
                                          onSelectionChange={onSelectionChange} />
                                      </div>
                                      {showModal1 ? (
                                        <>
                                          <div
                                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                            <div className="relative w-auto my-6 mx-auto w-6/12">
                                              <div
                                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                  <h3 className="text-xl font-semibold">
                                                    Documentos de
                                                    qualificação de
                                                    candidados
                                                  </h3>
                                                  <button
                                                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                    onClick={() =>
                                                      setShowModal1(
                                                        false
                                                      )
                                                    }
                                                  >
                                                    <span
                                                      className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none red-link">
                                                      ×
                                                    </span>
                                                  </button>
                                                </div>
                                                <div className="relative p-6 flex-auto">
                                                  <div className="mt-1">
                                                    <MultiSelect items={qualificationCandidateData} messages={{
                                                      searchPlaceholder: "Procurar...",
                                                      noItemsMessage: "Nenhum item...", noneSelectedMessage: "Nenhum seleccionado",
                                                      selectedMessage: "selecionado", selectAllMessage: "Selecionar tudo",
                                                      clearAllMessage: "Limpar tudo", disabledItemsTooltip: "Você só pode selecionar 1 arquivo",
                                                    }}
                                                      selectedItems={selectedQC} itemHeight={50} onChange={(e) =>
                                                        setSelectedQC(
                                                          e
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                        </>
                                      ) : null}
                                    </div>
                                  </Tab.Panel>
                                  <Tab.Panel className="pt-3">
                                    <h3 className="sr-only">
                                      Avaliação de propostas
                                    </h3>

                                    <div className="space-y-8 divide-y divide-gray-200">
                                      <div className="px-0">
                                        <div className="mt-4 flex flex-col">
                                          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                  <thead className="bg-gray-50">
                                                    <tr>
                                                      <th scope="col"
                                                        className="py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-6">
                                                        Critério de
                                                        avaliação
                                                      </th>
                                                      <th scope="col" className="px-3 py-3.5 text-left text-base font-semibold text-gray-900">
                                                        Peso (%)
                                                      </th>
                                                      <th scope="col" className="px-3 py-3.5 text-left text-base font-semibold text-gray-900">
                                                        Atributos
                                                      </th>
                                                      <th scope="col" className="px-3 py-3.5 text-left text-base font-semibold text-gray-900">
                                                        Pontuação
                                                      </th>
                                                      <th scope="col" className="px-3 py-3.5 text-left text-base font-semibold text-gray-900">
                                                        Estado
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="divide-y divide-gray-200 bg-white">
                                                    {tabEvaluationData &&
                                                      tabEvaluationData.length >
                                                      0
                                                      ? tabEvaluationData.map(
                                                        (
                                                          singleData
                                                        ) => {
                                                          return (
                                                            <tr key={singleData.id}>
                                                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base sm:pl-6">
                                                                <div className="flex items-center">
                                                                  <div className="ml-0">
                                                                    <div className="font-medium text-gray-500">
                                                                      {
                                                                        singleData
                                                                          .evaluation_criteria
                                                                          ?.name
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </td>
                                                              <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                                                <div className="text-gray-500">
                                                                  {
                                                                    singleData.weight
                                                                  }
                                                                </div>
                                                              </td>
                                                              <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                                                {singleData.multiAttrName.map(
                                                                  (
                                                                    element
                                                                  ) => {
                                                                    return (
                                                                      <div className="text-gray-500">
                                                                        {
                                                                          element
                                                                        }
                                                                      </div>
                                                                    );
                                                                  }
                                                                )}
                                                              </td>
                                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {singleData.multiPunctionName.map(
                                                                  (
                                                                    elementAttr
                                                                  ) => {
                                                                    return (
                                                                      <div className="text-gray-500">
                                                                        {
                                                                          elementAttr
                                                                        }
                                                                      </div>
                                                                    );
                                                                  }
                                                                )}
                                                              </td>
                                                              <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                                                <span className={getClassName(singleData.status)}>
                                                                  {changeinPortuguese(
                                                                    singleData.status
                                                                  )}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )
                                                      :
                                                      <>
                                                        <tr align="center">
                                                          <td colspan="5"
                                                            className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-normal text-gray-900 sm:pl-6 md:pl-0">
                                                            {"Nenhum registo encontrado"}
                                                          </td>
                                                        </tr>
                                                      </>
                                                    }
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Panel>
                                </Tab.Panels>
                              </Tab.Group>
                            }
                          </Tab.Panel>

                          <Tab.Panel className="mt-6">
                            <div className="py-5">
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-2">
                                  {/* <h2 className="text-base font-bold text-gray-500">
                                      Passageiros seleccionados
                                    </h2> */}
                                  <div className="datatable mt-3 datatable_bg_gray">
                                    <div className="px-4 sm:px-6 lg:px-8">
                                      <div className="flex flex-col">
                                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                          <div className="inline-block min-w-full align-middle">
                                            <table className="min-w-full divide-y divide-gray-300">
                                              <thead>
                                                <tr>
                                                  <th scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-6 md:pl-0">
                                                    Empresa
                                                  </th>
                                                  <th scope="col" className="py-3.5 px-3 text-left text-base font-semibold text-gray-900">
                                                    NIF
                                                  </th>
                                                  <th scope="col" className="py-3.5 px-3 text-left text-base font-semibold text-gray-900">
                                                    Tipo de empresa
                                                  </th>
                                                  <th scope="col" className="py-3.5 px-3 text-left text-base font-semibold text-gray-900">
                                                    E-mail
                                                  </th>
                                                  <th scope="col" className="py-3.5 px-3 text-left text-base font-semibold text-gray-900">
                                                    Endereço
                                                  </th>
                                                  <th scope="col" className="py-3.5 px-3 text-left text-base font-semibold text-gray-900">
                                                    Telemóvel
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody className="divide-y divide-gray-200">
                                                {
                                                  competitorData && competitorData.length > 0 ?
                                                    competitorData.map((singleElement) => {
                                                      return (
                                                        <>
                                                          <tr key={1}>
                                                            <td
                                                              className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-normal text-gray-900 sm:pl-6 md:pl-0">
                                                              {singleElement?.company}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              {singleElement?.tax_number}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              {singleElement?.supplier_type?.name}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              {singleElement?.email}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              {singleElement?.address}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              {singleElement?.phone_number}
                                                            </td>
                                                          </tr>
                                                        </>
                                                      )
                                                    })
                                                    :
                                                    <>
                                                      <tr align="center">
                                                        <td colspan="6"
                                                          className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-normal text-gray-900 sm:pl-6 md:pl-0">
                                                          {"Nenhum registo encontrado"}
                                                        </td>
                                                      </tr>
                                                    </>
                                                }
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </dl>
                            </div>
                          </Tab.Panel>
                        </Tab.Panels>
                      </Tab.Group>
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/*********** Doc generate start here ***********/}
      <div style={{ padding: "30px", display: "none" }}>
        <div ref={printInvoiceRef} style={{ padding: "50px" }}>
          <table style={{ borderCollapse: "collapse", width: "100%", background: "#ffffff" }}>
            <tr style={{ borderBottom: "3px solid #911c1d" }}>
              <td>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    <td valign='bottom' style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", textTransform: "uppercase", fontSize: "22px" }}>Comunicação Interna</td>
                    <td align='right' valign='top' style={{ paddingBottom: "26px" }}><img
                      src="/img/logo-anpg.png"
                      alt=""
                    /></td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ paddingTop: "10px" }}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    <td style={{ width: "70%", padding: "3px 2px 4px 0" }}>
                      <div style={{ background: "#e7e8ea", borderBottom: "1px solid #151515", borderLeft: "1px solid #151515", borderRadius: "0 0 0 20px", paddingBottom: "20px" }}>
                        <div style={{ display: "inline-block", color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", padding: "0 0 0 12px" }}>De:</div>
                        <div style={{ display: "inline-block", color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", padding: "0 0 0 12px" }}>{PDFData?.requester_area}</div>
                      </div>
                    </td>
                    <td style={{ width: "30%", padding: "3px 0 4px 2px" }}>
                      <div style={{ background: "#e7e8ea", borderBottom: "1px solid #151515", borderLeft: "1px solid #151515", borderRadius: "0 0 0 20px", paddingBottom: "20px" }}>
                        <div style={{ display: "inline-block", color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", padding: "0 0 0 12px" }}>Data:</div>
                        <div style={{ display: "inline-block", color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", padding: "0 0 0 12px" }}>{moment(PDFData?.request_date).format("DD-MM-YYYY")}</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "70%", padding: "3px 2px 4px 0" }}>
                      <div style={{ background: "#e7e8ea", borderBottom: "1px solid #151515", borderLeft: "1px solid #151515", borderRadius: "0 0 0 20px", paddingBottom: "20px" }}>
                        <div style={{ display: "inline-block", color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", padding: "0 0 0 12px" }}>Para:</div>
                        <div style={{ display: "inline-block", color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", padding: "0 0 0 12px" }}>{PDFData?.recipient?.recipient}</div>
                      </div>
                    </td>
                    <td style={{ width: "30%", padding: "3px 0 4px 2px" }}>
                      <div style={{ background: "#e7e8ea", borderBottom: "1px solid #151515", borderLeft: "1px solid #151515", borderRadius: "0 0 0 20px", paddingBottom: "20px" }}>
                        <div style={{ display: "inline-block", color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", padding: "0 0 0 12px" }}>N:</div>
                        <div style={{ display: "inline-block", color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", padding: "0 0 0 12px" }}>{PDFData?.ci_number}</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "100%", padding: "3px 2px 4px 0" }} colspan="2">
                      <div style={{ background: "#e7e8ea", borderBottom: "1px solid #151515", borderLeft: "1px solid #151515", borderRadius: "0 0 0 20px", paddingBottom: "20px" }}>
                        <div style={{ display: "inline-block", color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", padding: "0 0 0 12px" }}>Assunto:</div>
                        <div style={{ display: "inline-block", color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", padding: "0 0 0 12px" }}>{PDFData?.subject_matter}</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "100%", padding: "0", color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "12px", }} colspan="2">
                      Ci.GC.ANPG.01.REV.00 de 12/20
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            {/* <tr>
              <td style={{ padding: "90px" }}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    <td style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "18px", fontWeight: "bold", textTransform: "uppercase", padding: "20px 0" }}>AREA SOLICITANTE</td>
                    <td style={{ color: "#cccccc", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", textTransform: "uppercase", padding: "20px 0" }}>DATA</td>
                  </tr>
                  <tr>
                    <td style={{ color: "#cccccc", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", textTransform: "uppercase", padding: "20px 0" }}>ESTINATARIO</td>
                    <td style={{ color: "#cccccc", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", textTransform: "uppercase", padding: "20px 0" }}>NUMERO CI</td>
                  </tr>
                  <tr>
                    <td style={{ color: "#cccccc", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", textTransform: "uppercase", padding: "20px 0" }}>ASSUNTO</td>
                    <td style={{ color: "#cccccc", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", textTransform: "uppercase", padding: "20px 0" }}>&nbsp;</td>
                  </tr>
                </table>
              </td>
            </tr> */}
            <tr>
              <td style={{ paddingLeft: "40%", paddingRight: "90px" }}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    <td style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", fontWeight: "bold", textAlign: "center" }}><strong>VISTO</strong></td>
                  </tr>
                  <tr>
                    <td style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", textAlign: "center", borderBottom: "1px solid #000000", paddingTop: "12px" }}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", textAlign: "center", paddingTop: "12px" }}>{PDFData?.administrator}</td>
                    {/* <td style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", textAlign: "center", paddingTop: "12px" }}>Administrador</td> */}
                  </tr>
                  <tr>
                    <td style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", textAlign: "center", }}>{PDFData?.administrator_category}</td>
                    {/* <td style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", textAlign: "center", }}>Categoria Administrador</td> */}
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ padding: "40px 90px" }}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    {
                      PDFData?.description ?
                        <td style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "22px" }}>{PDFData?.description?.replace(/<[^>]*>?/gm, "") || ""}</td>
                        : ""
                    }
                    {/* <td style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", textTransform: "uppercase", fontSize: "22px" }}>CORPO CI</td> */}
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ padding: "80px 60px" }}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    <td style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", paddingTop: "12px" }}>Atenciosamente,</td>
                  </tr>
                  <tr>
                    <td style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", fontWeight: "bold", paddingTop: "20px" }}>{PDFData?.manager_name}</td>
                    {/* <td style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", fontWeight: "bold", paddingTop: "20px", textTransform: "uppercase" }}>NOME GESTOR</td> */}
                  </tr>
                  <tr>
                    <td style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px" }}>{PDFData?.manager_area_description}</td>
                    {/* <td style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px", textTransform: "uppercase" }}>DESCRICAO AREA DO GESTOR</td> */}
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td style={{ padding: "0" }}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    <td style={{ padding: "0" }}>
                      <table style={{ borderCollapse: "collapse", width: "100%" }}>
                        <tr>
                          <td>
                            <table style={{ borderCollapse: "collapse", width: "100%" }}>
                              <tr>
                                <td style={{}}>
                                  <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                    <tr>
                                      <td><img style={{ width: "26px" }}
                                        src="/img/facebook-inc.svg"
                                        alt=""
                                      /></td>
                                      <td>
                                        <span style={{ fontSize: "11px", color: "#000000", lineHeight: "100%", paddingLeft: "10px", display: "inline-block" }}>Lorem Ipsum</span>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                                <td style={{ width: "33.33%" }}>
                                  <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                    <tr>
                                      <td><img style={{ width: "26px" }}
                                        src="/img/instagram-inc.svg"
                                        alt=""
                                      /></td>
                                      <td>
                                        <span style={{ fontSize: "11px", color: "#000000", lineHeight: "100%", paddingLeft: "10px", display: "inline-block" }}>Lorem Ipsum</span>
                                      </td>
                                    </tr>
                                  </table>
                                  {/* <span style={{}}>
                              <img style={{ width: "26px", display: "inline-block" }}
                                src="/img/instagram-inc.svg"
                                alt=""
                              />
                            </span>
                            <span style={{ fontSize: "12px", color: "#000000", lineHeight: "100%", paddingLeft: "10px", display: "inline-block" }}>Lorem Ipsum</span> */}
                                </td>
                                <td style={{ width: "33.33%" }}>
                                  <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                    <tr>
                                      <td><img style={{ width: "26px" }}
                                        src="/img/youtube-inc.svg"
                                        alt=""
                                      /></td>
                                      <td>
                                        <span style={{ fontSize: "11px", color: "#000000", lineHeight: "100%", paddingLeft: "10px", display: "inline-block" }}>Lorem Ipsum</span>
                                      </td>
                                    </tr>
                                  </table>
                                  {/* <span style={{}}>
                              <img style={{ width: "26px", display: "inline-block" }}
                                src="/img/youtube-inc.svg"
                                alt=""
                              />
                            </span>
                            <span style={{ fontSize: "12px", color: "#000000", lineHeight: "100%", paddingLeft: "10px", display: "inline-block" }}>Lorem Ipsum</span> */}
                                </td>
                              </tr>
                              {/* <tr>
                          <td style={{ color: "#000000", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px" }}>Página <strong>1</strong> de <strong>2</strong> </td>
                        </tr> */}
                            </table>
                          </td>
                          <td>
                            <table style={{ borderCollapse: "collapse", width: "100%" }}>
                              <tr>
                                <td align='right'>

                                  <span style={{ borderTop: "3px solid #911c1d", fontSize: "12px", color: "#000000", lineHeight: "100%", fontFamily: "Arial, Helvetica, sans-serif", paddingTop: "10px" }}>Lorem Ipsum is simply dummy text</span>
                                </td>
                              </tr>
                              <tr>
                                <td align='right' style={{ fontFamily: "Arial, Helvetica, sans-serif", fontSize: "12px", color: "#000000", lineHeight: "100%", }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</td>
                              </tr>
                              <tr>
                                <td align='right' style={{ fontFamily: "Arial, Helvetica, sans-serif", fontSize: "12px", color: "#000000", lineHeight: "100%", paddingTop: "4px" }}>
                                  <span>C.P.: 3279</span> | <span>Tel.: (+244) 226 428 000</span> | <span>E-mail: geral@anpg.co.ao</span> | <span>Website: WWW.anpg.co.ao</span>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </div>
      {/*********** Doc generate end here ***********/}

      <Transition.Root show={open2} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen2}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100"
              leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child as={Fragment} enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpen2(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900 mb-5">
                      {/* Comprovativo */}
                    </Dialog.Title>
                    <div className="py-5">
                      {documentPDF !== "" && (
                        <iframe style={{ height: "45vh", display: "block" }} src={documentPDF} title="document"
                          className="w-4/6 mx-auto mt-5" />
                      )}
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

    </>
  );
}
