import Cookies from "js-cookie";

export const validateForm = (formBody, requiredField) => {
  const fields = { ...formBody };
  let errors = {};
  let formIsValid = true;
  if (requiredField.includes("documentTypeID")) {
    if (!fields["documentTypeID"]) {
      formIsValid = false;
      errors["documentTypeID"] = "Por favor, seleccione o  document type";
    }
  }

  if (requiredField.includes("identificationNumber")) {
    if (!fields["identificationNumber"]) {
      formIsValid = false;
      errors["identificationNumber"] =
        "Por favor, insira o Identification Document Number";
    }
  }
  if (requiredField.includes("issueDate")) {
    if (!fields["issueDate"]) {
      formIsValid = false;
      errors["issueDate"] = "Por favor, seleccione o  issue date";
    }
  }
  if (requiredField.includes("expireDate")) {
    if (!fields["expireDate"]) {
      formIsValid = false;
      errors["expireDate"] = "Por favor, seleccione o  expire date";
    }
  }
  if (requiredField.includes("cardCheck")) {
    if (!fields["cardCheck"]) {
      formIsValid = false;
      errors["cardCheck"] = "Por favor, seleccione o  Corporate Card Check";
    }
  }
  if (requiredField.includes("SAPnumber")) {
    if (!fields["SAPnumber"]) {
      formIsValid = false;
      errors["SAPnumber"] = "Por favor, insira o SAP Number";
    }
  }
  if (requiredField.includes("dispatch_number")) {
    if (!fields["dispatch_number"]) {
      formIsValid = false;
      errors["dispatch_number"] = "Por favor, insira o Despacho";
    }
  }
  if (requiredField.includes("contest_reference")) {
    if (!fields["contest_reference"]) {
      formIsValid = false;
      errors["contest_reference"] = "Por Favor, Seleccione O do Concurso";
    }
  }
  if (requiredField.includes("sap_number")) {
    if (!fields["sap_number"]) {
      formIsValid = false;
      errors["sap_number"] = "Por favor, insira o SAP Number";
    }
  }
  if (requiredField.includes("area")) {
    if (!fields["area"]) {
      formIsValid = false;
      errors["area"] = "Por favor, insira o area";
    }
  }
  if (requiredField.includes("title")) {
    if (!fields["title"]) {
      formIsValid = false;
      errors["title"] = "Por favor, insira o title";
    }
  }

  if (requiredField.includes("name")) {
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Por favor, insira o nome";
    }
  }
  if (requiredField.includes("full_name")) {
    if (!fields["full_name"]) {
      formIsValid = false;
      errors["full_name"] = "Por favor, insira o nome";
    }
  }

  if (requiredField.includes("ticketCategoryID")) {
    if (!fields["ticketCategoryID"]) {
      formIsValid = false;
      errors["ticketCategoryID"] = "Por favor, seleccione o  ticketCategory";
    }
  }
  if (requiredField.includes("ruleType")) {
    if (!fields["ruleType"]) {
      formIsValid = false;
      errors["ruleType"] = "Por favor, seleccione o  ruleType";
    }
  }
  if (requiredField.includes("ruleName")) {
    if (!fields["ruleName"]) {
      formIsValid = false;
      errors["ruleName"] = "Por favor, seleccione o  ruleName";
    }
  }
  if (requiredField.includes("hotelCategoryID")) {
    if (!fields["hotelCategoryID"]) {
      formIsValid = false;
      errors["hotelCategoryID"] = "Por favor, seleccione o  hotelCategory";
    }
  }
  if (requiredField.includes("transferCategoryID")) {
    if (!fields["transferCategoryID"]) {
      formIsValid = false;
      errors["transferCategoryID"] =
        "Por favor, seleccione o  transferCategory";
    }
  }

  if (requiredField.includes("agentOccupation")) {
    if (!fields["agentOccupation"]) {
      formIsValid = false;
      errors["agentOccupation"] = "Por favor, seleccione o  agentOccupation";
    }
  }
  if (requiredField.includes("department_id")) {
    if (!fields["department_id"]) {
      formIsValid = false;
      errors["department_id"] = "Por favor, seleccione o  department";
    }
  }
  if (requiredField.includes("serviceType")) {
    if (!fields["serviceType"]) {
      formIsValid = false;
      errors["serviceType"] = "Por favor, seleccione o  service type";
    }
  }
  if (requiredField.includes("roleID")) {
    if (!fields["roleID"]) {
      formIsValid = false;
      errors["roleID"] = "Por favor, seleccione o  role";
    }
  }

  if (requiredField.includes("email")) {
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Por favor, insira o email";
    } else {
      const lastAtPos = fields["email"].lastIndexOf("@");
      const lastDotPos = fields["email"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Por favor, insira o valid email address";
      }
    }
  }
  if (requiredField.includes("evalution_comiitee_mail")) {
    if (!fields["evalution_comiitee_mail"]) {
      formIsValid = false;
      errors["evalution_comiitee_mail"] = "Por favor, insira o E-mail da CA";
    } else {
      const lastAtPos = fields["evalution_comiitee_mail"].lastIndexOf("@");
      const lastDotPos = fields["evalution_comiitee_mail"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["evalution_comiitee_mail"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["evalution_comiitee_mail"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Por favor, insira o valid email address";
      }
    }
  }

  if (requiredField.includes("mobile")) {
    if (!fields["mobile"]) {
      formIsValid = false;
      errors["mobile"] = "Por favor, insira o mobile";
    } else {
      if (fields["mobile"].length < 7 || fields["mobile"].length > 11) {
        formIsValid = false;
        errors["mobile"] = "Mobile Number must be 7 to 11 digits";
      }
    }
  }

  if (requiredField.includes("password")) {
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Por favor, insira o senha";
    } else {
      if (fields["password"].length < 6) {
        formIsValid = false;
        errors["password"] = "Password must be 6 characters long";
      }
    }
  }

  if (requiredField.includes("cpassword")) {
    if (!fields["cpassword"]) {
      formIsValid = false;
      errors["cpassword"] = "Por favor, insira o Confirme sua senha";
    }

    if (fields["password"] !== fields["cpassword"]) {
      formIsValid = false;
      errors["cpassword"] = "Senha e confirmação de senha não correspondem.";
    }
  }

  if (requiredField.includes("status")) {
    if (!fields["status"]) {
      formIsValid = false;
      errors["status"] = "Por favor, seleccione o estado";
    }
  }

  if (requiredField.includes("requester_area")) {
    if (!fields["requester_area"]) {
      formIsValid = false;
      errors["requester_area"] = "Por favor, insira o Area solicitante";
    }
  }
  if (requiredField.includes("recipient_id")) {
    if (!fields["recipient_id"]) {
      formIsValid = false;
      errors["recipient_id"] = "Por favor, insira o Destinatário";
    }
  }
  if (requiredField.includes("subject_matter")) {
    if (!fields["subject_matter"]) {
      formIsValid = false;
      errors["subject_matter"] = "Por favor, insira o Assunto";
    }
  }
  if (requiredField.includes("procedure_type_id")) {
    if (!fields["procedure_type_id"]) {
      formIsValid = false;
      errors["procedure_type_id"] =
        "Por favor, seleccione o Tipo de procedimento";
    }
  }

  if (requiredField.includes("recipient")) {
    if (!fields["recipient"]) {
      formIsValid = false;
      errors["recipient"] = "Por favor, insira o Destinatário";
    }
  }

  if (requiredField.includes("serviceDescription")) {
    if (!fields["serviceDescription"]) {
      formIsValid = false;
      errors["serviceDescription"] = "Por favor, insira o service description";
    }
  }

  if (requiredField.includes("current_order")) {
    if (!fields["current_order"]) {
      formIsValid = false;
      errors["current_order"] = "Por favor, insira o Ordem actual";
    }
  }
  if (requiredField.includes("competition_type")) {
    if (!fields["competition_type"]) {
      formIsValid = false;
      errors["competition_type"] = "Por favor, Selecione o competitionv type";
    }
  }
  if (requiredField.includes("type_of_criteria")) {
    if (!fields["type_of_criteria"]) {
      formIsValid = false;
      errors["type_of_criteria"] = "Por favor, Selecione o tipo de critério";
    }
  }
  if (requiredField.includes("document_type")) {
    if (!fields["document_type"]) {
      formIsValid = false;
      errors["document_type"] = "Por favor, Selecione o Documentos";
    }
  }
  if (requiredField.includes("contract_value")) {
    if (!fields["contract_value"]) {
      formIsValid = false;
      errors["contract_value"] = "Por favor, insira o valor do contrato";
    }
  }
  if (requiredField.includes("contract_value_temp")) {
    if (!fields["contract_value_temp"]) {
      formIsValid = false;
      errors["contract_value_temp"] = "Por favor, insira o valor do contrato";
    }
  }
  if (requiredField.includes("budget_value")) {
    if (!fields["budget_value"]) {
      formIsValid = false;
      errors["budget_value"] = "Por favor, insira o  Valor orçamental";
    }
  }
  if (requiredField.includes("service_description")) {
    if (!fields["service_description"]) {
      formIsValid = false;
      errors["service_description"] =
        "Por favor, insira o  Descrição do Serviço";
    }
  }
  if (requiredField.includes("procedureTypeID")) {
    if (!fields["procedureTypeID"].length > 0) {
      formIsValid = false;
      errors["procedureTypeID"] =
        "Por favor, seleccione o tipos de procedimento";
    }
  }

  if (requiredField.includes("year")) {
    if (!fields["year"]) {
      formIsValid = false;
      errors["year"] = "Por favor, insira o ano";
    }
  }
  if (requiredField.includes("year_id")) {
    if (!fields["year_id"]) {
      formIsValid = false;
      errors["year_id"] = "Por favor, insira o ano orçamental";
    }
  }
  if (requiredField.includes("budget_year_id")) {
    if (!fields["budget_year_id"]) {
      formIsValid = false;
      errors["budget_year_id"] = "Por favor, insira o ano orçamental";
    }
  }

  if (requiredField.includes("currency_id")) {
    if (!fields["currency_id"]) {
      formIsValid = false;
      errors["currency_id"] = "Por favor, insira o moeda";
    }
  }
  if (requiredField.includes("currency_symbol")) {
    if (!fields["currency_symbol"]) {
      formIsValid = false;
      errors["currency_symbol"] = "Por favor, insira o  símbolo";
    }
  }
  if (requiredField.includes("currency_name")) {
    if (!fields["currency_name"]) {
      formIsValid = false;
      errors["currency_name"] = "Por favor, insira o  moeda";
    }
  }
  if (requiredField.includes("exchange_rate")) {
    if (!fields["exchange_rate"]) {
      formIsValid = false;
      errors["exchange_rate"] = "Por favor, insira o taxa de câmbio";
    }
  }

  if (requiredField.includes("contest_reference")) {
    if (!fields["contest_reference"]) {
      formIsValid = false;
      errors["contest_reference"] = "Por favor insira a referência";
    }
  }

  if (requiredField.includes("object_tender_id")) {
    if (!fields["object_tender_id"]) {
      formIsValid = false;
      errors["object_tender_id"] = "por favor, selecione o objeto do concurso";
    }
  }

  if (requiredField.includes("description_of_contest_type")) {
    if (!fields["description_of_contest_type"]) {
      formIsValid = false;
      errors["description_of_contest_type"] = "por favor insira a descrição";
    }
  }

  if (requiredField.includes("procedure_type_id")) {
    if (!fields["procedure_type_id"]) {
      formIsValid = false;
      errors["procedure_type_id"] =
        "por favor selecione o tipo de procedimento";
    }
  }
  if (requiredField.includes("company_type_id")) {
    if (!fields["company_type_id"]) {
      formIsValid = false;
      errors["company_type_id"] = "por favor selecione o tipo de  empresa";
    }
  }

  if (requiredField.includes("contest_reference_id")) {
    if (!fields["contest_reference_id"]) {
      formIsValid = false;
      errors["contest_reference_id"] =
        "por favor selecione o Referência do concurso";
    }
  }

  if (requiredField.includes("company")) {
    if (!fields["company"]) {
      formIsValid = false;
      errors["company"] = "por favor insira a Empresa";
    }
  }
  if (requiredField.includes("NIF")) {
    if (!fields["NIF"]) {
      formIsValid = false;
      errors["NIF"] = "por favor insira a NIF";
    }
  }

  if (requiredField.includes("address")) {
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "por favor insira a Endereço";
    }
  }
  // if (requiredField.includes("phone")) {
  //   if (!fields["phone"]) {
  //     formIsValid = false;
  //     errors["phone"] = "por favor insira a Telemóvel";
  //   }
  // }

  if (requiredField.includes("phone")) {
    let pattern = new RegExp(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
    );
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "Por favor, insira o phone";
    } else if (!pattern.test(fields["phone"])) {
      formIsValid = false;
      errors["phone"] = "Insira apenas o número";
    } else {
      if (fields["phone"].length < 7 || fields["phone"].length > 11) {
        formIsValid = false;
        errors["phone"] = "O número de telefone deve ter de 7 a 11 dígitos.";
      }
    }
  }

  if (requiredField.includes("tab_count")) {
    if (!fields["tab_count"]) {
      formIsValid = false;
      errors["tab_count"] = "Por favor, selecione a contagem de guias";
    }
  }

  if (requiredField.includes("concurringRequirementsId")) {
    if (!fields["concurringRequirementsId"]) {
      formIsValid = false;
      errors["concurringRequirementsId"] =
        "Documentos a pedidos (deve ser selecionado pelo menos um)";
    }
  }

  if (requiredField.includes("internal_communication_id")) {
    if (!fields["internal_communication_id"]) {
      formIsValid = false;
      errors["internal_communication_id"] = "Selecione Documento";
    }
  }

  if (requiredField.includes("reason")) {
    if (!fields["reason"]) {
      formIsValid = false;
      errors["reason"] = "Por favor, Digite o motivo";
    }
  }

  if (requiredField.includes("supplier_type_id")) {
    if (!fields["supplier_type_id"]) {
      formIsValid = false;
      errors["supplier_type_id"] = "Por favor, selecione o tipo de fornecedor";
    }
  }

  if (requiredField.includes("provider_name")) {
    if (!fields["provider_name"]) {
      formIsValid = false;
      errors["provider_name"] = "Por favor, digite o nome do provedor";
    }
  }

  // if (requiredField.includes("phone_number")) {
  //   if (!fields["phone_number"]) {
  //     formIsValid = false;
  //     errors["phone_number"] = "Por favor, digite o número de telefone";
  //   }
  // }
  if (requiredField.includes("phone_number")) {
    let pattern = new RegExp(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
    );
    if (!fields["phone_number"]) {
      formIsValid = false;
      errors["phone_number"] = "Por favor, digite o número de telefone";
    } else if (!pattern.test(fields["phone_number"])) {
      formIsValid = false;
      errors["phone_number"] = "Insira apenas o número";
    } else {
      if (
        fields["phone_number"].length < 7 ||
        fields["phone_number"].length > 11
      ) {
        formIsValid = false;
        errors["phone_number"] =
          "O número de telefone deve ter de 7 a 11 dígitos.";
      }
    }
  }

  if (requiredField.includes("country_id")) {
    if (!fields["country_id"]) {
      formIsValid = false;
      errors["country_id"] = "Por favor,Selecione o pais";
    }
  }

  if (requiredField.includes("tax_number")) {
    if (!fields["tax_number"]) {
      formIsValid = false;
      errors["tax_number"] = "Por favor,digite o número do imposto";
    }
  }

  if (requiredField.includes("contact_person_name")) {
    if (!fields["contact_person_name"]) {
      formIsValid = false;
      errors["contact_person_name"] =
        "Por favor,digite o nome da pessoa de contato";
    }
  }

  if (requiredField.includes("contact_person_email")) {
    if (!fields["contact_person_email"]) {
      formIsValid = false;
      errors["contact_person_email"] = "Por favor, insira o email";
    } else {
      const lastAtPos = fields["contact_person_email"].lastIndexOf("@");
      const lastDotPos = fields["contact_person_email"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["contact_person_email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["contact_person_email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["contact_person_email"] = "Por favor, insira o valid email address";
      }
    }
  }

  if (requiredField.includes("contact_person_phone_number")) {
    if (!fields["contact_person_phone_number"]) {
      formIsValid = false;
      errors["contact_person_phone_number"] =
        "Por favor,digite o número da pessoa de contato";
    }
  }

  if (requiredField.includes("bank_id")) {
    if (!fields["bank_id"]) {
      formIsValid = false;
      errors["bank_id"] = "Por favor, selecione o Nome do Banco";
    }
  }
  if (requiredField.includes("swift")) {
    if (!fields["swift"]) {
      formIsValid = false;
      errors["swift"] = "Por favor, selecione o SWIFT";
    }
  }

  if (requiredField.includes("physical_code")) {
    if (!fields["physical_code"]) {
      formIsValid = false;
      errors["physical_code"] = "por favor insira a Código Físico";
    }
  }

  if (requiredField.includes("object_of_the_contract")) {
    if (!fields["object_of_the_contract"]) {
      formIsValid = false;
      errors["object_of_the_contract"] =
        "por favor insira a Objecto do Contrato";
    }
  }

  if (requiredField.includes("contract_value")) {
    if (!fields["contract_value"]) {
      formIsValid = false;
      errors["contract_value"] = "por favor insira a Valor do Contrato";
    }
  }

  if (requiredField.includes("producer_id")) {
    if (!fields["producer_id"]) {
      formIsValid = false;
      errors["producer_id"] = "Por favor, selecione o Tipo do Procedimento";
    }
  }

  if (requiredField.includes("provider_id")) {
    if (!fields["provider_id"]) {
      formIsValid = false;
      errors["provider_id"] = "Por favor, selecione o Nome do Fornecedor";
    }
  }

  if (requiredField.includes("selectedradio4")) {
    if (!fields["selectedradio4"] && isNaN(fields["selectedradio4"])) {
      formIsValid = false;
      errors["selectedradio4"] = "Por favor, selecione o Tipo de Moeda";
    }
  }

  if (requiredField.includes("payment_id")) {
    if (!fields["payment_id"]) {
      formIsValid = false;
      errors["payment_id"] = "Por favor, selecione o Formas de Pagamento";
    }
  }

  if (requiredField.includes("service_category_id")) {
    if (!fields["service_category_id"]) {
      formIsValid = false;
      errors["service_category_id"] =
        "Por favor, selecione o Categoria do serviço";
    }
  }

  if (requiredField.includes("department_id")) {
    if (!fields["department_id"]) {
      formIsValid = false;
      errors["department_id"] = "Por favor, selecione o Área Beneficiária";
    }
  }

  if (requiredField.includes("start_date")) {
    if (!fields["start_date"]) {
      errors["start_date"] = "Por favor, selecione o Data de início";
    }
  }

  if (requiredField.includes("end_date")) {
    if (!fields["end_date"]) {
      formIsValid = false;
      errors["end_date"] = "Por favor, selecione o Data de Expiração";
    }
  }

  if (requiredField.includes("selectedradio1")) {
    if (!"selectedradio1") {
      formIsValid = false;
      errors["selectedradio1"] = "Por favor, selecione o Serviço recorrente";
    }
  }

  if (requiredField.includes("observation")) {
    if (!fields["observation"]) {
      formIsValid = false;
      errors["observation"] = "por favor insira a Observação";
    }
  }

  if (requiredField.includes("iban")) {
    if (!fields["iban"]) {
      formIsValid = false;
      errors["iban"] = "Por favor,digite o IBAN";
    }
  }
  if (requiredField.includes("routing_number")) {
    if (!fields["routing_number"]) {
      formIsValid = false;
      errors["routing_number"] = "Por favor,digite o routing number";
    }
  }

  if (requiredField.includes("evaluation_comitee_member_id")) {
    if (!fields["evaluation_comitee_member_id"]) {
      formIsValid = false;
      errors["evaluation_comitee_member_id"] = "Por favor, selecione Membro";
    }
  }

  if (requiredField.includes("evaluation_commitee_role_id")) {
    if (!fields["evaluation_commitee_role_id"]) {
      formIsValid = false;
      errors["evaluation_commitee_role_id"] = "Selecione a função do membro";
    }
  }

  if (requiredField.includes("trip_tabs")) {
    if (!fields["trip_tabs"].length > 0) {
      formIsValid = false;
      errors["trip_tabs"] = "Por favo, selecione a guia para solicitação";
    }
  }

  if (requiredField.includes("contest_tabs")) {
    if (!fields["contest_tabs"].length > 0) {
      formIsValid = false;
      errors["contest_tabs"] = "Por favo, selecione a aba para o concurso";
    }
  }

  if (requiredField.includes("role_id")) {
    if (!fields["role_id"]) {
      formIsValid = false;
      errors["role_id"] = "Por favor, selecione Papel";
    }
  }

  if (requiredField.includes("purpose_of_the_contest_id")) {
    if (!fields["purpose_of_the_contest_id"]) {
      formIsValid = false;
      errors["purpose_of_the_contest_id"] =
        "Por favor, seleccione Objeto do Concurso";
    }
    if (fields["purpose_of_the_contest_id"].length === 0) {
      formIsValid = false;
      errors["purpose_of_the_contest_id"] =
        "Por favor, seleccione Objeto do Concurso";
    }
  }

  if (requiredField.includes("rating_criteria_tabs")) {
    if (!fields["rating_criteria_tabs"].length > 0) {
      formIsValid = false;
      errors["rating_criteria_tabs"] =
        "Por favo, selecione Solicitações (Critérios de avaliação) Tab";
    }
  }

  if (requiredField.includes("ci_number")) {
    if (!fields["ci_number"]) {
      formIsValid = false;
      errors["ci_number"] = "por favor insira o número ci";
    }
  }

  // if (requiredField.includes("ci_number")) {
  //   if (fields["ci_number"] && fields["ci_number"].length < 6) {
  //     formIsValid = false;
  //     errors["ci_number"] = "A CI deve ter no mínimo 6 caracteres.";
  //   }

  //   if (fields["ci_number"] && fields["ci_number"].length > 16) {
  //     formIsValid = false;
  //     errors["ci_number"] = "número ci digite até 15";
  //   }
  // }

  if (requiredField.includes("contest_id")) {
    if (!fields["selectedContestID"]) {
      formIsValid = false;
      errors["contest_id"] = "Por favor, selecione o concurso";
    }
  }

  if (requiredField.includes("selectedQdData")) {
    if (fields["selectedQdData"].length == 0) {
      formIsValid = false;
      errors["selectedQdData"] = "Por favor, selecione o documento";
    }
  }

  if (requiredField.includes("percentageForQualification")) {
    if (!fields["percentageForQualification"]) {
      formIsValid = false;
      errors["percentage"] = "por favor insira a porcentagem";
    }
    if (fields["percentageForQualification"] > 100) {
      formIsValid = false;
      errors["percentage"] = "A percentagem deve estar entre 0 à 100";
    }
  }

  if (requiredField.includes("description")) {
    if (!fields["description"]) {
      formIsValid = false;
      errors["description"] =
        "Por favor, insira o  Descrição";
    }
  }

  if (requiredField.includes("manager")) {
    if (!fields["manager"]) {
      formIsValid = false;
      errors["manager"] =
        "Por favor, insira o  Director";
    }
  }

  if (requiredField.includes("amount")) {
    if (!fields["amount"]) {
      formIsValid = false;
      errors["amount"] = "por favor insira o valor";
    }
  }

  if (requiredField.includes("evaluation_criteria_id")) {
    if (!fields["evaluation_criteria_id"]) {
      formIsValid = false;
      errors["evaluation_criteria_id"] = "Por favor, Selecione o documento Critérios de avaliação";
    }
  }

  if (requiredField.includes("weight")) {
    if (!fields["weight"]) {
      formIsValid = false;
      errors["weight"] = "Por favor, insira o valor do peso";
    }
  }

  if (requiredField.includes("attribute_id")) {
    if (!fields["attribute_id"]) {
      formIsValid = false;
      errors["attribute_id"] = "Por favor, Selecione o Atributos";
    }
  }

  if (requiredField.includes("puncuation")) {
    if (!fields["puncuation"]) {
      formIsValid = false;
      errors["puncuation"] = "Por favor, insira o valor da pontuação";
    }
  }

  return {
    isValid: formIsValid,
    errors,
  };
};

export const logFormData = (data) => {
  for (var pair of data.entries()) {
    console.log("");
  }
};
export const isValidUser = (request) => {
  if (request?.response?.status === 401 || request?.response?.status === 500) {
    Cookies.remove("G-accessToken");
    return false;
  }
  return true;
};


export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const yes_no = [{ name: "NÃO" }, { name: "SIM" }];
export const currency_types = [
  { name: "AOA" },
  { name: "USD" },
  { name: "Euro" },
  { name: "Libra" },
  { name: "Real" },
];