import { Fragment, useState, useEffect } from "react";
import { Menu, Transition, Dialog, Disclosure } from "@headlessui/react";
import { Link, useHistory } from "react-router-dom";
import TopNavigation from "../TopNavigation/topNavigation";
import {
  BellIcon,
  SquaresPlusIcon,
  TruckIcon,
  Cog8ToothIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  Bars3CenterLeftIcon,
  CogIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  XMarkIcon,
  AdjustmentsVerticalIcon,
} from "@heroicons/react/20/solid";
import config from "../../utils/config.js";
import Cookies from "js-cookie";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Header() {
  let history = useHistory();
  const [authData, setAuthData] = useState({});

  useEffect(() => {
    const items = JSON.parse(Cookies.get("dataKey"));
    if (items) {
      setAuthData(items);
    }
  }, []);

  const handleLogout = () => {
    Cookies.remove("G-accessToken", { domain: process.env.REACT_APP_DOMAIN_NAME });
    window.history.pushState(null, null, "/");
    Cookies.remove("dataKey", { domain: process.env.REACT_APP_DOMAIN_NAME });
    Cookies.remove("area", { domain: process.env.REACT_APP_DOMAIN_NAME });
    Cookies.remove("user_id", { domain: process.env.REACT_APP_DOMAIN_NAME });
    Cookies.remove("areaID", { domain: process.env.REACT_APP_DOMAIN_NAME });
    Cookies.remove("role", { domain: process.env.REACT_APP_DOMAIN_NAME });
    history.push("/");
    // window.location.replace(SUPER_ADMIN_URL)
  };
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigation = [
    { name: "Dashboard", href: "", icon: SquaresPlusIcon, current: true },
    {
      name: "Menu Option 1",
      icon: TruckIcon,
      path: "../../img/plane.svg",
      current: false,
      children: [
        { name: "Submenu Option 1", href: "" },
        { name: "Submenu Option 2", href: "" },
      ],
    },
    { name: "Menu Option 2", href: "", icon: BellIcon },
    { name: "Outros Menus", href: "/setting", icon: AdjustmentsVerticalIcon },
  ];
  const secondaryNavigation = [
    { name: "Settings", href: "#", icon: CogIcon },
    { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
    { name: "Privacy", href: "#", icon: ShieldCheckIcon },
  ];
  return (
    <>
      <div className="flex h-16 flex-shrink-0 border-b bg-white lg:border-none shadow border-t border-gray-200">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-400 focus:outline-none lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        {/* Search bar */}
        <div className="flex flex-1 justify-between px-4 sm:px-6 lg:mx-auto lg:max-w-8xl lg:px-8">
          <div className="flex flex-1">
            <TopNavigation
              className="w-full"
              mode="horizontal"
              openAnimation="slide-up"
            />
          </div>
          <div className="ml-4 flex items-center md:ml-6">
            {/* <button
              type="button"
              className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            >
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </button> */}

            <Link to="/setting">
              <button
                type="button"
                className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ml-3"
              >
                <Cog8ToothIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </Link>

            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
                  {/* <img
                    className="h-8 w-8 rounded-full"
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  /> */}

                  {authData?.profilePic && authData?.profilePic !== "" ? (
                    <img
                      className="h-8 w-8 rounded-full"
                      src={`${config.USER_MODULE_PATH +
                        authData?.uId +
                        "/" +
                        authData?.profilePic
                        }`}
                      onError={(e) => (e.target.src = "/img/defaultAvatar.svg")}
                      alt=""
                    />
                  ) : (
                    <img
                      className="h-8 w-8 rounded-full"
                      src={"/img/defaultAvatar.svg"}
                      alt=""
                    />
                  )}

                  <span className="ml-3 hidden text-sm font-medium text-gray-700 lg:block">
                    <span className="sr-only">Open user menu for </span>
                    {authData?.userName}
                  </span>
                  <ChevronDownIcon
                    className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/profile/profile">
                        <a
                          href="#"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          Meu Perfil
                        </a>
                      </Link>
                    )}
                  </Menu.Item>
                  {/* <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Settings
                      </a>
                    )}
                  </Menu.Item> */}
                  <Menu.Item>
                    {({ active }) => (
                      // <Link to="/">
                      //   <a
                      //     href="#"
                      //     className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                      //   >
                      //     Logout

                      //   </a>
                      // </Link>
                      <div
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                        )}
                        onClick={() => handleLogout()}
                      >
                        Sair
                      </div>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="/img/logo-inner.png"
                      alt="Easywire logo"
                    />
                  </div>
                  {/* <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-skin-primary text-white'
                              : 'text-white hover:text-white hover:bg-cyan-600',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md left-menu-btn'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-white" aria-hidden="true" />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="group flex items-center rounded-md px-2 py-2 text-base font-medium text-white left-menu-btn hover:text-white"
                          >
                            <item.icon className="mr-4 h-6 w-6 text-white" aria-hidden="true" />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav> */}
                  <div className="mt-5 flex flex-grow flex-col">
                    <nav className="flex-1 space-y-1 px-2" aria-label="Sidebar">
                      {navigation.map((item) =>
                        !item.children ? (
                          <div key={item.name}>
                            <Link to={item.href}>
                              <a
                                href="#"
                                className={classNames(
                                  item.current
                                    ? "bg-skin-primary text-white"
                                    : "text-white left-menu-btn hover:text-white",
                                  "group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md"
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current
                                      ? "text-white"
                                      : "text-white group-hover:text-white",
                                    "mr-3 flex-shrink-0 h-6 w-6"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                            </Link>
                          </div>
                        ) : (
                          <Disclosure
                            as="div"
                            key={item.name}
                            className="space-y-1"
                          >
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className={classNames(
                                    item.current
                                      ? "bg-skin-secondary text-white"
                                      : "text-white hover:bg-gray-50 hover:text-white left-menu-btn",
                                    "group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none"
                                  )}
                                >
                                  <item.icon
                                    className="mr-3 h-6 w-6 flex-shrink-0 text-white group-hover:text-white"
                                    aria-hidden="true"
                                  />
                                  <span className="flex-1">{item.name}</span>
                                  <svg
                                    className={classNames(
                                      open
                                        ? "text-white rotate-90"
                                        : "text-white",
                                      "ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-white"
                                    )}
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M6 6L14 10L6 14V6Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </Disclosure.Button>
                                <Disclosure.Panel className="space-y-1">
                                  {item.children.map((subItem) => (
                                    <Link to={subItem.href}>
                                      <Disclosure.Button
                                        key={subItem.name}
                                        as="a"
                                        href={subItem.href}
                                        className="group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-white left-menu-btn hover:text-white"
                                      >
                                        {subItem.name}
                                      </Disclosure.Button>
                                    </Link>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )
                      )}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
}
