import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LeftNavigation from "../../components/LeftNavigation/leftNavigation.js";
import BtnRedirect from "../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../components/Form/Button/BtnSaveRecord";
import Header from "../../components/Header/Header";
import config from "../../utils/config.js";
import { toast } from "react-toastify";
import { validateForm } from "../../utils/common.js";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";
import { ClockIcon, CogIcon, CreditCardIcon, DocumentChartBarIcon, HomeIcon, QuestionMarkCircleIcon, ScaleIcon, ShieldCheckIcon, UserGroupIcon, XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import Cookies from "js-cookie";
import { reject } from "underscore";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Roles({ history }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [contestReference, setContestReference] = useState([]);
  const [supplierType, setsupplierType] = useState([]);
  const [error, setError] = useState({});
  const [tab, setTab] = useState(1);
  const [lastInsertId, setLastInsertId] = useState(0);
  const [info, setInfo] = useState({
    contest_reference_id: "",
    company: "",
    company_type_id: "",
    NIF: "",
    email: "",
    address: "",
    phone: "",
    status: "",
  });
  const [concurringRequirementsId, setConcurringRequirementsId] = useState();
  const [selectedConcurringRequirements, setSelectedConcurringRequirements] =
    useState([]);
  const steps1 = [
    { id: "01", name: "Informações gerais", href: "#", status: "current" },
    { id: "02", name: "Documentos a solicitar", href: "#", status: "upcoming" },
  ];
  const steps2 = [
    { id: "01", name: "Informações gerais", href: "#", status: "complete" },
    { id: "02", name: "Documentos a solicitar", href: "#", status: "current" },
  ];

  const [selectedContestReferenceID, setSelectedContestReferenceID] =
    useState(null);

  const getConcurringRequirementsList = async (id = "") => {
    const repsonse = await config.getAPI({
      url: "concurringRequirements/get-concurring-requirements-id-by-referance-id",
      params: {
        id: id,
      },
    });
    if (repsonse?.payload?.rows) {
      const itemArr = [];
      repsonse?.payload?.rows.map((item) => {
        itemArr.push({ id: item.id, label: item.name });
      });
      setConcurringRequirementsId(itemArr);
    }
  };

  const getcontestReference = async () => {
    try {
      config.getAPI({ url: "contest/list", params: {} }).then((data) => {
        if (data?.payload) {
          config.getAPI({ url: "competitor/list", params: {} }).then((data2) => {
            if (data2.payload.rows.length > 0) {
              data2.payload.rows.map((element) => {
                const finalData = reject(data.payload.rows, function (num) { return num.id == element.contest_reference_id });
                finalData.map((element) => {
                  element.label = element.contest_reference;
                  element.value = element.id;
                });
                setContestReference(data?.payload?.rows);
              })
            } else {
              data.payload.rows.map((element) => {
                element.label = element.contest_reference;
                element.value = element.id;
              });
              setContestReference(data?.payload?.rows);
            }
          }).catch((error) => {
            return toast.error(error.message)
          })
        }
      }).catch((error) => {
        return toast.error(error.message);
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const getSupplierType = async () => {
    config
      .getAPI({ url: "supplierType/list", params: { status: "Active" } })
      .then((data) => {
        if (data?.payload) {
          setsupplierType(data?.payload?.rows);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const handleChange = (e, name, index) => {
    if (name === "contest_reference_id") {
      const setData = { ...info, [name]: e?.value };
      setInfo(setData);
      let crId = e?.value;
      getConcurringRequirementsList(crId);
      setSelectedContestReferenceID(e);
    } else {
      const setData = { ...info, [name]: e?.target?.value };
      setInfo(setData);
    }
  };

  const handleSubmit = async (val = "") => {
    try {
      const includedFields = [
        "contest_reference_id",
        "company",
        "company_type_id",
        "NIF",
        "email",
        // "address",
        "phone",
        "status",
      ];

      const validationResponse = validateForm(info, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        if(lastInsertId){
          console.log("");
        }else{
          const insertResponse = await config.postAPI({
            url: "competitor/create",
            params: info,
          });
          if (insertResponse?.data?.error?.message) {
            return toast.error(insertResponse?.data?.error?.message);
          }
          if (insertResponse) {
            setLastInsertId(insertResponse?.payload?.id);
          }
        }

        if (val === "list") {
          history.push("/competitors/ViewCompetitors");
        } else {
          setTab(2);
        }
        setTimeout(() => {
          return toast.success("Registo criado com sucesso");
        }, 200);
        // history.push('/competitors/ViewCompetitors')
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  const submitConcurringRequirements = async () => {
    try {
      let indertData = {
        competitor_id: lastInsertId,
        concurring_requirements_id: selectedConcurringRequirements,
      };

      const includedFields = ["concurring_requirements_id", "competitor_id"];
      const validationResponse = validateForm(indertData, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        const insertResponse = await config.postAPI({
          url: "competitorsConcurringRequirements/create",
          params: indertData,
        });
        if (insertResponse?.data?.error?.message) {
          return toast.error(insertResponse?.data?.error?.message);
        }
        setTimeout(() => {
          return toast.success("Registo criado com sucesso");
        }, 200);
        history.push("/competitors/ViewCompetitors");
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };
  useEffect(() => {
    getcontestReference();
    getSupplierType();
    getConcurringRequirementsList();
  }, []);

  useEffect(() => {
    const retrievedObject = JSON.parse(Cookies.get("dataKey"));
    setInfo({ ...info, user_id: retrievedObject.uId });
  }, []);

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1 pb-8">
            <div className="mt-8">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="py-5 flex items-center justify-between">
                  <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
                    Novo Concorrentes
                  </h1>
                </div>
                <form className="space-y-6" action="#" method="POST">
                  <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                    <div className="space-y-8">
                      {tab === 1 ? (
                        <div className="w-full">
                          <div className="mb-6">
                            <nav aria-label="Progress">
                              <ol
                                role="list"
                                className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
                              >
                                {steps1.map((step, stepIdx) => (
                                  <li
                                    key={step.name}
                                    className="relative md:flex md:flex-1"
                                  >
                                    {step.status === "complete" ? (
                                      <a
                                        href={step.href}
                                        className="group flex w-full items-center"
                                      >
                                        <span className="flex items-center px-6 py-4 text-base font-medium">
                                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-600">
                                            <CheckIcon
                                              className="h-6 w-6 text-white"
                                              aria-hidden="true"
                                            />
                                          </span>
                                          <span className="ml-4 text-base font-medium text-gray-900">
                                            {step.name}
                                          </span>
                                        </span>
                                      </a>
                                    ) : step.status === "current" ? (
                                      <a
                                        href={step.href}
                                        className="flex items-center px-6 py-4 text-base font-medium"
                                        aria-current="step"
                                      >
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-red-600">
                                          <span className="text-red-600">
                                            {step.id}
                                          </span>
                                        </span>
                                        <span className="ml-4 text-base font-medium text-red-600">
                                          {step.name}
                                        </span>
                                      </a>
                                    ) : (
                                      <a
                                        href={step.href}
                                        className="group flex items-center"
                                      >
                                        <span className="flex items-center px-6 py-4 text-base font-medium">
                                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                            <span className="text-gray-500 group-hover:text-gray-900">
                                              {step.id}
                                            </span>
                                          </span>
                                          <span className="ml-4 text-base font-medium text-gray-500 group-hover:text-gray-900">
                                            {step.name}
                                          </span>
                                        </span>
                                      </a>
                                    )}

                                    {stepIdx !== steps1.length - 1 ? (
                                      <>
                                        {/* Arrow separator for lg screens and up */}
                                        <div
                                          className="absolute top-0 right-0 hidden h-full w-5 md:block"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            className="h-full w-full text-gray-300"
                                            viewBox="0 0 22 80"
                                            fill="none"
                                            preserveAspectRatio="none"
                                          >
                                            <path
                                              d="M0 -2L20 40L0 82"
                                              vectorEffect="non-scaling-stroke"
                                              stroke="currentcolor"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </div>
                                      </>
                                    ) : null}
                                  </li>
                                ))}
                              </ol>
                            </nav>
                          </div>
                          <div className="pt-1">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="last_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Referência do concurso{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <Select
                                    value={selectedContestReferenceID}
                                    placeholder="Selecionar Referência do Concurso"
                                    onChange={(e) => {
                                      handleChange(e, "contest_reference_id");
                                    }}
                                    options={contestReference}
                                    isSearchable={true}
                                  />
                                  {error?.contest_reference_id && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.contest_reference_id}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Empresa <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="company"
                                    id="company"
                                    autoComplete="company"
                                    onChange={(e) => {
                                      handleChange(e, "company");
                                    }}
                                    value={info?.company}
                                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${error?.name && "border-red-600"
                                      }`}
                                  />
                                </div>
                                {error?.company && (
                                  <span className="text-xs text-red-600 capitalize">
                                    {error?.company}
                                  </span>
                                )}
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  NIF <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="number"
                                    name="NIF"
                                    id="NIF"
                                    autoComplete="NIF"
                                    onChange={(e) => {
                                      handleChange(e, "NIF");
                                    }}
                                    value={info?.NIF}
                                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${error?.name && "border-red-600"
                                      }`}
                                  />
                                </div>
                                {error?.NIF && (
                                  <span className="text-xs text-red-600 capitalize">
                                    {error?.NIF}
                                  </span>
                                )}
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="last_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Tipo de empresa{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <select
                                    id="company_type_id"
                                    name="company_type_id"
                                    className={` ${error?.company_type_id && "border-red-600"
                                      } mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base`}
                                    onChange={(e) => {
                                      handleChange(e, "company_type_id");
                                    }}
                                    value={info?.company_type_id}
                                  >
                                    <option value="">
                                      {" "}
                                      -- Selecionar Tipo de empresa --{" "}
                                    </option>
                                    {supplierType?.map((supplier) => {
                                      return (
                                        <option
                                          key={supplier?.id}
                                          value={supplier?.id}
                                        >
                                          {supplier?.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {error?.company_type_id && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.company_type_id}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  E-mail <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    autoComplete="email"
                                    onChange={(e) => {
                                      handleChange(e, "email");
                                    }}
                                    value={info?.email}
                                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${error?.name && "border-red-600"
                                      }`}
                                  />
                                  {error?.email && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.email}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Endereço
                                </label>
                                <div className="mt-1">
                                  <textarea
                                    name="address"
                                    id="address"
                                    row="5"
                                    col="5"
                                    autoComplete="address"
                                    onChange={(e) => {
                                      handleChange(e, "address");
                                    }}
                                    value={info?.address}
                                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${error?.name && "border-red-600"
                                      }`}
                                  />
                                  {/* {error?.address && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.address}
                              </span>
                            )} */}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Telemóvel <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="tel"
                                    name="phone"
                                    id="phone"
                                    autoComplete="phone"
                                    onChange={(e) => {
                                      handleChange(e, "phone");
                                    }}
                                    // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                    value={info?.phone}
                                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${error?.phone && "border-red-600"
                                      }`}
                                  />
                                  {error?.phone && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.phone}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="status"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Estado <span className="mandatory">*</span>
                                </label>
                                <select
                                  id="status"
                                  name="status"
                                  autoComplete="status"
                                  onChange={(e) => {
                                    handleChange(e, "status");
                                  }}
                                  value={info?.status}
                                  className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${error?.status && "border-red-600"
                                    }`}
                                >
                                  <option>---Selecione Estado---</option>
                                  <option value="Active">Activo</option>
                                  <option value="Inactive">Inactivo</option>
                                </select>
                                {error?.status && (
                                  <span className="text-xs text-red-600 capitalize">
                                    {error?.status}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="pt-5">
                            <div className="flex justify-end">
                              <BtnRedirect
                                path={"/competitors/ViewCompetitors"}
                                title={"Cancelar"}
                              />
                              <BtnSaveRecord
                                path={"/competitors/ViewCompetitors"}
                                title={"Guardar e Sair"}
                                handleSubmit={() => handleSubmit("list")}
                              />
                              <BtnSaveRecord
                                path={"/competitors/ViewCompetitors"}
                                title={"Próximo"}
                                handleSubmit={() => handleSubmit("tab")}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {tab === 2 ? (
                        <div className="w-full">
                          <div>
                            <nav aria-label="Progress">
                              <ol
                                role="list"
                                className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
                              >
                                {steps2.map((step, stepIdx) => (
                                  <li
                                    key={step.name}
                                    className="relative md:flex md:flex-1"
                                  >
                                    {step.status === "complete" ? (
                                      <a
                                        href={step.href}
                                        className="group flex w-full items-center"
                                      >
                                        <span className="flex items-center px-6 py-4 text-base font-medium">
                                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-600">
                                            <CheckIcon
                                              className="h-6 w-6 text-white"
                                              aria-hidden="true"
                                            />
                                          </span>
                                          <span className="ml-4 text-base font-medium text-gray-900">
                                            {step.name}
                                          </span>
                                        </span>
                                      </a>
                                    ) : step.status === "current" ? (
                                      <a
                                        href={step.href}
                                        className="flex items-center px-6 py-4 text-base font-medium"
                                        aria-current="step"
                                      >
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-red-600">
                                          <span className="text-red-600">
                                            {step.id}
                                          </span>
                                        </span>
                                        <span className="ml-4 text-base font-medium text-red-600">
                                          {step.name}
                                        </span>
                                      </a>
                                    ) : (
                                      <a
                                        href={step.href}
                                        className="group flex items-center"
                                      >
                                        <span className="flex items-center px-6 py-4 text-base font-medium">
                                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                            <span className="text-gray-500 group-hover:text-gray-900">
                                              {step.id}
                                            </span>
                                          </span>
                                          <span className="ml-4 text-base font-medium text-gray-500 group-hover:text-gray-900">
                                            {step.name}
                                          </span>
                                        </span>
                                      </a>
                                    )}

                                    {stepIdx !== steps2.length - 1 ? (
                                      <>
                                        {/* Arrow separator for lg screens and up */}
                                        <div
                                          className="absolute top-0 right-0 hidden h-full w-5 md:block"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            className="h-full w-full text-gray-300"
                                            viewBox="0 0 22 80"
                                            fill="none"
                                            preserveAspectRatio="none"
                                          >
                                            <path
                                              d="M0 -2L20 40L0 82"
                                              vectorEffect="non-scaling-stroke"
                                              stroke="currentcolor"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </div>
                                      </>
                                    ) : null}
                                  </li>
                                ))}
                              </ol>
                            </nav>
                          </div>
                          <div className="pt-1">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 py-4">
                              <div className="pb-5 border-b border-gray-200 col-span-6">
                                <div className="pt-1">
                                  <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 rounded-lg border border-gray-200 bg-gray-50 p-6">
                                    <div className="sm:col-span-6">
                                      <label
                                        htmlFor="last_name"
                                        className="block text-base font-medium text-gray-700"
                                      >
                                        Documentos a solicitar{" "}
                                        <span className="mandatory">*</span>
                                      </label>
                                      <div className="mt-1">
                                        <MultiSelect
                                          items={concurringRequirementsId}
                                          messages={{
                                            searchPlaceholder: "Procurar...",
                                            noItemsMessage: "Nenhum item...",
                                            noneSelectedMessage:
                                              "Nenhum seleccionado",
                                            selectedMessage: "selecionado",
                                            selectAllMessage: "Selecionar tudo",
                                            clearAllMessage: "Limpar tudo",
                                            disabledItemsTooltip:
                                              "Você só pode selecionar 1 arquivo",
                                          }}
                                          selectedItems={
                                            selectedConcurringRequirements
                                          }
                                          itemHeight={50}
                                          onChange={(e) =>
                                            setSelectedConcurringRequirements(e)
                                          }
                                        />
                                      </div>
                                      {error?.concurringRequirementsId && (
                                        <span className="text-xs text-red-600 capitalize">
                                          {error?.concurringRequirementsId}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                onClick={() => setTab(1)}
                              >
                                Retroceder
                              </button>
                              {/* <button
                          type="button"
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                        onClick={() => submit()}
                        >
                          Submeter
                        </button> */}
                              <BtnSaveRecord
                                path={"/competitors/ViewCompetitors"}
                                title={"Submeter"}
                                handleSubmit={submitConcurringRequirements}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}