import { Fragment, useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dialog, Menu, Transition, Tab, RadioGroup } from "@headlessui/react";
import LeftNavigation from "../../../components/LeftNavigation/leftNavigation.js";
import Header from "../../../components/Header/Header";
import ActionButton from "../../../components/Other/ActionButton";
import { toast } from "react-toastify";
import { pluck } from "underscore";
import DeleteModel from "../../../components/Other/DeleteModel";
import DataTable from "../../../components/Table/MDataTable";
import config from "../../../utils/config";
import { logFormData, validateForm } from "../../../utils/common.js";
import {
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
  PencilIcon,
  EyeIcon,
  TrashIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Button } from "@mui/material";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Roles() {
  let history = useHistory();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [ids, setIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [viewRecord, setViewRecord] = useState("");
  const [error, setError] = useState({});
  const [department, setDepartment] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [info, setInfo] = useState({
    current_order: "",
    service_description: "",
    department_id: "",
    contract_value_temp: "",
    contract_value1: "",
    contract_value: "",
    variation: "",
    observation: "",
    status: "",
  });
  const [currency, setCurrency] = useState([]);
  const [getCurrency, setSelectedCurrency] = useState("AOA");

  const [exchangeRate, setExchangeRate] = useState();
  const [showLoader2, setShowLoader2] = useState(false);

  const btnGroups = [
    { title: "Novo", type: "addNew", path: "/master/budget_map/add" },
    { title: "Activar", type: "makeActive", path: "/master" },
    { title: "Desactivar", type: "makeInactive", path: "/master" },
    { title: "Eliminar", type: "delete", path: "/master" },
  ];

  const handleSubmit = async () => {
    try {
      const includedFields = [
        "current_order",
        "service_description",
        "department_id",
        "contract_value",
        "contract_value_temp",
        "observation",
        "status",
      ];
      const validationResponse = validateForm(info, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        const { department, tableData, ...newInfo } = info;
        const insertResponse = await config.postAPI({
          url: "budgetMap/update",
          params: { fields: newInfo, id: newInfo.id },
        });

        if (insertResponse?.response?.data?.error?.message) {
          return toast.error(insertResponse?.response?.data?.error?.message);
        }
        setEditOpen(false);
        setShowModal(false);
        getList();
        setTimeout(() => {
          return toast.success("Registo actualizado com sucesso");
        }, 200);
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  function handleCancel() {
    setShowModal(false);
  }

  function updateSingle(rowData) {
    setInfo({
      ...rowData,
    });
    setEditOpen(true);
  }

  const handleChange = (e, name) => {
    let setData = {};
    let target = getCurrency;

    if (name === "currency_radio") {
      target = e;
      // if (target.checked) {
      setSelectedCurrency(e);
      // }

      setData = {
        ...info,
        [name]: target?.value,
      };
    } else {
      setData = {
        ...info,
        [name]: e?.target?.value,
      };
    }
    setInfo(setData);
    // let fieldData = setData;
    // let exchange_rate = 0;
    // if (exchangeRate) {
    //   exchangeRate.map(async (elem) => {

    //     if (elem?.budget_year?.year === setData?.budget_year?.year) {
    //       if (elem?.currency?.currency_symbol === target) {
    //         exchange_rate = elem.exchange_rate
    //       }
    //     }

    //     // if (elem?.currency?.currency_symbol === target) {
    //     //   exchange_rate = elem.exchange_rate
    //     // }
    //   })
    // }

    // // if (getCurrency === "AOA") {
    // let contract_value = +(fieldData.contract_value_temp / exchange_rate);
    // let variation = +[(fieldData.contract_value - fieldData.budget_value) / fieldData.budget_value] * 100;

    // setInfo({ ...setData, contract_value: contract_value.toFixed(2), variation: variation.toFixed(2) });
    // // } else {
    // //   let variation = +[(fieldData.contract_value - fieldData.budget_value) / fieldData.budget_value] * 100;
    // // setInfo({ ...setData, variation: variation });
    // // }
    // // let variation = +fieldData.budget_value - fieldData.contract_value;
  };

  function calculateContractValue(e) {
    let fieldData = info;
    let exchange_rate = 0;
    let isValidData = false;
    e.preventDefault();
    const includedFields = ["contract_value_temp"];
    let formIsValid = true;
    let tempError = {};
    if (
      info?.contract_value_temp === "" ||
      info?.contract_value_temp === null
    ) {
      formIsValid = false;
      tempError["contract_value_temp"] =
        "Por favor, insira o valor do contrato";
    }

    const validationResponse = validateForm(info, includedFields);
    setError({
      ...tempError,
    });
    if (validationResponse?.isValid) {
      if (exchangeRate) {
        exchangeRate.map(async (elem) => {
          if (elem?.budget_year?.year === info?.budget_year?.year) {
            if (elem?.currency?.currency_symbol === getCurrency) {
              isValidData = true;
              exchange_rate = elem.exchange_rate;
            }
          }
        });
      }
      if (exchange_rate === 0 && getCurrency !== "USD") {
        toast.error("Nenhum câmbio orçamental disponível para este ano");
      }
      let contract_value, variation;
      if (getCurrency === "USD") {
        contract_value = fieldData.contract_value_temp;
        let contract_value1 = contract_value;
        contract_value1 = currencyConvert(contract_value1);
        variation =
          parseFloat(
            (fieldData.contract_value - fieldData.budget_value) /
            fieldData.budget_value
          ) * 100;
        setInfo({
          ...info,
          contract_value: fieldData.contract_value_temp,
          contract_value1: contract_value1,
          variation: variation.toFixed(2),
        });
      } else {
        if (isValidData) {
          contract_value = parseFloat(
            fieldData.contract_value_temp / parseInt(exchange_rate)
          );
          // contract_value = +(fieldData.contract_value_temp / (exchange_rate));
          contract_value = parseFloat(contract_value).toFixed(2);
          variation =
            parseFloat(
              (fieldData.contract_value - fieldData.budget_value) /
              fieldData.budget_value
            ) * 100;
          let contract_value1 = contract_value;
          contract_value1 = currencyConvert(contract_value1);

          if (variation === NaN) {
            variation = 0;
          }
          setInfo({
            ...info,
            contract_value: contract_value,
            contract_value1: contract_value1,
            variation: variation.toFixed(2),
          });
        }
      }
    }
  }

  const getCurrencyList = async () => {
    const repsonse = await config.getAPI({
      url: "currency/list",
      params: {
        status: "Active",
      },
    });
    if (repsonse?.payload) {
      setCurrency(repsonse?.payload?.rows);
    }
  };

  const getDepartmentList = async () => {
    const repsonse = await config.getAPI({
      url: "department/list",
      params: {
        status: "Active",
      },
    });
    if (repsonse?.payload) {
      setDepartment(repsonse?.payload?.rows);
    }
  };

  const getExchangeRate = async () => {
    const repsonse = await config.getAPI({
      url: "budgetExchange/list",
      params: {
        status: "Active",
      },
    });
    if (repsonse?.payload) {
      setExchangeRate(repsonse?.payload?.rows);
    }
  };

  useEffect(() => {
    // getDepartmentList();
    getCurrencyList();
    getExchangeRate();
  }, []);

  function getSingleRowData(data) {
    if (data) {
      setViewRecord(data);
    }
    setOpen(true);
  }

  useEffect(() => { }, [setViewRecord]);

  const onSelectionChange = (e) => {
    let idArr = pluck(e, "id");
    setIds(idArr);
  };

  async function getList(params = "") {
    await config
      .getAPI({ url: "budgetMap/list", params: {} })
      .then((data) => {
        if (data.payload) {
          // data.payload.rows.map(async (row) => {
          //   row.contract_value = currencyConvert(row.contract_value)
          //   row.contract_value = row.contract_value.replace('$', '')
          // });
          setData(data.payload.rows);
          setShowLoader2(false)

        }
        if (data?.error?.message) {
          history.push("/");
          // return toast.error(data?.error?.message)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  const handleDelete = async () => {
    const sendData = {
      ids,
      type: "delete",
    };
    const response = await config.deleteAPI({
      url: "budgetMap/delete",
      params: { id: sendData?.ids },
    });

    if (!response?.payload?.success) {
      return toast.error("Falha na inserção do registro");
    }
    setShowModal(false);
    getList();
    setIds([]);
    return toast.success("Registo removido com sucesso");
  };

  const handleAction = async (type, id = "") => {
    if (ids.length < 1 && id === "") {
      return toast.error("Selecione pelo menos um registro");
    } else if (type === "delete") {
      setShowModal(true);
      return;
    }
    const sendData = {
      id: ids,
      fields: {
        status:
          type === "makeActive"
            ? "Active"
            : type === "makeInactive"
              ? "Inactive"
              : type,
      },
    };
    const response = await config.postAPI({
      url: "budgetMap/update",
      params: sendData,
    });
    if (!response?.payload?.success) {
      return toast.error("Falha na inserção do registro");
    } else {
      getList();
    }
    setIds([]);
    return toast.success("Registo actualizado com sucesso");
  };

  useEffect(() => {
    const getYearList = async () => {
      const repsonse = await config.getAPI({
        url: "budgetYear/list",
        params: {
          status: "Active",
        },
      });
      if (repsonse?.payload) {
        setYear(repsonse?.payload?.rows);
      }
    };
    getYearList();
  }, []);

  useEffect(() => {
    setShowLoader2(true)

    getList();
  }, [setData]);

  const [frmSrc, setFrmsrc] = useState("");
  const [documentInfo, setDocumentInfo] = useState({ file: "", year_id: "" });
  const [year, setYear] = useState([]);
  const ref = useRef();

  const reset = () => {
    ref.current.value = "";
  };

  const handleDocumentChange = (e, name) => {
    if (name === "file") {
      setShowLoader(true);
      const setData = {
        ...documentInfo,
        [name]: e?.target?.files?.[0],
      };
      setFrmsrc(URL.createObjectURL(e?.target?.files?.[0]));
      setDocumentInfo(setData);
      setShowLoader(false);
    } else {
      const setData = {
        ...documentInfo,
        [name]: e?.target?.value,
      };
      setDocumentInfo(setData);
    }
  };

  function getSingleRowData(data) {
    if (data) {
      setViewRecord(data);
      let resData = data;
      let pName = "";
      config
        .getAPI({
          url: "contractDocuments/get-procedure-typename-by-id",
          params: { id: data.id },
        })
        .then((data) => {
          if (data?.payload?.rows) {
            pName = pluck(data?.payload?.rows, "name");
            setViewRecord({
              ...resData,
              procedure_type_name: pName.toString(),
            });
            setOpen(true);
          } else {
            setViewRecord(resData);
            setOpen(true);
          }
        });
    }
    setOpen(true);
  }
  const currencyConvert = (price) => {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    })
      .format(Number(price))
      .replace("$", "");
  };
  const handleDocumentSubmit = async () => {
    try {
      const tempError = {};
      const includedFields = ["file", "year_id"];
      let formIsValid = true;
      if (documentInfo?.file === "") {
        tempError["file"] = "Por Favor, Insira O file";
        formIsValid = false;
      }

      if (documentInfo?.year_id === "") {
        tempError["year_id"] = "Por favor, insira o ano orçamental";
        formIsValid = false;
      }
      if (documentInfo?.file === "" || documentInfo?.year_id === "") {
        const validationResponse = validateForm(documentInfo, includedFields);
        setError({
          ...tempError,
        });
      } else {
        setShowLoader(true);
        let formData = new FormData();
        formData.append("file", documentInfo?.file);
        formData.append("year_id", documentInfo?.year_id);
        logFormData(formData);

        const insertResponse = await config
          .postFormDataAPI({
            url: "budgetMap/import-to-excel",
            params: formData,
          })
          .then(async (res) => {
            if (res) {
              setDocumentInfo({
                file: "",
                year_id: "",
              });
              setFrmsrc("");
              setError({});
              reset();
              await getList();
              setShowLoader(false);
              return toast.success("Data Import Success");
            } else {
              setDocumentInfo({
                file: "",
                year_id: "",
              });
              reset();
              setFrmsrc("");
              setError({});
              await getList();
              setShowLoader(false);
              return toast.success("Data Import Success");
            }
          });

        if (insertResponse?.response?.data?.error?.message) {
          return toast.error(insertResponse?.response?.data?.error?.message);
        } else {
          await getList();
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const columns = [
    { title: "NIC", field: "nic" },
    { title: "Ano", field: "budget_year.year" },
    { title: "Área", field: "department.name" },
    { title: "Chave Orçamental", field: "current_order" },
    { title: "Descrição do serviço", field: "service_description" },
    {
      title: "Valor orçamental (USD)",
      field: "budget_value",
      render: (rowData) => <span>{currencyConvert(rowData.budget_value)}</span>,
    },
    {
      title: "Valor do contrato (USD)",
      field: "contract_value",
      render: (rowData) => (
        <span>{currencyConvert(rowData.contract_value)}</span>
      ),
    },
    { title: "Variação (%)", field: "variation" },
    {
      title: "Estado",
      field: "status",
      render: (rowData) => (
        <span
          className={
            rowData.status === "Active"
              ? "inline-flex items-center px-3 py-0.5 rounded-full text-basefont-medium bg-lime-200 text-lime-800 capitalize"
              : "inline-flex items-center px-3 py-0.5 rounded-full text-basefont-medium bg-red-200 text-red-800 capitalize"
          }
        >
          {rowData.status === "Active" ? "Activo" : "Inactivo"}
        </span>
      ),
    },
    {
      title: "Acções",
      render: (rowData) => (
        <div className="flex">
          <Link
            className="text-indigo-600 hover:text-indigo-900 mr-4 mt-1"
            to={`/master/budget_map/edit/${rowData.id}`}
          >
            <PencilIcon className="h-5 w-5 red-link" aria-hidden="true" />
          </Link>
          <button
            className="text-indigo-600 hover:text-indigo-900 focus:outline-none mr-4"
            onClick={() => getSingleRowData(rowData)}
          >
            <EyeIcon className="red-link h-5 w-5" aria-hidden="true" />
          </button>
          <button
            className="text-indigo-600 hover:text-indigo-900 focus:outline-none mr-4"
            onClick={() => updateSingle(rowData)}
          >
            <ArrowPathIcon className="h-5 w-5 red-link" aria-hidden="true" />
          </button>
          {/* <Link
            className="text-indigo-600 hover:text-indigo-900 mr-4 mt-1"
            to={`/master/budget_map/edit/${rowData.id}`}
          >
            <ArrowPathIcon className="h-5 w-5 red-link" aria-hidden="true" />
          </Link> */}

          <button
            className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
            onClick={() => {
              setIds([rowData?.id]);
              handleAction("delete", rowData?.id);
            }}
          >
            <TrashIcon className="red-link h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          div
          className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col"
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1 pb-8">
            <div className="mt-8">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="dark:bg-black">
                  <div className="py-5 flex items-center justify-between">
                    <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
                      Mapa de Intenção Contratual
                    </h1>
                    <div className="flex items-center gap-3">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="flex items-center rounded-full border-2 py-2 px-2 dark:border-[#00FF00] border-[#EA042B] outline-none">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon
                              className="h-5 w-5 dark:text-white red-text"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-30 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              {btnGroups.map((btn) => {
                                return (
                                  <ActionButton
                                    key={btn.id}
                                    btnTitle={btn.title}
                                    btnType={btn.type}
                                    btnPath={btn.path}
                                    handleAction={handleAction}
                                  />
                                );
                              })}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <DeleteModel
                    mode={showModal}
                    handleDelete={handleDelete}
                    handleCancel={handleCancel}
                  />

                  <form enctype="multipart/form-data">
                    <div
                      className="loader-img"
                      style={{
                        display: showLoader ? "block" : "none",
                      }}
                    ></div>
                    <div className="flex flex-row justify-end mb-6 relative">
                      <div className=" text-sm text-gray-600 flex-justify-end">
                        <div
                          style={{ width: "200px", float: "left" }}
                          className="col-span-6 sm:col-span-3 mr-2 inline-block"
                        >
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Ano
                          </label>
                          <select
                            name="year_id"
                            id="year_id"
                            autoComplete="year_id"
                            value={documentInfo?.year_id}
                            onChange={(e) => handleDocumentChange(e, "year_id")}
                            className={`mt-1 inline-block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${error.year_id && "border-red-600"
                              }`}
                          >
                            <option>--- Seleccione o ano ---</option>
                            {year?.map((yearDetails) => {
                              return (
                                <option
                                  key={yearDetails.id}
                                  value={yearDetails.id}
                                >
                                  {yearDetails.year}
                                </option>
                              );
                            })}
                          </select>
                          {error.year_id && (
                            <span className="text-xs text-red-600 capitalize">
                              {error?.year_id}
                            </span>
                          )}
                        </div>
                        <div
                          style={{ float: "left" }}
                          className="col-span-6 ml-2 sm:col-span-3 mr-2 inline-block"
                        >
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          ></label>
                          <div className="mt-1 inline-block w-full">
                            <a
                              className="inline-block mt-2 mr-4 border rounded-full bg-white border-gray-300 p-2"
                              href="/sample.xlsx"
                              download="sample.xlsx"
                            >
                              <img
                                src={"/img/excel.png"}
                                alt="Profile"
                                className="mx-auto w-8 text-gray-400"
                              />
                            </a>

                            <label class="custom-file-upload mt-2 inline-block border rounded-full bg-white border-gray-300 p-2">
                              <input
                                style={{ display: "none" }}
                                type="file"
                                name="import-excel"
                                // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                ref={ref}
                                onChange={(e) =>
                                  handleDocumentChange(e, "file")
                                }
                                className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${error.file && "border-red-600"
                                  }`}
                              />

                              <img
                                src={"/img/image.png"}
                                alt="Profile"
                                className="mx-auto w-8 text-gray-400"
                              />
                            </label>
                          </div>
                          <div className="w-full">
                            {error.file && (
                              <span className="text-xs text-red-600 capitalize ere_msg">
                                {error?.file}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="text-sm text-gray-600">
                        <button
                          type="button"
                          className="ml-3 mr-2 mt-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                          onClick={handleDocumentSubmit}
                        >
                          Carregar
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="dark:bg-black">
                    <div className="flex flex-col">
                      <div className="datatable">
                        <DataTable
                          columns={columns}
                          data={data}
                          disabledCheckbox={false}
                          onSelectionChange={onSelectionChange}
                          showLoader={showLoader2}

                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900 mb-5"
                    >
                      Detalhes da intenção contratual
                    </Dialog.Title>
                    <div className="py-5">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Área
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {viewRecord?.department?.name}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Chave Orçamental
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {viewRecord.current_order}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Descrição do serviço
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {viewRecord.service_description}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Valor do contrato (USD)
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {viewRecord.contract_value}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Valor orçamental (USD)
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {viewRecord.budget_value}
                          </dd>
                        </div>

                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Variação
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {viewRecord.variation}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Observações
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {viewRecord.observation}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Estado
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {" "}
                            {viewRecord.status === "Active"
                              ? "Activo"
                              : "Inactivo"}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setEditOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setEditOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900 mb-5"
                    >
                      Registo do valor do contrato
                    </Dialog.Title>
                    <div className="py-5">
                      <div className="mt-8">
                        <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                          <form className="space-y-6" action="#" method="POST">
                            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                              <div className="md:grid md:gap-6">
                                <div className="mt-5 md:mt-0">
                                  <div className="grid grid-cols-6 gap-6">
                                    {/* <div className="col-span-6 sm:col-span-3">
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                        Área
                                      </label>
                                      <select
                                        name="department_id"
                                        id="department_id"
                                        autoComplete="department_id"
                                        value={info?.department_id}
                                        onChange={(e) => handleChange(e, "department_id")}
                                        className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${error.department_id && "border-red-600"
                                          }`}
                                      >
                                        <option>---Seleccione a área---</option>
                                        {department?.map((departmentDetails) => {
                                          return (
                                            <option
                                              key={departmentDetails.id}
                                              value={departmentDetails.id}
                                            >
                                              {departmentDetails.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {error?.department_id && (
                                        <span className="text-xs text-red-600 capitalize">
                                          {error?.department_id}
                                        </span>
                                      )}
                                    </div> */}
                                    {/*
                                    <div className="col-span-6 sm:col-span-3">
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                            Chave Orçamental
                                      </label>
                                      <input
                                        type="text"
                                        name="current_order"
                                        id="current_order"
                                        autoComplete="current_order"
                                        onChange={(e) => {
                                          handleChange(e, "current_order");
                                        }}
                                        value={info?.current_order}
                                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${error?.current_order && "border-red-600"
                                          }`}
                                      />
                                      {error?.current_order && (
                                        <span className="text-xs text-red-600 capitalize">
                                          {error?.current_order}
                                        </span>
                                      )}
                                    </div> */}

                                    {/* <div className="col-span-6 sm:col-span-3">
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                        Descrição do serviço
                                      </label>
                                      <input
                                        type="text"
                                        name="service_description"
                                        id="service_description"
                                        autoComplete="service_description"
                                        onChange={(e) => {
                                          handleChange(e, "service_description");
                                        }}
                                        value={info?.service_description}
                                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${error?.service_description && "border-red-600"
                                          }`}
                                      />
                                      {error?.service_description && (
                                        <span className="text-xs text-red-600 capitalize">
                                          {error?.service_description}
                                        </span>
                                      )}
                                    </div> */}
                                    <div className="col-span-6 sm:col-span-6">
                                      <label
                                        htmlFor="first_name"
                                        className="block text-base font-medium text-gray-700"
                                      >
                                        Ano orçamental :{" "}
                                        {info?.budget_year?.year}
                                      </label>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                      <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Valor do contrato
                                      </label>
                                      <input
                                        type="number"
                                        name="contract_value_temp"
                                        id="contract_value_temp"
                                        autoComplete="contract_value_temp"
                                        onChange={(e) => {
                                          handleChange(
                                            e,
                                            "contract_value_temp"
                                          );
                                        }}
                                        min="0"
                                        value={info?.contract_value_temp}
                                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${error?.contract_value &&
                                          "border-red-600"
                                          }`}
                                      />
                                      {error?.contract_value_temp && (
                                        <span className="text-xs text-red-600 capitalize">
                                          {error?.contract_value_temp}
                                        </span>
                                      )}
                                    </div>
                                    {/* <div className="col-span-6 sm:col-span-3">
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                        Moeda
                                      </label>

                                      {
                                        currency.map((currency, index) => {
                                          return (
                                            <>
                                              <input
                                                className='mr-1 ml-2'
                                                type="radio"
                                                value={currency.currency_symbol}
                                                checked={getCurrency === currency?.currency_symbol}
                                                // onChange={
                                                //   handleChangeRadio
                                                // }
                                                onChange={(e) => {
                                                  handleChange(e, "currency_radio");
                                                }}

                                              />{currency?.currency_symbol}
                                            </>
                                          )
                                        })

                                      }
                                      {/* {error?.contract_value && (
                                        <span className="text-xs text-red-600 capitalize">
                                          {error?.contract_value}
                                        </span>
                                      )} *
                                    </div> */}

                                    <div className="col-span-6 sm:col-span-3">
                                      <label
                                        htmlFor="first_name"
                                        className="block text-base font-medium text-gray-700"
                                      >
                                        Moeda
                                      </label>
                                      <div className="mt-1 mb-6">
                                        <RadioGroup
                                          value={currency?.currency_symbol}
                                          defaultValue={getCurrency}
                                          onChange={(e) => {
                                            handleChange(e, "currency_radio");
                                          }}
                                          checked={
                                            getCurrency ===
                                            currency?.currency_symbol
                                          }
                                        >
                                          <RadioGroup.Label className="sr-only">
                                            {" "}
                                            Privacy setting{" "}
                                          </RadioGroup.Label>
                                          <div className="sm:flex sm:items-center flex-wrap">
                                            {currency.map((currency, index) => (
                                              <RadioGroup.Option
                                                key={currency.currency_symbol}
                                                value={currency.currency_symbol}
                                                className={({ checked }) =>
                                                  classNames(
                                                    index === 0
                                                      ? "rounded-tl-md rounded-tr-md"
                                                      : "",
                                                    index ===
                                                      currency.length - 1
                                                      ? "rounded-bl-md rounded-br-md"
                                                      : "",
                                                    checked
                                                      ? "bg-white border-white mr-3 mb-3"
                                                      : "border-white mr-3 mb-3",
                                                    "relative border flex cursor-pointer focus:outline-none"
                                                  )
                                                }
                                              >
                                                {({ active, checked }) => (
                                                  <>
                                                    <span
                                                      className={classNames(
                                                        checked
                                                          ? "bg-red-600 border-transparent"
                                                          : "bg-white border-gray-300",
                                                        active
                                                          ? "ring-2 ring-offset-2 ring-red-700"
                                                          : "",
                                                        "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                                      )}
                                                      aria-hidden="true"
                                                    >
                                                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                                                    </span>
                                                    <span className="ml-3 flex flex-col">
                                                      <RadioGroup.Label
                                                        as="span"
                                                        className={classNames(
                                                          checked
                                                            ? "text-red-700"
                                                            : "text-gray-900",
                                                          "block text-sm font-medium"
                                                        )}
                                                      >
                                                        {
                                                          currency.currency_symbol
                                                        }
                                                      </RadioGroup.Label>
                                                    </span>
                                                  </>
                                                )}
                                              </RadioGroup.Option>
                                            ))}
                                          </div>
                                        </RadioGroup>
                                        <div className="mt-6">
                                          <button
                                            type="button"
                                            className="btn-link inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-[16px] font-medium shadow-sm bg-skin-primary text-skin-white rounded-md"
                                            onClick={calculateContractValue}
                                          >
                                            Calcular
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                      <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Valor do contrato (USD)
                                      </label>
                                      <input
                                        type="text"
                                        name="contract_value1"
                                        id="contract_value1"
                                        autoComplete="contract_value1"
                                        min="0"
                                        disabled
                                        value={info?.contract_value1}
                                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${error?.contract_value &&
                                          "border-red-600"
                                          }`}
                                      />
                                      <input
                                        style={{ display: "none" }}
                                        type="number"
                                        name="contract_value"
                                        id="contract_value"
                                        autoComplete="contract_value"
                                        onChange={(e) => {
                                          handleChange(e, "contract_value");
                                        }}
                                        min={0}
                                        disabled
                                        value={info?.contract_value}
                                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${error?.contract_value &&
                                          "border-red-600"
                                          }`}
                                      />
                                      {error?.contract_value && (
                                        <span className="text-xs text-red-600 capitalize">
                                          {error?.contract_value}
                                        </span>
                                      )}
                                    </div>

                                    {/* <div className="col-span-6 sm:col-span-3">
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                        Valor orçamental (USD)
                                      </label>
                                      <input
                                        type="number"
                                        name="budget_value"
                                        id="budget_value"
                                        onChange={(e) => {
                                          handleChange(e, "budget_value");
                                        }}
                                        min="0"
                                        value={info?.budget_value}
                                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${error?.contract_value && "border-red-600"
                                          }`}
                                      />
                                      {error?.budget_value && (
                                        <span className="text-xs text-red-600 capitalize">
                                          {error?.budget_value}
                                        </span>
                                      )}


                                    </div> */}

                                    {/* <div className="col-span-6 sm:col-span-3">
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                        Variação
                                      </label>
                                      <input
                                        type="number"
                                        name="variation"
                                        id="variation"
                                        placeholder="Variação 1"
                                        onChange={(e) => {
                                          handleChange(e, "variation");
                                        }}
                                        min="0"
                                        value={info?.variation}
                                        disabled
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      />
                                    </div> */}

                                    <div className="col-span-6 sm:col-span-3">
                                      <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Observações
                                      </label>
                                      <textarea
                                        name="observation"
                                        id="observation"
                                        autoComplete="given-name"
                                        onChange={(e) => {
                                          handleChange(e, "observation");
                                        }}
                                        row="5"
                                        col="5"
                                        value={info?.observation}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      />
                                    </div>

                                    {/* <div className="col-span-6 sm:col-span-3">
                                      <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                        Estado
                                      </label>
                                      <select
                                        id="status"
                                        name="status"
                                        autoComplete="status"
                                        onChange={(e) => {
                                          handleChange(e, "status");
                                        }}
                                        value={info?.status}
                                        className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${error?.status && "border-red-600"
                                          }`}
                                      >
                                        <option>---Selecione Estado---</option>
                                        <option value="Active">Activo</option>
                                        <option value="Inactive">Inactivo</option>
                                      </select>
                                      {error?.status && (
                                        <span className="text-xs text-red-600 capitalize">
                                          {error?.status}
                                        </span>
                                      )}
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="flex justify-end">
                              <button
                                type="button"
                                className="btn-link py-2 px-4 border border-gray-300 rounded-md shadow-sm text-[16px] font-medium shadow-sm bg-skin-white text-skin-black rounded-md"
                                onClick={() => setEditOpen(false)}
                              >
                                Cancelar
                              </button>
                              <button
                                type="button"
                                className="btn-link ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-[16px] font-medium shadow-sm bg-skin-primary text-skin-white rounded-md"
                                title={"Submeter"}
                                onClick={handleSubmit}
                              >
                                Submeter
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
