import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LeftNavigation from "../../../components/LeftNavigation/leftNavigation.js";
import BtnRedirect from "../../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import Header from "../../../components/Header/Header";
import config from "../../../utils/config";
import { toast } from "react-toastify";
import { validateForm } from "../../../utils/common";
import { ClockIcon, CogIcon, CreditCardIcon, DocumentChartBarIcon, HomeIcon, QuestionMarkCircleIcon, ScaleIcon, ShieldCheckIcon, UserGroupIcon, XMarkIcon } from "@heroicons/react/24/outline";
import MultiSelect from "@kenshooui/react-multi-select";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Roles({ history }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [info, setInfo] = useState({ name: "", status: "" });
  const [error, setError] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [selectedTripTabs, setSelectedTripTabs] = useState([]);
  const [tripTabList, setTripTabList] = useState([
    {
      id: 1,
      label: "Candidato - Novo",
      value: "candidate_add"
    },
    {
      id: 2,
      label: "Candidato - Editar",
      value: "candidate_edit"
    },
    {
      id: 3,
      label: "Candidato - Excluir",
      value: "candidate_delete"
    },
    {
      id: 4,
      label: "Candidato - Acções",
      value: "candidate_access"
    },
    {
      id: 5,
      label: "Concorrentes - Novo",
      value: "competitor_add"
    },
    {
      id: 6,
      label: "Concorrentes - Editar",
      value: "competitor_edit"
    },
    {
      id: 7,
      label: "Concorrentes - Excluir",
      value: "competitor_delete"
    },
    {
      id: 8,
      label: "Concorrentes - Acções",
      value: "competitor_access"
    },
    {
      id: 9,
      label: "Concurso - Editar",
      value: "contest_edit"
    },
    {
      id: 10,
      label: "Concurso - Notificação de não adjudicação",
      value: "contest_notification_of_non_award"
    },
    {
      id: 11,
      label: "Concurso - Reclamações/Impugnações",
      value: "contest_complaints_challenges"
    },
    {
      id: 12,
      label: "Concurso - Acções",
      value: "contest_access"
    },
    {
      id: 13,
      label: "Qualificação de Candidatos - avaliação",
      value: "qualification_of_candidates_evaluation"
    },
    {
      id: 14,
      label: "Avaliação de propostas - avaliação",
      value: "evaluation_of_proposals_evaluation"
    },
    {
      id: 15,
      label: "Candidato -Submissão de documentos",
      value: "candidate_document_submission"
    },
    {
      id: 16,
      label: "Concorrentes -Submissão de documentos",
      value: "competitor_document_submission"
    }
  ]);

  useEffect(() => {
    getRoleList();
  }, []);

  const getRoleList = async () => {
    try {
      const response = await config.getAPI({
        url: "evaluationCommiteeRole/list",
        params: {}
      });
      setRoleList(response.payload.rows)
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleChange = (e, name) => {
    const setData = { ...info, [name]: e?.target?.value };
    setInfo(setData);
  };

  const handleSubmit = async () => {
    try {
      let sendData = info;
      sendData = {
        ...info,
        trip_tabs: selectedTripTabs,
      };
      let includedFields = ["role_id", "trip_tabs"];
      const validationResponse = validateForm(sendData, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        sendData = {
          ...info,
          module_access: JSON.stringify(selectedTripTabs),
        };
        const insertResponse = await config.postAPI({
          url: "accessrights/create",
          params: sendData,
        });
        if (insertResponse?.response?.data?.error?.message) {
          return toast.error(insertResponse?.response?.data?.error?.message);
        }
        setTimeout(() => {
          return toast.success("Registo criado com sucesso");
        }, 200);
        history.push("/accessrights");
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  const handleChangeTab = (e) => {
    setSelectedTripTabs(e);
  };

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1 pb-8">
            <div className="mt-8">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="py-5 flex items-center justify-between">
                  <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
                    Novo Privilégios da Comissão de Avaliação
                  </h1>
                </div>
                <form className="space-y-6" action="#" method="POST">
                  <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                    <div className="md:grid md:gap-6">
                      <div className="mt-5 md:mt-0">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="role"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Papel <span className="mandatory">*</span>
                            </label>
                            <select
                              id="role_id"
                              name="role_id"
                              autoComplete="role_id"
                              onChange={(e) => {
                                handleChange(e, "role_id");
                              }}
                              value={info?.role_id}
                              className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${error?.status && "border-red-600"
                                }`}
                            >
                              <option>---Selecione Papel---</option>
                              {
                                roleList?.map((element) => {
                                  return (
                                    <>
                                      <option value={element.id}>{element.name}</option>
                                    </>
                                  )

                                })
                              }
                            </select>
                            {error?.role_id && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.role_id}
                              </span>
                            )}
                          </div>

                          <div className="col-span-12 sm:col-span-6">
                            <label
                              htmlFor="trip_tabs"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Access{" "}
                              <span className="mandatory">*</span>
                            </label>

                            <div className="mt-1">
                              <MultiSelect
                                items={tripTabList}
                                messages={{
                                  searchPlaceholder: "Procurar...",
                                  noItemsMessage: "Nenhum item...",
                                  noneSelectedMessage: "Nenhum seleccionado",
                                  selectedMessage: "selecionado",
                                  selectAllMessage: "Selecionar tudo",
                                  clearAllMessage: "Limpar tudo",
                                  disabledItemsTooltip:
                                    "Você só pode selecionar 1 arquivo",
                                }}
                                selectedItems={selectedTripTabs}
                                itemHeight={50}
                                onChange={(e) => handleChangeTab(e)}
                              />
                            </div>

                            {error?.trip_tabs && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.trip_tabs}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <BtnRedirect
                      path={"/accessrights"}
                      title={"Cancelar"}
                    />
                    <BtnSaveRecord
                      path={"/accessrights"}
                      title={"Submeter"}
                      handleSubmit={handleSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}