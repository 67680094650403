import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import Menu, { SubMenu, Item as MenuItem, Divider } from "rc-menu";
import "rc-menu/assets/index.css";

function handleClick(info) {
  console.log("");
}

// const nestSubMenu = (
//   <SubMenu
//     title={<span className="submenu-title-wrapper">Relatórios</span>}
//     key="5"
//     popupOffset={[0, 2]}
//   >
//     <MenuItem key="4-1">
//       <Link to="/overduecontracts">Contratos em Atraso</Link>
//     </MenuItem>
//     <MenuItem key="4-2">
//       <Link to="/expiredcontracts">Contratos Expirados</Link>
//     </MenuItem>
//     <MenuItem key="4-3">
//       <Link to="/activecontracts">Contratos em Execução</Link>
//     </MenuItem>
//     <MenuItem key="4-4">
//       <Link to="/completedcontracts">Contratos Concluídos</Link>
//     </MenuItem>
//   </SubMenu>
// );

function onOpenChange(value) {
  console.log("");
}

const children1 = [
  <MenuItem key="2">
    <Link to="/activities/activityview">Actividades</Link>
  </MenuItem>,
  // <MenuItem key="3">
  //   <Link to="/contracts/contracts_view">Contratos</Link>
  // </MenuItem>,
  // <MenuItem key="4">
  //   <Link to="/provider/ViewProvider">Fornecedores</Link>
  // </MenuItem>,
  // nestSubMenu,
];

const children2 = [
  <SubMenu
    title={<span className="submenu-title-wrapper">sub menu</span>}
    key="1"
  >
    <MenuItem key="1-1">0-1</MenuItem>
    <MenuItem key="1-2">0-2</MenuItem>
  </SubMenu>,
  <MenuItem key="2">1</MenuItem>,
  <MenuItem key="3">outer</MenuItem>,
];

const customizeIndicator = <span>Add More Items</span>;

class CommonMenu extends React.Component {
  state = {
    children: children1,
    overflowedIndicator: undefined,
  };
  toggleChildren = () => {
    this.setState({
      children: this.state.children === children1 ? children2 : children1,
    });
  };
  toggleOverflowedIndicator = () => {
    this.setState({
      overflowedIndicator:
        this.state.overflowedIndicator === undefined
          ? customizeIndicator
          : undefined,
    });
  };
  render() {
    const { triggerSubMenuAction } = this.props;
    const { children, overflowedIndicator } = this.state;
    return (
      <div className="w-full">
        {this.props.updateChildrenAndOverflowedIndicator && (
          <div>
            <button onClick={this.toggleChildren}>toggle children</button>
            <button onClick={this.toggleOverflowedIndicator}>
              toggle overflowedIndicator
            </button>
          </div>
        )}
        <Menu
          onClick={handleClick}
          triggerSubMenuAction={triggerSubMenuAction}
          onOpenChange={onOpenChange}
          selectedKeys={["2"]}
          mode={this.props.mode}
          defaultOpenKeys={this.props.defaultOpenKeys}
          overflowedIndicator={overflowedIndicator}
        >
          {children}
        </Menu>
      </div>
    );
  }
}

export default CommonMenu;
