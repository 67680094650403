import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import LeftNavigation from "../../components/LeftNavigation/leftNavigation.js";
import Header from "../../components/Header/Header";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
	ClockIcon,
	CogIcon,
	CreditCardIcon,
	DocumentChartBarIcon,
	HomeIcon,
	QuestionMarkCircleIcon,
	ScaleIcon,
	ShieldCheckIcon,
	UserGroupIcon,
	XMarkIcon,
	MagnifyingGlassIcon,
	ChevronLeftIcon,
	ChevronRightIcon
} from "@heroicons/react/24/outline";
import configuration from "../../utils/config.js";
import _ from "underscore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Select from "react-select";
import config from "../../utils/config.js";
import BtnSaveRecord from "../../components/Form/Button/BtnSaveRecord.js";
import BtnClearRecord from "../../components/Form/Button/BtnClearRecord.js";
import DatePicker from "react-date-picker";

const navigation = [
	{ name: "Home", href: "#", icon: HomeIcon, current: true },
	{ name: "History", href: "#", icon: ClockIcon, current: false },
	{ name: "Balances", href: "#", icon: ScaleIcon, current: false },
	{ name: "Cards", href: "#", icon: CreditCardIcon, current: false },
	{ name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
	{ name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
	{ name: "Settings", href: "#", icon: CogIcon },
	{ name: "Help", href: "#", icon: QuestionMarkCircleIcon },
	{ name: "Privacy", href: "#", icon: ShieldCheckIcon },
];


function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}
export default function Roles() {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState([]);
	const [userList, setUserList] = useState([]);
	const [total_data, setTotal_data] = useState([]);
	const [activity_count, setActivity_count] = useState(0);
	const [maxPageNo, setMaxPageNo] = useState(3);
	const [minPageNo, setMinPageNo] = useState(1);
	const [page, setPage] = useState(0);
	const [budgetYearFilter, setBudgetYearFilter] = useState({});
	const [year, setYear] = useState([]);
	const [fields2, setFields2] = useState({
		search_date: "",
		end_date: "",
	});

	useEffect(() => {
		getPagination();
		getUserList();
		getActivity({ page });
	}, []);

	const handleSubmit = async () => {
		setIsLoading(true)
		configuration.getAPI({
			url: "activity/get",
			params: fields2,
		}).then((res) => {
			setIsLoading(true);
			if (res.payload) {
				setIsLoading(true)
				setData(res.payload);
				let totalRecord = _.pluck(res.payload, "data");
				totalRecord = _.flatten(totalRecord);
				setTotal_data(totalRecord.length);
				setIsLoading(false);
			} else if (res.error) {
				return toast.error(res.error.message);
			} else {
				return toast.error("Ocorreu algum erro");
			}
		}).catch((error) => {
			console.error(error);
			return toast.error(error.message);
		});
	};


	const handleClearFilter = async () => {
		setIsLoading(true)
		getActivity();
		setFields2({ physical_code: "" });
		setBudgetYearFilter({});
	}

	const handleFilterChange = (e, field) => {
		if (field === "search_id") {
			const setData = { ...fields2, [field]: e?.value };
			setFields2(setData);
			setBudgetYearFilter(e)
		} else if (field === "physical_code") {
			const setData = { ...fields2, [field]: e?.target?.value };
			setFields2(setData);
		}
		else if (field === "search_date") {
			const setData = { ...fields2, [field]: new Date(e) };
			setFields2(setData);
		}
	};

	const getActivity = (req) => {
		let query = "";
		if (req?.page) {
			query += "?page=" + req?.page || 0;
		}

		configuration.getAPI({
			url: "activity/get",
			params: query,
		}).then((res) => {
			setIsLoading(true);
			if (res.payload) {
				setIsLoading(true)
				setData(res.payload);
				let totalRecord = _.pluck(res.payload, "data");
				totalRecord = _.flatten(totalRecord);
				setTotal_data(totalRecord.length);
				setIsLoading(false);
			} else if (res.error) {
				return toast.error(res.error.message);
			} else {
				return toast.error("Ocorreu algum erro");
			}
		}).catch((error) => {
			console.error(error);
			return toast.error(error.message);
		});
	};

	const getUserList = () => {
		configuration.getAPI({
			url: "activity/get-users",
			params: {},
		}).then((res) => {
			setIsLoading(true);
			if (res.payload) {
				setUserList(res.payload);
				const yearArr = res.payload.map(({ id, name }) => ({ value: id, label: name }))
				yearArr.unshift({ value: "", label: "Selecione o Utilizador" })
				setYear(yearArr);
			} else if (res.error) {
				return toast.error(res.error.message);
			} else {
				return toast.error("Ocorreu algum erro");
			}
		}).catch((error) => {
			console.error(error);
			return toast.error(error.message);
		});
	};

	const getPagination = () => {
		configuration.getAPI({
			url: "activity/total-activity",
			params: {},
		}).then((res) => {
			if (res.payload) {
				setIsLoading(true)
				let activity = Math.floor(parseInt(res.payload) / 10);
				activity += 1;
				setActivity_count(activity);
			} else if (res.error) {
				return toast.error(res.error.message);
			} else {
				return toast.error("Ocorreu algum erro");
			}
		}).catch((error) => {
			console.error(error);
			return toast.error(error.message);
		});
	};

	const handlePageNo = (type) => {
		if (type === "previous") {
			if (page === 0) {
			} else {
				setPage(maxPageNo - 3);
				getActivity({ page: maxPageNo - 3 });
			}

			let maxPage = maxPageNo - 3;
			setMaxPageNo(maxPage);
			setMinPageNo(maxPage - 2);
		} else {
			if (maxPageNo >= activity_count) {
			} else {
				let maxPage = maxPageNo + 3;
				setMaxPageNo(maxPage);
				setMinPageNo(maxPage - 2);

				setPage(maxPage - 3);
				getActivity({ page: maxPage - 3 });
			}
		}
	};

	const handlePagination = (i) => {
		setPage(i);
		getActivity({ page: i });
	};

	const fields = [];
	for (let i = 1; i <= activity_count; i++) {
		if (i >= minPageNo && i <= maxPageNo) {
			fields.push(
				<span
					key={i}
					href="#"
					onClick={() => handlePagination(i - 1)}
					aria-current="page"
					className={`z-10 relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-${page + 1 === i ? "indigo" : "gray"
						}-50 border-${page + 1 === i ? "indigo" : "gray"}-500 text-${page + 1 === i ? "indigo" : "gray"
						}-600`}
				>
					{i}
				</span>
			);
		}
	}

	return (
		<>
			<div className="min-h-full">
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-40 lg:hidden"
						onClose={setSidebarOpen}
					>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
						</Transition.Child>

						<div className="fixed inset-0 z-40 flex">
							<Transition.Child
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute top-0 right-0 -mr-12 pt-2">
											<button
												type="button"
												className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
												onClick={() => setSidebarOpen(false)}
											>
												<span className="sr-only">Close sidebar</span>
												<XMarkIcon
													className="h-6 w-6 text-white"
													aria-hidden="true"
												/>
											</button>
										</div>
									</Transition.Child>
									<div className="flex flex-shrink-0 items-center px-4">
										<img
											className="h-8 w-auto"
											src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
											alt="Easywire logo"
										/>
									</div>
									<nav
										className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
										aria-label="Sidebar"
									>
										<div className="space-y-1 px-2">
											{navigation.map((item) => (
												<a
													key={item.name}
													href={item.href}
													className={classNames(
														item.current
															? "bg-skin-primary text-white"
															: "text-white hover:text-white left-menu-btn",
														"group flex items-center px-2 py-2 text-base font-medium rounded-md"
													)}
													aria-current={item.current ? "page" : undefined}
												>
													<item.icon
														className="mr-4 h-6 w-6 flex-shrink-0 text-white"
														aria-hidden="true"
													/>
													{item.name}
												</a>
											))}
										</div>
										<div className="mt-6 pt-6">
											<div className="space-y-1 px-2">
												{secondaryNavigation.map((item) => (
													<a
														key={item.name}
														href={item.href}
														className={classNames(
															item.current
																? "bg-skin-primary text-white"
																: "text-white hover:text-white left-menu-btn",
															"group flex items-center px-2 py-2 text-base font-medium rounded-md"
														)}
													>
														<item.icon
															className="mr-4 h-6 w-6 text-white"
															aria-hidden="true"
														/>
														{item.name}
													</a>
												))}
											</div>
										</div>
									</nav>
								</Dialog.Panel>
							</Transition.Child>
							<div className="w-14 flex-shrink-0" aria-hidden="true">
								{/* Dummy element to force sidebar to shrink to fit close icon */}
							</div>
						</div>
					</Dialog>
				</Transition.Root>

				{/* Static sidebar for desktop */}
				<div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<LeftNavigation />
				</div>

				<div className="flex flex-1 flex-col lg:pl-64">
					<Header />
					<main className="flex-1 pb-8">
						<div className="mt-8">
							<div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
								<div className="py-5 flex items-center justify-between">
									<h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
										Actividades
									</h1>
								</div>

								<div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
									<div className="md:grid md:gap-6">
										<div className="mt-5 md:mt-0">
											<div className="space-y-8">
												<div className="pt-1">
													<div className="flex flex-col">
														<div className="datatable">
															<div className="flex w-3/4 m-5">
																<div className="relative w-80	 lg:max-w-md">
																	<label
																		htmlFor="first-name"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Utilizador
																	</label>
																	<Select
																		className="w-full text-gray-500 bg-white outline-none appearance-none focus:border-indigo-600 "
																		value={budgetYearFilter}
																		placeholder="Selecione o Utilizador"
																		onChange={(e) => {
																			handleFilterChange(e, "search_id");
																		}}
																		options={year}
																		isSearchable={true}
																	/>
																</div>
																<div className="relative mx-10 w-80	 lg:max-w-md">
																	<label
																		htmlFor="first-name"
																		className="block text-sm font-medium text-gray-700"
																	>
																		código físico
																	</label>
																	<input
																		type="text"
																		name="physical_code"
																		id="physical_code"
																		autoComplete="given-name"
																		className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
																		onChange={(e) =>
																			handleFilterChange(e, "physical_code")
																		}
																		value={fields2?.physical_code}
																	/>
																</div>
															</div>
															<div className="flex w-3/4 m-5">
																<div className="relative w-80	 lg:max-w-md">
																	<label
																		htmlFor="first-name"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Data
																	</label>
																	<DatePicker
																		className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
																		onChange={(e) => {
																			handleFilterChange(e, "search_date");
																		}}
																		value={fields2?.search_date}
																	/>
																</div>
															</div>
															<div className="flex w-full items-center my-5 mx-2 justify-between">
																<div className="flex w-auto justify-between">
																	<BtnSaveRecord
																		title="Aplicar Filtro"
																		handleSubmit={handleSubmit}
																	/>
																	<BtnClearRecord
																		title="Limpar"
																		handleSubmit={handleClearFilter}
																	/>
																</div>
															</div>
														</div>
													</div>
													<div className="mx-auto w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
														<Tab.Group as="div">
															<div className="border-b border-gray-200">
																<Tab.List className="-mb-px flex space-x-8">
																	<Tab
																		className={({ selected }) =>
																			classNames(
																				selected
																					? "border-red-600 text-red-600"
																					: "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
																				"whitespace-nowrap border-b-2 py-6 text-base font-bold"
																			)
																		}
																	>
																		Actividades recentes
																	</Tab>
																	{/* <Tab
                                    className={({ selected }) =>
                                      classNames(
                                        selected
                                          ? "border-red-600 text-red-600"
                                          : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                        "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                      )
                                    }
                                  >
                                    Actividades por Utilizador
                                  </Tab> */}
																</Tab.List>
															</div>
															<Tab.Panels as={Fragment}>
																<Tab.Panel className="text-base text-gray-500">
																	<h3 className="sr-only">
																		Actividades recentes
																	</h3>
																	{isLoading ? (
																		<div class="loader-img"></div>
																	) : data.length > 0 ? (
																		<>
																			<VerticalTimeline>
																				{data.map((singleData) => {
																					return (
																						<>
																							{
																								singleData.day == moment().format("YYYY-MM-DD") ?
																									<VerticalTimelineElement
																										className="vertical-timeline-element--work"
																										contentStyle={{
																											background: "rgb(185, 26, 0)",
																											color: "#fff",
																										}}
																										contentArrowStyle={{
																											borderRight:
																												"7px solid  rgb(185, 26, 0)",
																										}}
																										date=""
																										style={{ color: "#232733" }}
																										iconStyle={{
																											background: "rgb(33, 150, 243)",
																											color: "#fff",
																										}}
																										icon={<ClockIcon />}
																									>
																										{singleData.data.map((singleActivity) => {
																											return (
																												<>
																													<div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
																														<div className="lg:w-full">
																															<div>
																																<ul
																																	role="list"
																																	className="divide-y divide-gray-200"
																																>
																																	{/* {activityItems.map((item) => ( */}
																																	<li
																																		key=""
																																		className="py-2"
																																	>
																																		<div className="flex space-x-3">
																																			<div className="flex-1 space-y-1">
																																				<div class="flex items-center space-x-4">
																																					<div class="flex-shrink-0">
																																						{singleActivity?.file && singleActivity?.file !== "" ? (
																																							<img
																																								className="inline-block h-12 w-12 rounded-full"
																																								src={
																																									typeof singleActivity?.file === "object"
																																										? URL.createObjectURL(singleActivity?.file)
																																										: `${configuration.USER_MODULE_PATH + "user/" +
																																										singleActivity?.user_id +
																																										"/" +
																																										singleActivity?.file
																																										}`
																																								}
																																								onError={(e) =>
																																								(e.target.src =
																																									"/img/defaultAvatar.svg")
																																								}
																																								alt=""
																																							/>
																																						) : (
																																							<img
																																								className="inline-block h-12 w-12 rounded-full"
																																								src={"/img/defaultAvatar.svg"}
																																								alt=""
																																							/>
																																						)}

																																					</div>
																																					<div class="min-w-0 flex-1">
																																						<h3 class="truncate text-base font-bold text-white">
																																							{singleActivity.name}
																																						</h3>
																																						<h4 class="truncate text-sm text-white">
																																							{singleActivity.role}
																																						</h4>
																																					</div>
																																					<div>{singleActivity.time}</div>
																																				</div>
																																				<p className="text-sm text-white">
																																					{singleActivity.message}
																																				</p>
																																			</div>
																																		</div>
																																	</li>
																																	<hr></hr>
																																</ul>
																															</div>
																														</div>
																													</div>
																												</>
																											);
																										})}
																									</VerticalTimelineElement>
																									:
																									<VerticalTimelineElement
																										className="vertical-timeline-element--work"
																										contentStyle={{
																											background: "rgb(243, 244, 246)",
																											color: "#000000",
																										}}
																										contentArrowStyle={{
																											borderRight:
																												"7px solid  rgb(243, 244, 246)",
																										}}
																										date={moment(singleData.day).format("DD/MM/YYYY")}
																										style={{ color: "#232733" }}
																										iconStyle={{
																											background: "rgb(33, 150, 243)",
																											color: "#fff",
																										}}
																										icon={<ClockIcon />}
																									>
																										{singleData.data.map((singleActivity) => {
																											return (
																												<>
																													<div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0" >
																														<div className="lg:w-full">
																															<div>
																																<ul
																																	role="list"
																																	className="divide-y divide-gray-200"
																																>
																																	{/* {activityItems.map((item) => ( */}
																																	<li
																																		key=""
																																		className="py-2"
																																	>
																																		<div className="flex space-x-3">
																																			<div className="flex-1 space-y-1">
																																				<div class="flex items-center space-x-4">
																																					<div class="flex-shrink-0">
																																						{singleActivity?.file && singleActivity?.file !== "" ? (
																																							<img
																																								className="inline-block h-12 w-12 rounded-full"
																																								src={
																																									typeof singleActivity?.file === "object"
																																										? URL.createObjectURL(singleActivity?.file)
																																										: `${configuration.USER_MODULE_PATH + "user/" +
																																										singleActivity?.user_id +
																																										"/" +
																																										singleActivity?.file
																																										}`
																																								}
																																								onError={(e) =>
																																								(e.target.src =
																																									"/img/defaultAvatar.svg")
																																								}
																																								alt=""
																																							/>
																																						) : (
																																							<img
																																								className="inline-block h-12 w-12 rounded-full"
																																								src={"/img/defaultAvatar.svg"}
																																								alt=""
																																							/>
																																						)}

																																					</div>
																																					<div class="min-w-0 flex-1">
																																						<h3 class="truncate text-base text-black">
																																							{singleActivity.name}
																																						</h3>
																																						<h4 class="truncate text-sm text-black" style={{ fontWeight: 300 }}>
																																							{singleActivity.role}
																																						</h4>
																																					</div>
																																					<div>{singleActivity.time}</div>
																																				</div>
																																				<p className="text-sm text-black" style={{ fontWeight: 400 }}>
																																					{singleActivity.message}
																																				</p>
																																			</div>
																																		</div>
																																	</li>
																																	<hr></hr>
																																</ul>
																															</div>
																														</div>
																													</div>
																												</>
																											);
																										})}
																									</VerticalTimelineElement>
																							}
																						</>
																					);
																				})}

																			</VerticalTimeline>
																			<div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
																				<div className="flex-1 flex justify-between sm:hidden">
																					<span className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
																						Previous
																					</span>
																					<span className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
																						Próximo
																					</span>
																				</div>
																				<div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
																					<div>
																					</div>
																					<div>
																						<nav
																							className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
																							aria-label="Pagination"
																						>
																							{page > 2 ? (
																								<span
																									href="#"
																									onClick={() => handlePageNo("previous")}
																									className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
																								>
																									<span className="sr-only">Previous</span>
																									<ChevronLeftIcon
																										className="h-5 w-5"
																										aria-hidden="true"
																									/>
																								</span>
																							) : (
																								<span></span>
																							)}
																							{fields}
																							{maxPageNo < activity_count ? (
																								<span
																									onClick={() => handlePageNo("next")}
																									className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
																								>
																									<span className="sr-only">Next</span>
																									<ChevronRightIcon
																										className="h-5 w-5"
																										aria-hidden="true"
																									/>
																								</span>
																							) : null}
																						</nav>
																					</div>
																				</div>
																			</div>
																		</>
																	) : (
																		<div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
																			<div className="max-w-max mx-auto">
																				<main className="sm:flex">
																					<div className="sm:ml-6">
																						<div className="sm:pl-6">
																							<h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
																								Nenhuma atividade encontrada
																							</h1>
																						</div>
																					</div>
																				</main>
																			</div>
																		</div>
																	)}
																</Tab.Panel>

																{/* <Tab.Panel className="text-base text-gray-500">
                                  <h3 className="sr-only">
                                    Actividades por Utilizador
                                  </h3>

                                  <div className="mx-auto max-w-4xl py-16 px-4 sm:px-6 lg:px-8">
                                    <div className="rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:p-8">
                                      <div className="mt-0">
                                        <label
                                          htmlFor="search"
                                          className="sr-only"
                                        >
                                          Search
                                        </label>
                                        <div className="relative mt-1 rounded-md shadow-sm">
                                          <div
                                            className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                            aria-hidden="true"
                                          >
                                            <MagnifyingGlassIcon
                                              className="mr-3 h-4 w-4 text-gray-400"
                                              aria-hidden="true"
                                            />
                                          </div>
                                          <input
                                            type="text"
                                            name="search"
                                            id="search"
                                            className="block w-full rounded-md border-gray-300 pl-9 focus:border-indigo-500 focus:ring-indigo-500 sm:text-base"
                                            placeholder="Procurar utilizador"
                                          />
                                        </div>
                                      </div>
                                      <nav
                                        className="h-full overflow-y-auto"
                                        aria-label="Directory"
                                      >
                                        {Object.keys(directory).map(
                                          (letter) => (
                                            <div
                                              key={letter}
                                              className="relative"
                                            >
                                              <div className="sticky top-0 z-10 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                                                <h3></h3>
                                              </div>
                                              <ul
                                                role="list"
                                                className="relative z-0 divide-y divide-gray-200"
                                              >
                                                {userList.map(
                                                  (person) => (
                                                    <li
                                                      key={person.id}
                                                      className="bg-white"
                                                    >
                                                      <div className="relative flex items-center space-x-3 px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 hover:bg-gray-50">
                                                        <div className="flex-shrink-0">
                                                          {
                                                            person?.profile && person?.profile !== "" ? (
                                                              <img
                                                                className="h-10 w-10 rounded-full"
                                                                src={
                                                                  typeof person?.profile === "object"
                                                                    ? URL.createObjectURL(person?.profile)
                                                                    : `${configuration.USER_MODULE_PATH + "user/" +
                                                                    person?.id +
                                                                    "/" +
                                                                    person?.profile
                                                                    }`
                                                                }
                                                                onError={(e) =>
                                                                (e.target.src =
                                                                  "/img/defaultAvatar.svg")
                                                                }
                                                                alt=""
                                                              />
                                                            ) : (
                                                              <img
                                                                className="h-10 w-10 rounded-full"
                                                                src={"/img/defaultAvatar.svg"}
                                                                alt=""
                                                              />
                                                            )
                                                          }
                                                        </div>
                                                        <div className="min-w-0 flex-1">
                                                          <a
                                                            href="#"
                                                            className="focus:outline-none"
                                                          >
                                                            <span
                                                              className="absolute inset-0"
                                                              aria-hidden="true"
                                                            />
                                                            <p className="text-sm font-medium text-gray-900">
                                                              {person.name}
                                                            </p>
                                                            <p className="truncate text-sm text-gray-500">
                                                              Contratos
                                                            </p>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </div>
                                          )
                                        )}
                                      </nav>
                                    </div>
                                  </div>
                                </Tab.Panel> */}
															</Tab.Panels>
														</Tab.Group>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
		</>
	);
}
