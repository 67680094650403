import { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import LeftNavigation from "../../../components/LeftNavigation/leftNavigation.js";
import Header from "../../../components/Header/Header";
import ActionButton from "../../../components/Other/ActionButton";
import { toast } from "react-toastify";
import { pluck } from "underscore";
import DeleteModel from "../../../components/Other/DeleteModel";
import DataTable from "../../../components/Table/MDataTable";
import config from "../../../utils/config";
import {
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
  PencilIcon,
  EyeIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Roles() {
  let history = useHistory();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [ids, setIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [viewRecord, setViewRecord] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const btnGroups = [
    { title: "Novo", type: "addNew", path: "/master/supplier-type/add" },
    { title: "Activar", type: "makeActive", path: "/master" },
    { title: "Desactivar", type: "makeInactive", path: "/master" },
    { title: "Eliminar", type: "delete", path: "/master" },
  ];

  function handleCancel() {
    setShowModal(false);
  }

  function getSingleRowData(data) {
    if (data) {
      setViewRecord(data);
    }
    setOpen(true);
  }

  useEffect(() => { }, [setViewRecord]);

  function getList(params = "") {
    config
      .getAPI({ url: "supplierType/list", params: {} })
      .then((data) => {
        if (data.payload) {
          setData(data.payload.rows);
          setShowLoader(false)
        }
        if (data?.error?.message) {
          history.push("/");
          // return toast.error(data?.error?.message)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  const handleDelete = async () => {
    const sendData = {
      ids,
      type: "delete",
    };
    const response = await config.deleteAPI({
      url: "supplierType/delete",
      params: { id: sendData?.ids },
    });

    if (!response?.payload?.success) {
      return toast.error("Falha na inserção do registro");
    }
    setShowModal(false);
    getList();
    setIds([]);
    return toast.success("Registo removido com sucesso");
  };

  const handleAction = async (type, id = "") => {
    if (ids.length < 1 && id === "") {
      return toast.error("Selecione pelo menos um registro");
    } else if (type === "delete") {
      setShowModal(true);
      return;
    }

    const sendData = {
      id: ids,
      fields: {
        status:
          type === "makeActive"
            ? "Active"
            : type === "makeInactive"
              ? "Inactive"
              : type,
      },
    };
    const response = await config.postAPI({
      url: "supplierType/update",
      params: sendData,
    });
    if (!response?.payload?.success) {
      return toast.error("Falha na inserção do registro");
    } else {
      getList();
    }
    setIds([]);
    return toast.success("Registo actualizado com sucesso");
  };

  useEffect(() => {
    setShowLoader(true)
    getList();
  }, [setData]);

  const onSelectionChange = (e) => {
    let idArr = pluck(e, "id");
    setIds(idArr);
  };
  const columns = [
    { title: "Nome", field: "name" },
    {
      title: "Estado",
      field: "status",
      render: (rowData) => (
        <span
          className={
            rowData.status === "Active"
              ? "inline-flex items-center px-3 py-0.5 rounded-full text-basefont-medium bg-lime-200 text-lime-800 capitalize"
              : "inline-flex items-center px-3 py-0.5 rounded-full text-basefont-medium bg-red-200 text-red-800 capitalize"
          }
        >
          {rowData.status === "Active" ? "Activo" : "Inactivo"}
        </span>
      ),
    },
    {
      title: "Acções",
      render: (rowData) => (
        <div className="flex">
          <Link
            className="text-indigo-600 hover:text-indigo-900 mr-4 mt-1"
            to={`/master/supplier-type/edit/${rowData.id}`}
          >
            <PencilIcon className="h-5 w-5 red-link" aria-hidden="true" />
          </Link>
          <button
            className="text-indigo-600 hover:text-indigo-900 focus:outline-none mr-4"
            onClick={() => getSingleRowData(rowData)}
          >
            <EyeIcon className="red-link h-5 w-5" aria-hidden="true" />
          </button>

          <button
            className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
            onClick={() => {
              setIds([rowData?.id]);
              handleAction("delete", rowData?.id);
            }}
          >
            <TrashIcon className="red-link h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          div
          className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col"
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1 pb-8">
            <div className="mt-8">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="dark:bg-black">
                  <div className="py-5 flex items-center justify-between">
                    <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
                      Tipo de fornecedor
                    </h1>
                    <div className="flex items-center gap-3">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="flex items-center rounded-full border-2 py-2 px-2 dark:border-[#00FF00] border-[#EA042B] outline-none">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon
                              className="h-5 w-5 dark:text-white red-text"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-30 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              {btnGroups.map((btn) => {
                                return (
                                  <ActionButton
                                    key={btn.id}
                                    btnTitle={btn.title}
                                    btnType={btn.type}
                                    btnPath={btn.path}
                                    handleAction={handleAction}
                                  />
                                );
                              })}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <DeleteModel
                    mode={showModal}
                    handleDelete={handleDelete}
                    handleCancel={handleCancel}
                  />
                  <div className="dark:bg-black">
                    <div className="flex flex-col">
                      <div className="datatable">
                        <DataTable
                          columns={columns}
                          data={data}
                          disabledCheckbox={false}
                          onSelectionChange={onSelectionChange}
                          showLoader={showLoader}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900 mb-5"
                    >
                      Detalhes da tipo de fornecedor
                    </Dialog.Title>
                    <div className="py-5">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Nome
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {viewRecord.name}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Estado
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {" "}
                            {viewRecord.status === "Active"
                              ? "Activo"
                              : "Inactivo"}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
