import { React, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { validateForm } from "../../../src/utils/common";
import { toast } from "react-toastify";
import config from "../../../src/utils/config";
import Cookies from "js-cookie";
export default function Example() {
  let history = useHistory();
  const [fields, setFields] = useState();
  const [error, setError] = useState();
  const OPTIONS = {
    CONTRACT: process.env.REACT_APP_CONTRACT_LINK,
    AREA_MANAGER: process.env.REACT_APP_AREA_MANAGER_LINK,
    CONCURRING: process.env.REACT_APP_CONCURRING_LINK,
    EVCOMMITEE: process.env.REACT_APP_EVCOMMITEE_LINK
  };

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value });
  }

  const login = async () => {
    try {
      const includedFields = ["email", "password"];
      const validationResponse = validateForm(fields, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        const loginResponse = await config.postAPI({
          url: "auth/login",
          params: fields,
        });

        if (loginResponse.error) {
          return toast.error(loginResponse.error.message);
        } else {
          Cookies.set("G-accessToken", loginResponse?.payload?.token, { domain: process.env.REACT_APP_DOMAIN_NAME });
          Cookies.set("dataKey", JSON.stringify(loginResponse?.payload), { domain: process.env.REACT_APP_DOMAIN_NAME });
          Cookies.set("area", loginResponse?.payload?.area, { domain: process.env.REACT_APP_DOMAIN_NAME });
          Cookies.set("areaID", loginResponse?.payload.area_id, { domain: process.env.REACT_APP_DOMAIN_NAME });
          Cookies.set("user_id", loginResponse?.payload?.uId, { domain: process.env.REACT_APP_DOMAIN_NAME });
          Cookies.set("role", loginResponse?.payload?.roleCode, { domain: process.env.REACT_APP_DOMAIN_NAME });
          if (OPTIONS?.[loginResponse?.payload?.roleCode]) {
            window.location.replace(OPTIONS?.[loginResponse?.payload?.roleCode] + "/dashboard");
          } else {
            // history.push("/setting");
            history.push("/activities/activityview");
          }
        }
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      login()
    }
  };

  return (
    <>
      <div className="bg-gray-50 h-screen">
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src="img/logo-front.png"
              alt="Your Company"
            />
            {/* <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Faça login em sua conta</h2> */}
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form className="space-y-6" action="#" method="POST">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    E-mail
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => handleChange("email", e)}
                      onKeyDown={(e) => handleKeyDown(e)}
                    />
                    {error?.email && (
                      <span className="text-xs text-red-600 capitalize">
                        {error?.email}
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Senha
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => handleChange("password", e)}
                      onKeyDown={(e) => handleKeyDown(e)}
                    />
                    {error?.password && (
                      <span className="text-xs text-red-600 capitalize">
                        {error?.password}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      Lembra-me
                    </label>
                  </div>

                  <div className="text-sm">
                    <Link
                      to="/forgot-password"
                      className="font-medium link text-skin-primary"
                    >
                      {" "}
                      Esqueci a senha
                    </Link>
                  </div>
                </div>

                <div>
                  {/* <Link to="/setting"> */}
                  <button
                    type="button"
                    className="flex w-full justify-center bg-skin-primary btn-link rounded-md border border-transparent  py-2 px-4 font-medium text-white shadow-sm focus:outline-none"
                    onClick={() => login()}
                  >
                    Entrar
                  </button>
                  {/* </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
