import React from "react";

function ClearRecord({ title, handleSubmit }) {
  const handleClick = () => {
    handleSubmit();
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className="btn-link py-2 mx-2 px-4 border border-gray-300 rounded-md shadow-sm text-[16px] font-medium shadow-sm bg-skin-white text-skin-black rounded-md"
    >
      {title}
    </button>
  );
}

export default ClearRecord;
