import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import LeftNavigation from "../../components/LeftNavigation/leftNavigation.js";
import Header from "../../components/Header/Header";
import { toast } from "react-toastify";
import DataTable from "../../components/Table/MDataTable";
import Status from "../../constants/status";
import {
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
  PencilIcon,
  EyeIcon,
  TrashIcon,
  PlusIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import moment from 'moment';
import { reactLocalStorage } from "reactjs-localstorage";
import config from "../../utils/config";
import DatePicker from "react-date-picker";
import Cookies from "js-cookie";
import { validateForm } from "../../utils/common.js";

let user_id = "";
const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Roles() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [ids, setIds] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [deletedDoc, setDeletedDoc] = useState();
  const [selectedRecordInfo, setSelectedRecordInfo] = useState({});
  const [fields, setFields] = useState({});
  const [framSrc, setFrameSrc] = useState("");
  const [open2, setOpen2] = useState(false);
  const [tab, setTab] = useState(1);
  const [error, setError] = useState({});
  const [contract_document, setFile] = useState({ preview: "", raw: "" });
  const [paymentFrameSrc, setPaymentFrameSrc] = useState("");
  const [physicalCode, setPhysicalCode] = useState("");

  useEffect(() => {
    setShowLoader(true)
    user_id = reactLocalStorage.get("user_id");
    id && getListExecustionContract();
    id && contractDetails();
  }, []);

  const getListExecustionContract = () => {
    config.getAPI({ url: "contractexecution/list", params: { contract_id: id } }).then((data) => {
      if (data.payload) {
        setData(data.payload.rows);
        setShowLoader(false)
      }
    }).catch((error) => {
      return toast.error(error.message);
    });
  }

    const contractDetails = () => {
    try {
      config.getAPI({ url: "contract/getdatabyId", params: { id } }).then((data) => {
        if (data.payload) {
          setPhysicalCode(data.payload?.physical_code)
        }
      }).catch((error) => {
        return toast.error(error.message);
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const getDataByID = (id) => {
    setShowLoader(true)
    setIsUpdate(true)
    setUpdateId(id)
    config.getAPI({ url: "contractexecution/getdatabyId", params: { id } }).then((data) => {
      if (data.payload) {
        setFields({ ...data.payload, execution_date: new Date(data.payload?.execution_date) });
        setFrameSrc(config.staticPath + data.payload?.evidence);
        setFrameSrc(config.staticPath + data.payload?.invoice);
        setDeletedDoc(data.payload?.invoice)
        setShowLoader(false)
        setOpen2(true)
      }
    }).catch((error) => {
      return toast.error(error.message);
    })
  }

  function showPDF(rowData) {
    setOpen(true);
    setFrameSrc(config.staticPath + rowData?.evidence);
    setSelectedRecordInfo(rowData)
  }

  const handleChange = (field, e) => {
    if (field === "execution_date") {
      const setData = {
        ...fields,
        [field]: new Date(e),
      };
      setFields(setData);
    } else {
      setFields({ ...fields, [field]: e.target.value });
    }
  };


  const calculation = () => {
    if (!fields.amount) {
      setError({ ...error, "amount": "Por favor, insira o Montante Pago" })
    } else {
      setError({ ...error, "amount": "" })
    }
    const calculatedAmount = ((Number(fields.amount) / fields?.contracts.contract_value) * 100).toFixed(2)
    setFields({ ...fields, ['calculated_amount']: calculatedAmount })
  }

  const handleSubmit = async () => {
    try {
      const includedFields = [
        "description",
        "evidence",
        "execution_date",
        "physical_execution",
        "amount"
      ];
      let invoiceError = {};
      let isValid = true;
      if (fields?.invoice === undefined || fields?.invoice == null || !fields?.invoice) {
        invoiceError["invoice"] = "Por Favor, Selecione Factura";
        isValid = false
      }
      const validationResponse = validateForm(fields, includedFields);
      setError({ ...validationResponse?.errors, ...invoiceError });
      if (validationResponse?.isValid && isValid) {
        setShowLoader(true);
        setOpen2(false)
        let formData = new FormData();
        formData.append("user_id", JSON.parse(Cookies.get("dataKey")).uId);
        formData.append("id", updateId);
        formData.append("amount", fields?.amount);
        formData.append("calculated_amount", fields?.calculated_amount);
        formData.append("invoice", fields?.invoice);
        formData.append("contract_id", id);

        if (deletedDoc !== fields.invoice) {
          formData.append("deletedDoc", deletedDoc);
        }

        const insertResponse = await config.postFormDataAPI({
          url: "contractexecution/update-from-contract",
          params: formData,
        });

        if (insertResponse?.response?.data?.error?.message) {
          setShowLoader(false)
          return toast.error(insertResponse?.response?.data?.error?.message);
        }
        getListExecustionContract()
        setShowLoader(false);
        setOpen2(false);
        setFields({});
        setDeletedDoc("")
        if (isUpdate) {
          return toast.success("Registro atualizado com sucesso");
        } else {
          return toast.success("Registro adicionado com sucesso");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const columns = [
    { title: "Descrição da execução", field: "description" },
    { title: "Execução Física (%)", field: "physical_execution" },
    {
      title: "Data da Execução",
      field: "execution_date",
      render: (rowData) => (rowData = moment(rowData.execution_date).format("DD-MM-YYYY")),
    },
    {
      title: "Estado",
      field: "status",
      render: (rowData) => (
        <span className={getClassName(rowData.status)}>
          {" "}
          {Status[rowData.status]}
        </span>
      ),
    },
    {
      title: "Acções",
      render: (rowData) => (
        <div className="flex">
          {/* <button
            className="text-indigo-600 hover:text-indigo-900 focus:outline-none mr-4"
            onClick={() => getDataByID(rowData.id)}
          >
            <PencilIcon
              className="flex-shrink-0 h-5 w-5 red-link"
              aria-hidden="true"
            />
          </button> */}

          <button
            className="text-indigo-600 hover:text-indigo-900 focus:outline-none mr-4"
            onClick={() => {
              showPDF(rowData)
            }}
          >
            <EyeIcon
              className="flex-shrink-0 h-5 w-5 red-link"
              aria-hidden="true"
            />
          </button>
          {/* <button
            className="text-indigo-600 hover:text-indigo-900 focus:outline-none mr-4"
            onClick={() => {
              setIds([rowData?.id]);
              handleAction("delete", rowData?.id);
            }}
          >
            <TrashIcon
              className="flex-shrink-0 h-5 w-5 red-link"
              aria-hidden="true"
            />
          </button> */}

          <button
            className="text-indigo-600 hover:text-indigo-900 focus:outline-none mr-4"
            onClick={() => getDataByID(rowData.id)}
          >
            <ArrowPathIcon
              className="flex-shrink-0 h-5 w-5 red-link"
              aria-hidden="true"
            />
          </button>
        </div>
      ),
    },
  ];

  const getClassName = (status) => {
    if (status === "execution") {
      return "inline-flex items-center px-3 py-0.5 rounded-full text-base font-normal bg-indigo-200 text-indigo-800";
    } else if (status === "delayed" || status === "expired") {
      return "inline-flex items-center px-3 py-0.5 rounded-full text-base font-normal bg-red-200 text-red-800";
    } else if (status === "approved") {
      return "inline-flex items-center px-3 py-0.5 rounded-full text-base font-normal bg-lime-200 text-lime-800";
    } else {
      return "inline-flex items-center px-3 py-0.5 rounded-full text-base font-normal bg-yellow-200 text-yellow-800";
    }
  };
  const [showModal2, setShowModal2] = React.useState(false);

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div
          div
          className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col"
        >
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1 pb-8">
            <div
              className="loader-img"
              style={{
                display: showLoader ? "block" : "none",
              }}
            >
            </div>
            <div className="mt-8">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="dark:bg-black">
                  <div className="py-5 flex items-center justify-between">
                    <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
                      Execução do contrato
                    </h1>
                  </div>
                  <div className="mt-2 mb-3 flex flex-wrap items-center justify-between sm:flex-nowrap">
                    <div className="mt-2">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Código do contrato : {physicalCode}
                      </h3>
                    </div>
                    {/* <div className="ml-4 mt-2 flex-shrink-0 pb-1">
                      <button
                        type="button"
                        className="ml-3 mt-0 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-skin-primary hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 xl-admin-btn"
                        onClick={() => setOpen2(true)}
                      >
                        <PlusIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div> */}
                  </div>

                  <div className="dark:bg-black">
                    <div className="flex flex-col">
                      <div className="datatable view_request_wrap">
                        <DataTable
                          columns={columns}
                          data={data}
                          disabledCheckbox={false}
                        // onSelectionChange={onSelectionChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900 mb-5"
                    >
                      Detalhes da execução do contrato
                    </Dialog.Title>
                    <div className="py-5">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Descrição da execução
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {selectedRecordInfo?.description}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Execução física (%)
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {selectedRecordInfo?.physical_execution}
                          </dd>
                        </div>

                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Data da Execução
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {moment(selectedRecordInfo?.execution_date).format("DD-MM-YYYY")}
                          </dd>
                        </div>
                      </dl>
                      {framSrc !== "" && (
                        <iframe
                          style={{ height: "45vh", display: "block" }}
                          src={framSrc}
                          title="document"
                          className="w-4/6 mx-auto mt-5"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={open2} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen2}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpen2(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900"
                    >
                      {
                        isUpdate ? "Nova execução de contrato" : "Editar a execução do contrato"
                      }

                    </Dialog.Title>
                    <div className="pt-1">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 py-4">
                        <div className="pb-5 col-span-6">
                          <div className="py-2 align-middle inline-block min-w-full">
                            <div className="shadow overflow-hidden sm:rounded-lg bg-white">
                              <div className="lg:gap-y-0 lg:gap-x-8 sm:p-6 border border-gray-200 bg-gray-50">
                                <div
                                  className="loader-img"
                                  style={{
                                    display: showLoader ? "block" : "none",
                                  }}
                                ></div>
                                <div className="space-y-8 divide-y divide-gray-200">
                                  {tab === 1 ?
                                    <div className='w-full'>
                                      <div className="pt-1">
                                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                          <div className="sm:col-span-3">
                                            <label
                                              htmlFor="first_name"
                                              className="block text-base font-medium text-gray-700"
                                            >
                                              Descrição da execução
                                            </label>
                                            <div className="mt-1">
                                              <input
                                                type="text"
                                                name="payment-condition"
                                                id="payment-condition"
                                                autoComplete="given-name"
                                                placeholder='Descrição da execução'
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base"
                                                onChange={(e) => handleChange('description', e)}
                                                value={fields?.description}
                                                disabled
                                              />
                                              {error?.description && (
                                                <span className="text-xs text-red-600 capitalize">
                                                  {error?.description}
                                                </span>
                                              )}
                                            </div>
                                          </div>

                                          <div className="sm:col-span-3">
                                            <label
                                              htmlFor="last_name"
                                              className="block text-base font-medium text-gray-700"
                                            >
                                              Execução física (%)
                                            </label>
                                            <div className="mt-1">
                                              <input
                                                type="text"
                                                name="payment-condition"
                                                id="payment-condition"
                                                autoComplete="given-name"
                                                placeholder='96%'
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base"
                                                onChange={(e) => handleChange('physical_execution', e)}
                                                value={fields?.physical_execution}
                                                disabled
                                              />
                                              {error?.physical_execution && (
                                                <span className="text-xs text-red-600 capitalize">
                                                  {error?.physical_execution}
                                                </span>
                                              )}
                                            </div>
                                          </div>

                                          <div className="sm:col-span-3">
                                            <label
                                              htmlFor="last_name"
                                              className="block text-base font-medium text-gray-700"
                                            >
                                              Montante Pago
                                            </label>
                                            <div className="mt-1">
                                              <input
                                                type="text"
                                                name="amount"
                                                id="amount"
                                                autoComplete="given-name"
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base"
                                                onChange={(e) => handleChange('amount', e)}
                                                value={fields?.amount}
                                              />
                                              {error?.amount && (
                                                <span className="text-xs text-red-600 capitalize">
                                                  {error?.amount}
                                                </span>
                                              )}
                                            </div>
                                            <button className="mt-2 inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white" style={{ float: "right" }}
                                              onClick={() => calculation()}
                                            >calculate</button>
                                          </div>

                                          <div className="sm:col-span-3">
                                            <label
                                              htmlFor="first_name"
                                              className="block text-base font-medium text-gray-700"
                                            >
                                              Data da Execução
                                            </label>
                                            <div className="mt-1 relative">
                                              <DatePicker
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                onChange={(e) => {
                                                  handleChange("execution_date", e);
                                                }}
                                                value={fields?.execution_date}
                                                disabled
                                              />
                                              {error?.execution_date && (
                                                <span className="text-xs text-red-600 capitalize">
                                                  {error?.execution_date}
                                                </span>
                                              )}
                                            </div>
                                          </div>

                                          <div className="sm:col-span-3">
                                            <label
                                              htmlFor="last_name"
                                              className="block text-base font-medium text-gray-700"
                                            >
                                              Montante Pago (%)
                                            </label>
                                            <div className="mt-1">
                                              <input
                                                disabled
                                                type="text"
                                                name="calculated_amount"
                                                id="calculated_amount"
                                                autoComplete="given-name"
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base"
                                                onChange={(e) => handleChange('calculated_amount', e)}
                                                value={fields?.calculated_amount}
                                              />
                                              {error?.calculated_amount && (
                                                <span className="text-xs text-red-600 capitalize">
                                                  {error?.calculated_amount}
                                                </span>
                                              )}
                                            </div>
                                          </div>

                                          <div className="sm:col-span-6 mb-4">
                                            <label
                                              htmlFor="first_name"
                                              className="block text-base font-medium text-gray-700"
                                            >
                                              Evidência/Relatório
                                            </label>
                                            {framSrc !== "" ? (
                                              <iframe
                                                style={{ height: "45vh", display: "block" }}
                                                src={framSrc}
                                                title="document"
                                                className="w-4/6 mx-auto mt-5"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {/* <div className="w-4/6 mx-auto mt-5">
                                              <div
                                                className={`${error?.evidence && "border-red-600"
                                                  }  mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md`}
                                              >
                                                <div className="space-y-1 text-center">
                                                  {contract_document.preview ? (
                                                    <img
                                                      src={"/img/done.svg"}
                                                      alt="Profile"
                                                      className="mx-auto h-12 w-12 text-gray-400"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={"/img/pdf.png"}
                                                      alt="Profile"
                                                      className="mx-auto h-12 w-12 text-gray-400"
                                                    />
                                                  )}
                                                  <div className="flex text-sm text-gray-600">
                                                    <label
                                                      htmlFor="file-upload"
                                                      className={`relative cursor-pointer rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500`}
                                                    >
                                                      <span>Submeta o documento em .PDF</span>
                                                      <input
                                                        accept="application/pdf"
                                                        id="file-upload"
                                                        name="file-upload"
                                                        type="file"
                                                        className={` sr-only`}
                                                        onChange={(e) => {
                                                          setFrameSrc(
                                                            URL.createObjectURL(
                                                              e.target.files[0]
                                                            )
                                                          );
                                                          setFields({
                                                            ...fields,
                                                            evidence:
                                                              e.target.files[0],
                                                          });
                                                        }}
                                                      />
                                                      {error?.docError && (
                                                        <span className="text-xs text-red-600 capitalize">
                                                          {error?.docError}
                                                        </span>
                                                      )}
                                                    </label>
                                                  </div>

                                                  <p className="text-xs text-gray-500">
                                                    PDF até 10MB
                                                  </p>
                                                </div>
                                              </div>
                                              {error?.evidence && (
                                                <span className="text-xs text-red-600 capitalize">
                                                  {error?.evidence}
                                                </span>
                                              )}
                                            </div> */}
                                          </div>


                                          <div className="sm:col-span-6 mb-4">
                                            <label
                                              htmlFor="first_name"
                                              className="block text-base font-medium text-gray-700"
                                            >
                                              Factura
                                            </label>
                                            {paymentFrameSrc !== "" ? (
                                              <iframe
                                                style={{ height: "45vh", display: "block" }}
                                                src={paymentFrameSrc}
                                                title="document"
                                                className="w-4/6 mx-auto mt-5"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            <div className="w-4/6 mx-auto mt-5">
                                              <div
                                                className={`${error?.invoice && "border-red-600"
                                                  }  mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md`}
                                              >
                                                <div className="space-y-1 text-center">
                                                  {contract_document.preview ? (
                                                    <img
                                                      src={"/img/done.svg"}
                                                      alt="Profile"
                                                      className="mx-auto h-12 w-12 text-gray-400"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={"/img/pdf.png"}
                                                      alt="Profile"
                                                      className="mx-auto h-12 w-12 text-gray-400"
                                                    />
                                                  )}
                                                  <div className="flex text-sm text-gray-600">
                                                    <label
                                                      htmlFor="file-upload"
                                                      className={`relative cursor-pointer rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500`}
                                                    >
                                                      <span>Submeta o documento em .PDF</span>
                                                      <input
                                                        accept="application/pdf"
                                                        id="file-upload"
                                                        name="file-upload"
                                                        type="file"
                                                        className={` sr-only`}
                                                        onChange={(e) => {
                                                          setPaymentFrameSrc(
                                                            URL.createObjectURL(
                                                              e.target.files[0]
                                                            )
                                                          );
                                                          setFields({
                                                            ...fields,
                                                            invoice:
                                                              e.target.files[0],
                                                          });
                                                        }}
                                                      />
                                                      {error?.invoice && (
                                                        <span className="text-xs text-red-600 capitalize">
                                                          {error?.invoice}
                                                        </span>
                                                      )}
                                                    </label>
                                                  </div>

                                                  <p className="text-xs text-gray-500">
                                                    PDF até 10MB
                                                  </p>
                                                </div>
                                              </div>
                                              {error?.invoice && (
                                                <span className="text-xs text-red-600 capitalize">
                                                  {error?.invoice}
                                                </span>
                                              )}
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                      <div className="pt-5">
                                        <div className="flex justify-end">
                                          <button
                                            type="button"
                                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                            onClick={() => setOpen2(false)}
                                          >
                                            Cancelar
                                          </button>
                                          <button
                                            type="button"
                                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                            onClick={() => handleSubmit()}
                                          >
                                            Submeter
                                          </button>
                                        </div>
                                      </div>
                                    </div> : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}