import React, { useLayoutEffect } from "react";
import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Menu, Transition, Tab } from "@headlessui/react";
import LeftNavigation from "../../components/LeftNavigation/leftNavigation.js";
import "@kenshooui/react-multi-select/dist/style.css";
import Header from "../../components/Header/Header";
import DataTable from "../../components/MDataTable";
import { pluck, filter, find, findWhere } from "underscore";
import {
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
  TrashIcon,
  CheckIcon,
  PencilIcon,
  EyeIcon,
  EllipsisVerticalIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import config from "../../utils/config";
import { validateForm, logFormData } from "../../utils/common";
import { toast } from "react-toastify";
import moment from "moment/moment.js";
import Select from "react-select";
import MultiSelect from "@kenshooui/react-multi-select";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];

const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

const steps1 = [
  { id: "01", name: "Informações Gerais", href: "#", status: "current" },
  { id: "02", name: "Peças do Procedimento", href: "#", status: "upcoming" },
];
const steps2 = [
  { id: "01", name: "Informações Gerais", href: "#", status: "complete" },
  { id: "02", name: "Peças do Procedimento", href: "#", status: "current" },
];

const steps3 = [
  { id: "01", name: "Informações Gerais", href: "#", status: "complete" },
  { id: "02", name: "Peças do Procedimento", href: "#", status: "complete" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Roles({ history }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tab, setTab] = useState(1);
  const { id } = useParams();
  const [fields, setFields] = useState();
  const [pTData, setPTData] = useState();
  const [oTData, setOTData] = useState();
  const [error, setError] = useState({});
  const [cDData, setCDData] = useState();
  const [documentInfo, setDocumentInfo] = useState({
    contract_doc_id: "",
    file: "",
  });
  const [contestDocument, setContestDocument] = useState([]);
  const [selectedDocInfo, setSelectedDocInfo] = useState({
    contract_doc_id: "",
    id: "",
    file: "",
  });
  const [open1, setOpen1] = useState(false);
  const [open, setOpen] = useState(false);
  const [editDocFrmSrc, setEditDocFrmSrc] = useState("");
  const [deletedDocID, setDeletedDocID] = useState("");
  const [cDDetails, setCDDetails] = useState();
  const [frmSrc, setFrmsrc] = useState("");
  const [selectedOTID, setSelectedOTID] = useState(null);
  const [selectedPTID, setSelectedPTID] = useState(null);
  const [selectedCDID, setSelectedCDID] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [ciNumber, setCiNumber] = useState("");
  const [isShowTab, setIsShowTab] = useState(false);
  const [qualificationCandidateData, setQualificationCandidateData] = useState(
    []
  );
  const [tabEvaluationData, setTabEvaluationData] = useState([]);
  const [evaluationDiv, setEvaluationDiv] = useState([
    {
      evaluation_criteria_id: "",
      weight: 0,
      fieldArr: [
        {
          attribute_id: "",
          puncuation: "",
        },
      ],
    },
  ]);
  const [rTTabs, setRTTabs] = useState([]);
  const [showModal1, setShowModal1] = React.useState(false);
  const [evaluationDivNew, setEvaluationDivNew] = useState([]);
  const [open3, setOpen3] = useState(false);
  const [attrList, setAttrList] = useState();
  const [showThirdTab, setShowThirdTab] = useState(false);

  const changeinPortuguese = (status) => {
    if (status === "Active") {
      return (status = "Ativo");
    } else if (status === "Approved") {
      return (status = "Aprovado");
    } else if (status === "Rejected") {
      return (status = "Rejeitada");
    } else if (status === "Inactive") {
      return (status = "Inativa");
    } else if (status === "Correct") {
      return (status = "Correto");
    } else if (status === "Pending") {
      return (status = "Pendente");
    }
  };

  const getClassName = (status) => {
    if (status === "execution") {
      return "inline-flex items-center px-3 py-0.5 rounded-full text-base font-normal bg-indigo-200 text-indigo-800";
    } else if (
      status === "delayed" ||
      status === "expired" ||
      status === "Rejected"
    ) {
      return "inline-flex items-center px-3 py-0.5 rounded-full text-base font-normal bg-red-200 text-red-800";
    } else if (status === "Approved" || status === "Correct") {
      return "inline-flex items-center px-3 py-0.5 rounded-full text-base font-normal bg-lime-200 text-lime-800";
    } else {
      return "inline-flex items-center px-3 py-0.5 rounded-full text-base font-normal bg-yellow-200 text-yellow-800";
    }
  };

  const handleChangeEvaluation = (e, field, divIndex, attrIndex) => {
    evaluationDiv[divIndex][field] = e.target.value;
    if (attrIndex === 0 || attrIndex > 0) {
      evaluationDiv[divIndex].fieldArr[attrIndex][field] = e.target.value;
    }
    setEvaluationDiv(evaluationDiv);
  };

  const deleteContestDoc = async (id, contest_id, file_name) => {
    const response = await config.deleteAPI({
      url: "contestDocument/delete",
      params: { id, file_name },
    });

    if (!response?.payload?.success) {
      return toast.error("Falha na inserção do registro");
    }

    documentList(contest_id);
    return toast.success("Registo removido com sucesso");
  };

  const updateEvaluationData = async () => {
    try {
      const sendData = {
        trip_request_id: tripID,
        evaluationDiv,
      };
      const insertResponse = await config.postAPI({
        url: "tripEvaluationProposal/update",
        params: sendData,
      });

      if (insertResponse?.response?.data?.error?.message) {
        return toast.error(insertResponse?.response?.data?.error?.message);
      }
      setOpen3(false);

      setTimeout(() => {
        return toast.success("Registo criado com sucesso");
      }, 200);

      config
        .getAPI({
          url: "contest/list-qualification-candidate-docs",
          params: { ci_number: ciNumber },
        })
        .then((data) => {
          if (data.payload) {
            setIsShowTab(true);
            setTripID(tripID);
            getEvaluationData(tripID);
            data.payload.tripQCIDs.map((element) => {
              element.label = element.qualification_documents.name;
            });
            setQualificationCandidateData(data.payload.tripQCIDs);

            //start
            config
              .getAPI({
                url: "contest/list-evaluation-proposal-data",
                params: { trip_id: tripID },
              })
              .then((data) => {
                if (data.payload) {
                  setTabEvaluationData(data.payload);
                }
              })
              .catch((error) => {
                return toast.error(error.message);
              });
            //end
          } else {
            setError({ ci_number: "Número de CI inválido" });
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  const updateproposalStatus = async (status) => {
    const insertResponse = await config.postAPI({
      url: "contest/update-proposal-attribute-status",
      params: { tripID, status },
    });
    if (insertResponse?.response?.data?.error?.message) {
      return toast.error(insertResponse?.response?.data?.error?.message);
    }

    config
      .getAPI({
        url: "contest/list-qualification-candidate-docs",
        params: { ci_number: ciNumber },
      })
      .then((data) => {
        if (data.payload) {
          setIsShowTab(true);
          setTripID(tripID);
          getEvaluationData(tripID);
          data.payload.tripQCIDs.map((element) => {
            element.label = element.qualification_documents.name;
          });
          setQualificationCandidateData(data.payload.tripQCIDs);

          //start
          config
            .getAPI({
              url: "contest/list-evaluation-proposal-data",
              params: { trip_id: tripID },
            })
            .then((data) => {
              if (data.payload) {
                setTabEvaluationData(data.payload);
              }
            })
            .catch((error) => {
              return toast.error(error.message);
            });
          //end
        } else {
          setError({ ci_number: "Número de CI inválido" });
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    return toast.success("Registo criado com sucesso");
  };

  const handleOpen3 = (data) => {
    const newData = findWhere(evaluationDiv, {
      trip_evaluation_proposal_id: data.id,
    });
    setEvaluationDivNew([newData]);
    setOpen3(true);
  };

  const handleDocumentSubmit = async () => {
    try {
      const tempError = {};
      if (id !== "" || id !== undefined) {
        if (documentInfo?.file === "") {
          tempError["file"] = "Please select file";
        }
        if (documentInfo?.contract_doc_id === "") {
          tempError["contract_doc_id"] = "Please select Documento";
        }
        if (Object.keys(tempError).length !== 0) {
          setError(tempError);
        } else {
          setShowLoader(true);
          let formData = new FormData();
          formData.append("file", documentInfo?.file);
          formData.append("contract_doc_id", documentInfo?.contract_doc_id);
          formData.append("contest_id", id);
          logFormData(formData);
          const insertResponse = await config.postFormDataAPI({
            url: "contestDocument/create",
            params: formData,
          });
          setShowLoader(false);

          if (insertResponse?.response?.data?.error?.message) {
            return toast.error(insertResponse?.response?.data?.error?.message);
          }
          setDocumentInfo({
            contract_doc_id: "",
            file: "",
          });
          setFrmsrc("");
          documentList(id);
          setError({});
        }
      } else {
        console.log("failed to updalod");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const showContestDocDetails = async (id) => {
    const selectedData = find(contestDocument, function (num) {
      return num.id === id;
    });
    setCDDetails(selectedData);
    setOpen1(true);
  };

  const updateQC = async (status) => {
    let updateData = { status, tripID };
    if (status === "Correct") {
      updateData = { ...updateData, selectedQC };
    }
    const updateResponse = await config.postAPI({
      url: "contest/update-qualification-candidate-docs",
      params: updateData,
    });
    if (updateResponse?.response?.data?.error?.message) {
      return toast.error(updateResponse?.response?.data?.error?.message);
    }
    setShowModal1(false);
    showTabData();
    setSelectedQC([]);
  };

  const showTabData = async () => {
    const includedFields = ["ci_number"];
    const validationResponse = validateForm(
      { ci_number: ciNumber },
      includedFields
    );
    setError(validationResponse?.errors);
    if (validationResponse?.isValid) {
      const checkCINumber = await config.getAPI({
        url: "contest/check-ci-number-exist-with-contest",
        params: { contest_id: id, ci_number: ciNumber, type: "update" },
      });
      if (checkCINumber.error) {
        setShowLoader(false);
        setCiNumber("");
        setIsShowTab(false);
        return toast.error(checkCINumber.error.message.message);
      } else {
        getCiNumber(ciNumber);
      }
    }
  };

  const handleSubmit = async (type) => {
    try {
      const includedFields = [
        "contest_reference",
        "object_tender_id",
        "description_of_contest_type",
        "procedure_type_id",
      ];
      const validationResponse = validateForm(fields, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        setShowLoader(true);
        const insertResponse = await config.postAPI({
          url: "contest/update",
          params: { fields, id: id },
        });
        setShowLoader(false);

        if (insertResponse?.response?.data?.error?.message) {
          return toast.error(insertResponse?.response?.data?.error?.message);
        }

        setTimeout(() => {
          return toast.success("Registo actualizado com sucesso");
        }, 200);
        if (type) {
          history.push("/contest/ViewContests");
        }
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  const [contract_document, setFile] = useState({ preview: "", raw: "" });
  const [ids, setIds] = useState([]);
  const [tripID, setTripID] = useState();
  const [selectedQC, setSelectedQC] = useState([]);
  const [eCList, setECList] = useState();

  const onSelectionChange = (e) => {
    let idArr = pluck(e, "contract_part");
    setIds(idArr);
  };

  const columns1 = [
    {
      title: "Peças do Procedimento",
      field: "contract_doc.name",
    },
    { title: "Ficheiro", field: "file_name" },
    {
      title: "Data de submissão",
      field: "createdAt",
      render: (rowData) => (rowData = moment(rowData).format("DD-MM-YYYY")),
    },
    {
      title: "Acções",
      render: (rowData) => (
        <span className="flex">
          <button
            className="red-link focus:outline-none mr-3"
            onClick={() => {
              setOpen(true);
              setSelectedDocInfo({
                contract_doc_id: rowData?.contract_doc_id,
                id: rowData?.id,
                fileName: rowData?.file_name,
              });
              setDeletedDocID(rowData?.file_name);
              setEditDocFrmSrc(config.staticPath + "contest/" + id + "/" +rowData?.file_name);
            }}
          >
            <PencilIcon
              className="flex-shrink-0 h-5 w-5"
              aria-hidden="true"
              onClick={() => setOpen(true)}
            />
          </button>

          <button
            className="red-link focus:outline-none mr-3"
            onClick={() =>
              deleteContestDoc(
                rowData.id,
                rowData.contest_id,
                rowData.file_name
              )
            }
          >
            <TrashIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          </button>
          <button
            className="red-link focus:outline-none"
            onClick={() => showContestDocDetails(rowData.id)}
          >
            <EyeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
            {/* Edit  */}
          </button>
        </span>
      ),
    },
  ];

  const columns4 = [
    {
      title: "Documentos de Qualificação",
      field: "qualification_documents.name",
    },
    {
      title: "Estado",
      field: "status",
      render: (rowData) => (
        <span className={getClassName(rowData.status)}>
          {changeinPortuguese(rowData.status)}
        </span>
      ),
    },
  ];

  const handleChange = (e, field) => {
    if (field === "procedure_type_id") {
      const tempData = find(pTData, function (singlePT) {
        return singlePT.id === e.value;
      });
      setRTTabs(JSON.parse(tempData.contest_tabs));
      if (JSON.parse(tempData.contest_tabs).length > 0) {
        if (steps1.length == 2) {
          steps1.push({
            id: "03",
            name: "Critérios de avaliação",
            href: "#",
            status: "upcoming",
          });
          steps2.push({
            id: "03",
            name: "Critérios de avaliação",
            href: "#",
            status: "upcoming",
          });
          steps3.push({
            id: "03",
            name: "Critérios de avaliação",
            href: "#",
            status: "current",
          });
        }
        setShowThirdTab(true);
      } else {
        steps1.length === 3 && steps1.pop();
        steps2.length === 3 && steps2.pop();
        steps3.length === 3 && steps3.pop();
        setShowThirdTab(false);
      }
      const data = { ...fields, [field]: e?.value };
      setFields(data);
      setSelectedPTID(e);
    } else if (field === "object_tender_id") {
      const data = { ...fields, [field]: e?.value };
      setFields(data);
      setSelectedOTID(e);
    } else {
      const data = {
        ...fields,
        [field]: e?.target?.value,
      };
      setFields(data);
    }
  };

  function getObjectTenderData() {
    config
      .getAPI({ url: "objectTender/list", params: { status: "Active" } })
      .then((data) => {
        if (data.payload) {
          data.payload.rows.map((element) => {
            element.value = element.id;
            element.label = element.name;
          });
          setOTData(data.payload.rows);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function procedureTypeData() {
    config
      .getAPI({ url: "procedureType/list", params: {} })
      .then((data) => {
        if (data.payload) {
          data.payload.rows.map((element) => {
            element.value = element.id;
            element.label = element.name;
          });
          setPTData(data.payload.rows);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useLayoutEffect(() => {
    procedureTypeData();
    getObjectTenderData();
  }, []);

  useLayoutEffect(() => {
    setShowLoader(true);
    config
      .getAPI({ url: "contest/getdatabyId", params: { id } })
      .then((data) => {
        if (data.payload) {
          setFields(data.payload.response);
          if (data.payload?.response?.ci_number) {
            setCiNumber(data.payload.response.ci_number);
            getCiNumber(data.payload?.response?.ci_number);
          }
          setSelectedOTID({
            value: data.payload.object_tender_id,
            label: data.payload.otRes.name,
          });
          setSelectedPTID({
            value: data.payload.procedure_type_id,
            label: data.payload.ptRes.name,
          });

          //start
          data.payload.response?.procedure_type_id &&
            config
              .getAPI({
                url: "procedureType/getDataById",
                params: { id: data.payload.response?.procedure_type_id },
              })
              .then((data1) => {
                if (data1.payload) {
                  setRTTabs(JSON.parse(data1.payload.contest_tabs));
                  if (JSON.parse(data1.payload.contest_tabs).length > 0) {
                    if (steps1.length == 2) {
                      steps1.push({
                        id: "03",
                        name: "Critérios de avaliação",
                        href: "#",
                        status: "upcoming",
                      });
                      steps2.push({
                        id: "03",
                        name: "Critérios de avaliação",
                        href: "#",
                        status: "upcoming",
                      });
                      steps3.push({
                        id: "03",
                        name: "Critérios de avaliação",
                        href: "#",
                        status: "current",
                      });
                    }
                    setShowThirdTab(true);
                  } else {
                    steps1.length === 3 && steps1.pop();
                    steps2.length === 3 && steps2.pop();
                    steps3.length === 3 && steps3.pop();
                    setShowThirdTab(false);
                  }
                }
              })
              .catch((error) => {
                console.log("error", error);
              });
          //end
          setShowLoader(false);
        }
        if (data?.error?.message) {
          history.push("/");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  function handleTab() {
    handleSubmit();
    contractDocsList();
    documentList();
    setTab(2);
  }

  function contractDocsList() {
    config
      .getAPI({
        url: "contest/list-contract-docs",
        params: { procedure_type_id: fields.procedure_type_id },
      })
      .then((data) => {
        if (data.payload) {
          const dataFilter = filter(data.payload, function (num) {
            return num.contract_doc_id !== null;
          });
          dataFilter.map((element) => {
            element.value = element.contract_doc_id;
            element.label = element.contract_documents.name;
          });

          setCDData(dataFilter);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const documentList = async (contest_id) => {
    config
      .getAPI({ url: "contestDocument/list", params: { contest_id: id } })
      .then((data) => {
        if (data.payload) {
          setContestDocument(data.payload.rows);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const getEvaluationData = (trip_id) => {
    config
      .getAPI({
        url: "tripEvaluationProposal/getdatabyId",
        params: { trip_request_id: trip_id },
      })
      .then((data) => {
        if (data.payload) {
          let tempData = [];
          data.payload.response.rows.map((single) => {
            let obj = {
              evaluation_criteria_id: single.evaluation_criteria_id,
              weight: single.weight,
              trip_evaluation_proposal_id: single.id,
            };
            obj.fieldArr = data.payload.response2.rows.filter((data) => {
              if (data.trip_evaluation_proposal_id === single.id) {
                return data;
              }
            });
            tempData.push(obj);
          });
          setEvaluationDiv(tempData);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const handleDocumentUpdate = async () => {
    let formData = new FormData();
    formData.append("id", selectedDocInfo?.id);
    formData.append("contract_doc_id", selectedDocInfo?.contract_doc_id);
    formData.append("contest_id", id);
    if (
      deletedDocID !== selectedDocInfo?.file &&
      selectedDocInfo?.file !== undefined
    ) {
      formData.append("file", selectedDocInfo?.file);
      formData.append("deletedDoc", deletedDocID);
    }
    config
      .postFormDataAPI({ url: "contestDocument/update", params: formData })
      .then((data) => {
        if (data.payload) {
          documentList(id);
          setOpen(false);
          setSelectedDocInfo({
            file: "",
            id: "",
            contract_doc_id: "",
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        return toast.error(error.message);
      });
  };

  const handleDocumentChange = (e, name) => {
    if (name === "file") {
      const setData = {
        ...documentInfo,
        [name]: e?.target?.files?.[0],
      };
      setFrmsrc(URL.createObjectURL(e?.target?.files?.[0]));
      setDocumentInfo(setData);
    } else {
      const setData = {
        ...documentInfo,
        [name]: e?.target?.value,
      };
      setDocumentInfo(setData);
    }
  };

  const handleChangeContractDoc = (e) => {
    setDocumentInfo({
      contract_doc_id: e.contract_doc_id,
    });
    setSelectedCDID({
      value: e.contract_doc_id,
      label: e.contract_documents.name,
    });
  };

  const addCiNumber = async () => {
    try {
      await config.postAPI({
        url: "contest/add-ci-number",
        params: {
          contest_id: id,
          ci_number: ciNumber,
          trip_request_id: tripID,
        },
      });
      history.push("/contest/ViewContests");
    } catch (error) {
      return toast.error(error);
    }
  };

  const getCiNumber = async (ciNumber) => {
    setShowLoader(true);
    config
      .getAPI({
        url: "contest/list-qualification-candidate-docs",
        params: { ci_number: ciNumber },
      })
      .then((data) => {
        if (data.payload) {
          setShowLoader(false);
          setIsShowTab(true);
          setTripID(data.payload.trip_id);
          getEvaluationData(data.payload.trip_id);
          data.payload.tripQCIDs.map((element) => {
            element.label = element.qualification_documents.name;
          });
          setQualificationCandidateData(data.payload.tripQCIDs);
          //start
          config
            .getAPI({
              url: "contest/list-evaluation-proposal-data",
              params: { trip_id: data.payload.trip_id },
            })
            .then((data) => {
              if (data.payload) {
                setTabEvaluationData(data.payload);
              }
            })
            .catch((error) => {
              return toast.error(error.message);
            });
          //end
        } else {
          setShowLoader(false);
          setError({ ci_number: "Registo não encontrado" });
          setIsShowTab(false);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true"></div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1 pb-8">
            <div
              className="loader-img"
              style={{
                display: showLoader ? "block" : "none",
              }}
            ></div>
            <div className="mt-8">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="py-5 flex items-center justify-between">
                  <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
                    Editar Concurso
                  </h1>
                </div>
                <div className="space-y-6">
                  <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                    <div className="space-y-8 divide-y divide-gray-200">
                      {tab === 1 ? (
                        <div className="w-full">
                          <div className="mb-6">
                            <nav aria-label="Progress">
                              <ol
                                role="list"
                                className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
                              >
                                {steps1.map((step, stepIdx) => (
                                  <li
                                    key={step.name}
                                    className="relative md:flex md:flex-1"
                                  >
                                    {step.status === "complete" ? (
                                      <a
                                        href={step.href}
                                        className="group flex w-full items-center"
                                      >
                                        <span className="flex items-center px-6 py-4 text-base font-medium">
                                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-600">
                                            <CheckIcon
                                              className="h-6 w-6 text-white"
                                              aria-hidden="true"
                                            />
                                          </span>
                                          <span className="ml-4 text-base font-medium text-gray-900">
                                            {step.name}
                                          </span>
                                        </span>
                                      </a>
                                    ) : step.status === "current" ? (
                                      <a
                                        href={step.href}
                                        className="flex items-center px-6 py-4 text-base font-medium"
                                        aria-current="step"
                                      >
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-red-600">
                                          <span className="text-red-600">
                                            {step.id}
                                          </span>
                                        </span>
                                        <span className="ml-4 text-base font-medium text-red-600">
                                          {step.name}
                                        </span>
                                      </a>
                                    ) : (
                                      <a
                                        href={step.href}
                                        className="group flex items-center"
                                      >
                                        <span className="flex items-center px-6 py-4 text-base font-medium">
                                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                            <span className="text-gray-500 group-hover:text-gray-900">
                                              {step.id}
                                            </span>
                                          </span>
                                          <span className="ml-4 text-base font-medium text-gray-500 group-hover:text-gray-900">
                                            {step.name}
                                          </span>
                                        </span>
                                      </a>
                                    )}

                                    {stepIdx !== steps1.length - 1 ? (
                                      <>
                                        {/* Arrow separator for lg screens and up */}
                                        <div
                                          className="absolute top-0 right-0 hidden h-full w-5 md:block"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            className="h-full w-full text-gray-300"
                                            viewBox="0 0 22 80"
                                            fill="none"
                                            preserveAspectRatio="none"
                                          >
                                            <path
                                              d="M0 -2L20 40L0 82"
                                              vectorEffect="non-scaling-stroke"
                                              stroke="currentcolor"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </div>
                                      </>
                                    ) : null}
                                  </li>
                                ))}
                              </ol>
                            </nav>
                          </div>
                          <div className="pt-1">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Referência do Concurso
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="contest_reference"
                                    id="contest_reference"
                                    autoComplete="given-reference"
                                    placeholder="34/ANPG-DAF/2022"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                    onChange={(e) =>
                                      handleChange(e, "contest_reference")
                                    }
                                    value={fields?.contest_reference}
                                  />
                                  {error?.contest_reference && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.contest_reference}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Objecto do Concurso
                                </label>
                                <div className="mt-1">
                                  <Select
                                    value={selectedOTID}
                                    placeholder="Seleccionar Objecto do Concurso"
                                    onChange={(e) =>
                                      handleChange(e, "object_tender_id")
                                    }
                                    options={oTData}
                                    isSearchable={true}
                                  />

                                  {error?.object_tender_id && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.object_tender_id}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Descrição do objecto do concurso
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="reference"
                                    id="reference"
                                    autoComplete="given-reference"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                    value={fields?.description_of_contest_type}
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        "description_of_contest_type"
                                      )
                                    }
                                  />
                                  {error?.description_of_contest_type && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.description_of_contest_type}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Tipo de Procedimento
                                </label>
                                <div className="mt-1">
                                  <Select
                                    value={selectedPTID}
                                    placeholder="Seleccionar Tipo de Procedimento"
                                    onChange={(e) =>
                                      handleChange(e, "procedure_type_id")
                                    }
                                    options={pTData}
                                    isSearchable={true}
                                  />

                                  {error?.procedure_type_id && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.procedure_type_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                onClick={() =>
                                  history.push("/contest/ViewContests")
                                }
                              >
                                Cancelar
                              </button>

                              <button
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                onClick={() => handleSubmit("save_and_exit")}
                              >
                                Guardar e Sair
                              </button>
                              <button
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                onClick={() => handleTab()}
                              >
                                Próximo
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {tab === 2 ? (
                        <div className="w-full">
                          <div>
                            <nav aria-label="Progress">
                              <ol
                                role="list"
                                className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
                              >
                                {steps2.map((step, stepIdx) => (
                                  <li
                                    key={step.name}
                                    className="relative md:flex md:flex-1"
                                  >
                                    {step.status === "complete" ? (
                                      <a
                                        href={step.href}
                                        className="group flex w-full items-center"
                                      >
                                        <span className="flex items-center px-6 py-4 text-base font-medium">
                                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-600">
                                            <CheckIcon
                                              className="h-6 w-6 text-white"
                                              aria-hidden="true"
                                            />
                                          </span>
                                          <span className="ml-4 text-base font-medium text-gray-900">
                                            {step.name}
                                          </span>
                                        </span>
                                      </a>
                                    ) : step.status === "current" ? (
                                      <a
                                        href={step.href}
                                        className="flex items-center px-6 py-4 text-base font-medium"
                                        aria-current="step"
                                      >
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-red-600">
                                          <span className="text-red-600">
                                            {step.id}
                                          </span>
                                        </span>
                                        <span className="ml-4 text-base font-medium text-red-600">
                                          {step.name}
                                        </span>
                                      </a>
                                    ) : (
                                      <a
                                        href={step.href}
                                        className="group flex items-center"
                                      >
                                        <span className="flex items-center px-6 py-4 text-base font-medium">
                                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                            <span className="text-gray-500 group-hover:text-gray-900">
                                              {step.id}
                                            </span>
                                          </span>
                                          <span className="ml-4 text-base font-medium text-gray-500 group-hover:text-gray-900">
                                            {step.name}
                                          </span>
                                        </span>
                                      </a>
                                    )}

                                    {stepIdx !== steps2.length - 1 ? (
                                      <>
                                        {/* Arrow separator for lg screens and up */}
                                        <div
                                          className="absolute top-0 right-0 hidden h-full w-5 md:block"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            className="h-full w-full text-gray-300"
                                            viewBox="0 0 22 80"
                                            fill="none"
                                            preserveAspectRatio="none"
                                          >
                                            <path
                                              d="M0 -2L20 40L0 82"
                                              vectorEffect="non-scaling-stroke"
                                              stroke="currentcolor"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </div>
                                      </>
                                    ) : null}
                                  </li>
                                ))}
                              </ol>
                            </nav>
                          </div>
                          <div className="pt-3">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 px-4 py-4">
                              <div className="pb-5 border-b border-gray-200 col-span-6">
                                <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
                                  <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                      <label
                                        for="first_name"
                                        className="block text-base font-medium text-gray-700 mt-4"
                                      >
                                        {" "}
                                        Peças do Procedimento{" "}
                                        <span className="mandatory">*</span>
                                      </label>
                                    </div>
                                    <div className="sm:col-span-3 text-right">
                                      <button
                                        type="button"
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                        onClick={() => handleDocumentSubmit()}
                                      >
                                        Carregar
                                      </button>
                                    </div>
                                  </div>

                                  <div className="sm:col-span-6">
                                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                      <div className="sm:col-span-6 mt-3">
                                        <div className="mt-0">
                                          <Select
                                            value={selectedCDID}
                                            placeholder="Selecione Peças do Procedimento"
                                            onChange={(e) =>
                                              handleChangeContractDoc(e)
                                            }
                                            options={cDData}
                                            isSearchable={true}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {frmSrc !== "" ? (
                                      <iframe
                                        style={{ height: "45vh" }}
                                        src={frmSrc}
                                        title="document"
                                        className="w-4/6 mx-auto mt-5"
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <div className="w-4/6 mx-auto mt-8 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                      <div className="space-y-1 text-center">
                                        {contract_document.preview ? (
                                          <img
                                            src={"/img/done.svg"}
                                            alt="Profile"
                                            className="mx-auto h-12 w-12 text-gray-400"
                                          />
                                        ) : (
                                          <img
                                            src={"/img/pdf.png"}
                                            alt="Profile"
                                            className="mx-auto h-12 w-12 text-gray-400"
                                          />
                                        )}
                                        <div className="flex text-sm text-gray-600">
                                          <label
                                            htmlFor="file-upload"
                                            className="relative cursor-pointer rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                          >
                                            <span>
                                              Submeta o documento em .PDF
                                            </span>
                                            <input
                                              accept="application/pdf"
                                              id="file-upload"
                                              name="file-upload"
                                              type="file"
                                              className="sr-only"
                                              onChange={(e) => {
                                                handleDocumentChange(e, "file");
                                              }}
                                            />
                                          </label>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                          PDF até 10MB
                                        </p>
                                      </div>
                                    </div>
                                    {error?.file && (
                                      <span className="text-xs text-red-600 capitalize">
                                        {error?.file}
                                      </span>
                                    )}
                                  </div>
                                  <div className="sm:col-span-6 mt-6">
                                    <DataTable
                                      columns={columns1}
                                      data={contestDocument}
                                      disabledCheckbox={false}
                                      onSelectionChange={onSelectionChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                onClick={() => setTab(1)}
                              >
                                Retroceder
                              </button>
                              <button
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                onClick={() =>
                                  showThirdTab
                                    ? setTab(3)
                                    : history.push("/contest/ViewContests")
                                }
                              >
                                {showThirdTab ? "Próximo" : "Submeter"}
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {tab === 3 ? (
                        <div className="w-full">
                          <div className="mb-0">
                            <nav aria-label="Progress">
                              <ol
                                role="list"
                                className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
                              >
                                {steps3.map((step, stepIdx) => (
                                  <li
                                    key={step.name}
                                    className="relative md:flex md:flex-1"
                                  >
                                    {step.status === "complete" ? (
                                      <a
                                        href={step.href}
                                        className="group flex w-full items-center"
                                      >
                                        <span className="flex items-center px-6 py-4 text-base font-medium">
                                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-600">
                                            <CheckIcon
                                              className="h-6 w-6 text-white"
                                              aria-hidden="true"
                                            />
                                          </span>
                                          <span className="ml-4 text-base font-medium text-gray-900">
                                            {step.name}
                                          </span>
                                        </span>
                                      </a>
                                    ) : step.status === "current" ? (
                                      <a
                                        href={step.href}
                                        className="flex items-center px-6 py-4 text-base font-medium"
                                        aria-current="step"
                                      >
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-red-600">
                                          <span className="text-red-600">
                                            {step.id}
                                          </span>
                                        </span>
                                        <span className="ml-4 text-base font-medium text-red-600">
                                          {step.name}
                                        </span>
                                      </a>
                                    ) : (
                                      <a
                                        href={step.href}
                                        className="group flex items-center"
                                      >
                                        <span className="flex items-center px-6 py-4 text-base font-medium">
                                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                            <span className="text-gray-500 group-hover:text-gray-900">
                                              {step.id}
                                            </span>
                                          </span>
                                          <span className="ml-4 text-base font-medium text-gray-500 group-hover:text-gray-900">
                                            {step.name}
                                          </span>
                                        </span>
                                      </a>
                                    )}

                                    {stepIdx !== steps1.length - 1 ? (
                                      <>
                                        <div
                                          className="absolute top-0 right-0 hidden h-full w-5 md:block"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            className="h-full w-full text-gray-300"
                                            viewBox="0 0 22 80"
                                            fill="none"
                                            preserveAspectRatio="none"
                                          >
                                            <path
                                              d="M0 -2L20 40L0 82"
                                              vectorEffect="non-scaling-stroke"
                                              stroke="currentcolor"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </div>
                                      </>
                                    ) : null}
                                  </li>
                                ))}
                              </ol>
                            </nav>
                          </div>
                          <div className="pt-1">
                            <div className="mt-3 mb-6">
                              <label
                                htmlFor="ci_number"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Número da CI
                              </label>
                              <div className="mt-1 flex relative">
                                <input
                                  type="text"
                                  name="ci_number"
                                  id="ci_number"
                                  autoComplete="given-name"
                                  defaultValue={ciNumber}
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  onChange={(e) => {
                                    setCiNumber(e.target.value);
                                  }}
                                />
                                {error?.ci_number && (
                                  <span className="text-xs text-red-600 capitalize absolute top-12">
                                    {error?.ci_number}
                                  </span>
                                )}
                                <button
                                  type="button"
                                  onClick={() => showTabData()}
                                  className="order-0 inline-flex items-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:order-1 sm:ml-3 whitespace-nowrap btn-link bg-skin-primary text-skin-white float-right"
                                >
                                  Consultar critérios de avaliação
                                </button>
                              </div>
                            </div>
                            {isShowTab ? (
                              <Tab.Group as="div">
                                <div className="border-b border-gray-200">
                                  <Tab.List className="-mb-px flex space-x-8">
                                    {rTTabs &&
                                      rTTabs.map((single) => {
                                        return (
                                          <>
                                            {single.value ===
                                            "qualification_of_competitors_01" ? (
                                              <Tab
                                                className={({ selected }) =>
                                                  classNames(
                                                    selected
                                                      ? "border-red-600 text-red-600"
                                                      : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                                    "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                                  )
                                                }
                                              >
                                                Qualificação de Candidatos
                                              </Tab>
                                            ) : (
                                              <Tab
                                                className={({ selected }) =>
                                                  classNames(
                                                    selected
                                                      ? "border-red-600 text-red-600"
                                                      : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                                    "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                                  )
                                                }
                                              >
                                                Avaliação de propostas
                                              </Tab>
                                            )}
                                          </>
                                        );
                                      })}
                                  </Tab.List>
                                </div>
                                <Tab.Panels as={Fragment}>
                                  {rTTabs &&
                                    rTTabs.map((single) => {
                                      return (
                                        <>
                                          {single.value ===
                                          "qualification_of_competitors_01" ? (
                                            <Tab.Panel className="pt-3">
                                              <h3 className="sr-only">
                                                Habilitação de Concorrentes
                                              </h3>
                                              <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none bg-gray-100 px-4 py-4">
                                                <div className="mx-auto py-2 lg:flex lg:items-center lg:justify-between">
                                                  <div className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                                    <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                                      &nbsp;
                                                    </h2>
                                                  </div>
                                                  <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
                                                    <span className="ml-3 inline-flex sm:ml-0">
                                                      <Menu
                                                        as="div"
                                                        className="relative inline-block text-left"
                                                      >
                                                        <div>
                                                          <Menu.Button className="inline-flex items-center p-3 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                                            <span className="sr-only">
                                                              Open options
                                                            </span>
                                                            <EllipsisVerticalIcon
                                                              className="h-5 w-5"
                                                              aria-hidden="true"
                                                            />
                                                          </Menu.Button>
                                                        </div>

                                                        <Transition
                                                          as={Fragment}
                                                          enter="transition ease-out duration-100"
                                                          enterFrom="transform opacity-0 scale-95"
                                                          enterTo="transform opacity-100 scale-100"
                                                          leave="transition ease-in duration-75"
                                                          leaveFrom="transform opacity-100 scale-100"
                                                          leaveTo="transform opacity-0 scale-95"
                                                        >
                                                          <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div className="py-1">
                                                              <Menu.Item>
                                                                {({
                                                                  active,
                                                                }) => (
                                                                  <button
                                                                    className={classNames(
                                                                      active
                                                                        ? "bg-gray-100 text-gray-900"
                                                                        : "text-gray-700",
                                                                      "flex px-4 py-2 text-base"
                                                                    )}
                                                                    onClick={() =>
                                                                      updateQC(
                                                                        "Approved"
                                                                      )
                                                                    }
                                                                  >
                                                                    <HandThumbUpIcon
                                                                      className="mr-3 h-5 w-5 text-red-600"
                                                                      aria-hidden="true"
                                                                    />
                                                                    <span>
                                                                      Aprovar
                                                                    </span>
                                                                  </button>
                                                                )}
                                                              </Menu.Item>

                                                              <Menu.Item>
                                                                {({
                                                                  active,
                                                                }) => (
                                                                  <button
                                                                    className={classNames(
                                                                      active
                                                                        ? "bg-gray-100 text-gray-900"
                                                                        : "text-gray-700",
                                                                      "flex px-4 py-2 text-base"
                                                                    )}
                                                                    onClick={() =>
                                                                      setShowModal1(
                                                                        true
                                                                      )
                                                                    }
                                                                  >
                                                                    <CheckIcon
                                                                      className="mr-3 h-5 w-5 text-red-600"
                                                                      aria-hidden="true"
                                                                    />
                                                                    <span>
                                                                      Corrigir
                                                                    </span>
                                                                  </button>
                                                                )}
                                                              </Menu.Item>
                                                            </div>
                                                          </Menu.Items>
                                                        </Transition>
                                                      </Menu>
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="check_none">
                                                  <DataTable
                                                    columns={columns4}
                                                    data={
                                                      qualificationCandidateData
                                                    }
                                                    disabledCheckbox={false}
                                                    onSelectionChange={
                                                      onSelectionChange
                                                    }
                                                  />
                                                </div>
                                                {showModal1 ? (
                                                  <>
                                                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                                      <div className="relative w-auto my-6 mx-auto w-6/12">
                                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                            <h3 className="text-xl font-semibold">
                                                              Documentos de
                                                              qualificação de
                                                              candidados
                                                            </h3>
                                                            <button
                                                              className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                              onClick={() =>
                                                                setShowModal1(
                                                                  false
                                                                )
                                                              }
                                                            >
                                                              <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none red-link">
                                                                ×
                                                              </span>
                                                            </button>
                                                          </div>
                                                          <div className="relative p-6 flex-auto">
                                                            <div className="mt-1">
                                                              <MultiSelect
                                                                items={
                                                                  qualificationCandidateData
                                                                }
                                                                messages={{
                                                                  searchPlaceholder:
                                                                    "Procurar...",
                                                                  noItemsMessage:
                                                                    "Nenhum item...",
                                                                  noneSelectedMessage:
                                                                    "Nenhum seleccionado",
                                                                  selectedMessage:
                                                                    "selecionado",
                                                                  selectAllMessage:
                                                                    "Selecionar tudo",
                                                                  clearAllMessage:
                                                                    "Limpar tudo",
                                                                  disabledItemsTooltip:
                                                                    "Você só pode selecionar 1 arquivo",
                                                                }}
                                                                selectedItems={
                                                                  selectedQC
                                                                }
                                                                itemHeight={50}
                                                                onChange={(e) =>
                                                                  setSelectedQC(
                                                                    e
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          </div>
                                                          <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                            <button
                                                              type="button"
                                                              onClick={() =>
                                                                setShowModal1(
                                                                  false
                                                                )
                                                              }
                                                              className="sm:order-0 order-1 ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:ml-0"
                                                            >
                                                              Cancelar
                                                            </button>
                                                            <button
                                                              type="button"
                                                              onClick={() =>
                                                                updateQC(
                                                                  "Correct"
                                                                )
                                                              }
                                                              className="order-0 inline-flex items-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:order-1 sm:ml-3 whitespace-nowrap btn-link bg-skin-primary text-skin-white"
                                                            >
                                                              Atualizar
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                                  </>
                                                ) : null}
                                              </div>
                                            </Tab.Panel>
                                          ) : (
                                            <Tab.Panel className="pt-3">
                                              <h3 className="sr-only">
                                                Avaliação de propostas
                                              </h3>

                                              <div className="space-y-8 divide-y divide-gray-200">
                                                <div className="px-0">
                                                  <div className="sm:flex sm:items-center">
                                                    <div className="sm:flex-auto">
                                                      &nbsp;
                                                    </div>
                                                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                                      <Menu
                                                        as="div"
                                                        className="relative inline-block text-left mt-2"
                                                      >
                                                        <div>
                                                          <Menu.Button className="inline-flex items-center p-3 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                                            <span className="sr-only">
                                                              Open options
                                                            </span>
                                                            <EllipsisVerticalIcon
                                                              className="h-5 w-5"
                                                              aria-hidden="true"
                                                            />
                                                          </Menu.Button>
                                                        </div>

                                                        <Transition
                                                          as={Fragment}
                                                          enter="transition ease-out duration-100"
                                                          enterFrom="transform opacity-0 scale-95"
                                                          enterTo="transform opacity-100 scale-100"
                                                          leave="transition ease-in duration-75"
                                                          leaveFrom="transform opacity-100 scale-100"
                                                          leaveTo="transform opacity-0 scale-95"
                                                        >
                                                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div className="py-1">
                                                              <Menu.Item>
                                                                {({
                                                                  active,
                                                                }) => (
                                                                  <button
                                                                    className={classNames(
                                                                      active
                                                                        ? "bg-gray-100 text-gray-900"
                                                                        : "text-gray-700",
                                                                      "flex px-4 py-2 text-base"
                                                                    )}
                                                                    onClick={() =>
                                                                      updateproposalStatus(
                                                                        "Approved"
                                                                      )
                                                                    }
                                                                  >
                                                                    <HandThumbUpIcon
                                                                      className="mr-3 h-5 w-5 text-red-600"
                                                                      aria-hidden="true"
                                                                    />
                                                                    <span>
                                                                      Aprovar
                                                                    </span>
                                                                  </button>
                                                                )}
                                                              </Menu.Item>
                                                            </div>
                                                          </Menu.Items>
                                                        </Transition>
                                                      </Menu>
                                                    </div>
                                                  </div>
                                                  <div className="mt-4 flex flex-col">
                                                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                          <table className="min-w-full divide-y divide-gray-300">
                                                            <thead className="bg-gray-50">
                                                              <tr>
                                                                <th
                                                                  scope="col"
                                                                  className="py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-6"
                                                                >
                                                                  Critério de
                                                                  avaliação
                                                                </th>
                                                                <th
                                                                  scope="col"
                                                                  className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                                                                >
                                                                  Peso (%)
                                                                </th>
                                                                <th
                                                                  scope="col"
                                                                  className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                                                                >
                                                                  Atributos
                                                                </th>
                                                                <th
                                                                  scope="col"
                                                                  className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                                                                >
                                                                  Pontuação
                                                                </th>
                                                                <th
                                                                  scope="col"
                                                                  className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                                                                >
                                                                  Estado
                                                                </th>
                                                                <th
                                                                  scope="col"
                                                                  className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                                                                >
                                                                  Acções
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                              {tabEvaluationData &&
                                                              tabEvaluationData.length >
                                                                0
                                                                ? tabEvaluationData.map(
                                                                    (
                                                                      singleData
                                                                    ) => {
                                                                      return (
                                                                        <tr
                                                                          key={
                                                                            singleData.id
                                                                          }
                                                                        >
                                                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base sm:pl-6">
                                                                            <div className="flex items-center">
                                                                              <div className="ml-0">
                                                                                <div className="font-medium text-gray-500">
                                                                                  {
                                                                                    singleData
                                                                                      .evaluation_criteria
                                                                                      ?.name
                                                                                  }
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                          <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                                                            <div className="text-gray-500">
                                                                              {
                                                                                singleData.weight
                                                                              }
                                                                            </div>
                                                                          </td>
                                                                          <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                                                            {singleData.multiAttrName.map(
                                                                              (
                                                                                element
                                                                              ) => {
                                                                                return (
                                                                                  <div className="text-gray-500">
                                                                                    {
                                                                                      element
                                                                                    }
                                                                                  </div>
                                                                                );
                                                                              }
                                                                            )}
                                                                          </td>
                                                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                            {singleData.multiPunctionName.map(
                                                                              (
                                                                                elementAttr
                                                                              ) => {
                                                                                return (
                                                                                  <div className="text-gray-500">
                                                                                    {
                                                                                      elementAttr
                                                                                    }
                                                                                  </div>
                                                                                );
                                                                              }
                                                                            )}
                                                                          </td>
                                                                          <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                                                            <span
                                                                              className={getClassName(
                                                                                singleData.status
                                                                              )}
                                                                            >
                                                                              {changeinPortuguese(
                                                                                singleData.status
                                                                              )}
                                                                            </span>
                                                                          </td>
                                                                          <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                                                            <button
                                                                              className="text-indigo-600 hover:text-indigo-900 focus:outline-none mr-4"
                                                                              onClick={() =>
                                                                                handleOpen3(
                                                                                  singleData
                                                                                )
                                                                              }
                                                                            >
                                                                              <PencilIcon
                                                                                className="flex-shrink-0 h-5 w-5 red-link"
                                                                                aria-hidden="true"
                                                                              />
                                                                            </button>
                                                                          </td>
                                                                        </tr>
                                                                      );
                                                                    }
                                                                  )
                                                                : ""}
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Tab.Panel>
                                          )}
                                        </>
                                      );
                                    })}
                                </Tab.Panels>
                              </Tab.Group>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                onClick={() => setTab(2)}
                              >
                                Retroceder
                              </button>
                              <button
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                onClick={() => addCiNumber()}
                              >
                                Submeter
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900 mb-5"
                    >
                      Editar Peças do Procedimento
                    </Dialog.Title>
                    <div className="py-5">
                      <div className="space-y-8">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-6">
                            <label
                              htmlFor="street-address"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Peças do Procedimento
                            </label>
                            <div className="mt-1">
                              <select
                                id="country"
                                name="country"
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                value={selectedDocInfo?.contract_doc_id}
                                onChange={(e) => {
                                  setSelectedDocInfo({
                                    ...selectedDocInfo,
                                    contract_doc_id: e?.target?.value,
                                  });
                                }}
                              >
                                {cDData?.map((element) => {
                                  return (
                                    <option value={element.contract_doc_id}>
                                      {element?.contract_documents?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className="sm:col-span-6">
                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                              <div className="space-y-1 text-center">
                                {contract_document.preview ? (
                                  <img
                                    src={"/img/done.svg"}
                                    alt="Profile"
                                    className="mx-auto h-12 w-12 text-gray-400"
                                  />
                                ) : (
                                  <img
                                    src={"/img/pdf.png"}
                                    alt="Profile"
                                    className="mx-auto h-12 w-12 text-gray-400"
                                  />
                                )}
                                <div className="flex text-sm text-gray-600">
                                  <label
                                    htmlFor="file-upload2"
                                    className="relative cursor-pointer rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
                                    value={selectedDocInfo?.file}
                                    onChange={(e) => {
                                      setSelectedDocInfo({
                                        ...selectedDocInfo,
                                        file: e?.target?.files?.[0],
                                      });
                                      setEditDocFrmSrc(
                                        URL.createObjectURL(
                                          e?.target?.files?.[0]
                                        )
                                      );
                                    }}
                                  >
                                    <span>Seleccione o ficheiro</span>
                                    <input
                                      accept="application/pdf"
                                      id="file-upload2"
                                      type="file"
                                      className="sr-only"
                                    />
                                  </label>
                                </div>
                                <p className="text-xs text-gray-500">
                                  PDF até 10MB
                                </p>
                              </div>
                            </div>
                            {editDocFrmSrc !== "" && (
                              <iframe
                                style={{ height: "45vh" }}
                                src={editDocFrmSrc}
                                title="document"
                                className="w-4/6 mx-auto mt-5"
                              />
                            )}
                          </div>
                        </div>
                        <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              type="button"
                              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                              onClick={() => setOpen(false)}
                            >
                              Cancelar
                            </button>
                            <button
                              type="submit"
                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                              onClick={() => handleDocumentUpdate()}
                            >
                              Submeter
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={open1} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen1}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpen1(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900 mb-5"
                    >
                      Detalhes do Peças do Procedimento
                    </Dialog.Title>
                    <div className="py-5">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Peças do Procedimento
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {cDDetails && cDDetails.contract_doc.name}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Ficheiro
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {cDDetails && cDDetails.file_name}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Data de submissão
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {cDDetails &&
                              moment(cDDetails).format("DD-MM-YYYY")}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open3} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen3}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={() => setOpen3(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 font-medium text-gray-900 mb-5"
                    >
                      Avaliação de propostas
                    </Dialog.Title>
                    <div className="py-5">
                      <div className="sm:col-span-6">
                        {evaluationDivNew && evaluationDivNew.length > 0
                          ? evaluationDivNew.map((element, index) => {
                              return (
                                <>
                                  <div className="rounded-lg border border-gray-200 mt-2">
                                    <div className="mx-auto lg:flex lg:items-center lg:justify-between p-6 bg-gray-50 rounded-lg">
                                      <div className="tracking-tight text-gray-900 w-full">
                                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                          <div className="sm:col-span-2">
                                            <div className="mt-1">
                                              <label
                                                htmlFor="first_name"
                                                className="block text-base font-medium text-gray-700"
                                              >
                                                Critério de avaliação
                                              </label>
                                              <select
                                                id="country"
                                                name="country"
                                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base"
                                                defaultValue={
                                                  element?.evaluation_criteria_id
                                                }
                                                // value={element?.evaluation_criteria_id}
                                                onChange={(e) =>
                                                  handleChangeEvaluation(
                                                    e,
                                                    "evaluation_criteria_id",
                                                    index
                                                  )
                                                }
                                              >
                                                <option>
                                                  {" "}
                                                  -- Selecionar Critério de
                                                  avaliação --{" "}
                                                </option>
                                                {eCList && eCList.length > 0
                                                  ? eCList.map((singleEC) => {
                                                      return (
                                                        <option
                                                          value={singleEC.id}
                                                        >
                                                          {singleEC.name}
                                                        </option>
                                                      );
                                                    })
                                                  : ""}
                                              </select>
                                            </div>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <div className="mt-1">
                                              <label
                                                htmlFor="first_name"
                                                className="block text-base font-medium text-gray-700"
                                              >
                                                Peso (%)
                                              </label>
                                              <input
                                                type="text"
                                                name="weight"
                                                id="weight"
                                                maxLength="3"
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base"
                                                onChange={(e) =>
                                                  handleChangeEvaluation(
                                                    e,
                                                    "weight",
                                                    index
                                                  )
                                                }
                                                defaultValue={element?.weight}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="w-full px-6 mt-4">
                                      {element.fieldArr &&
                                      element.fieldArr.length > 0
                                        ? element.fieldArr.map(
                                            (attrElement, attrIndex) => {
                                              return (
                                                <>
                                                  <div className="mx-auto lg:flex lg:items-center lg:justify-between pb-4">
                                                    <div className="tracking-tight text-gray-900 w-full">
                                                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                        <div className="sm:col-span-2">
                                                          <div className="mt-0">
                                                            <label
                                                              htmlFor="first_name"
                                                              className="block text-base font-medium text-gray-700"
                                                            >
                                                              Atributos
                                                            </label>
                                                            <select
                                                              id="country"
                                                              name="country"
                                                              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base"
                                                              defaultValue={
                                                                attrElement?.attribute_id
                                                              }
                                                              onChange={(e) =>
                                                                handleChangeEvaluation(
                                                                  e,
                                                                  "attribute_id",
                                                                  index,
                                                                  attrIndex
                                                                )
                                                              }
                                                            >
                                                              <option>
                                                                {" "}
                                                                -- Selecionar
                                                                Atributos --{" "}
                                                              </option>
                                                              {attrList &&
                                                              attrList.length >
                                                                0
                                                                ? attrList.map(
                                                                    (
                                                                      singleAttr
                                                                    ) => {
                                                                      return (
                                                                        <option
                                                                          value={
                                                                            singleAttr.id
                                                                          }
                                                                        >
                                                                          {
                                                                            singleAttr.name
                                                                          }
                                                                        </option>
                                                                      );
                                                                    }
                                                                  )
                                                                : ""}
                                                            </select>
                                                          </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                          <div className="mt-0">
                                                            <label
                                                              htmlFor="first_name"
                                                              className="block text-base font-medium text-gray-700"
                                                            >
                                                              Pontuação
                                                            </label>
                                                            <input
                                                              type="text"
                                                              name="puncuation"
                                                              id="puncuation"
                                                              maxLength="3"
                                                              className="mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base"
                                                              onChange={(e) =>
                                                                handleChangeEvaluation(
                                                                  e,
                                                                  "puncuation",
                                                                  index,
                                                                  attrIndex
                                                                )
                                                              }
                                                              defaultValue={
                                                                attrElement?.puncuation
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          )
                                        : ""}
                                      <div className="border-t border-gray-200 pt-4 pb-4 lg:flex lg:items-center lg:justify-between xl:mt-0">
                                        <div className="mt-2 sm:flex sm:max-w-md lg:mt-0">
                                          <button
                                            type="button"
                                            className="sm:order-0 order-1 ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:ml-0"
                                            onClick={() => setOpen3(false)}
                                          >
                                            Cancelar
                                          </button>
                                          <button
                                            type="button"
                                            className="order-0 inline-flex items-center rounded-md border border-transparent btn-link bg-skin-primary px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:order-1 sm:ml-3 whitespace-nowrap"
                                            onClick={() =>
                                              updateEvaluationData()
                                            }
                                          >
                                            Guardar
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
