import { Fragment, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import LeftNavigation from "../../components/LeftNavigation/leftNavigation.js";
import Header from "../../components/Header/Header";
import config from "../../utils/config.js";
import { toast } from "react-toastify";
import {
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
  CheckIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { validateForm } from "../../utils/common.js";
import DataTable from "../../components/MDataTable";
import DeleteModel from "../../components/Other/DeleteModel";
import { pluck, find, reject, findWhere } from "underscore";
import Select from "react-select";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];

const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EditEvaluationCommittee({ }) {
  const history = useHistory();
  const [tab, setTab] = useState(1);
  const steps1 = [
    { id: "01", name: "Informação Geral", href: "#", status: "current" },
    {
      id: "02",
      name: "Membros da comissão de avaliação",
      href: "#",
      status: "upcoming",
    },
  ];
  const steps2 = [
    { id: "01", name: "Informação Geral", href: "#", status: "complete" },
    {
      id: "02",
      name: "Membros da comissão de avaliação",
      href: "#",
      status: "current",
    },
  ];
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [role, setRole] = useState([]);
  const [deletedDocumentID, setDeletedDocumentID] = useState(null);
  const [error, setError] = useState({});
  const [framSrc, setFrameSrc] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionrole, setSelectedOptionrole] = useState(null);
  const { id } = useParams();
  const [contestReference, setContestReference] = useState([]);
  const [committeeInfo, setCommitteeInfo] = useState({
    id: "",
    dispatch_number: "",
    contest_reference: "",
    evalution_comiitee_mail: "",
    comiitee_details: [
      {
        evaluation_comitee_member_id: "",
        evaluation_commitee_role_id: "",
      },
    ],
    dispatch_document: "",
  });
  const [contract_document, setFile] = useState({ preview: "", raw: "" });
  const [ids, setIds] = useState([]);
  const [data, setData] = useState([]);
  const [eCId, setECId] = useState();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [eMRData, setEMRData] = useState();
  const [count, setCount] = useState(0);
  const [allEmployee, setAllEmployee] = useState([]);
  const [allRole, setAllRole] = useState([]);
  const [selectedContestReferenceID, setSelectedContestReferenceID] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const onSelectionChange = (e) => {
    let idArr = pluck(e, "contract_part");
    setIds(idArr);
  };

  const getEmployeeList = async () => {
    config
      .getAPI({ url: "employee/list", params: {} })
      .then((data) => {
        if (data.payload) {
          let optionsArr = [];
          data?.payload?.rows.map(async (row) => {
            optionsArr.push({ value: row.id, label: row.full_name });
          });
          setEmployee(optionsArr);
          setAllEmployee(optionsArr);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const getcontestReference = async () => {
    config.getAPI({ url: "procedureEvaluation/getdatabyCode", params: { module_code: "contest_competitor" } })
      .then((dataPE) => {
        if (dataPE?.payload) {
          const PTIDs = pluck(dataPE.payload, "procedure_type_id");
          config.getAPI({ url: "contest/list", params: { PTIDs: JSON.stringify(PTIDs), status: "published" } }).then((data) => {
            if (data.payload) {
              config.getAPI({ url: "evaluationCommitee/list", params: {} }).then((data2) => {
                if (data2.payload) {
                  const existsIds = pluck(data2.payload.rows, 'contest_reference');
                  data.payload.rows.map((element) => {
                    const isExist = existsIds.includes(element.id);
                    if (isExist) {
                      data.payload.rows = reject(data.payload.rows, function (num) { return num.id == element.id; })
                    }
                  });
                  data.payload.rows.map((element) => {
                    element.label = element.contest_reference;
                    element.value = element.id;
                  });
                  setContestReference(data.payload.rows);
                }
              }).catch((error) => {
                return toast.error(error.message);
              });
            }
          }).catch((error) => {
            return toast.error(error.message);
          });
        }
      }).catch((error) => {
        return toast.error(error.message);
      });
  };

  const getEvaluationRole = async () => {
    config
      .getAPI({ url: "evaluationCommiteeRole/list", params: { status: "Active" } })
      .then((data) => {
        if (data.payload) {
          let optionsArr = [];
          data?.payload?.rows.map(async (row) => {
            optionsArr.push({ value: row.id, label: row.name });
          });
          setRole(optionsArr);
          setAllRole(optionsArr);
        }
        if (data?.error?.message) {
          history.push("/");
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };
  const getEvaluationCommittee = async () => {
    config
      .getAPI({ url: "evaluationCommitee/getdatabyId", params: { id } })
      .then((data) => {
        if (data.payload) {
          setSelectedContestReferenceID({
            value: data.payload.response.contest_reference,
            label: data.payload.response.contest.contest_reference,
          });
          setECId(data.payload?.response?.id);
          setDeletedDocumentID(data?.payload?.response?.dispatch_document);
          if (data?.payload?.response?.dispatch_document) {
            setFrameSrc(
              config.staticPath + "evaluationCommitee/" + id +"/"+ data?.payload?.response?.dispatch_document
            );
          }
          if (
            data.payload?.comiitee_details === undefined ||
            data.payload?.comiitee_details?.length === 0
          ) {
            let comiitee_details = [];
            data?.payload?.results.map(async (data) => {
              comiitee_details.push({
                evaluation_comitee_member_id: data.emp_id,
                evaluation_commitee_role_id: data.role_id,
                contest_reference_id: data.contest_reference_id,
              });
            });
            setCommitteeInfo({
              ...data.payload.response,
              comiitee_details: comiitee_details,
            });
          } else {
            setCommitteeInfo(data.payload.response);
          }
          setShowLoader(false)
        }
        
        setShowLoader(false)
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  useEffect(() => {
    setShowLoader(true);
    getEmployeeList();
    getEvaluationRole();
    getEvaluationCommittee();
    getcontestReference(selectedContestReferenceID);
  }, []);

  const handleSelectionList = () => {
    setOpen(true);
    const memberIDs = pluck(data, "evaluation_comitee_member_id");
    const isRoleExist = findWhere(data, { evaluation_commitee_role_id: 4 });
    const existEmp = reject(employee, (num) => {
      return memberIDs.includes(num?.value);
    });
    setEmployee(existEmp);
    isRoleExist &&
      setRole(
        reject(role, function (num) {
          return num.value === "4";
        })
      );
  };

  const handleChange = (e, name, index) => {
    if (
      name === "evaluation_commitee_role_id" ||
      name === "evaluation_comitee_member_id"
    ) {
      const updatedFields = {
        ...committeeInfo?.comiitee_details[index],
        [name]: e?.target?.value,
      };
      setCommitteeInfo({
        ...committeeInfo,
        comiitee_details: [
          ...committeeInfo?.comiitee_details.slice(0, index),
          updatedFields,
          ...committeeInfo?.comiitee_details.slice(index + 1),
        ],
      });
    } else if (name === "contest_reference") {
      const setData = {
        ...committeeInfo,
        [name]: e?.value,
      };
      setCommitteeInfo(setData);
      setSelectedContestReferenceID(e);
    } else {
      const setData = {
        ...committeeInfo,
        [name]: e?.target?.value,
      };
      setCommitteeInfo(setData);
    }
  };

  const handleChangeEMR = (e, field) => {
    if (field === "evaluation_comitee_member_id") {
      setSelectedOption(e);
      setEMRData({ ...eMRData, [field]: e.value });
    } else {
      setSelectedOptionrole(e);
      setEMRData({ ...eMRData, [field]: e.value });
    }
  };

  const handleSumbit = async (type) => {
    try {
      let formData = new FormData();
      formData.append("id", committeeInfo?.id);
      formData.append("file", committeeInfo?.dispatch_document);
      formData.append("dispatch_number", committeeInfo?.dispatch_number);
      formData.append("contest_reference", committeeInfo?.contest_reference);
      formData.append(
        "evalution_comiitee_mail",
        committeeInfo?.evalution_comiitee_mail
      );
      formData.append(
        "comiitee_details",
        JSON.stringify(committeeInfo?.comiitee_details)
      );
      if (deletedDocumentID !== committeeInfo?.dispatch_document) {
        formData.append("deleted_document", deletedDocumentID);
      }
      const includedFields = [
        "dispatch_number",
        "contest_reference",
        "evalution_comiitee_mail",
        "comiitee_details",
      ];
      let cError = {};
      if (
        committeeInfo?.dispatch_document === undefined ||
        committeeInfo?.dispatch_document === ""
      ) {
        cError["dispatch_document"] = "Por Favor, Seleccione O Despacho PCA";
      }
      const validationResponse = validateForm(committeeInfo, includedFields);
      setError({
        ...validationResponse?.errors,
        ...cError,
      });
      if (validationResponse?.isValid && Object.keys(cError)?.length === 0) {
        setError({
          ...validationResponse?.errors,
          ...cError,
        });
        setShowLoader(true)
        const insertResponse = await config.postFormDataAPI({
          url: "evaluationCommitee/update",
          params: formData,
        });
        if (insertResponse?.response?.data?.error?.message) {
          setShowLoader(false)
          return toast.error(insertResponse?.response?.data?.error?.message);
        }
        setShowLoader(false)
        setTimeout(() => {
          return toast.success("Registo criado com sucesso");
        }, 200);
        await getEvaluationCommiteeMemberdata(eCId);
        // setTab(2);
        if (type === "save_and_exit") {
          history.push("/evaluation-committee/SeeEvaluationCommittee");
        } else {
          setTab(2);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getEvaluationCommiteeMemberdata = async (eCId) => {
    config
      .getAPI({
        url: "evaluationCommiteeMember/list",
        params: { evaluation_comitee_id: eCId },
      })
      .then((data1) => {
        if (data1.payload) {
          setData(data1.payload.rows);
          setCount(data1?.payload?.count);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const getEvaluationCommiteeMembers = async () => {
    config
      .getAPI({ url: "evaluationCommitee/getdatabyId", params: { ids } })
      .then((data) => {
        if (data.payload) {
          setEmployee(data.payload.rows);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const showPopUPData = (id) => {
    const tempData = find(data, function (singlePT) {
      return singlePT.id === id;
    });
    setEMRData(tempData);
    setSelectedOption({
      value: tempData.evaluation_comitee_member_id,
      label: tempData.evaluation_comitee_member.full_name,
    });
    setSelectedOptionrole({
      value: tempData.evaluation_commitee_role_id,
      label: tempData.evaluation_comitee_member_role.name,
    });

    const memberIDs = pluck(data, "evaluation_comitee_member_id");
    const isRoleExist = findWhere(data, { evaluation_commitee_role_id: 4 });
    const existEmp = reject(employee, (num) => {
      return memberIDs.includes(num?.value);
    });
    setEmployee(existEmp);
    isRoleExist &&
      setRole(
        reject(role, function (num) {
          return num.value === "4";
        })
      );

    setOpen(false);
    setOpen2(true);
  };

  function handleCancel() {
    setShowModal(false);
  }

  const handleDelete = async () => {
    const existData = find(data, function (singlePT) {
      return singlePT.id === ids[0];
    });
    const delEmp = find(allEmployee, function (singlePT) {
      return singlePT.value === existData.evaluation_comitee_member_id;
    });
    setEmployee([...employee, delEmp]);
    if (existData.evaluation_commitee_role_id === 4) {
      const delRole = find(allRole, function (singlePT) {
        return singlePT.value === 4;
      });
      setRole([...role, delRole]);
    }

    const sendData = {
      ids,
      type: "delete",
    };
    const response = await config.deleteAPI({
      url: "evaluationCommiteeMember/delete",
      params: { id: sendData?.ids },
    });

    if (!response?.payload?.success) {
      return toast.error("Falha na inserção do registro");
    }
    setShowModal(false);
    getEvaluationCommiteeMemberdata(eCId);
    setIds([]);
    return toast.success("Registo removido com sucesso");
  };

  const handleAction = async (type, id = "") => {
    if (ids.length < 1 && id === "") {
      return toast.error("Selecione pelo menos um registro");
    } else if (type === "delete") {
      setShowModal(true);
      return;
    }
    const sendData = {
      id: ids,
      fields: {
        status:
          type === "makeActive"
            ? "Active"
            : type === "makeInactive"
              ? "Inactive"
              : type,
      },
    };
    const response = await config.postAPI({
      url: "supplierBank/update",
      params: sendData,
    });
    if (!response?.payload?.success) {
      return toast.error("Falha na inserção do registro");
    } else {
      await getEvaluationCommiteeMembers();
    }
    setIds([]);
    return toast.success("Registo actualizado com sucesso");
  };

  const columns = [
    {
      title: "Membros da Comissão",
      field: "evaluation_comitee_member.full_name",
    },
    {
      title: "Função do Membro na CA",
      field: "evaluation_comitee_member_role.name",
    },
    {
      title: "Acções",
      render: (rowData) => (
        <span className="flex">
          <button
            className="red-link focus:outline-none mr-3"
            onClick={() => {
              showPopUPData(rowData.id);
            }}
          >
            <PencilIcon
              className="flex-shrink-0 h-5 w-5"
              aria-hidden="true"
              onClick={() => showPopUPData(rowData.id)}
            />
          </button>

          <button
            className="red-link focus:outline-none mr-3"
            onClick={() => {
              setIds([rowData?.id]);
              handleAction("delete", rowData?.id);
            }}
          >
            <TrashIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          </button>
        </span>
      ),
    },
  ];

  const addEvaulationCommiteeMember = async (ecmId) => {
    try {
      const sendData = {
        evaluation_comitee_id: eCId,
        contest_reference_id: committeeInfo?.contest_reference,
        eMRData,
      };
      let insertResponse = {};
      const includedFields = [
        "evaluation_comitee_member_id",
        "evaluation_commitee_role_id",
      ];
      const validationResponse = validateForm(eMRData, includedFields);
      setError(validationResponse?.errors);
      console.log("committeeInfo?.contest_reference", committeeInfo?.contest_reference);
      if (validationResponse?.isValid) {
        console.log("sendData", sendData);
        if (ecmId) {
          const sendData = {
            evaluation_comitee_member_id: ecmId,
            contest_reference_id: committeeInfo?.contest_reference,
            eMRData,
          };
          insertResponse = await config.postAPI({
            url: "evaluationCommiteeMember/update",
            params: sendData,
          });
          setSelectedOption(null);
          setSelectedOptionrole(null);
          setOpen2(false);
        } else {
          insertResponse = await config.postAPI({
            url: "evaluationCommiteeMember/create",
            params: sendData,
          });
          setSelectedOption(null);
          setSelectedOptionrole(null);
        }
        if (insertResponse?.response?.data?.error?.message) {
          return toast.error(insertResponse?.response?.data?.error?.message);
        }
        getEvaluationCommiteeMemberdata(eCId);
        setOpen(false);
        setTimeout(() => {
          return toast.success("Registo criado com sucesso");
        }, 200);
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          <LeftNavigation />
        </div>
        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1 pb-8">
            <div
              className="loader-img"
              style={{
                display: showLoader ? "block" : "none",
              }}
            ></div>
            <div className="mt-8">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="py-5 flex items-center justify-between">
                  <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
                    Editar Comissão de Avaliação
                  </h1>
                </div>
                <div className="space-y-6">
                  <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                    {tab === 1 ? (
                      <div className="w-full">
                        <div className="mb-6">
                          <nav aria-label="Progress">
                            <ol
                              role="list"
                              className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
                            >
                              {steps1.map((step, stepIdx) => (
                                <li
                                  key={step.name}
                                  className="relative md:flex md:flex-1"
                                >
                                  {step.status === "complete" ? (
                                    <a
                                      href={step.href}
                                      className="group flex w-full items-center"
                                    >
                                      <span className="flex items-center px-6 py-4 text-base font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-600">
                                          <CheckIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                          />
                                        </span>
                                        <span className="ml-4 text-base font-medium text-gray-900">
                                          {step.name}
                                        </span>
                                      </span>
                                    </a>
                                  ) : step.status === "current" ? (
                                    <a
                                      href={step.href}
                                      className="flex items-center px-6 py-4 text-base font-medium"
                                      aria-current="step"
                                    >
                                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-red-600">
                                        <span className="text-red-600">
                                          {step.id}
                                        </span>
                                      </span>
                                      <span className="ml-4 text-base font-medium text-red-600">
                                        {step.name}
                                      </span>
                                    </a>
                                  ) : (
                                    <a
                                      href={step.href}
                                      className="group flex items-center"
                                    >
                                      <span className="flex items-center px-6 py-4 text-base font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                          <span className="text-gray-500 group-hover:text-gray-900">
                                            {step.id}
                                          </span>
                                        </span>
                                        <span className="ml-4 text-base font-medium text-gray-500 group-hover:text-gray-900">
                                          {step.name}
                                        </span>
                                      </span>
                                    </a>
                                  )}

                                  {stepIdx !== steps1.length - 1 ? (
                                    <>
                                      {/* Arrow separator for lg screens and up */}
                                      <div
                                        className="absolute top-0 right-0 hidden h-full w-5 md:block"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          className="h-full w-full text-gray-300"
                                          viewBox="0 0 22 80"
                                          fill="none"
                                          preserveAspectRatio="none"
                                        >
                                          <path
                                            d="M0 -2L20 40L0 82"
                                            vectorEffect="non-scaling-stroke"
                                            stroke="currentcolor"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </div>
                                    </>
                                  ) : null}
                                </li>
                              ))}
                            </ol>
                          </nav>
                        </div>
                        <div className="space-y-6">
                          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                            <div className="md:grid md:gap-6">
                              <div className="mt-5 md:mt-0">
                                <div className="grid grid-cols-6 gap-6">
                                  <div className="col-span-6 sm:col-span-3">
                                    <label
                                      htmlFor="dispatch_number"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Despacho{" "}
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="dispatch_number"
                                      id="dispatch_number"
                                      onChange={(e) => {
                                        handleChange(e, "dispatch_number");
                                      }}
                                      value={committeeInfo?.dispatch_number}
                                      className={`${error?.dispatch_number &&
                                        "border-red-600"
                                        } mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-base `}
                                    />
                                    {error?.dispatch_number && (
                                      <span className="text-xs text-red-600 capitalize">
                                        {error?.dispatch_number}
                                      </span>
                                    )}
                                  </div>

                                  <div className="col-span-6 sm:col-span-3">
                                    <label
                                      htmlFor="contest_reference"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Referência do Concurso{" "}
                                      <span className="mandatory">*</span>
                                    </label>
                                    <Select
                                      value={selectedContestReferenceID}
                                      placeholder="Selecionar Referência do Concurso"
                                      onChange={(e) => {
                                        handleChange(e, "contest_reference");
                                      }}
                                      options={contestReference}
                                      isSearchable={true}
                                    />
                                    {error?.contest_reference && (
                                      <span className="text-xs text-red-600 capitalize">
                                        {error?.contest_reference}
                                      </span>
                                    )}
                                  </div>
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="first_name"
                                      className="block text-base font-medium text-gray-700"
                                    >
                                      E-mail da CA{" "}
                                      <span className="mandatory">*</span>
                                    </label>
                                    <div className="mt-1">
                                      <input
                                        type="text"
                                        name="evalution_comiitee_mail"
                                        id="evalution_comiitee_mail"
                                        className={` ${error?.evalution_comiitee_mail &&
                                          "border-red-600"
                                          } mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base`}
                                        onChange={(e) => {
                                          handleChange(
                                            e,
                                            "evalution_comiitee_mail"
                                          );
                                        }}
                                        value={
                                          committeeInfo?.evalution_comiitee_mail
                                        }
                                      />
                                      {error?.evalution_comiitee_mail && (
                                        <span className="text-xs text-red-600 capitalize">
                                          {error?.evalution_comiitee_mail}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="sm:col-span-6">
                                    <label
                                      htmlFor="first_name"
                                      className="block text-base font-medium text-gray-700"
                                    >
                                      Despacho PCA{" "}
                                      <span className="mandatory">*</span>
                                    </label>
                                    {framSrc !== "" ? (
                                      <iframe
                                        style={{ height: "45vh" }}
                                        src={framSrc}
                                        title="document"
                                        className="w-4/6 mx-auto mt-5"
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <div className="w-4/6 mx-auto mt-5">
                                      <div
                                        className={`${error?.dispatch_document &&
                                          "border-red-600"
                                          }  mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md`}
                                      >
                                        <div className="space-y-1 text-center">
                                          {contract_document.preview ? (
                                            <img
                                              src={"/img/done.svg"}
                                              alt="Profile"
                                              className="mx-auto h-12 w-12 text-gray-400"
                                            />
                                          ) : (
                                            <img
                                              src={"/img/pdf.png"}
                                              alt="Profile"
                                              className="mx-auto h-12 w-12 text-gray-400"
                                            />
                                          )}
                                          <div className="flex text-sm text-gray-600">
                                            <label
                                              htmlFor="file-upload"
                                              className="relative cursor-pointer rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
                                            >
                                              <span>
                                                Submeta o documento em .PDF
                                              </span>
                                              <input
                                                accept="application/pdf"
                                                id="file-upload"
                                                name="file-upload"
                                                type="file"
                                                className="sr-only"
                                                onChange={(e) => {
                                                  setFrameSrc(
                                                    URL.createObjectURL(
                                                      e.target.files[0]
                                                    )
                                                  );
                                                  setCommitteeInfo({
                                                    ...committeeInfo,
                                                    dispatch_document:
                                                      e.target.files[0],
                                                  });
                                                }}
                                              />
                                            </label>
                                          </div>
                                          <p className="text-xs text-gray-500">
                                            PDF até 10MB
                                          </p>
                                        </div>
                                      </div>
                                      {error?.dispatch_document && (
                                        <span className="text-xs text-red-600 capitalize">
                                          {error?.dispatch_document}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                onClick={() =>
                                  history.push(
                                    "/evaluation-committee/SeeEvaluationCommittee"
                                  )
                                }
                              >
                                Cancelar
                              </button>

                              <button
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                onClick={() => {
                                  handleSumbit("save_and_exit");
                                }}
                              >
                                Guardar e Sair
                              </button>
                              <button
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                onClick={() => {
                                  handleSumbit();
                                }}
                              >
                                Próximo
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {tab === 2 ? (
                      <div className="w-full">
                        <div>
                          <nav aria-label="Progress">
                            <ol
                              role="list"
                              className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
                            >
                              {steps2.map((step, stepIdx) => (
                                <li
                                  key={step.name}
                                  className="relative md:flex md:flex-1"
                                >
                                  {step.status === "complete" ? (
                                    <a
                                      href={step.href}
                                      className="group flex w-full items-center"
                                    >
                                      <span className="flex items-center px-6 py-4 text-base font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-600">
                                          <CheckIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                          />
                                        </span>
                                        <span className="ml-4 text-base font-medium text-gray-900">
                                          {step.name}
                                        </span>
                                      </span>
                                    </a>
                                  ) : step.status === "current" ? (
                                    <a
                                      href={step.href}
                                      className="flex items-center px-6 py-4 text-base font-medium"
                                      aria-current="step"
                                    >
                                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-red-600">
                                        <span className="text-red-600">
                                          {step.id}
                                        </span>
                                      </span>
                                      <span className="ml-4 text-base font-medium text-red-600">
                                        {step.name}
                                      </span>
                                    </a>
                                  ) : (
                                    <a
                                      href={step.href}
                                      className="group flex items-center"
                                    >
                                      <span className="flex items-center px-6 py-4 text-base font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                          <span className="text-gray-500 group-hover:text-gray-900">
                                            {step.id}
                                          </span>
                                        </span>
                                        <span className="ml-4 text-base font-medium text-gray-500 group-hover:text-gray-900">
                                          {step.name}
                                        </span>
                                      </span>
                                    </a>
                                  )}
                                  {stepIdx !== steps2.length - 1 ? (
                                    <>
                                      {/* Arrow separator for lg screens and up */}
                                      <div
                                        className="absolute top-0 right-0 hidden h-full w-5 md:block"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          className="h-full w-full text-gray-300"
                                          viewBox="0 0 22 80"
                                          fill="none"
                                          preserveAspectRatio="none"
                                        >
                                          <path
                                            d="M0 -2L20 40L0 82"
                                            vectorEffect="non-scaling-stroke"
                                            stroke="currentcolor"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </div>
                                    </>
                                  ) : null}
                                </li>
                              ))}
                            </ol>
                          </nav>
                        </div>
                        <div className="pt-1">
                          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 py-4">
                            <div className="pb-5 border-b border-gray-200 col-span-6">
                              <div className="pt-1">
                                <DeleteModel
                                  mode={showModal}
                                  handleDelete={handleDelete}
                                  handleCancel={handleCancel}
                                />
                                <div className="flex justify-end">
                                  <button
                                    type="button"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                    onClick={() => handleSelectionList()}
                                  >
                                    Novo
                                  </button>
                                </div>
                                <div className="sm:col-span-6 mt-6">
                                  <DataTable
                                    columns={columns}
                                    data={data}
                                    disabledCheckbox={false}
                                    onSelectionChange={onSelectionChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {tab === 2 ? (
                      <div className="flex justify-end">
                        <button
                          type="button"
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                          onClick={() => setTab(1)}
                        >
                          Retroceder
                        </button>
                        {count >= 3 ? (
                          <button
                            type="button"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                            onClick={() =>
                              history.push(
                                "/evaluation-committee/SeeEvaluationCommittee"
                              )
                            }
                          >
                            Sair
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900 mb-5"
                    >
                      Nova Comissão de Avaliação
                    </Dialog.Title>
                    <div className="pt-1">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 py-4">
                        <div className="pb-5 border-b border-gray-200 col-span-6">
                          <form className="space-y-6" action="#" method="POST">
                            <div className="sm:col-span-6">
                              <div className="rounded-lg border border-gray-200 bg-gray-50 p-6">
                                <div className="mx-auto lg:flex lg:items-center lg:justify-between">
                                  <div className="tracking-tight text-gray-900 w-full">
                                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                      <div className="sm:col-span-3">
                                        <label
                                          htmlFor="first_name"
                                          className="block text-base font-medium text-gray-700"
                                        >
                                          Membros da Comissão{" "}
                                          <span className="mandatory">*</span>
                                        </label>
                                      </div>
                                      <div className="sm:col-span-3">
                                        <label
                                          htmlFor="first_name"
                                          className="block text-base font-medium text-gray-700"
                                        >
                                          Função do Membro na CA{" "}
                                          <span className="mandatory">*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mx-auto lg:flex lg:items-center lg:justify-between">
                                  <div className="tracking-tight text-gray-900 w-full">
                                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                      <div className="sm:col-span-3">
                                        <div className="mt-1 21">
                                          <Select
                                            value={selectedOption}
                                            placeholder="Selecionar Membros da Comissão"
                                            onChange={(e) =>
                                              handleChangeEMR(
                                                e,
                                                "evaluation_comitee_member_id"
                                              )
                                            }
                                            options={employee}
                                            isSearchable={true}
                                          />
                                          {error?.evaluation_comitee_member_id && (
                                            <span className="text-xs text-red-600 capitalize">
                                              {
                                                error?.evaluation_comitee_member_id
                                              }
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="sm:col-span-3">
                                        <div className="mt-1">
                                          <Select
                                            value={selectedOptionrole}
                                            placeholder="Selecionar Função do Membro na CA"
                                            onChange={(e) =>
                                              handleChangeEMR(
                                                e,
                                                "evaluation_commitee_role_id"
                                              )
                                            }
                                            options={role}
                                            isSearchable={true}
                                          />
                                          {error?.evaluation_commitee_role_id && (
                                            <span className="text-xs text-red-600 capitalize">
                                              {
                                                error?.evaluation_commitee_role_id
                                              }
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pt-5">
                                <div className="flex justify-end">
                                  <button
                                    type="button"
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                    onClick={() => {
                                      setOpen(false);
                                    }}
                                  >
                                    Cancelar
                                  </button>
                                  <button
                                    type="button"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                    onClick={() =>
                                      addEvaulationCommiteeMember()
                                    }
                                  >
                                    Submeter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={open2} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen2}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpen2(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900 mb-5"
                    >
                      Editar Comissão de Avaliação
                    </Dialog.Title>
                    <div className="pt-1">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 py-4">
                        <div className="pb-5 border-b border-gray-200 col-span-6">
                          <form className="space-y-6" action="#" method="POST">
                            <div className="sm:col-span-6">
                              <div className="rounded-lg border border-gray-200 bg-gray-50 p-6">
                                <div className="mx-auto lg:flex lg:items-center lg:justify-between">
                                  <div className="tracking-tight text-gray-900 w-full">
                                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                      <div className="sm:col-span-3">
                                        <label
                                          htmlFor="first_name"
                                          className="block text-base font-medium text-gray-700"
                                        >
                                          Membros da Comissão{" "}
                                          <span className="mandatory">*</span>
                                        </label>
                                      </div>
                                      <div className="sm:col-span-3">
                                        <label
                                          htmlFor="first_name"
                                          className="block text-base font-medium text-gray-700"
                                        >
                                          Função do Membro na CA{" "}
                                          <span className="mandatory">*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mx-auto lg:flex lg:items-center lg:justify-between">
                                  <div className="tracking-tight text-gray-900 w-full">
                                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                      <div className="sm:col-span-3">
                                        <div className="mt-1">
                                          <Select
                                            value={selectedOption}
                                            placeholder="Selecionar Membros da Comissão"
                                            onChange={(e) =>
                                              handleChangeEMR(
                                                e,
                                                "evaluation_comitee_member_id"
                                              )
                                            }
                                            options={employee}
                                            isSearchable={true}
                                          />
                                          {error?.evaluation_comitee_member_id && (
                                            <span className="text-xs text-red-600 capitalize">
                                              {
                                                error?.evaluation_comitee_member_id
                                              }
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="sm:col-span-3">
                                        <div className="mt-1">
                                          <Select
                                            value={selectedOptionrole}
                                            placeholder="Selecionar Função do Membro na CA"
                                            onChange={(e) =>
                                              handleChangeEMR(
                                                e,
                                                "evaluation_commitee_role_id"
                                              )
                                            }
                                            options={role}
                                            isSearchable={true}
                                          />
                                          {error?.evaluation_commitee_role_id && (
                                            <span className="text-xs text-red-600 capitalize">
                                              {
                                                error?.evaluation_commitee_role_id
                                              }
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pt-5">
                                <div className="flex justify-end">
                                  <button
                                    type="button"
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                    onClick={() => {
                                      setOpen2(false);
                                    }}
                                  >
                                    Cancelar
                                  </button>
                                  <button
                                    type="button"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                    onClick={() =>
                                      addEvaulationCommiteeMember(eMRData.id)
                                    }
                                  >
                                    Submeter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
