import { Fragment, useEffect, useState } from "react";
import LeftNavigation from "../../components/LeftNavigation/leftNavigation.js";
import Header from "../../components/Header/Header";
import { Dialog, Transition, RadioGroup, Tab } from "@headlessui/react";
import { CheckIcon, ClockIcon, CogIcon, CreditCardIcon, DocumentChartBarIcon, EyeIcon, HomeIcon, PencilIcon, PlusIcon, QuestionMarkCircleIcon, ScaleIcon, ShieldCheckIcon, TrashIcon, UserGroupIcon, XMarkIcon, MinusIcon } from "@heroicons/react/24/outline";
import { useHistory, useParams } from "react-router-dom";
import DatePicker from "react-date-picker";
import TrixEditor from "../../components/TrixEditor";
import DataTable from "../../components/MDataTable.js";
import config from "../../utils/config.js";
import { toast } from "react-toastify";
import { validateForm } from "../../utils/common.js";
import { find, pluck, contains, last, findWhere, reject } from "underscore";
import MultiSelect from "@kenshooui/react-multi-select";
import Select from "react-select";
import { useCallback } from "react";
import _ from "underscore";
import Cookies from "js-cookie";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];

const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Roles() {
  const { tripID } = useParams();
  const ci = [
    { name: "NÃO", value: "no" },
    { name: "SIM", value: "yes" },
  ];
  const [deletedDocID, setDeletedDocID] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const histroy = useHistory();
  const [ciDocSrc, setCidocSrc] = useState("");
  const [frmSrc, setFrmsrc] = useState("");
  const [editDocFrmSrc, setEditDocFrmSrc] = useState("");
  const [tripRequestedDocument, setTripRequestedDocument] = useState([]);
  const [ciList, setCiList] = useState([]);
  const [selectedDocInfo, setSelectedDocInfo] = useState({
    id: "",
    internal_communication_id: "",
    file: "",
  });
  const [documentInfo, setDocumentInfo] = useState({
    internal_communication_id: "",
    file: "",
  });
  const [error, setError] = useState({});
  const [recipientList, setRecipientList] = useState([]);
  const [procedureTypeList, setProcedureTypeList] = useState([]);
  const [requestInfo, setRequestInfo] = useState({
    requester_area: "",
    recipient_id: "",
    subject_matter: "",
    request_date: new Date(),
    procedure_type_id: "",
    internal_communication_document: "",
    internal_communication_document_signed: ci[0]?.value,
    description: "",
    ci_number: "",
    nic_id: "",
    nic_name: ""
  });
  const [initRequestInfo, setInitRequestInfo] = useState({
    requester_area: "",
    recipient_id: "",
    subject_matter: "",
    request_date: new Date(),
    procedure_type_id: "",
    internal_communication_document: "",
    internal_communication_document_signed: ci[0]?.value,
    description: "",
    ci_number: "",
  });
  const [tab, setTab] = useState(1);
  const [editorData, setEditorData] = useState("");
  const [tabCountList, setTabCountList] = useState();
  const [lastTab, setLastTab] = useState();
  const [rCTTabs, setRCTTabs] = useState([]);
  const [qDData, setQDData] = useState([]);
  const [selectedQdData, setSelectedQdData] = useState();
  const [selectedContestID, setSelectedContestID] = useState();
  const [percentageForQualification, setPercentageForQualification] =
    useState();
  const [selectedRecipientID, setSelectedRecipientID] = useState(null);
  const [selectedProcedureTypeID, setSelectedProcedureTypeID] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedICID, setSelectedICID] = useState(null);

  const [steps1, setSteps1] = useState([
    { id: 1, label: "Nova CI", href: "#", status: "current", display: true },
    {
      id: 2,
      label: "Suporte da CI",
      href: "#",
      status: "upcoming",
      display: true,
    },
    // { id: 3, label: 'Critérios de avaliação', href: '#', status: 'upcoming', display: true },
    // { id: 4, label: 'Concorrentes', href: '#', status: 'upcoming', display: false },
  ]);

  const [contract_document, setFile] = useState({ preview: "", raw: "" });
  const [eCList, setECList] = useState();
  const [attrList, setAttrList] = useState();
  const [contestData, setContestData] = useState([]);
  const [selectedNICID, setSelectedNICID] = useState(null);
  const [NICList, setNICList] = useState([]);

  const handleDocumentChange = (e, name) => {
    if (name === "file") {
      const setData = {
        ...documentInfo,
        [name]: e?.target?.files?.[0],
      };
      setFrmsrc(URL.createObjectURL(e?.target?.files?.[0]));
      setDocumentInfo(setData);
    } else if (name == "internal_communication_id") {
      const setData = {
        ...documentInfo,
        [name]: e?.value,
      };
      setDocumentInfo(setData);
      setSelectedICID(e)
    } else {
      const setData = {
        ...documentInfo,
        [name]: e?.target?.value,
      };
      setDocumentInfo(setData);
    }
  };

  const handleDocumentSubmit = async () => {
    try {
      const includedFields = ["internal_communication_id"];
      const validationResponse = validateForm(documentInfo, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        setShowLoader(true);

        let formData = new FormData();
        formData.append("file", documentInfo?.file);
        formData.append(
          "internal_communication_id",
          documentInfo?.internal_communication_id
        );
        formData.append("trip_request_id", tripID);

        const insertResponse = await config.postFormDataAPI({
          url: "tripRequestDocument/create",
          params: formData,
        });
        setShowLoader(false);
        if (insertResponse?.response?.data?.error?.message) {
          return toast.error(insertResponse?.response?.data?.error?.message);
        }

        setDocumentInfo({
          internal_communication_id: "",
          file: "",
        });
        setFrmsrc("");
        documentList(tripID);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChange = (e, name) => {
    if (name === "procedure_type_id") {
      const tempData = find(procedureTypeList, function (singlePT) {
        return singlePT.id === e.value;
      });
      setRCTTabs(JSON.parse(tempData.rating_criteria_tabs));
      setSteps1(JSON.parse(tempData.trip_tabs));
      setLastTab(last(JSON.parse(tempData.trip_tabs)).id);
      const setData = { ...requestInfo, [name]: e?.value };
      setRequestInfo(setData);
      setSelectedProcedureTypeID(e);
    } else if (name === "request_date") {
      const setData = { ...requestInfo, [name]: new Date(e) };
      setRequestInfo(setData);
    } else if (name === "recipient_id") {
      const setData = { ...requestInfo, [name]: e?.value };
      setRequestInfo(setData);
      setSelectedRecipientID(e);
    } else if (name === "nic") {
      const setData = { ...requestInfo, ['nic_id']: e?.value, ['nic_name']: e?.label };
      setRequestInfo(setData);
      setSelectedNICID(e);
    } else {
      const setData = { ...requestInfo, [name]: e?.target?.value };
      setRequestInfo(setData);
    }
  };

  function handleEditorChange(type, e) {
    setEditorData(type);
  }

  const editDocumentData = [
    {
      title: "Documento",
      field: "internal_communication_id",
      render: (rowData) => {
        return <span>{rowData?.internal_communication?.name}</span>;
      },
    },
    { title: "Ficheiro", field: "file_name" },
    {
      title: "Data de registo",
      field: "date",
      render: (rowData) => {
        return <span>{new Date(rowData?.createdAt).toLocaleDateString()}</span>;
      },
    },
    {
      title: "Acções",
      render: (rowData) => (
        <span className="flex">
          <button
            className="red-link mr-3"
            onClick={() => {
              setOpen(true);
              setSelectedDocInfo({
                internal_communication_id: rowData?.internal_communication_id,
                id: rowData?.id,
                fileName: rowData?.file_name,
              });
              setDeletedDocID(rowData?.file_name);
              setEditDocFrmSrc(config.staticPath + "tripRequest/" + tripID + "/" + rowData?.file_name);
            }}
          >
            <PencilIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          </button>
          <button
            className="red-link focus:outline-none mr-3"
            onClick={() => {
              handleDeleteDocument({
                id: rowData?.id,
                fileName: rowData?.file_name,
              });
            }}
          >
            <TrashIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          </button>
          <button
            className="red-link focus:outline-none"
            onClick={() => {
              setOpen1(true);
              setSelectedDocInfo({
                internal_communication_id: rowData?.internal_communication_id,
                id: rowData?.id,
                fileName: rowData?.file_name,
              });
              setEditDocFrmSrc(config.staticPath + "tripRequest/" + tripID + "/" + rowData?.file_name);
            }}
          >
            <EyeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          </button>
        </span>
      ),
    },
  ];

  const documentList = async (trip_request_id) => {
    config
      .getAPI({ url: "tripRequestDocument/list", params: { trip_request_id } })
      .then((data) => {
        if (data.payload) {
          setTripRequestedDocument(data.payload.rows);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const handleDeleteDocument = async ({ id, fileName }) => {
    config
      .deleteAPI({
        url: "tripRequestDocument/delete",
        params: { id, fileName },
      })
      .then((data) => {
        if (data.payload) {
          documentList();
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const getTripRequestInfo = async () => {
    config
      .getAPI({ url: "tripRequest/getdatabyId", params: { id: tripID } })
      .then((data) => {
        if (data.payload.response) {
          const {
            description,
            internal_communication_document,
            internal_communication_document_signed,
            procedure_type_id,
            recipient_id,
            requester_area,
            subject_matter,
            request_date,
            ci_number,
            nic_id,
            nic_name
          } = data.payload.response;
          setRequestInfo({
            description,
            internal_communication_document: internal_communication_document,
            internal_communication_document_signed,
            procedure_type_id,
            recipient_id,
            requester_area,
            subject_matter,
            request_date: new Date(request_date),
            ci_number,
            nic_id,
            nic_name
          });
          setSelectedRecipientID({
            value: recipient_id,
            label: data.payload?.recipientData?.recipient,
          });
          setSelectedProcedureTypeID({
            value: procedure_type_id,
            label: data.payload.PTData.name,
          });
          setInitRequestInfo({
            description,
            internal_communication_document: internal_communication_document,
            internal_communication_document_signed,
            procedure_type_id,
            recipient_id,
            requester_area,
            subject_matter,
            request_date: new Date(request_date),
            ci_number,
          });
          setSelectedNICID({
            value: nic_id,
            label: nic_name
          })
          if (internal_communication_document !== "") {
            setCidocSrc(config.staticPath + "tripRequest/" + tripID + "/" + internal_communication_document);
          }
          if (description !== "") {
            const oldDesc = description?.replace(/<[^>]*>?/gm, "");
            setEditorData(description?.replace(/<[^>]*>?/gm, ""));
          }
          setShowLoader(false)
        }
        if (data?.error?.message) {
          histroy.push("/");
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  useEffect(() => {
    const tempData = find(procedureTypeList, function (singlePT) {
      return singlePT.id === requestInfo?.procedure_type_id;
    });
    if (tempData) {
      setRCTTabs(JSON.parse(tempData.rating_criteria_tabs));
      setSteps1(JSON.parse(tempData.trip_tabs));
      setLastTab(last(JSON.parse(tempData.trip_tabs)).id);
    }
  }, [requestInfo]);

  const getRecipient = useCallback(() => {
    config
      .getAPI({ url: "recipient/list", params: {} })
      .then((data) => {
        if (data.payload) {
          data.payload.rows = data.payload.rows?.filter(
            (singlePT) => singlePT.requester_area == parseInt(Cookies.get("areaID"))
          );
          data.payload.rows.map((element) => {
            element.value = element.id;
            element.label = element.recipient;
          });
          setRecipientList(data.payload.rows);
          const selected = findWhere(data.payload.rows, {
            id: initRequestInfo.recipient_id,
          });
          selected &&
            setSelectedRecipientID({
              value: selected?.id,
              label: selected?.recipient,
            });
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }, []);

  const getProcedureType = async () => {
    config
      .getAPI({ url: "procedureType/list", params: { status: "Active" } })
      .then((data) => {
        if (data.payload) {
          data.payload.rows.map((element) => {
            element.value = element.id;
            element.label = element.name;
          });
          setProcedureTypeList(data.payload.rows);
          const selected = findWhere(data.payload.rows, {
            id: initRequestInfo.procedure_type_id,
          });
          selected &&
            setSelectedProcedureTypeID({
              value: selected?.id,
              label: selected?.name,
            });
          const dataList = data.payload.rows?.filter(
            (singlePT) => singlePT.tab_count === "4"
          );
          const listIds = pluck(dataList, "id");
          setTabCountList(listIds);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const getInternalComunicationList = async () => {
    config
      .getAPI({ url: "internalCommunication/list", params: { status: "Active" } })
      .then((data) => {
        if (data.payload) {
          data.payload.rows.map((element) => {
            element.value = element.id
            element.label = element.name
          })
          setCiList(data?.payload?.rows);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const updateRequest = async (goBack = false) => {
    try {
      const includedFields = [
        "requester_area",
        "recipient_id",
        "subject_matter",
        "request_date",
        "procedure_type_id",
        "internal_communication_document_signed",
        "ci_number",
      ];
      const validationResponse = validateForm(requestInfo, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        config.getAPI({ url: 'tripRequest/check-ci-number', params: { ci_number: requestInfo?.ci_number, trip_request_id: tripID } }).then(async (data1) => {
          if (data1.payload) {
            return toast.error("Insira o número ci até 15");
          } else {
            setShowLoader(true)
            let formData = new FormData()
            formData.append("id", tripID)
            formData.append("requester_area", requestInfo?.requester_area)
            formData.append("recipient_id", requestInfo?.recipient_id)
            formData.append("subject_matter", requestInfo?.subject_matter)
            formData.append("request_date", new Date(requestInfo?.request_date).toUTCString())
            formData.append("procedure_type_id", requestInfo?.procedure_type_id)
            formData.append("ci_number", requestInfo?.ci_number)
            if (requestInfo?.internal_communication_document && typeof requestInfo?.internal_communication_document === 'object') {
              formData.append("file", requestInfo?.internal_communication_document)
            }
            formData.append("internal_communication_document_signed", requestInfo?.internal_communication_document_signed)
            formData.append("description", editorData)
            formData.append("nic_id", requestInfo?.nic_id)
            formData.append("nic_name", requestInfo?.nic_name)

            const insertResponse = await config.postFormDataAPI({
              url: "tripRequest/update",
              params: formData,
            });
            setShowLoader(false)
            if (insertResponse?.error?.message) return toast.error(insertResponse?.error?.message);
            setTimeout(() => {
              return toast.success("Registo criado com sucesso");
            }, 200);
            if (goBack) histroy.goBack()
            else {
              setTab(steps1[1].id);
              steps1.forEach((stepData) => {
                if (stepData?.id == 2) {
                  stepData.status = 'current'
                } else {
                  stepData.status = 'upcoming'
                }
              })
              documentList(tripID)
            }
          }
        }).catch(error => {
          return toast.error(error.message)
        });
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  const handleDocumentUpdate = async () => {
    let formData = new FormData();
    formData.append("id", selectedDocInfo?.id);
    formData.append("trip_request_id", tripID);
    formData.append(
      "internal_communication_id",
      selectedDocInfo?.internal_communication_id
    );
    if (
      deletedDocID !== selectedDocInfo?.file &&
      selectedDocInfo?.file !== undefined
    ) {
      formData.append("file", selectedDocInfo?.file);
      formData.append("deletedDoc", deletedDocID);
    }
    config
      .postFormDataAPI({ url: "tripRequestDocument/update", params: formData })
      .then((data) => {
        if (data.payload) {
          documentList(tripID);
          setOpen(false);
          setSelectedDocInfo({
            file: "",
            id: "",
            internal_communication_id: "",
          });
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const updateEvaluationData = async () => {
    try {
      if (rCTTabs.length > 0) {
        if (findWhere(rCTTabs, { value: "qualification_of_candidates_01" })) {
          const sendData = {
            selectedContestID,
            selectedQdData,
            tripID,
            percentageForQualification,
          };
          const includedFields = ["contest_id", "selectedQdData", "percentageForQualification"];
          const validationResponse = validateForm(sendData, includedFields);
          setError(validationResponse?.errors);
          if (validationResponse?.isValid == true) {
            await submitQCData();
          } else {
            return
          }
        }

        if (findWhere(rCTTabs, { value: "evaluation_of_proposals_02" })) {
          const evaluation_criteria_id = evaluationDiv[0].evaluation_criteria_id
          const weight = evaluationDiv[0].weight
          const attribute_id = evaluationDiv[0].fieldArr[0].attribute_id
          const puncuation = evaluationDiv[0].fieldArr[0].puncuation
          const sendData = {
            evaluation_criteria_id,
            weight,
            attribute_id,
            puncuation
          };
          const includedFields = ["evaluation_criteria_id", "weight", "attribute_id", "puncuation"];
          const validationResponse = validateForm(sendData, includedFields);
          if (validationResponse.isValid == false) {
            return toast.error("Por favor, preencha as informações em falta")
          }
        }
      }

      const sum = _.reduce(
        evaluationDiv,
        function (memo, num) {
          return parseFloat(memo) + parseFloat(num.weight);
        },
        0
      );
      if (sum > 100) {
        return toast.error("O somatório das pontuações inseridas não pode ser superior a 100%");
      }

      const sendData = {
        trip_request_id: tripID,
        evaluationDiv,
      };

      const insertResponse = await config.postAPI({
        url: "tripEvaluationProposal/update",
        params: sendData,
      });

      if (insertResponse?.response?.data?.error?.message) {
        return toast.error(insertResponse?.response?.data?.error?.message);
      }

      setTimeout(() => {
        return toast.success("Registo criado com sucesso");
      }, 200);

      if (steps1.findIndex((x) => x.id === tab) === steps1.length - 1) {
        if (selectedRecipientID && selectedRecipientID.label == "DAF") {
          histroy.push("/requests/RequestsReceived");
        } else if (selectedRecipientID && selectedRecipientID.label == "PCA") {
          histroy.push("/requests/RequestsPCA");
        } else if (selectedRecipientID && selectedRecipientID.label == "TPE") {
          histroy.push("/requests/RequestsTPE");
        }
      } else {
        setTab(steps1[parseInt(steps1.findIndex((x) => x.id === tab) + 1)].id);
        steps1.forEach((stepData) => {
          if (stepData?.id === tab + 1) {
            stepData.status = "current";
          } else {
            stepData.status = "upcoming";
          }
        });
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  const [evaluationDiv, setEvaluationDiv] = useState([
    {
      evaluation_criteria_id: "",
      weight: 0,
      fieldArr: [
        {
          attribute_id: "",
          puncuation: "",
        },
      ],
    },
  ]);

  const [competitorDiv, setCompetitorDiv] = useState([
    {
      company: "",
      tax_number: "",
      company_type_id: 0,
      email: "",
      address: "",
      phone_number: "",
    },
  ]);
  const [supplerList, setSupplerList] = useState();

  useEffect(() => {
    setShowLoader(true)
    getProcedureType();
    getTripRequestInfo();
    getRecipient();
    getInternalComunicationList();
    getECList();
    getAttrList();
    getEvaluationData();
    getCompetitorData();
    getSupplierList();
    getContestList();
    getselectedQCData();
    setRequestInfo({
      ...requestInfo,
      requester_area: Cookies.get("area"),
    });
    getNICList();
  }, [getRecipient]);

  const getNICList = async () => {
    const response = await config.getAPI({ url: "budgetMap/list", params: {} });
    response.payload.rows.map((element) => {
      element.value = element.id;
      element.label = element.nic;
    });
    response.payload.rows = reject(response.payload.rows, function (num) { return num.nic == null })
    setNICList(response.payload.rows)
  }

  const getselectedQCData = async () => {
    config
      .getAPI({
        url: "tripQualificationCandidate/list",
        params: { id: tripID },
      })
      .then((data) => {
        if (data.payload) {
          setSelectedContestID(data.payload[0]?.contest_id);
          setPercentageForQualification(data.payload[0]?.percentage);
          getDocData(data.payload[0]?.contest_id);
          const tempID = pluck(data.payload, "qualification_document_id");
          config
            .getAPI({
              url: "tripQualificationCandidate/doc-list",
              params: { id: JSON.stringify(tempID) },
            })
            .then((data2) => {
              if (data2.payload) {
                data2.payload.map((element) => {
                  element.label = element.name;
                });
                setSelectedQdData(data2.payload);
              }
            })
            .catch((error) => {
              return toast.error(error.message);
            });
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const getContestList = () => {
    config
      .getAPI({ url: "objectTender/list", params: { status: "Active" } })
      .then((data) => {
        if (data.payload) {
          setContestData(data.payload.rows);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const getSupplierList = () => {
    config
      .getAPI({ url: "supplierType/list", params: { status: "Active" } })
      .then((data) => {
        if (data.payload) {
          setSupplerList(data.payload.rows);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const getEvaluationData = () => {
    config
      .getAPI({
        url: "tripEvaluationProposal/getdatabyId",
        params: { trip_request_id: tripID },
      })
      .then((data) => {
        if (data.payload) {
          let tempData = [];
          data.payload.response.rows.map((single) => {
            let obj = {
              evaluation_criteria_id: single.evaluation_criteria_id,
              weight: single.weight,
              trip_evaluation_proposal_id: single.id,
            };
            obj.fieldArr = data.payload.response2.rows.filter((data) => {
              if (data.trip_evaluation_proposal_id === single.id) {
                return data;
              }
            });
            tempData.push(obj);
          });
          if (tempData.length === 0) {
            setEvaluationDiv([
              {
                evaluation_criteria_id: "",
                weight: 0,
                fieldArr: [
                  {
                    attribute_id: "",
                    puncuation: "",
                  },
                ],
              },
            ]);
          } else {
            setEvaluationDiv(tempData);
          }
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const addEvaluationDiv = () => {
    setEvaluationDiv([
      ...evaluationDiv,
      {
        evaluation_criteria_id: "",
        weight: 0,
        fieldArr: [
          {
            attribute_id: "",
            puncuation: "",
          },
        ],
      },
    ]);
  };

  const removeEvaluationDiv = (divIndex) => {
    const response = evaluationDiv?.filter(
      (element, index) => index !== divIndex
    );
    setEvaluationDiv(response);
  };

  const addNewRow = (divIndex) => {
    evaluationDiv[divIndex].fieldArr = [
      ...evaluationDiv[divIndex].fieldArr,
      {
        attribute_id: "",
        puncuation: "",
      },
    ];
    setEvaluationDiv([...evaluationDiv]);
  };

  const deleteNewRow = (divIndex, attrIndex) => {
    evaluationDiv[divIndex].fieldArr = evaluationDiv[divIndex].fieldArr?.filter(
      (element, index) => index !== attrIndex
    );
    setEvaluationDiv([...evaluationDiv]);
  };

  const getECList = () => {
    config
      .getAPI({ url: "evaluationCriteria/list", params: { status: "Active" } })
      .then((data) => {
        if (data.payload) {
          setECList(data.payload.rows);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const getAttrList = () => {
    config
      .getAPI({ url: "attributes/list", params: { status: "Active" } })
      .then((data) => {
        if (data.payload) {
          setAttrList(data.payload.rows);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const handleChangeEvaluation = (e, field, divIndex, attrIndex) => {
    evaluationDiv[divIndex][field] = e.target.value;
    if (attrIndex === 0 || attrIndex > 0) {
      evaluationDiv[divIndex].fieldArr[attrIndex][field] = e.target.value;
    }
    setEvaluationDiv(evaluationDiv);
  };

  const getCompetitorData = () => {
    config
      .getAPI({
        url: "tripCompetitor/getdatabyId",
        params: { trip_request_id: tripID },
      })
      .then((data) => {
        if (data.payload) {
          let tempData = [];
          data.payload.response.rows.map((single) => {
            let obj = {
              company: single.company,
              tax_number: single.tax_number,
              company_type_id: single.company_type_id,
              email: single.email,
              address: single.address,
              phone_number: single.phone_number,
              trip_competitor_id: single.id,
            };
            tempData.push(obj);
          });
          setCompetitorDiv(tempData);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const addCompetitorDiv = () => {
    setCompetitorDiv([
      ...competitorDiv,
      {
        company: "",
        tax_number: "",
        company_type_id: 0,
        email: "",
        address: "",
        phone_number: "",
      },
    ]);
  };

  const removeCompetitorDiv = (divIndex) => {
    const response = competitorDiv?.filter(
      (element, index) => index !== divIndex
    );
    setCompetitorDiv(response);
  };

  const handleChangeCompetitor = (e, field, divIndex) => {
    competitorDiv[divIndex][field] = e.target.value;
    setCompetitorDiv(competitorDiv);
  };

  const updateCompetitorData = async () => {
    try {
      const sendValidationData = {
        company: competitorDiv[0].company,
        tax_number: competitorDiv[0].tax_number,
        company_type_id: competitorDiv[0].company_type_id,
        email: competitorDiv[0].email,
        address: competitorDiv[0].address,
        phone_number: competitorDiv[0].phone_number
      };
      const includedFields = ["company", "tax_number", "company_type_id", "email", "address", "phone_number"];
      const validationResponse = validateForm(sendValidationData, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid == false) {
        return toast.error("Por favor, preencha as informações em falta")
      }

      const sendData = {
        trip_request_id: tripID,
        competitorDiv,
      };
      const insertResponse = await config.postAPI({
        url: "tripCompetitor/update",
        params: sendData,
      });
      if (insertResponse?.response?.data?.error?.message) {
        return toast.error(insertResponse?.response?.data?.error?.message);
      }
      setTimeout(() => {
        return toast.success("Registo criado com sucesso");
      }, 200);

      if (selectedRecipientID && selectedRecipientID.label == "DAF") {
        histroy.push("/requests/RequestsReceived");
      } else if (selectedRecipientID && selectedRecipientID.label == "PCA") {
        histroy.push("/requests/RequestsPCA");
      } else if (selectedRecipientID && selectedRecipientID.label == "TPE") {
        histroy.push("/requests/RequestsTPE");
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  const getDocData = (id) => {
    setSelectedContestID(id);
    config
      .getAPI({ url: "tripRequest/getdatabyContestID", params: { id, status: "Active" } })
      .then((data) => {
        if (data.payload) {
          data.payload?.map((element) => {
            element.label = element.name;
          });
          setQDData(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const submitQCData = async () => {
    try {
      const sendData = {
        selectedContestID,
        selectedQdData,
        tripID,
        percentageForQualification,
      };
      const includedFields = ["selectedContestID", "selectedQdData", "percentageForQualification"];
      const validationResponse = validateForm(sendData, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        const insertResponse = await config.postAPI({
          url: "tripQualificationCandidate/update",
          params: sendData,
        });
        if (insertResponse?.response?.data?.error?.message) {
          return toast.error(insertResponse?.response?.data?.error?.message);
        }
        setTimeout(() => {
          return toast.success("Registo criado com sucesso");
        }, 200);
      }
    } catch (error) {
      return toast.error(error.message);
    }
  };

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1 pb-8">
            <div
              className="loader-img"
              style={{
                display: showLoader ? "block" : "none",
              }}
            ></div>
            <div className="mt-8">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="py-5 flex items-center justify-between">
                  <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
                    Editar solicitações
                  </h1>
                </div>
                <div className="space-y-6" action="#" method="POST">
                  <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                    <div className="space-y-6">
                      <div className="mb-6">
                        <nav aria-label="Progress">
                          <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
                            {steps1.map((step, stepIdx) => {
                              return (
                                //  step?.display ?
                                <li
                                  key={step.label}
                                  className="relative md:flex md:flex-1"
                                >
                                  {step.status === "complete" ? (
                                    <a
                                      href={step.href}
                                      className="group flex w-full items-center"
                                    >
                                      <span className="flex items-center px-6 py-4 text-base font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-600">
                                          <CheckIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                          />
                                        </span>
                                        <span className="ml-4 text-base font-medium text-gray-900">
                                          {step.label}
                                        </span>
                                      </span>
                                    </a>
                                  ) : step.status === "current" ? (
                                    <a
                                      href={step.href}
                                      className="flex items-center px-6 py-4 text-base font-medium"
                                      aria-current="step"
                                    >
                                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-red-600">
                                        <span className="text-red-600">
                                          {step.id}
                                        </span>
                                      </span>
                                      <span className="ml-4 text-base font-medium text-red-600">
                                        {step.label}
                                      </span>
                                    </a>
                                  ) : (
                                    <a
                                      href={step.href}
                                      className="group flex items-center"
                                    >
                                      <span className="flex items-center px-6 py-4 text-base font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                          <span className="text-gray-500 group-hover:text-gray-900">
                                            {step.id}
                                          </span>
                                        </span>
                                        <span className="ml-4 text-base font-medium text-gray-500 group-hover:text-gray-900">
                                          {step.label}
                                        </span>
                                      </span>
                                    </a>
                                  )}

                                  {stepIdx !== steps1.length - 1 ? (
                                    <>
                                      <div
                                        className="absolute top-0 right-0 hidden h-full w-5 md:block"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          className="h-full w-full text-gray-300"
                                          viewBox="0 0 22 80"
                                          fill="none"
                                          preserveAspectRatio="none"
                                        >
                                          <path
                                            d="M0 -2L20 40L0 82"
                                            vectorEffect="non-scaling-stroke"
                                            stroke="currentcolor"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </div>
                                    </>
                                  ) : null}
                                </li>
                              );
                              //  : null
                            })}
                          </ol>
                        </nav>
                      </div>
                      {tab === 1 ? (
                        <div className="w-full">
                          <div className="pt-1">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Área solicitante{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="requester_area"
                                    placeholder="DAF"
                                    // onChange={(e) => {
                                    //   handleChange(e, "requester_area")
                                    // }}
                                    value={Cookies.get("area")}
                                    className={`${error?.requester_area && "border-red-600"
                                      }  mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base`}
                                    disabled
                                  />
                                  {error?.requester_area && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.requester_area}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="last_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Destinatário{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <Select
                                    value={selectedRecipientID}
                                    placeholder="Selecionar o Destinatário"
                                    onChange={(e) => {
                                      handleChange(e, "recipient_id");
                                    }}
                                    options={recipientList}
                                    isSearchable={true}
                                  />
                                  {error?.recipient_id && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.recipient_id}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Assunto <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="first-name"
                                    onChange={(e) => {
                                      handleChange(e, "subject_matter");
                                    }}
                                    value={requestInfo?.subject_matter}
                                    className={`${error?.subject_matter && "border-red-600"
                                      } mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base `}
                                  />
                                  {error?.subject_matter && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.subject_matter}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Data da solicitação{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1 relative">
                                  <DatePicker
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    onChange={(e) => {
                                      handleChange(e, "request_date");
                                    }}
                                    value={requestInfo?.request_date}
                                  />
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="last_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Tipo de procedimento{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  {/* <select
                                    name="procedure_type_id"
                                    onChange={(e) => {
                                      handleChange(e, "procedure_type_id")
                                      steps1.forEach((stepData) => {
                                        if (contains(tabCountList, Number(e.target.value))) {
                                          if (stepData.id === 4) {
                                            stepData.display = true
                                          }
                                        } else {
                                          if (stepData.id === 4) {
                                            stepData.display = false
                                          }
                                        }
                                      })
                                    }}
                                    value={requestInfo?.procedure_type_id}
                                    className={` ${error?.procedure_type_id && 'border-red-600'} mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base`}
                                  >
                                    <option value=""> -- Selecionar -- </option>
                                    {
                                      procedureTypeList?.map((info) => {
                                        return <option key={info?.id} value={info?.id}>{info?.name}</option>
                                      })
                                    }
                                  </select> */}
                                  <Select
                                    value={selectedProcedureTypeID}
                                    placeholder="Selecionar Tipo de procedimento"
                                    onChange={(e) => {
                                      handleChange(e, "procedure_type_id");
                                      steps1.forEach((stepData) => {
                                        if (
                                          contains(
                                            tabCountList,
                                            Number(e.value)
                                          )
                                        ) {
                                          if (stepData.id === 4) {
                                            stepData.display = true;
                                          }
                                        } else {
                                          if (stepData.id === 4) {
                                            stepData.display = false;
                                          }
                                        }
                                      });
                                    }}
                                    options={procedureTypeList}
                                    isSearchable={true}
                                  />

                                  {error?.procedure_type_id && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.procedure_type_id}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="ci_number"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Número da CI{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="ci_number"
                                    onChange={(e) => {
                                      handleChange(e, "ci_number");
                                    }}
                                    value={requestInfo?.ci_number}
                                    className={`${error?.ci_number && "border-red-600"
                                      } mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base `}
                                    disabled={true}
                                  />
                                  {error?.ci_number && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.ci_number}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="last_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  NIC{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <Select
                                    value={selectedNICID}
                                    placeholder="Selecionar NIC"
                                    onChange={(e) => {
                                      handleChange(e, "nic");
                                    }}
                                    options={NICList}
                                    isSearchable={true}
                                  />
                                  {error?.nic && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.nic}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Já possui a CI assinada?
                                </label>
                                <div className="mt-1 mb-6">
                                  <RadioGroup
                                    value={
                                      requestInfo?.internal_communication_document_signed
                                    }
                                    onChange={(e) => {
                                      setRequestInfo({
                                        ...requestInfo,
                                        internal_communication_document_signed:
                                          e,
                                      });
                                    }}
                                  >
                                    <RadioGroup.Label className="sr-only">
                                      {" "}
                                      Privacy setting{" "}
                                    </RadioGroup.Label>
                                    <div className="-space-y-px sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                      {ci.map((setting, settingIdx) => (
                                        <RadioGroup.Option
                                          key={setting.name}
                                          value={setting.value}
                                          className={({ checked }) =>
                                            classNames(
                                              settingIdx === 0
                                                ? "rounded-tl-md rounded-tr-md"
                                                : "",
                                              settingIdx === ci.length - 1
                                                ? "rounded-bl-md rounded-br-md"
                                                : "",
                                              checked
                                                ? "bg-white border-white"
                                                : "border-white",
                                              "relative border flex cursor-pointer focus:outline-none"
                                            )
                                          }
                                        >
                                          {({ active, checked }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  checked
                                                    ? "bg-red-600 border-transparent"
                                                    : "bg-white border-gray-300",
                                                  active
                                                    ? "ring-2 ring-offset-2 ring-red-700"
                                                    : "",
                                                  "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                                )}
                                                aria-hidden="true"
                                              >
                                                <span className="rounded-full bg-white w-1.5 h-1.5" />
                                              </span>
                                              <span className="ml-3 flex flex-col">
                                                <RadioGroup.Label
                                                  as="span"
                                                  className={classNames(
                                                    checked
                                                      ? "text-red-700"
                                                      : "text-gray-900",
                                                    "block text-sm font-medium"
                                                  )}
                                                >
                                                  {setting.name}
                                                </RadioGroup.Label>
                                              </span>
                                            </>
                                          )}
                                        </RadioGroup.Option>
                                      ))}
                                    </div>
                                  </RadioGroup>
                                </div>

                              </div>
                              <div className="sm:col-span-6">
                                {requestInfo?.internal_communication_document_signed ===
                                  "no" ? (
                                  <>
                                    <div className="sm:col-span-6">
                                      <TrixEditor
                                        className=""
                                        name={"Trix"}
                                        value={editorData}
                                        handleChange={handleEditorChange}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                {requestInfo?.internal_communication_document_signed ===
                                  "yes" ? (
                                  <>
                                    <div className="sm:col-span-6 px-4 py-4">
                                      <label
                                        htmlFor="first_name"
                                        className="block text-base font-medium text-gray-700"
                                      >
                                        Submeta a CI
                                      </label>
                                      {ciDocSrc !== "" ? (
                                        <iframe
                                          style={{ height: "45vh" }}
                                          src={ciDocSrc}
                                          title="document"
                                          className="w-4/6 mx-auto mt-5"
                                        />
                                      ) : (
                                        ""
                                      )}

                                      <div className="w-4/6 mx-auto mt-6 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                        <div className="space-y-1 text-center">
                                          {contract_document.preview ? (
                                            <img
                                              src={"/img/done.svg"}
                                              alt="Profile"
                                              className="mx-auto h-12 w-12 text-gray-400"
                                            />
                                          ) : (
                                            <img
                                              src={"/img/pdf.png"}
                                              alt="Profile"
                                              className="mx-auto h-12 w-12 text-gray-400"
                                            />
                                          )}
                                          <div className="flex text-sm text-gray-600">
                                            <label
                                              htmlFor="file-upload"
                                              className="relative cursor-pointer rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                            >
                                              <span>Seleccione o ficheiro</span>
                                              <input
                                                accept="application/pdf"
                                                id="file-upload"
                                                name="file-upload"
                                                type="file"
                                                className="sr-only"
                                                onChange={(e) => {
                                                  setCidocSrc(
                                                    URL.createObjectURL(
                                                      e?.target?.files?.[0]
                                                    )
                                                  );
                                                  setRequestInfo({
                                                    ...requestInfo,
                                                    internal_communication_document:
                                                      e?.target?.files?.[0],
                                                  });
                                                }}
                                              />
                                            </label>
                                          </div>
                                          <p className="text-xs text-gray-500">
                                            PDF até 10MB
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                onClick={() => histroy.goBack()}
                              >
                                Cancelar
                              </button>

                              <button
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                onClick={() => {
                                  updateRequest(true);
                                }}
                              >
                                Guardar e Sair
                              </button>
                              <button
                                id="1"
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                onClick={() => {
                                  updateRequest();
                                }}
                              >
                                Próximo
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {tab === 2 ? (
                        <div className="w-full">
                          <div className="pt-1">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 px-4">
                              <div className="pb-5 border-b border-gray-200 col-span-6">
                                <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
                                  <h3 className="sr-only">Documento</h3>
                                  <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                      <label for="first_name" className="block text-base font-medium text-gray-700 mt-4">Documento <span className="mandatory">*</span></label>
                                    </div>
                                    <div className="sm:col-span-3 text-right">
                                      <button type="button"
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                        onClick={handleDocumentSubmit}>Carregar</button>
                                    </div>

                                  </div>
                                  <div className="sm:col-span-6">
                                    <div className="sm:col-span-3 mt-3">
                                      <div className="mt-1">
                                        <Select
                                          value={selectedICID}
                                          placeholder="Selecione Documento"
                                          onChange={(e) => { handleDocumentChange(e, "internal_communication_id"); }}
                                          options={ciList}
                                          isSearchable={true}
                                        />
                                        {error?.internal_communication_id && (
                                          <span className="text-xs text-red-600 capitalize">
                                            {error?.internal_communication_id}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    {frmSrc !== "" ? (
                                      <iframe
                                        style={{ height: "45vh" }}
                                        src={frmSrc}
                                        title="document"
                                        className="w-4/6 mx-auto mt-5"
                                      />
                                    ) : (
                                      ""
                                    )}

                                    <div className="w-4/6 mx-auto mt-8 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                      <div className="space-y-1 text-center">
                                        {contract_document.preview ? (
                                          <img
                                            src={"/img/done.svg"}
                                            alt="Profile"
                                            className="mx-auto h-12 w-12 text-gray-400"
                                          />
                                        ) : (
                                          <img
                                            src={"/img/pdf.png"}
                                            alt="Profile"
                                            className="mx-auto h-12 w-12 text-gray-400"
                                          />
                                        )}
                                        <div className="flex text-sm text-gray-600">
                                          <label
                                            htmlFor="file-upload1"
                                            className="relative cursor-pointer rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                          >
                                            <span>Seleccione o ficheiro</span>
                                            <input
                                              accept="application/pdf"
                                              id="file-upload1"
                                              name="file-upload"
                                              type="file"
                                              className="sr-only"
                                              onChange={(e) => {
                                                handleDocumentChange(e, "file");
                                              }}
                                              value={
                                                documentInfo
                                                  ?.internal_communication_id
                                                  ?.name || ""
                                              }
                                            />
                                          </label>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                          PDF até 10MB
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="sm:col-span-6 mt-6">
                                    <DataTable
                                      columns={editDocumentData}
                                      data={tripRequestedDocument}
                                      disabledCheckbox={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                onClick={() => {
                                  setTab(tab - 1);
                                  steps1.forEach((stepData) => {
                                    if (stepData?.id > 1) {
                                      stepData.status = "upcoming";
                                    } else {
                                      stepData.status = "current";
                                    }
                                  });
                                }}
                              >
                                Retroceder
                              </button>
                              <button
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                // onClick={() => {
                                //   setTab(tab + 1);
                                //   steps1.forEach((stepData) => {
                                //     if (stepData?.id > 3) {
                                //       stepData.status = "upcoming";
                                //     } else {
                                //       stepData.status = "current";
                                //     }
                                //   });
                                // }}

                                onClick={() => {
                                  if (
                                    steps1.findIndex((x) => x.id === tab) ===
                                    steps1.length - 1
                                  ) {
                                    if (selectedRecipientID && selectedRecipientID.label == "DAF") {
                                      histroy.push(
                                        "/requests/RequestsReceived"
                                      );
                                    } else if (
                                      selectedRecipientID && selectedRecipientID.label == "PCA"
                                    ) {
                                      histroy.push("/requests/RequestsPCA");
                                    } else if (
                                      selectedRecipientID && selectedRecipientID.label == "TPE"
                                    ) {
                                      histroy.push("/requests/RequestsTPE");
                                    }
                                  } else {
                                    setTab(tab + 1);
                                  }
                                  steps1.forEach((stepData) => {
                                    if (stepData?.id > 3) {
                                      stepData.status = "upcoming";
                                    } else {
                                      stepData.status = "current";
                                    }
                                  });
                                }}
                              >
                                Guardar e Sair
                              </button>
                              <button
                                id="2"
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                onClick={() => {
                                  if (
                                    steps1.findIndex((x) => x.id === tab) ===
                                    steps1.length - 1
                                  ) {
                                    if (selectedRecipientID && selectedRecipientID.label == "DAF") {
                                      histroy.push(
                                        "/requests/RequestsReceived"
                                      );
                                    } else if (
                                      selectedRecipientID && selectedRecipientID.label == "PCA"
                                    ) {
                                      histroy.push("/requests/RequestsPCA");
                                    } else if (
                                      selectedRecipientID && selectedRecipientID.label == "TPE"
                                    ) {
                                      histroy.push("/requests/RequestsTPE");
                                    }
                                  } else {
                                    setTab(
                                      steps1[
                                        parseInt(
                                          steps1.findIndex(
                                            (x) => x.id === tab
                                          ) + 1
                                        )
                                      ].id
                                    );
                                  }

                                  steps1.forEach((stepData) => {
                                    if (stepData?.id === tab + 1) {
                                      stepData.status = "current";
                                    } else {
                                      stepData.status = "upcoming";
                                    }
                                  });
                                }}
                              >
                                Próximo
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {tab === 3 ? (
                        <div className="w-full">
                          <div className="pt-1">
                            <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
                              <div className="mt-1">
                                {
                                  <div className="sm:col-span-6">
                                    <Tab.Group as="div">
                                      <div className="border-b border-gray-200">
                                        <Tab.List className="-mb-px flex space-x-8">
                                          {rCTTabs &&
                                            rCTTabs.map((singleTab) => {
                                              return (
                                                <>
                                                  {singleTab.value ===
                                                    "qualification_of_candidates_01" ? (
                                                    <Tab
                                                      className={({
                                                        selected,
                                                      }) =>
                                                        classNames(
                                                          selected
                                                            ? "border-red-600 text-red-600"
                                                            : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                                          "whitespace-nowrap border-b-2 pb-6 text-base font-bold"
                                                        )
                                                      }
                                                    >
                                                      Qualificação de candidatos
                                                    </Tab>
                                                  ) : (
                                                    <Tab
                                                      className={({
                                                        selected,
                                                      }) =>
                                                        classNames(
                                                          selected
                                                            ? "border-red-600 text-red-600"
                                                            : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                                          "whitespace-nowrap border-b-2 pb-6 text-base font-bold"
                                                        )
                                                      }
                                                    >
                                                      Avaliação de propostas
                                                    </Tab>
                                                  )}
                                                </>
                                              );
                                            })}
                                        </Tab.List>
                                      </div>

                                      <Tab.Panels as={Fragment}>
                                        {rCTTabs &&
                                          rCTTabs.map((singleTab) => {
                                            return (
                                              <>
                                                {singleTab.value ===
                                                  "qualification_of_candidates_01" ? (
                                                  <Tab.Panel className="pt-3">
                                                    <h3 className="sr-only">
                                                      Habilitação de
                                                      Concorrentes
                                                    </h3>
                                                    <div className="sm:col-span-6 mt-6">
                                                      <label
                                                        htmlFor="last_name"
                                                        className="block text-base font-medium text-gray-700"
                                                      >
                                                        {/* Âmbito do concurso */}
                                                        Objeto do Concurso
                                                      </label>
                                                      <div className="mt-1">
                                                        <select
                                                          id="country"
                                                          name="country"
                                                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base"
                                                          onChange={(e) =>
                                                            getDocData(
                                                              e.target.value
                                                            )
                                                          }
                                                          defaultValue={
                                                            selectedContestID
                                                          }
                                                        >
                                                          <option>
                                                            {" "}
                                                            -- Selecionar Objeto
                                                            do Concurso --{" "}
                                                          </option>
                                                          {contestData &&
                                                            contestData.map(
                                                              (singleCD) => {
                                                                return (
                                                                  <option
                                                                    value={
                                                                      singleCD.id
                                                                    }
                                                                  >
                                                                    {
                                                                      singleCD.name
                                                                    }
                                                                  </option>
                                                                );
                                                              }
                                                            )}
                                                        </select>
                                                        {error?.contest_id && (
                                                          <span className="text-xs text-red-600 capitalize">
                                                            {error?.contest_id}
                                                          </span>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="sm:col-span-6 mt-6">
                                                      <label
                                                        htmlFor="street-address"
                                                        className="block text-base font-medium text-gray-700"
                                                      >
                                                        Documentos que constituirão a candidatura (Critérios técnico e Financeiro)
                                                      </label>
                                                      <div className="mt-1">
                                                        <MultiSelect
                                                          items={qDData}
                                                          messages={{
                                                            searchPlaceholder:
                                                              "Procurar...",
                                                            noItemsMessage:
                                                              "Nenhum item...",
                                                            noneSelectedMessage:
                                                              "Nenhum seleccionado",
                                                            selectedMessage:
                                                              "selecionado",
                                                            selectAllMessage:
                                                              "Selecionar tudo",
                                                            clearAllMessage:
                                                              "Limpar tudo",
                                                            disabledItemsTooltip:
                                                              "Você só pode selecionar 1 arquivo",
                                                          }}
                                                          selectedItems={
                                                            selectedQdData
                                                          }
                                                          itemHeight={50}
                                                          onChange={(e) =>
                                                            setSelectedQdData(e)
                                                          }
                                                        />
                                                        {error?.selectedQdData && (
                                                          <span className="text-xs text-red-600 capitalize">
                                                            {
                                                              error?.selectedQdData
                                                            }
                                                          </span>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="sm:col-span-6 mt-6 w-64">
                                                      <label
                                                        htmlFor="last_name"
                                                        className="block text-base font-medium text-gray-700"
                                                      >
                                                        Percentagem para qualificação (%)
                                                      </label>
                                                      <div className="mt-1">
                                                        <input
                                                          type="text"
                                                          name="puncuation"
                                                          id="puncuation"
                                                          maxLength="4"
                                                          className="mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base"
                                                          defaultValue={
                                                            percentageForQualification
                                                          }
                                                          onChange={(e) =>
                                                            setPercentageForQualification(
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                        {error?.percentage && (
                                                          <span className="text-xs text-red-600 capitalize">
                                                            {error?.percentage}
                                                          </span>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="w-full pt-4">
                                                      <button
                                                        type="button"
                                                        className="order-0 inline-flex items-center rounded-md border border-transparent btn-link bg-skin-primary px-4 py-2 text-sm font-medium text-white shadow-sm   sm:order-1"
                                                        onClick={() =>
                                                          submitQCData()
                                                        }
                                                      >
                                                        Guardar
                                                      </button>
                                                    </div>
                                                  </Tab.Panel>
                                                ) : (
                                                  <Tab.Panel className="pt-3">
                                                    <div className="space-y-8 divide-y divide-gray-200">
                                                      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                        <div className="sm:col-span-6">
                                                          {evaluationDiv &&
                                                            evaluationDiv.length >
                                                            0
                                                            ? evaluationDiv.map(
                                                              (
                                                                element,
                                                                index
                                                              ) => {
                                                                return (
                                                                  <>
                                                                    <div className="rounded-lg border border-gray-200 mt-2">
                                                                      <div className="mx-auto lg:flex lg:items-center lg:justify-between p-6 bg-gray-50 rounded-lg">
                                                                        <div className="tracking-tight text-gray-900 w-full">
                                                                          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                                            <div className="sm:col-span-2">
                                                                              <div className="mt-1">
                                                                                <label
                                                                                  htmlFor="first_name"
                                                                                  className="block text-base font-medium text-gray-700"
                                                                                >
                                                                                  Critério
                                                                                  de
                                                                                  avaliação
                                                                                </label>
                                                                                <select
                                                                                  id="country"
                                                                                  name="country"
                                                                                  className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base"
                                                                                  defaultValue={
                                                                                    element?.evaluation_criteria_id
                                                                                  }
                                                                                  // value={element?.evaluation_criteria_id}
                                                                                  onChange={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleChangeEvaluation(
                                                                                      e,
                                                                                      "evaluation_criteria_id",
                                                                                      index
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <option>
                                                                                    {" "}
                                                                                    --
                                                                                    Selecionar
                                                                                    Critério
                                                                                    de
                                                                                    avaliação
                                                                                    --{" "}
                                                                                  </option>
                                                                                  {eCList &&
                                                                                    eCList.length >
                                                                                    0
                                                                                    ? eCList.map(
                                                                                      (
                                                                                        singleEC
                                                                                      ) => {
                                                                                        return (
                                                                                          <option
                                                                                            value={
                                                                                              singleEC.id
                                                                                            }
                                                                                          >
                                                                                            {
                                                                                              singleEC.name
                                                                                            }
                                                                                          </option>
                                                                                        );
                                                                                      }
                                                                                    )
                                                                                    : ""}
                                                                                </select>
                                                                              </div>
                                                                            </div>
                                                                            <div className="sm:col-span-1">
                                                                              <div className="mt-1">
                                                                                <label
                                                                                  htmlFor="first_name"
                                                                                  className="block text-base font-medium text-gray-700"
                                                                                >
                                                                                  Peso
                                                                                  (%)
                                                                                </label>
                                                                                <input
                                                                                  type="text"
                                                                                  name="weight"
                                                                                  id="weight"
                                                                                  maxLength="3"
                                                                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base"
                                                                                  onChange={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleChangeEvaluation(
                                                                                      e,
                                                                                      "weight",
                                                                                      index
                                                                                    )
                                                                                  }
                                                                                  // value={element?.weight}
                                                                                  defaultValue={
                                                                                    element?.weight
                                                                                  }
                                                                                />
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>

                                                                      <div className="w-full px-6 mt-4">
                                                                        {element.fieldArr &&
                                                                          element
                                                                            .fieldArr
                                                                            .length >
                                                                          0
                                                                          ? element.fieldArr.map(
                                                                            (
                                                                              attrElement,
                                                                              attrIndex
                                                                            ) => {
                                                                              return (
                                                                                <>
                                                                                  <div className="mx-auto lg:flex lg:items-center lg:justify-between pb-4">
                                                                                    <div className="tracking-tight text-gray-900 w-full">
                                                                                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                                                        <div className="sm:col-span-2">
                                                                                          <div className="mt-0">
                                                                                            <label
                                                                                              htmlFor="first_name"
                                                                                              className="block text-base font-medium text-gray-700"
                                                                                            >
                                                                                              Atributos
                                                                                            </label>
                                                                                            <select
                                                                                              id="country"
                                                                                              name="country"
                                                                                              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base"
                                                                                              // value={attrElement?.attribute_id}
                                                                                              defaultValue={
                                                                                                attrElement?.attribute_id
                                                                                              }
                                                                                              onChange={(
                                                                                                e
                                                                                              ) =>
                                                                                                handleChangeEvaluation(
                                                                                                  e,
                                                                                                  "attribute_id",
                                                                                                  index,
                                                                                                  attrIndex
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <option>
                                                                                                {" "}
                                                                                                --
                                                                                                Selecionar
                                                                                                Atributos
                                                                                                --{" "}
                                                                                              </option>
                                                                                              {attrList &&
                                                                                                attrList.length >
                                                                                                0
                                                                                                ? attrList.map(
                                                                                                  (
                                                                                                    singleAttr
                                                                                                  ) => {
                                                                                                    return (
                                                                                                      <option
                                                                                                        value={
                                                                                                          singleAttr.id
                                                                                                        }
                                                                                                      >
                                                                                                        {
                                                                                                          singleAttr.name
                                                                                                        }
                                                                                                      </option>
                                                                                                    );
                                                                                                  }
                                                                                                )
                                                                                                : ""}
                                                                                            </select>
                                                                                          </div>
                                                                                        </div>
                                                                                        <div className="sm:col-span-1">
                                                                                          <div className="mt-0">
                                                                                            <label
                                                                                              htmlFor="first_name"
                                                                                              className="block text-base font-medium text-gray-700"
                                                                                            >
                                                                                              Pontuação
                                                                                            </label>
                                                                                            <input
                                                                                              type="text"
                                                                                              name="puncuation"
                                                                                              id="puncuation"
                                                                                              maxLength="3"
                                                                                              className="mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base"
                                                                                              onChange={(
                                                                                                e
                                                                                              ) =>
                                                                                                handleChangeEvaluation(
                                                                                                  e,
                                                                                                  "puncuation",
                                                                                                  index,
                                                                                                  attrIndex
                                                                                                )
                                                                                              }
                                                                                              // value={attrElement?.puncuation}
                                                                                              defaultValue={
                                                                                                attrElement?.puncuation
                                                                                              }
                                                                                            />
                                                                                          </div>
                                                                                        </div>
                                                                                        {attrIndex ===
                                                                                          0 ? (
                                                                                          <div className="sm:col-span-1">
                                                                                            <button
                                                                                              href="#"
                                                                                              className="ml-3 mt-7 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-skin-primary hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                                                                              onClick={() =>
                                                                                                addNewRow(
                                                                                                  index,
                                                                                                  attrIndex
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <PlusIcon
                                                                                                className="h-6 w-6 text-white"
                                                                                                aria-hidden="true"
                                                                                              />
                                                                                            </button>
                                                                                          </div>
                                                                                        ) : (
                                                                                          <div className="sm:col-span-1">
                                                                                            <button
                                                                                              href="#"
                                                                                              className="ml-3 mt-7 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-skin-primary hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                                                                              onClick={() =>
                                                                                                deleteNewRow(
                                                                                                  index,
                                                                                                  attrIndex
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <MinusIcon
                                                                                                className="h-6 w-6 text-white"
                                                                                                aria-hidden="true"
                                                                                              />
                                                                                            </button>
                                                                                          </div>
                                                                                        )}
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </>
                                                                              );
                                                                            }
                                                                          )
                                                                          : ""}
                                                                        <div className="border-t border-gray-200 pt-4 pb-4 lg:flex lg:items-center lg:justify-between xl:mt-0">
                                                                          <div className="mt-2 sm:flex sm:max-w-md lg:mt-0">
                                                                            {/* <button
                                                                                type="button"
                                                                                className="sm:order-0 order-1 ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:ml-0"
                                                                              >
                                                                                Cancelar
                                                                              </button>
                                                                              <button
                                                                                type="button"
                                                                                className="order-0 inline-flex items-center rounded-md border border-transparent btn-link bg-skin-primary px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:order-1 sm:ml-3 whitespace-nowrap"
                                                                              >
                                                                                Guardar
                                                                              </button> */}
                                                                            {index ===
                                                                              0 ? (
                                                                              <button
                                                                                type="button"
                                                                                className="order-0 inline-flex items-center rounded-md border border-transparent btn-link bg-skin-primary px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:order-1 sm:ml-3 whitespace-nowrap"
                                                                                onClick={() =>
                                                                                  addEvaluationDiv()
                                                                                }
                                                                                style={{
                                                                                  marginLeft:
                                                                                    "287px",
                                                                                }}
                                                                              >
                                                                                Novo
                                                                                critério
                                                                                de
                                                                                avaliação
                                                                              </button>
                                                                            ) : (
                                                                              <button
                                                                                type="button"
                                                                                className="order-0 inline-flex items-center rounded-md border border-transparent btn-link bg-skin-primary px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:order-1 sm:ml-3 whitespace-nowrap"
                                                                                onClick={() =>
                                                                                  removeEvaluationDiv(
                                                                                    index
                                                                                  )
                                                                                }
                                                                              >
                                                                                Remover
                                                                                critérios
                                                                                de
                                                                                avaliação
                                                                              </button>
                                                                            )}
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </>
                                                                );
                                                              }
                                                            )
                                                            : ""}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Tab.Panel>
                                                )}
                                              </>
                                            );
                                          })}
                                      </Tab.Panels>
                                    </Tab.Group>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                onClick={() => {
                                  setTab(tab - 1);
                                  steps1.forEach((stepData) => {
                                    if (stepData?.id > 2) {
                                      stepData.status = "upcoming";
                                    } else {
                                      stepData.status = "current";
                                    }
                                  });
                                }}
                              >
                                Retroceder
                              </button>
                              {steps1.length - tab > 0 ? (
                                <div>
                                  <button
                                    type="button"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-skin-primary hover:bg-red-600 focus:outline-none xl-admin-btn"
                                  >
                                    Guardar e Sair
                                  </button>
                                  <button
                                    id="3"
                                    type="button"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-skin-primary hover:bg-red-600 focus:outline-none xl-admin-btn"
                                    onClick={() => updateEvaluationData()}
                                  >
                                    Próximo
                                  </button>
                                </div>
                              ) : (
                                <button
                                  type="button"
                                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                  onClick={() => updateEvaluationData()}
                                >
                                  Submeter
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {tab === 4 ? (
                        <div className="w-full">
                          <div className="pt-1">
                            <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
                              {/* multipale add form 4th step */}
                              {/* formState?.procedureType === "1" && */}
                              {competitorDiv?.length > 0
                                ? competitorDiv.map(
                                  (singlecompetitor, index) => {
                                    return (
                                      <>
                                        <div className="rounded-lg border border-gray-200 bg-gray-50 p-6">
                                          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                            <div className="sm:col-span-3">
                                              <label
                                                htmlFor="first_name"
                                                className="block text-base font-medium text-gray-700"
                                              >
                                                Empresa
                                              </label>
                                              <div className="mt-1">
                                                <input
                                                  type="text"
                                                  name="first-name"
                                                  id="first-name"
                                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base"
                                                  defaultValue={
                                                    singlecompetitor?.company
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeCompetitor(
                                                      e,
                                                      "company",
                                                      index
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>

                                            <div className="sm:col-span-3">
                                              <label
                                                htmlFor="first_name"
                                                className="block text-base font-medium text-gray-700"
                                              >
                                                NIF
                                              </label>
                                              <div className="mt-1">
                                                <input
                                                  type="text"
                                                  name="first-name"
                                                  id="first-name"
                                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base"
                                                  onChange={(e) =>
                                                    handleChangeCompetitor(
                                                      e,
                                                      "tax_number",
                                                      index
                                                    )
                                                  }
                                                  defaultValue={
                                                    singlecompetitor?.tax_number
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="sm:col-span-3">
                                              <label
                                                htmlFor="last_name"
                                                className="block text-base font-medium text-gray-700"
                                              >
                                                Tipo de empresa
                                              </label>
                                              <div className="mt-1">
                                                <select
                                                  id="country"
                                                  name="country"
                                                  className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base"
                                                  onChange={(e) =>
                                                    handleChangeCompetitor(
                                                      e,
                                                      "company_type_id",
                                                      index
                                                    )
                                                  }
                                                  defaultValue={
                                                    singlecompetitor?.company_type_id
                                                  }
                                                >
                                                  <option>
                                                    {" "}
                                                    -- Selecionar Tipo de
                                                    empresa --{" "}
                                                  </option>
                                                  {supplerList.length > 0
                                                    ? supplerList.map(
                                                      (element) => {
                                                        return (
                                                          <option
                                                            value={
                                                              element.id
                                                            }
                                                          >
                                                            {element.name}
                                                          </option>
                                                        );
                                                      }
                                                    )
                                                    : ""}
                                                </select>
                                              </div>
                                            </div>

                                            <div className="sm:col-span-3">
                                              <label
                                                htmlFor="first_name"
                                                className="block text-base font-medium text-gray-700"
                                              >
                                                E-mail
                                              </label>
                                              <div className="mt-1">
                                                <input
                                                  type="text"
                                                  name="first-name"
                                                  id="first-name"
                                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base"
                                                  onChange={(e) =>
                                                    handleChangeCompetitor(
                                                      e,
                                                      "email",
                                                      index
                                                    )
                                                  }
                                                  defaultValue={
                                                    singlecompetitor?.email
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="sm:col-span-3">
                                              <label
                                                htmlFor="first_name"
                                                className="block text-base font-medium text-gray-700"
                                              >
                                                Endereço
                                              </label>
                                              <div className="mt-1">
                                                <input
                                                  type="text"
                                                  name="first-name"
                                                  id="first-name"
                                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base"
                                                  onChange={(e) =>
                                                    handleChangeCompetitor(
                                                      e,
                                                      "address",
                                                      index
                                                    )
                                                  }
                                                  defaultValue={
                                                    singlecompetitor?.address
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="sm:col-span-3">
                                              <label
                                                htmlFor="first_name"
                                                className="block text-base font-medium text-gray-700"
                                              >
                                                Telemóvel
                                              </label>
                                              <div className="mt-1">
                                                <input
                                                  type="number"
                                                  name="first-name"
                                                  id="first-name"
                                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-base"
                                                  onChange={(e) =>
                                                    handleChangeCompetitor(
                                                      e,
                                                      "phone_number",
                                                      index
                                                    )
                                                  }
                                                  defaultValue={
                                                    singlecompetitor?.phone_number
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="pt-6 flex items-center xl:mt-0 justify-end">
                                            <div className="mt-2 sm:flex sm:max-w-md lg:mt-0">
                                              {index === 0 ? (
                                                <button
                                                  type="button"
                                                  className="order-0 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:order-1 sm:ml-3 whitespace-nowrap"
                                                  onClick={() =>
                                                    addCompetitorDiv()
                                                  }
                                                >
                                                  <PlusIcon
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                  />
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="sm:order-0 order-1 ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-0"
                                                  onClick={() =>
                                                    removeCompetitorDiv(index)
                                                  }
                                                >
                                                  <TrashIcon
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                  />
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                )
                                : ""}
                            </div>
                          </div>
                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                onClick={() => {
                                  setTab(tab - 1);
                                  steps1.forEach((stepData) => {
                                    if (stepData?.id > 2) {
                                      stepData.status = "upcoming";
                                    } else {
                                      stepData.status = "current";
                                    }
                                  });
                                }}
                              >
                                Retroceder
                              </button>
                              <button
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                onClick={() => updateCompetitorData()}
                              >
                                Submeter
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* <div className="flex justify-end">
                    <button
                      type="button"
                      className="btn-link py-2 px-4 border border-gray-300 rounded-md shadow-sm text-[16px] font-medium shadow-sm bg-skin-white text-skin-black rounded-md"
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className="btn-link ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-[16px] font-medium shadow-sm bg-skin-primary text-skin-white rounded-md"
                    >
                      Submeter
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900 mb-5"
                    >
                      Editar CI support
                    </Dialog.Title>
                    <div className="py-5">
                      <div className="space-y-8">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-6">
                            <label
                              htmlFor="street-address"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Documento
                            </label>
                            <div className="mt-1">
                              <select
                                id="country"
                                name="country"
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                value={
                                  selectedDocInfo?.internal_communication_id
                                }
                                onChange={(e) => {
                                  setSelectedDocInfo({
                                    ...selectedDocInfo,
                                    internal_communication_id: e?.target?.value,
                                  });
                                }}
                              >
                                {ciList?.map((list) => {
                                  return (
                                    <option key={list?.id} value={list?.id}>
                                      {list?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                              <div className="space-y-1 text-center">
                                {contract_document.preview ? (
                                  <img
                                    src={"/img/done.svg"}
                                    alt="Profile"
                                    className="mx-auto h-12 w-12 text-gray-400"
                                  />
                                ) : (
                                  <img
                                    src={"/img/pdf.png"}
                                    alt="Profile"
                                    className="mx-auto h-12 w-12 text-gray-400"
                                  />
                                )}
                                <div className="flex text-sm text-gray-600">
                                  <label
                                    htmlFor="file-upload2"
                                    className="relative cursor-pointer rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
                                    value={selectedDocInfo?.file}
                                    onChange={(e) => {
                                      setSelectedDocInfo({
                                        ...selectedDocInfo,
                                        file: e?.target?.files?.[0],
                                      });
                                      setEditDocFrmSrc(
                                        URL.createObjectURL(
                                          e?.target?.files?.[0]
                                        )
                                      );
                                    }}
                                  >
                                    <span>Seleccione o ficheiro</span>
                                    <input
                                      accept="application/pdf"
                                      id="file-upload2"
                                      type="file"
                                      className="sr-only"
                                    />
                                  </label>
                                </div>
                                <p className="text-xs text-gray-500">
                                  PDF até 10MB
                                </p>
                              </div>
                            </div>
                            {editDocFrmSrc !== "" && (
                              <iframe
                                style={{ height: "45vh" }}
                                src={editDocFrmSrc}
                                title="document"
                                className="w-4/6 mx-auto mt-5"
                              />
                            )}
                          </div>
                        </div>
                        <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              type="button"
                              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                              onClick={() => {
                                setOpen(false);
                              }}
                            >
                              Cancelar
                            </button>
                            <button
                              type="submit"
                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                              onClick={() => {
                                handleDocumentUpdate();
                              }}
                            >
                              Submeter
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={open1} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen1}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpen1(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900 mb-5"
                    >
                      Detalhes do CI support
                    </Dialog.Title>
                    <div className="py-5">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Documento
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {
                              ciList?.filter(
                                (data) =>
                                  data.id ===
                                  selectedDocInfo?.internal_communication_id
                              )?.[0]?.name
                            }
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Ficheiro
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {selectedDocInfo?.fileName}
                          </dd>
                        </div>
                      </dl>
                      {editDocFrmSrc !== "" && (
                        <iframe
                          style={{ height: "45vh" }}
                          src={editDocFrmSrc}
                          title="document"
                          className="w-4/6 mx-auto mt-5"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
