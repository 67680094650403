import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Spinner from "./components/Spinner";
import GuestRoute from "./layout/GuestRoute";
import TemplateRoute from "./layout/TemplateRoute";
import SignIn from "./pages/signin/Signin";
import Setting from "./pages/setting/setting";
import Country from "./pages/master/country/View";
import AddCountry from "./pages/master/country/Add";
import EditCountry from "./pages/master/country/Edit";
import SupplierType from "./pages/master/supplier-type/View";
import AddSupplierType from "./pages/master/supplier-type/Add";
import EditSupplierType from "./pages/master/supplier-type/Edit";
import Bank from "./pages/master/bank/View";
import AddBank from "./pages/master/bank/Add";
import EditBank from "./pages/master/bank/Edit";

import Recipient from "./pages/master/recipient/View";
import AddRecipient from "./pages/master/recipient/Add";
import EditRecipient from "./pages/master/recipient/Edit";

import ContractDocuments from "./pages/master/contractDocuments/View";
import AddContractDocuments from "./pages/master/contractDocuments/Add";
import EditContractDocuments from "./pages/master/contractDocuments/Edit";

import PaymentMode from "./pages/master/paymentMode/View";
import AddPaymentMode from "./pages/master/paymentMode/Add";
import EditPaymentMode from "./pages/master/paymentMode/Edit";

import ProcedureType from "./pages/master/procedure_type/View";
import AddProcedureType from "./pages/master/procedure_type/Add";
import EditProcedureType from "./pages/master/procedure_type/Edit";

import ServiceCategory from "./pages/master/service_category/View";
import AddServiceCategory from "./pages/master/service_category/Add";
import EditServiceCategory from "./pages/master/service_category/Edit";

import Department from "./pages/master/department/View";
import AddDepartment from "./pages/master/department/Add";
import EditDepartment from "./pages/master/department/Edit";

import Role from "./pages/master/role/View";
import AddRole from "./pages/master/role/Add";
import EditRole from "./pages/master/role/Edit";

import QualificationCriteria from "./pages/master/qualification_criteria/View";
import AddQualificationCriteria from "./pages/master/qualification_criteria/Add";
import EditQualificationCriteria from "./pages/master/qualification_criteria/Edit";

import BudgetMap from "./pages/master/budget_map/View";
import AddBudgetMap from "./pages/master/budget_map/Add";
import EditBudgetMap from "./pages/master/budget_map/Edit";

import InternalCommunication from "./pages/master/internal_communication/View";
import AddInternalCommunication from "./pages/master/internal_communication/Add";
import EditInternalCommunication from "./pages/master/internal_communication/Edit";

import Employee from "./pages/master/employee/View";
import AddEmployee from "./pages/master/employee/Add";
import EditEmployee from "./pages/master/employee/Edit";

import NewEvaluationCommittee from "./pages/evaluation-committee/NewEvaluationCommittee";
import SeeEvaluationCommittee from "./pages/evaluation-committee/SeeEvaluationCommittee";
import EditEvaluationCommittee from "./pages/evaluation-committee/EditEvaluationCommittee";

import NewEvaluationCommitteeRole from "./pages/master/evaluationCommiteeRole/Add";
import SeeEvaluationCommitteeRole from "./pages/master/evaluationCommiteeRole/View";
import EditEvaluationCommitteeRole from "./pages/master/evaluationCommiteeRole/Edit";

import BudgetYear from "./pages/master/budgetYear/View";
import AddBudgetYear from "./pages/master/budgetYear/Add";
import EditBudgetYear from "./pages/master/budgetYear/Edit";

import BudgetExchange from "./pages/master/budgetExchange/View";
import AddBudgetExchange from "./pages/master/budgetExchange/Add";
import EditBudgetExchange from "./pages/master/budgetExchange/Edit";

import Currency from "./pages/master/currency/View";
import AddCurrency from "./pages/master/currency/Add";
import EditCurrency from "./pages/master/currency/Edit";

import ObjectTender from "./pages/master/object_tender/View";
import AddObjectTender from "./pages/master/object_tender/Add";
import EditObjectTender from "./pages/master/object_tender/Edit";

import NewContest from "./pages/contest/NewContest";
import ViewContests from "./pages/contest/ViewContests";
import EditContest from "./pages/contest/EditContest";
import NewRequest from "./pages/requests/NewRequest";
import RequestsReceived from "./pages/requests/RequestsReceived";
import RequestEdit from "./pages/requests/RequestEdit";
import DocumentsConstitute from "./pages/requests/DocumentsConstitute";
import RequestsPCA from "./pages/requests/RequestsPCA";
import RequestsPCAEdit from "./pages/requests/RequestsPCAEdit";
import RequestsTPE from "./pages/requests/RequestsTPE";
import NewProvider from "./pages/provider/NewProvider";
import ViewProvider from "./pages/provider/ViewProvider";
import EditProvider from "./pages/provider/EditProvider";

import EvaluationCriteria from "./pages/master/evaluationCriteria/View";
import AddEvaluationCriteria from "./pages/master/evaluationCriteria/Add";
import EditEvaluationCriteria from "./pages/master/evaluationCriteria/Edit";

import Attributes from "./pages/master/attributes/View";
import AddAttributes from "./pages/master/attributes/Add";
import EditAttributes from "./pages/master/attributes/Edit";

import ViewCompetitors from "./pages/competitors/ViewCompetitors";
import EditCompetitors from "./pages/competitors/EditCompetitors";
import AddCompetitors from "./pages/competitors/AddCompetitors";

import ConcurringRequirements from "./pages/master/concurringRequirements/View";
import AddConcurringRequirements from "./pages/master/concurringRequirements/Add";
import EditConcurringRequirements from "./pages/master/concurringRequirements/Edit";

import NewContract from "./pages/new-contract/NewContract";
import EditContract from "./pages/new-contract/EditContrax";
import Activities from "./pages/activities/activityview";
import Contracts from "./pages/contracts/contracts_view";
import ExecutionContract from "./pages/contracts/execution-contract";
import ExecutionContractAdd from "./pages/contracts/execution-contract-add";
import ExecutionContractEdit from "./pages/contracts/execution-contract-edit";

import ViewUsers from "./pages/master/users/View";
import AddUsers from "./pages/master/users/Add";
import EditUsers from "./pages/master/users/Edit";

import ViewQualificationDocument from "./pages/master/qualificationDocument/View";
import AddQualificationDocument from "./pages/master/qualificationDocument/Add";
import EditQualificationDocument from "./pages/master/qualificationDocument/Edit";

import Profile from "./pages/profile/profile";
import ProcedureEvalution from "./pages/procedureEvalution/add";

import ViewCategoryAdministrator from "./pages/master/categoryAdministrator/View";
import AddCategoryAdministrator from "./pages/master/categoryAdministrator/Add";
import EditCategoryAdministrator from "./pages/master/categoryAdministrator/Edit";

import ViewAdministrator from "./pages/master/administrator/View.js";
import AddAdministrator from "./pages/master/administrator/Add.js";
import EditAdministrator from "./pages/master/administrator/Edit.js";

import ViewAccessRights from "./pages/master/accessRights/View.js";
import AddAccessRights from "./pages/master/accessRights/Add.js";
import EditAccessRights from "./pages/master/accessRights/Edit.js";

function App() {
  return (
    <main className="">
      <div className="bg-skin-gray h-screen">
        <Router>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <GuestRoute exact path="/" component={SignIn} />
              <GuestRoute exact path="/setting" component={Setting} />
              <TemplateRoute exact path="/master/country" component={Country} />
              <TemplateRoute
                exact
                path="/master/country/add"
                component={AddCountry}
              />
              <TemplateRoute
                exact
                path="/master/country/edit/:id"
                component={EditCountry}
              />
              <TemplateRoute
                exact
                path="/master/supplier-type"
                component={SupplierType}
              />
              <TemplateRoute
                exact
                path="/master/supplier-type/add"
                component={AddSupplierType}
              />
              <TemplateRoute
                exact
                path="/master/supplier-type/edit/:id"
                component={EditSupplierType}
              />
              <TemplateRoute exact path="/master/bank" component={Bank} />
              <TemplateRoute
                exact
                path="/master/bank/add"
                component={AddBank}
              />
              <TemplateRoute
                exact
                path="/master/bank/edit/:id"
                component={EditBank}
              />

              <TemplateRoute
                exact
                path="/master/recipient"
                component={Recipient}
              />
              <TemplateRoute
                exact
                path="/master/recipient/add"
                component={AddRecipient}
              />
              <TemplateRoute
                exact
                path="/master/recipient/edit/:id"
                component={EditRecipient}
              />

              <TemplateRoute
                exact
                path="/master/contractDocuments"
                component={ContractDocuments}
              />
              <TemplateRoute
                exact
                path="/master/contractDocuments/add"
                component={AddContractDocuments}
              />
              <TemplateRoute
                exact
                path="/master/contractDocuments/edit/:id"
                component={EditContractDocuments}
              />

              <TemplateRoute
                exact
                path="/master/paymentMode"
                component={PaymentMode}
              />
              <TemplateRoute
                exact
                path="/master/paymentMode/add"
                component={AddPaymentMode}
              />
              <TemplateRoute
                exact
                path="/master/paymentMode/edit/:id"
                component={EditPaymentMode}
              />

              <TemplateRoute
                exact
                path="/master/service_category"
                component={ServiceCategory}
              />
              <TemplateRoute
                exact
                path="/master/service_category/add"
                component={AddServiceCategory}
              />
              <TemplateRoute
                exact
                path="/master/service_category/edit/:id"
                component={EditServiceCategory}
              />

              <TemplateRoute
                exact
                path="/master/department"
                component={Department}
              />
              <TemplateRoute
                exact
                path="/master/department/add"
                component={AddDepartment}
              />
              <TemplateRoute
                exact
                path="/master/department/edit/:id"
                component={EditDepartment}
              />

              <TemplateRoute
                exact
                path="/master/procedure_type"
                component={ProcedureType}
              />
              <TemplateRoute
                exact
                path="/master/procedure_type/add"
                component={AddProcedureType}
              />
              <TemplateRoute
                exact
                path="/master/procedure_type/edit/:id"
                component={EditProcedureType}
              />

              <TemplateRoute exact path="/master/role" component={Role} />
              <TemplateRoute
                exact
                path="/master/role/add"
                component={AddRole}
              />
              <TemplateRoute
                exact
                path="/master/role/edit/:id"
                component={EditRole}
              />

              <TemplateRoute
                exact
                path="/master/qualification_criteria"
                component={QualificationCriteria}
              />
              <TemplateRoute
                exact
                path="/master/qualification_criteria/add"
                component={AddQualificationCriteria}
              />
              <TemplateRoute
                exact
                path="/master/qualification_criteria/edit/:id"
                component={EditQualificationCriteria}
              />

              <TemplateRoute
                exact
                path="/master/budget_map"
                component={BudgetMap}
              />
              <TemplateRoute
                exact
                path="/master/budget_map/add"
                component={AddBudgetMap}
              />
              <TemplateRoute
                exact
                path="/master/budget_map/edit/:id"
                component={EditBudgetMap}
              />

              <TemplateRoute
                exact
                path="/master/internal_communication"
                component={InternalCommunication}
              />
              <TemplateRoute
                exact
                path="/master/internal_communication/add"
                component={AddInternalCommunication}
              />
              <TemplateRoute
                exact
                path="/master/internal_communication/edit/:id"
                component={EditInternalCommunication}
              />

              <TemplateRoute
                exact
                path="/master/employee"
                component={Employee}
              />
              <TemplateRoute
                exact
                path="/master/employee/add"
                component={AddEmployee}
              />
              <TemplateRoute
                exact
                path="/master/employee/edit/:id"
                component={EditEmployee}
              />

              <TemplateRoute
                exact
                path="/evaluation-committee/NewEvaluationCommittee"
                component={NewEvaluationCommittee}
              />
              <TemplateRoute
                exact
                path="/evaluation-committee/SeeEvaluationCommittee"
                component={SeeEvaluationCommittee}
              />
              <TemplateRoute
                exact
                path="/evaluation-committee/EditEvaluationCommittee/:id"
                component={EditEvaluationCommittee}
              />
              <TemplateRoute
                exact
                path="/master/evaluation-committee-role/add"
                component={NewEvaluationCommitteeRole}
              />
              <TemplateRoute
                exact
                path="/master/evaluation-committee-role/view"
                component={SeeEvaluationCommitteeRole}
              />
              <TemplateRoute
                exact
                path="/master/evaluation-committee-role/edit/:id"
                component={EditEvaluationCommitteeRole}
              />

              <TemplateRoute
                exact
                path="/master/budget-year"
                component={BudgetYear}
              />
              <TemplateRoute
                exact
                path="/master/budget-year/add"
                component={AddBudgetYear}
              />
              <TemplateRoute
                exact
                path="/master/budget-year/edit/:id"
                component={EditBudgetYear}
              />

              <TemplateRoute
                exact
                path="/master/budgetExchange"
                component={BudgetExchange}
              />
              <TemplateRoute
                exact
                path="/master/budgetExchange/add"
                component={AddBudgetExchange}
              />
              <TemplateRoute
                exact
                path="/master/budgetExchange/edit/:id"
                component={EditBudgetExchange}
              />

              <TemplateRoute
                exact
                path="/master/currency"
                component={Currency}
              />
              <TemplateRoute
                exact
                path="/master/currency/add"
                component={AddCurrency}
              />
              <TemplateRoute
                exact
                path="/master/currency/edit/:id"
                component={EditCurrency}
              />

              <TemplateRoute
                exact
                path="/master/object_tender"
                component={ObjectTender}
              />
              <TemplateRoute
                exact
                path="/master/object_tender/add"
                component={AddObjectTender}
              />
              <TemplateRoute
                exact
                path="/master/object_tender/edit/:id"
                component={EditObjectTender}
              />

              <TemplateRoute
                exact
                path="/contest/NewContest"
                component={NewContest}
              />
              <TemplateRoute
                exact
                path="/contest/ViewContests"
                component={ViewContests}
              />
              <TemplateRoute
                exact
                path="/contest/EditContest/:id"
                component={EditContest}
              />
              <TemplateRoute
                exact
                path="/requests/NewRequest"
                component={NewRequest}
              />
              <TemplateRoute
                exact
                path="/requests/RequestsReceived"
                component={RequestsReceived}
              />
              <TemplateRoute
                exact
                path="/requests/RequestEdit/:tripID"
                component={RequestEdit}
              />
              <TemplateRoute
                exact
                path="/requests/DocumentsConstitute"
                component={DocumentsConstitute}
              />
              <TemplateRoute
                exact
                path="/requests/RequestsPCA"
                component={RequestsPCA}
              />
              <TemplateRoute
                exact
                path="/requests/RequestsPCAEdit"
                component={RequestsPCAEdit}
              />
              <TemplateRoute
                exact
                path="/requests/RequestsTPE"
                component={RequestsTPE}
              />
              <TemplateRoute
                exact
                path="/provider/NewProvider"
                component={NewProvider}
              />
              <TemplateRoute
                exact
                path="/provider/ViewProvider"
                component={ViewProvider}
              />
              <TemplateRoute
                exact
                path="/provider/EditProvider/:id"
                component={EditProvider}
              />

              <TemplateRoute
                exact
                path="/master/evaluation_criteria"
                component={EvaluationCriteria}
              />
              <TemplateRoute
                exact
                path="/master/evaluation_criteria/add"
                component={AddEvaluationCriteria}
              />
              <TemplateRoute
                exact
                path="/master/evaluation_criteria/edit/:id"
                component={EditEvaluationCriteria}
              />

              <TemplateRoute
                exact
                path="/master/attributes"
                component={Attributes}
              />
              <TemplateRoute
                exact
                path="/master/attributes/add"
                component={AddAttributes}
              />
              <TemplateRoute
                exact
                path="/master/attributes/edit/:id"
                component={EditAttributes}
              />

              <TemplateRoute
                exact
                path="/competitors/ViewCompetitors"
                component={ViewCompetitors}
              />
              <TemplateRoute
                exact
                path="/competitors/AddCompetitors"
                component={AddCompetitors}
              />
              <TemplateRoute
                exact
                path="/competitors/EditCompetitors/:id"
                component={EditCompetitors}
              />

              <TemplateRoute
                exact
                path="/master/concurringRequirements"
                component={ConcurringRequirements}
              />
              <TemplateRoute
                exact
                path="/master/concurringRequirements/add"
                component={AddConcurringRequirements}
              />
              <TemplateRoute
                exact
                path="/master/concurringRequirements/edit/:id"
                component={EditConcurringRequirements}
              />

              <TemplateRoute
                exact
                path="/new-contract/NewContract"
                component={NewContract}
              />
              <TemplateRoute
                exact
                path="/new-contract/edit/:tripID"
                component={EditContract}
              />
              <TemplateRoute
                exact
                path="/activities/activityview"
                component={Activities}
              />
              <TemplateRoute
                exact
                path="/contracts/contracts_view"
                component={Contracts}
              />
              <TemplateRoute
                exact
                path="/contracts/execution-contract/:id"
                component={ExecutionContract}
              />
              <TemplateRoute
                exact
                path="/contracts/execution-contract-add"
                component={ExecutionContractAdd}
              />
              <TemplateRoute
                exact
                path="/contracts/execution-contract-edit"
                component={ExecutionContractEdit}
              />

              <TemplateRoute
                exact
                path="/users/ViewUsers"
                component={ViewUsers}
              />
              <TemplateRoute
                exact
                path="/users/AddUsers"
                component={AddUsers}
              />
              <TemplateRoute
                exact
                path="/users/EditUsers/:id"
                component={EditUsers}
              />

              <TemplateRoute
                exact
                path="/master/qualification-document/view"
                component={ViewQualificationDocument}
              />
              <TemplateRoute
                exact
                path="/master/qualification-document/add"
                component={AddQualificationDocument}
              />
              <TemplateRoute
                exact
                path="/master/qualification-document/edit/:id"
                component={EditQualificationDocument}
              />

              <TemplateRoute
                exact
                path="/profile/profile"
                component={Profile}
              />

              <TemplateRoute
                exact
                path="/procedure_to_evaluation/add"
                component={ProcedureEvalution}
              />

              <TemplateRoute
                exact
                path="/category-administrator"
                component={ViewCategoryAdministrator}
              />
              <TemplateRoute
                exact
                path="/category-administrator/add"
                component={AddCategoryAdministrator}
              />
              <TemplateRoute
                exact
                path="/category-administrator/edit/:id"
                component={EditCategoryAdministrator}
              />

              <TemplateRoute
                exact
                path="/administrator"
                component={ViewAdministrator}
              />
              <TemplateRoute
                exact
                path="/administrator/add"
                component={AddAdministrator}
              />
              <TemplateRoute
                exact
                path="/administrator/edit/:id"
                component={EditAdministrator}
              />

              <TemplateRoute
                exact
                path="/accessrights"
                component={ViewAccessRights}
              />
              <TemplateRoute
                exact
                path="/accessrights/add"
                component={AddAccessRights}
              />
              <TemplateRoute
                exact
                path="/accessrights/edit/:id"
                component={EditAccessRights}
              />

            </Switch>
          </Suspense>
        </Router>
      </div>
    </main>
  );
}
export default App;