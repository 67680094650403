import {
  XMarkIcon,
  CheckIcon,
  TrashIcon,
  ArchiveBoxIcon,
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
} from "@heroicons/react/24/outline";
import { PlusIcon as PlusIconSolid } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";
import React from "react";

// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Buttons
function ActionButton({
  btnType,
  key = parseInt(Math.random(100) * 9999),
  btnPath,
  btnTitle,
  handleAction,
  csvData,
  csvTitle,
}) {
  let btnTypeStr = "";
  switch (btnType) {
    case "addNew":
      btnTypeStr = (
        <PlusIconSolid
          className="-ml-0.5 mr-2 h-5 w-5 red-text"
          aria-hidden="true"
        />
      );
      break;
    case "makeActive":
      btnTypeStr = (
        <CheckIcon
          className="-ml-0.5 mr-2 h-5 w-5 red-text"
          aria-hidden="true"
        />
      );
      break;
    case "makeInactive":
      btnTypeStr = (
        <XMarkIcon
          className="-ml-0.5 mr-2 h-5 w-5 red-text"
          aria-hidden="true"
        />
      );
      break;
    case "makeApproved":
      btnTypeStr = (
        <CheckIcon
          className="-ml-0.5 mr-2 h-5 w-5 red-text"
          aria-hidden="true"
        />
      );
      break;
    case "makeUnapproved":
      btnTypeStr = (
        <XMarkIcon
          className="-ml-0.5 mr-2 h-5 w-5 red-text"
          aria-hidden="true"
        />
      );
      break;
    case "archive":
      btnTypeStr = (
        <ArchiveBoxIcon
          className="-ml-0.5 mr-2 h-5 w-5 red-text"
          aria-hidden="true"
        />
      );
      break;
    case "trashed":
      btnTypeStr = (
        <ArchiveBoxIcon
          className="-ml-0.5 mr-2 h-5 w-5 red-text"
          aria-hidden="true"
        />
      );
      break;
    case "delete":
      btnTypeStr = (
        <TrashIcon
          className="-ml-0.5 mr-2 h-5 w-5 red-text"
          aria-hidden="true"
        />
      );
      break;
    case "exportCSV":
      btnTypeStr = (
        <ArrowDownTrayIcon
          className="-ml-0.5 mr-2 h-5 w-5 red-text"
          aria-hidden="true"
        />
      );
      break;
    case "importCSV":
      btnTypeStr = (
        <ArrowUpTrayIcon
          className="-ml-0.5 mr-2 h-5 w-5 red-text"
          aria-hidden="true"
        />
      );
      break;
    default:
      btnTypeStr = "";
  }

  return (
    <Menu.Item key={key ? key : parseInt(Math.random(100) * 9999)}>
      {({ active }) =>
        btnType === "addNew" ? (
          <Link
            to={btnPath}
            className={classNames(
              active ? "bg-gray-100 text-gray-900" : "text-gray-700",
              "block px-4 py-2 text-base flex"
            )}
          >
            {btnTypeStr} {btnTitle}
          </Link>
        ) : btnType === "exportCSV" ? (
          <span
            className={classNames(
              active ? "bg-gray-100 text-gray-900" : "text-gray-700",
              "block px-4 py-2 text-base flex cursor-pointer"
            )}
          >
            {/* <CSVLink style={{display: 'flex'}} data={csvData}  filename={(csvTitle)?`${csvTitle}${moment().format('MM-DD-YYYY, h-mm-ss a')}.csv`:"Language_Lable_"+moment().format('MM-DD-YYYY, h-mm-ss a')+".csv"}>{btnTypeStr} {btnTitle}</CSVLink> */}
          </span>
        ) : (
          <span
            // to={btnPath}
            onClick={() => handleAction(btnType)}
            className={classNames(
              active ? "bg-gray-100 text-gray-900" : "text-gray-700",
              "block px-4 py-2 text-base flex cursor-pointer"
            )}
          >
            {btnTypeStr} {btnTitle}
          </span>
        )
      }
    </Menu.Item>
  );
}

export default ActionButton;
