import { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import LeftNavigation from "../../../components/LeftNavigation/leftNavigation.js";
import Header from "../../../components/Header/Header";
import BtnRedirect from "../../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import { ClockIcon, CogIcon, CreditCardIcon, DocumentChartBarIcon, HomeIcon, QuestionMarkCircleIcon, ScaleIcon, ShieldCheckIcon, UserGroupIcon, XMarkIcon } from "@heroicons/react/24/outline";
import config from "../../../utils/config.js";
import { validateForm } from "../../../utils/common.js";
import { toast } from "react-toastify";
import Select from "react-select";
import { find } from "underscore";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];

const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Roles({ history }) {
  const [data, setData] = useState([]);
  const [error, setError] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [info, setInfo] = useState({});
  const [selectedDept, setSelectedDept] = useState(null);
  const { id } = useParams();
  const [removeImage, setRemoveImage] = useState("");

  const getDetails = async () => {
    try {
      const detailInfo = await config.getAPI({
        url: `employee/getdatabyId`,
        params: { id: id },
      });
      if (detailInfo?.payload) {
        setInfo({ ...detailInfo?.payload, cpassword: detailInfo?.payload?.password });
        setRemoveImage(detailInfo?.payload?.profile_picture);

        config.getAPI({ url: "department/list", params: { isPaginate: true } })
          .then((data) => {
            if (data.payload) {
              data.payload.rows.map((element) => {
                element.value = element.id;
                element.label = element.name;
              });
              setData(data.payload.rows);
              const existData = find(data.payload.rows, function (singlePT) {
                return singlePT.id === detailInfo?.payload.department_id;
              });
              setSelectedDept({ value: existData.id, label: existData.name });
            }
          })
          .catch((error) => { });
      }
      if (detailInfo?.error?.message) {
        history.push("/");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getDetails();
  }, [id]);

  const handleChange = (e, name) => {
    if (name === "department_id") {
      const setData = { ...info, [name]: e?.value };
      setInfo(setData);
      setSelectedDept(e);
    } else if (name === "profile_picture") {
      const setData = { ...info, [name]: e?.target?.files?.[0] };
      setInfo(setData);
    } else {
      const setData = { ...info, [name]: e?.target?.value };
      setInfo(setData);
    }
  };

  const handleSubmit = async () => {
    try {
      const includedFields = [
        "full_name",
        "sap_number",
        "email",
        "status",
        "mobile",
        "department_id",
        "password",
        "cpassword"
      ];
      const validationResponse = validateForm(info, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        let formData = new FormData();
        formData.append("id", info.id);
        formData.append("sap_number", info.sap_number);
        formData.append("full_name", info.full_name);
        formData.append("email", info.email);
        formData.append("password", info.password);
        formData.append("mobile", info.mobile);
        formData.append("department_id", info.department_id);
        formData.append("status", info.status);
        if (removeImage !== info?.profile_picture) {
          formData.append("remove_image", removeImage);
          formData.append("file", info.profile_picture);
        }
        const insertResponse = await config.postFormDataAPI({
          url: "employee/update",
          params: formData
        });
        if (insertResponse?.response?.data?.error?.message) {
          return toast.error(insertResponse?.response?.data?.error?.message);
        }
        if (
          insertResponse?.payload?.errors ||
          insertResponse?.payload?.name === "SequelizeUniqueConstraintError"
        ) {
          return toast.error("Este registo já existe");
        }
        setTimeout(() => {
          return toast.success("Registo criado com sucesso");
        }, 200);
        history.push("/master/employee");
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  const handleProfileRemove = async () => {
    if (info?.profile_picture) {
      setInfo({ ...info, profile_picture: "" });
    }
  };

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1 pb-8">
            <div className="mt-8">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="py-5 flex items-center justify-between">
                  <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
                    Novo Colaborador
                  </h1>
                </div>
                <form className="space-y-6" action="#" method="POST">
                  <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                    <div className="md:grid md:gap-6">
                      <div className="mt-5 md:mt-0">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="sap_number"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Número SAP <span className="mandatory">*</span>
                            </label>
                            <input
                              type="text"
                              name="sap_number"
                              id="sap_number"
                              onChange={(e) => {
                                handleChange(e, "sap_number");
                              }}
                              value={info?.sap_number}
                              className={` ${error?.sap_number && "border-red-600"
                                } mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                            />
                            {error?.sap_number && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.sap_number}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="sap_number"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Nome completo <span className="mandatory">*</span>
                            </label>
                            <input
                              type="text"
                              name="full_name"
                              id="full_name"
                              onChange={(e) => {
                                handleChange(e, "full_name");
                              }}
                              value={info?.full_name}
                              className={` ${error?.full_name && "border-red-600"
                                } mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                            />
                            {error?.full_name && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.full_name}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium text-gray-700"
                            >
                              E-mail corporativo{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <input
                              type="text"
                              name="email"
                              id="email"
                              onChange={(e) => {
                                handleChange(e, "email");
                              }}
                              value={info?.email}
                              className={` ${error?.email && "border-red-600"
                                } mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                            />
                            {error?.email && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.email}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="mobile"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Telemóvel <span className="mandatory">*</span>
                            </label>
                            <input
                              type="text"
                              name="mobile"
                              id="mobile"
                              onChange={(e) => {
                                handleChange(e, "mobile");
                              }}
                              value={info?.mobile}
                              className={`${error?.mobile && "border-red-600"
                                } mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                            />
                            {error?.mobile && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.mobile}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Direcção <span className="mandatory">*</span>
                            </label>

                            <Select
                              value={selectedDept}
                              placeholder="Seleccione a Direcção"
                              onChange={(e) => {
                                handleChange(e, "department_id");
                              }}
                              options={data}
                              isSearchable={true}
                            />
                            {error?.department_id && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.department_id}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="status"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Estado <span className="mandatory">*</span>
                            </label>
                            <select
                              id="status"
                              name="status"
                              autoComplete="country-name"
                              onChange={(e) => {
                                handleChange(e, "status");
                              }}
                              value={info?.status}
                              className={` ${error?.status && "border-red-600"
                                } mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                            >
                              <option>---Selecione o estado---</option>
                              <option value="Active">Activo</option>
                              <option value="Inactive">Inactivo</option>
                            </select>
                            {error?.status && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.status}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="password"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Senha <span className="mandatory">*</span>
                            </label>
                            <input
                              type="password"
                              name="password"
                              id="password"
                              autoComplete="password"
                              onChange={(e) => {
                                handleChange(e, "password");
                              }}
                              value={info?.password}
                              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${error?.password && "border-red-600"
                                }`}
                            />
                            {error?.password && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.password}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="cpassword"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Confirme sua senha{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <input
                              type="password"
                              name="cpassword"
                              id="cpassword"
                              autoComplete="cpassword"
                              onChange={(e) => {
                                handleChange(e, "cpassword");
                              }}
                              value={info?.cpassword}
                              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${error?.cpassword && "border-red-600"
                                }`}
                            />
                            {error?.cpassword && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.cpassword}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Foto de Perfil
                            </label>
                            <div className="mt-1 flex items-center">
                              {removeImage && info?.profile_picture && info?.profile_picture !== "" ?
                                (
                                  <img
                                    className="inline-block h-12 w-12 rounded-full"
                                    src={`${(removeImage == info?.profile_picture) ? (config.USER_MODULE_PATH  + "employee/" + info.id + "/" + info?.profile_picture) : URL.createObjectURL(info?.profile_picture)}`}
                                    onError={(e) =>
                                    (e.target.src =
                                      "/img/defaultAvatar.svg")
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    className="inline-block h-12 w-12 rounded-full"
                                    src={"/img/defaultAvatar.svg"}
                                    alt=""
                                  />
                                )}

                              <div className="ml-4 flex">
                                <div className="relative flex cursor-pointer items-center rounded-md border border-blue-gray-300 bg-white py-2 px-3 shadow-sm focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 hover:bg-blue-gray-50">
                                  <label
                                    htmlFor="user-photo"
                                    className="pointer-events-none relative text-sm font-medium text-blue-gray-900"
                                  >
                                    <span>Upload Picture</span>
                                    <span className="sr-only"> user photo</span>
                                  </label>
                                  <input
                                    id="user-photo"
                                    name="user-photo"
                                    type="file"
                                    onChange={(e) => handleChange(e, "profile_picture")}
                                    className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
                                  />
                                </div>
                                <button
                                  type="button"
                                  onClick={handleProfileRemove}
                                  className="ml-3 rounded-md border border-transparent bg-transparent py-2 px-3 text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:border-blue-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-gray-50"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <BtnRedirect path={"/master/employee"} title={"Cancelar"} />
                    <BtnSaveRecord
                      path={"/master/employee"}
                      title={"Submeter"}
                      handleSubmit={handleSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}