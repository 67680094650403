import React, { useEffect, useRef } from "react";
import "trix";
import "trix/dist/trix.css";

// For Text Area Element
function TextArea({ name, handleChange, value }) {
  const trixEditor = useRef(null);

  useEffect(() => {
    trixEditor.current.addEventListener("trix-change", (e) => {
      handleChange(trixEditor.current.value);
    });
  }, [trixEditor]);

  useEffect(() => {
    if (!trixEditor.current) return;
    if (trixEditor.current.inputElement.value === value) return;
    trixEditor.current.value = value;
  }, [value]);

  return React.createElement("trix-editor", { ref: trixEditor });
}

export default TextArea;
