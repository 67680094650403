import React, { Fragment, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Dialog, Menu, Transition, Tab } from "@headlessui/react";
import LeftNavigation from "../../components/LeftNavigation/leftNavigation.js";
import TopNavigation from "../../components/TopNavigation/topNavigation";
import Header from "../../components/Header/Header";
import ActionButton from "../../components/Other/ActionButton";
import { toast } from "react-toastify";
import { pluck } from "underscore";
import DeleteModel from "../../components/Other/DeleteModel";
import DataTable from "../../components/Table/MDataTable";
import Status from "../../constants/status";
import {
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
  PencilIcon,
  EyeIcon,
  DocumentTextIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import config from "../../utils/config.js";
import { find } from "underscore";
import { validateForm } from "../../utils/common.js";
import Cookies from "js-cookie";
import moment from 'moment';
import MultiSelect from "@kenshooui/react-multi-select";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function RequestPCA() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const [error, setError] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [tripRequestedDocument, setTripRequestedDocument] = useState([]);
  const [tabEvaluationData, setTabEvaluationData] = useState([]);
  const [qualificationCandidateData, setQualificationCandidateData] = useState([]);
  const [competitorData, setCompetitorData] = useState([]);
  const [documentPDF, setDocumentPDF] = useState("false");
  const [open2, setOpen2] = useState(false);
  const [selectedQC, setSelectedQC] = useState([]);

  function handleCancelDel() {
    setShowDelModal(false);
  }
  const history = useHistory();

  const btnGroups = [
    { title: "Novo", type: "addNew", path: "/requests/NewRequest" },
    // { title: "Activar", type: "makeActive", path: "/requests" },
    // { title: "Desactivar", type: "makeInactive", path: "/requests" },
    { title: "Eliminar", type: "delete", path: "/requests" },
  ];
  function handleAction(type) {
    if (ids.length < 1) {
      return toast.error("Selecione pelo menos um registro");
    } else if (type === "delete") {
      setShowModal(true);
      return;
    }
    const sendData = {
      ids,
      type:
        type === "makeActive"
          ? "active"
          : type === "makeInactive"
            ? "inactive"
            : type,
    };
  }
  // function handleDelete() {
  //   const sendData = {
  //     ids,

  //     type: 'delete'
  //   }
  // }

  async function handleDelete() {
    try {
      const sendData = {
        ids,
        docsName,
        type: "delete",
      };
      const response = await config.deleteAPI({
        url: "tripRequest/delete",
        params: { id: sendData?.ids, docsName: docsName?.docsName },
      });

      if (!response?.payload?.success) {
        return toast.error("Falha na inserção do registro");
      }
      getList();
      setShowModal(false);
      setIds([]);
      setDocsName([]);
      return toast.success("Registo removido com sucesso");
    } catch (error) {
      console.log("error", error);
    }
  }

  function handleCancel() {
    setShowModal(false);
  }
  const [open, setOpen] = useState(false);
  const [ids, setIds] = useState([]);
  const [docsName, setDocsName] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [details, setDetails] = useState();
  let [tripID, setTripID] = useState();
  const [reason, setReason] = useState();

  useEffect(() => {
    setShowLoader(true);
    getList();
  }, []);

  function getList(params = "") {
    config
      .getAPI({ url: "tripRequest/list", params: { area_id: parseInt(Cookies.get("areaID")), recipient: "PCA" } })
      .then((data) => {
        if (data.payload) {
          setData(data.payload.rows);
          setShowLoader(false);
        }
        if (data?.error?.message) {
          history.push("/");
          // return toast.error(data?.error?.message)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  const documentList = async (trip_request_id) => {
    config
      .getAPI({ url: "tripRequestDocument/list", params: { trip_request_id } })
      .then((data) => {
        if (data.payload) {
          setTripRequestedDocument(data.payload.rows);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const getTabEvaluationData = async (trip_request_id) => {
    try {
      const response = await config.getAPI({
        url: "contest/list-evaluation-proposal-data",
        params: { trip_id: trip_request_id }
      });
      setTabEvaluationData(response.payload);
    } catch (error) {
      console.log("error", error);
    }
  }

  const getQualificationCandidateData = async (ci_number) => {
    try {
      const response = await config.getAPI({
        url: "contest/list-qualification-candidate-docs",
        params: { ci_number }
      });
      setQualificationCandidateData(response.payload?.tripQCIDs);
    } catch (error) {
      console.log("error", error);
    }
  }

  const getCompetitorData = async (id) => {
    try {
      const response = await config.getAPI({
        url: "tripCompetitor/getdatabyId",
        params: { trip_request_id: id }
      });
      setCompetitorData(response.payload?.response?.rows);
    } catch (error) {
      console.log("error", error);
    }
  }

  function info(id, ci_number) {
    const selectedData = find(data, function (num) {
      return num.id === id;
    });
    setDetails(selectedData);
    documentList(id)
    setOpen(true);
    getTabEvaluationData(id);
    getQualificationCandidateData(ci_number);
    getCompetitorData(id);
  }

  const onSelectionChange = (e) => {
    const idArr = pluck(e, "id");
    const docsArr = pluck(e, "internal_communication_document");
    setIds(idArr);
    setDocsName(docsArr);
  };
  const columns = [
    { title: "Número da CI", field: "ci_number" },
    {
      title: "Área solicitante",
      field: "requester_area",
    },
    {
      title: "Assunto",
      field: "subject_matter",
      // render: (rowData) => <span>{rowData?.recipient?.recipient}</span>,
    },
    { title: "Tipo de procedimento", field: "procedure_type.name" },

    {
      title: "Data da solicitação",
      field: "contract_start_date",
      render: (rowData) => (
        <span>{new Date(rowData?.createdAt).toLocaleDateString()}</span>
      ),
    },
    {
      title: "Estado",
      field: "status",
      render: (rowData) => (
        <span className={getClassName(rowData.status)}>
          {" "}
          {changeinPortuguese(rowData.status)}
        </span>
      ),
    },
    {
      title: "Acções",
      render: (rowData) => (
        <div className="flex">
          {
            rowData.status !== "Approved" ?
              < Link
                className="text-indigo-600 hover:text-indigo-900 mr-4 mt-1"
                to={`/requests/RequestEdit/${rowData?.id}`}
              >
                <PencilIcon
                  className="flex-shrink-0 h-5 w-5 red-link"
                  aria-hidden="true"
                />
              </Link> : ""
          }
          < button
            className="text-indigo-600 hover:text-indigo-900 focus:outline-none mr-4"
            onClick={() => info(rowData.id, rowData.ci_number)
            }
          >
            <EyeIcon
              className="flex-shrink-0 h-5 w-5 red-link"
              aria-hidden="true"
            />
            {/* Edit  */}
          </button >

          {/* <button
            className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
          >
            <PrinterIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          </button> */}

          <div className="inline-block self-center relative top-1" >
            {
              rowData.status == "Pending" || rowData.status == "In_Preparation_Of_Parts" ?
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon
                        className="h-6 w-6 red-link"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex px-4 py-2 text-sm"
                              )}
                              onClick={() =>
                                handleStatusUpdate(rowData?.id, "Approved")
                              }
                            >
                              <CheckIcon
                                className="mr-3 h-5 w-5 red-link"
                                aria-hidden="true"
                              />
                              <span>Aprovar</span>
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900 cursor-pointer"
                                  : "text-gray-700",
                                "flex px-4 py-2 text-sm"
                              )}
                              onClick={() => {
                                setTripID(rowData?.id);
                                setShowModal1(true);
                              }}
                            >
                              <XMarkIcon
                                className="mr-3 h-5 w-5 red-link"
                                aria-hidden="true"
                              />
                              <span>Rejeitar</span>
                            </div>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu> : ""}
          </div >
        </div >
      ),
    },
  ];

  function updateStatus(params) {
    config
      .postAPI({ url: "tripRequest/updateStatus", params })
      .then((data) => {
        getList();
        return toast.success("Atualização de status com sucesso");
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }
  const handleStatusUpdate = async (id, status) => {
    let params = { trip_request_id: id, status };
    if (status === "Rejected") {
      params = { ...params, reason };
      const includedFields = ["reason"];
      const validationResponse = validateForm({ reason }, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        setShowModal1(false);
        updateStatus(params);
      }
    } else {
      updateStatus(params);
    }
  };

  const columns4 = [
    {
      title: "Documentos de Qualificação",
      field: "qualification_documents.name",
    },
    {
      title: "Estado",
      field: "status",
      render: (rowData) => (
        <span className={getClassName(rowData.status)}>
          {changeinPortuguese(rowData.status)}
        </span>
      ),
    },
  ];

  const getClassName = (status) => {
    if (status === "execution") {
      return "inline-flex items-center w-max px-3 py-0.5 rounded-full text-base font-normal bg-indigo-200 text-indigo-800";
    } else if (
      status === "delayed" ||
      status === "expired" ||
      status === "Rejected"
    ) {
      return "inline-flex items-center w-max px-3 py-0.5 rounded-full text-base font-normal bg-red-200 text-red-800";
    } else if (status === "Approved") {
      return "inline-flex items-center w-max px-3 py-0.5 rounded-full text-base font-normal bg-lime-200 text-lime-800";
    } else {
      return "inline-flex items-center w-max px-3 py-0.5 rounded-full text-base font-normal bg-yellow-200 text-yellow-800";
    }
  };
  const changeinPortuguese = (status) => {
    if (status === "Active") {
      return (status = "Ativo");
    } else if (status === "Approved") {
      return (status = "Aprovado");
    } else if (status === "Rejected") {
      return (status = "Rejeitada");
    } else if (status === "Inactive") {
      return (status = "Inativa");
    } else if (status === "Pending") {
      return (status = "Pendente");
    } else if (status === "In_Preparation_Of_Parts") {
      return (status = "Em Prep. Peças");
    }
  };
  const [showModal1, setShowModal1] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          div
          className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col"
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1 pb-8">
            <div className="mt-8">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="dark:bg-black">
                  <div className="py-5 flex items-center justify-between">
                    <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
                      Solicitações para o PCA
                    </h1>
                    <div className="flex items-center gap-3">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="flex items-center rounded-full border-2 py-2 px-2 dark:border-[#00FF00] border-[#EA042B] outline-none">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon
                              className="h-5 w-5 dark:text-white red-text"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-30 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              {btnGroups.map((btn) => {
                                return (
                                  <ActionButton
                                    key={btn.id}
                                    btnTitle={btn.title}
                                    btnType={btn.type}
                                    btnPath={btn.path}
                                    handleAction={handleAction}
                                  />
                                );
                              })}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <DeleteModel
                    mode={showModal}
                    handleDelete={handleDelete}
                    handleCancel={handleCancel}
                  />
                  <div className="dark:bg-black">
                    <div className="flex flex-col">
                      <div className="datatable">
                        <DataTable
                          columns={columns}
                          data={data}
                          disabledCheckbox={false}
                          onSelectionChange={onSelectionChange}
                          showLoader={showLoader}
                        />
                        {/* <DeleteModel
                          mode={showModal}
                          handleDelete={() => handleAction("deleted")}
                          handleCancel={handleCancel}
                        />
                        <DeleteModel
                          mode={showDelModal}
                          handleDelete={() => handleDelete("deleted")}
                          handleCancel={handleCancelDel}
                        /> */}
                      </div>
                      {showModal1 ? (
                        <>
                          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative my-6 mx-auto max-w-4xl w-96">
                              {/*content*/}
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between pt-5 rounded-t px-3">
                                  {/* <h3 className="text-xl font-semibold leading-6 font-medium text-gray-900">
                                            Número da CI
                                          </h3> */}
                                  <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-medium focus:outline-none"
                                    onClick={() => setShowModal1(false)}
                                  >
                                    <span className="bg-transparent text-gray-400 hover:text-gray-500 h-8 w-8 text-3xl block outline-none focus:outline-none">
                                      ×
                                    </span>
                                  </button>
                                </div>
                                {/*body*/}
                                <div className="relative px-6 flex-auto">
                                  <div className="sm:col-span-6">
                                    <label
                                      htmlFor="about"
                                      className="block text-base font-bold text-gray-900"
                                    >
                                      Número da CI
                                    </label>
                                    <div className="mt-1">
                                      <textarea
                                        id="about"
                                        name="about"
                                        rows={5}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        defaultValue={""}
                                        onChange={(e) =>
                                          setReason(e.target.value)
                                        }
                                      />
                                    </div>
                                    {error?.reason && (
                                      <span className="text-xs text-red-600 capitalize">
                                        {error?.reason}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 rounded-b">
                                  <button
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                    type="button"
                                    onClick={() => setShowModal1(false)}
                                  >
                                    Cancelar
                                  </button>
                                  <button
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                    type="button"
                                    onClick={() =>
                                      handleStatusUpdate(tripID, "Rejected")
                                    }
                                  >
                                    Submeter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}

                      {showModal2 ? (
                        <>
                          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative my-6 mx-auto max-w-4xl w-96">
                              {/*content*/}
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between pt-5 rounded-t px-3">
                                  {/* <h3 className="text-xl font-semibold leading-6 font-medium text-gray-900">
                                            Número da CI
                                          </h3> */}
                                  <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-medium focus:outline-none"
                                    onClick={() => setShowModal2(false)}
                                  >
                                    <span className="bg-transparent text-gray-400 hover:text-gray-500 h-8 w-8 text-3xl block outline-none focus:outline-none">
                                      ×
                                    </span>
                                  </button>
                                </div>
                                {/*body*/}
                                <div className="relative px-6 flex-auto">
                                  <div className="sm:col-span-6">
                                    <label
                                      htmlFor="about"
                                      className="block text-base font-bold text-gray-900"
                                    >
                                      Motivo
                                    </label>
                                    <div className="mt-1">
                                      <textarea
                                        id="about"
                                        name="about"
                                        rows={5}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        defaultValue={""}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 rounded-b">
                                  <button
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                    type="button"
                                    onClick={() => setShowModal2(false)}
                                  >
                                    Cancelar
                                  </button>
                                  <button
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                    type="button"
                                    onClick={() => setShowModal2(false)}
                                  >
                                    Submeter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 font-medium text-gray-900 mb-5"
                    >
                      Detalhes da solicitação
                    </Dialog.Title>
                    <div className="pb-3">
                      <Tab.Group as="div">
                        <div className="border-b border-gray-200">
                          <Tab.List className="-mb-px flex space-x-8">
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? "border-red-600 text-red-600"
                                    : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                  "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                )
                              }
                            >
                              Informações gerais
                            </Tab>
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? "border-red-600 text-red-600"
                                    : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                  "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                )
                              }
                            >
                              Suporte da CI
                            </Tab>
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? "border-red-600 text-red-600"
                                    : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                  "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                )
                              }
                            >
                              Critérios de avaliação
                            </Tab>
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? "border-red-600 text-red-600"
                                    : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                  "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                )
                              }
                            >
                              Concorrentes
                            </Tab>
                          </Tab.List>
                        </div>
                        <Tab.Panels as={Fragment}>
                          <Tab.Panel className="mt-6">
                            <h3 className="sr-only">Informações gerais</h3>
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                CI Number
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details && details.ci_number}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  NIC
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details && details.nic_name}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  Área solicitante
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details && details.requester_area}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  Destinatário
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details && details.recipient?.recipient}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  Assunto
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details?.subject_matter}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  Tipo de procedimento
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details?.procedure_type?.name}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  Data da solicitação
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details && details
                                    ? moment(details.request_date).format(
                                      "DD-MM-YYYY"
                                    )
                                    : ""}
                                </dd>
                              </div>
                            </dl>
                          </Tab.Panel>
                          <Tab.Panel className="mt-6">
                            <div className="py-5">
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-2">
                                  {/* <h2 className="text-base font-bold text-gray-500">
                                    Passageiros seleccionados
                                  </h2> */}
                                  <div className="datatable mt-3 datatable_bg_gray">
                                    <div className="px-4 sm:px-6 lg:px-8">
                                      <div className="flex flex-col">
                                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                          <div className="inline-block min-w-full align-middle">
                                            <table className="min-w-full divide-y divide-gray-300">
                                              <thead>
                                                <tr>
                                                  <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-6 md:pl-0"
                                                  >
                                                    Documento
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="py-3.5 px-3 text-left text-base font-semibold text-gray-900"
                                                  >
                                                    Ficheiro
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="py-3.5 px-3 text-left text-base font-semibold text-gray-900"
                                                  >
                                                    Data de registo
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="py-3.5 px-3 text-left text-base font-semibold text-gray-900"
                                                  >
                                                    Acções
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody className="divide-y divide-gray-200">
                                                {
                                                  tripRequestedDocument && tripRequestedDocument.length > 0 ?
                                                    tripRequestedDocument.map((singleElement) => {
                                                      return (
                                                        <>
                                                          <tr key={1}>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-normal text-gray-900 sm:pl-6 md:pl-0">
                                                              {singleElement?.internal_communication?.name}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              {singleElement?.file_name}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              {new Date(singleElement?.createdAt).toLocaleDateString()}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                                <button
                                                                >
                                                                  <DocumentTextIcon className="red-link h-5 w-5" aria-hidden="true" onClick={() => (setDocumentPDF(config.staticPath + "tripRequest/" + singleElement?.trip_request_id + "/" + singleElement?.file_name), setOpen2(true))} />
                                                                </button>
                                                              </td>
                                                            </td>
                                                          </tr>
                                                        </>
                                                      )
                                                    })
                                                    :
                                                    <>
                                                      <tr
                                                        align="center"
                                                      >
                                                        <td
                                                          colspan="4"
                                                          className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-normal text-gray-900 sm:pl-6 md:pl-0">
                                                          {"Nenhum registo encontrado"}
                                                        </td>
                                                      </tr>
                                                    </>
                                                }

                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </dl>
                            </div>
                          </Tab.Panel>

                          <Tab.Panel className="mt-6">
                            <h3 className="sr-only">Critérios de avaliação</h3>
                            {
                              <Tab.Group as="div">
                                <div className="border-b border-gray-200">
                                  <Tab.List className="-mb-px flex space-x-8">
                                    <Tab className={({ selected }) =>
                                      classNames(
                                        selected
                                          ? "border-red-600 text-red-600"
                                          : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                        "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                      )
                                    }
                                    >
                                      Qualificação de Candidatos
                                    </Tab>
                                    <Tab className={({ selected }) =>
                                      classNames(
                                        selected
                                          ? "border-red-600 text-red-600"
                                          : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                        "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                      )
                                    }
                                    >
                                      Avaliação de propostas
                                    </Tab>
                                  </Tab.List>
                                </div>
                                <Tab.Panels as={Fragment}>
                                  <Tab.Panel className="pt-3">
                                    <h3 className="sr-only">
                                      Habilitação de Concorrentes
                                    </h3>
                                    <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none bg-gray-100 px-4 py-4">
                                      <div className="check_none">
                                        <DataTable
                                          columns={columns4}
                                          data={qualificationCandidateData}
                                          disabledCheckbox={false}
                                          onSelectionChange={onSelectionChange} />
                                      </div>
                                      {showModal1 ? (
                                        <>
                                          <div
                                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                            <div className="relative w-auto my-6 mx-auto w-6/12">
                                              <div
                                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                  <h3 className="text-xl font-semibold">
                                                    Documentos de
                                                    qualificação de
                                                    candidados
                                                  </h3>
                                                  <button
                                                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                    onClick={() =>
                                                      setShowModal1(
                                                        false
                                                      )
                                                    }
                                                  >
                                                    <span
                                                      className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none red-link">
                                                      ×
                                                    </span>
                                                  </button>
                                                </div>
                                                <div className="relative p-6 flex-auto">
                                                  <div className="mt-1">
                                                    <MultiSelect items={qualificationCandidateData} messages={{
                                                      searchPlaceholder: "Procurar...",
                                                      noItemsMessage: "Nenhum item...", noneSelectedMessage: "Nenhum seleccionado",
                                                      selectedMessage: "selecionado", selectAllMessage: "Selecionar tudo",
                                                      clearAllMessage: "Limpar tudo", disabledItemsTooltip: "Você só pode selecionar 1 arquivo",
                                                    }}
                                                      selectedItems={selectedQC} itemHeight={50} onChange={(e) =>
                                                        setSelectedQC(
                                                          e
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                        </>
                                      ) : null}
                                    </div>
                                  </Tab.Panel>
                                  <Tab.Panel className="pt-3">
                                    <h3 className="sr-only">
                                      Avaliação de propostas
                                    </h3>

                                    <div className="space-y-8 divide-y divide-gray-200">
                                      <div className="px-0">
                                        <div className="mt-4 flex flex-col">
                                          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                  <thead className="bg-gray-50">
                                                    <tr>
                                                      <th scope="col"
                                                        className="py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-6">
                                                        Critério de
                                                        avaliação
                                                      </th>
                                                      <th scope="col" className="px-3 py-3.5 text-left text-base font-semibold text-gray-900">
                                                        Peso (%)
                                                      </th>
                                                      <th scope="col" className="px-3 py-3.5 text-left text-base font-semibold text-gray-900">
                                                        Atributos
                                                      </th>
                                                      <th scope="col" className="px-3 py-3.5 text-left text-base font-semibold text-gray-900">
                                                        Pontuação
                                                      </th>
                                                      <th scope="col" className="px-3 py-3.5 text-left text-base font-semibold text-gray-900">
                                                        Estado
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="divide-y divide-gray-200 bg-white">
                                                    {tabEvaluationData &&
                                                      tabEvaluationData.length >
                                                      0
                                                      ? tabEvaluationData.map(
                                                        (
                                                          singleData
                                                        ) => {
                                                          return (
                                                            <tr key={singleData.id}>
                                                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base sm:pl-6">
                                                                <div className="flex items-center">
                                                                  <div className="ml-0">
                                                                    <div className="font-medium text-gray-500">
                                                                      {
                                                                        singleData
                                                                          .evaluation_criteria
                                                                          ?.name
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </td>
                                                              <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                                                <div className="text-gray-500">
                                                                  {
                                                                    singleData.weight
                                                                  }
                                                                </div>
                                                              </td>
                                                              <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                                                {singleData.multiAttrName.map(
                                                                  (
                                                                    element
                                                                  ) => {
                                                                    return (
                                                                      <div className="text-gray-500">
                                                                        {
                                                                          element
                                                                        }
                                                                      </div>
                                                                    );
                                                                  }
                                                                )}
                                                              </td>
                                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {singleData.multiPunctionName.map(
                                                                  (
                                                                    elementAttr
                                                                  ) => {
                                                                    return (
                                                                      <div className="text-gray-500">
                                                                        {
                                                                          elementAttr
                                                                        }
                                                                      </div>
                                                                    );
                                                                  }
                                                                )}
                                                              </td>
                                                              <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                                                <span className={getClassName(singleData.status)}>
                                                                  {changeinPortuguese(
                                                                    singleData.status
                                                                  )}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )
                                                      :
                                                      <>
                                                        <tr align="center">
                                                          <td colspan="5"
                                                            className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-normal text-gray-900 sm:pl-6 md:pl-0">
                                                            {"Nenhum registo encontrado"}
                                                          </td>
                                                        </tr>
                                                      </>
                                                    }
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Panel>
                                </Tab.Panels>
                              </Tab.Group>
                            }
                          </Tab.Panel>

                          <Tab.Panel className="mt-6">
                            <div className="py-5">
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-2">
                                  {/* <h2 className="text-base font-bold text-gray-500">
                                      Passageiros seleccionados
                                    </h2> */}
                                  <div className="datatable mt-3 datatable_bg_gray">
                                    <div className="px-4 sm:px-6 lg:px-8">
                                      <div className="flex flex-col">
                                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                          <div className="inline-block min-w-full align-middle">
                                            <table className="min-w-full divide-y divide-gray-300">
                                              <thead>
                                                <tr>
                                                  <th scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-6 md:pl-0">
                                                    Empresa
                                                  </th>
                                                  <th scope="col" className="py-3.5 px-3 text-left text-base font-semibold text-gray-900">
                                                    NIF
                                                  </th>
                                                  <th scope="col" className="py-3.5 px-3 text-left text-base font-semibold text-gray-900">
                                                    Tipo de empresa
                                                  </th>
                                                  <th scope="col" className="py-3.5 px-3 text-left text-base font-semibold text-gray-900">
                                                    E-mail
                                                  </th>
                                                  <th scope="col" className="py-3.5 px-3 text-left text-base font-semibold text-gray-900">
                                                    Endereço
                                                  </th>
                                                  <th scope="col" className="py-3.5 px-3 text-left text-base font-semibold text-gray-900">
                                                    Telemóvel
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody className="divide-y divide-gray-200">
                                                {
                                                  competitorData && competitorData.length > 0 ?
                                                    competitorData.map((singleElement) => {
                                                      return (
                                                        <>
                                                          <tr key={1}>
                                                            <td
                                                              className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-normal text-gray-900 sm:pl-6 md:pl-0">
                                                              {singleElement?.company}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              {singleElement?.tax_number}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              {singleElement?.supplier_type?.name}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              {singleElement?.email}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              {singleElement?.address}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 px-3 text-base text-gray-500">
                                                              {singleElement?.phone_number}
                                                            </td>
                                                          </tr>
                                                        </>
                                                      )
                                                    })
                                                    :
                                                    <>
                                                      <tr align="center">
                                                        <td colspan="6"
                                                          className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-normal text-gray-900 sm:pl-6 md:pl-0">
                                                          {"Nenhum registo encontrado"}
                                                        </td>
                                                      </tr>
                                                    </>
                                                }

                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </dl>
                            </div>
                          </Tab.Panel>
                        </Tab.Panels>
                      </Tab.Group>
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={open2} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen2}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100"
              leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child as={Fragment} enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpen2(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900 mb-5">
                      {/* Comprovativo */}
                    </Dialog.Title>
                    <div className="py-5">
                      {documentPDF !== "" && (
                        <iframe style={{ height: "45vh", display: "block" }} src={documentPDF} title="document"
                          className="w-4/6 mx-auto mt-5" />
                      )}
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
