import MaterialTable from "material-table";
import tableIcons from "../containers/miui-icons";

const DataTable = (props) => {
  let selectedRow = "";
  let selection = true;
  if ("selection" in props) {
    selection = props.selection;
  }
  let pageSizeOptions = [];
  addPush(5);
  addPush(10);
  addPush(20);
  addPush(50);
  addPush(100);
  addPush(200);
  addPush(500);
  addPush(1000);
  function addPush(number) {
    if (props?.data?.length > number) {
      pageSizeOptions.push(number);
    }
  }

  pageSizeOptions.push(props.data.length);
  return (
    <>
      <MaterialTable
        isLoading={props.isLoading}
        icons={tableIcons}
        columns={props.columns}
        data={props.data}
        title={""}
        options={{
          search: true,
          Pagination: {
            labelDisplayedRows: "",
            labelRowsSelect: "",
          },
          selection: selection,
          pageSizeOptions: pageSizeOptions,
          sorting: true,
          rowStyle: (rowData) => ({
            backgroundColor:
              selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
          }),
        }}
        localization={{
          toolbar: {
            searchPlaceholder: "Procurar",
          },
          pagination: {
            labelRowsSelect: "registos",
          },
          body: {
            emptyDataSourceMessage: "Nenhum registo encontrado",
          },
        }}
        onSelectionChange={props.onSelectionChange}
      />
    </>
  );
};

export default DataTable;
