export default {
  expired: "Expirado",
  overdue: "Atrasado",
  active: "Activo",
  inactive: "Inactivo",
  delivered: "Concluído",
  completed: "Concluído",
  draft: "Rascunho",
  archive: "Arquivar",
  deleted: "removido",
  unpaid: "Não pago",
  paid: "Pago",
  partial: "Parcial",
  due: "Vencido",
  pending: "Pendente",
  delayed: "Atrasado",
  execution: "Execução",
  approved: "Aprovado",
};
