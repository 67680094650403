import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LeftNavigation from "../../../components/LeftNavigation/leftNavigation.js";
import Header from "../../../components/Header/Header";
import BtnRedirect from "../../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import config from "../../../utils/config";
import { toast } from "react-toastify";
import { validateForm } from "../../../utils/common.js";
import { useParams } from "react-router-dom";
import {
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import MultiSelect from "@kenshooui/react-multi-select";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Roles({ history }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { id } = useParams();
  const [info, setInfo] = useState({ name: "", status: "" });
  const [error, setError] = useState({});
  const [tripTabList, setTripTabList] = useState([
    { id: 1, label: "Nova CI", value: "new_ci_01", status: "current" },
    {
      id: 2,
      label: "Suporte da CI",
      value: "ci_support_02",
      status: "upcoming",
    },
    {
      id: 3,
      label: "Critérios de avaliação",
      value: "rating_criteria_03",
      status: "upcoming",
    },
    {
      id: 4,
      label: "Concorrentes",
      value: "competitors_04",
      status: "upcoming",
    },
  ]);
  const [contestTabList, setContestTabList] = useState([
    {
      id: 1,
      label: "Qualificação de Candidatos",
      value: "qualification_of_competitors_01",
    },
    {
      id: 2,
      label: "Avaliação de propostas",
      value: "evaluation_of_proposals_02",
    },
  ]);
  const [ratingCriteriaTabList, setRatingCriteriaTabList] = useState([
    {
      id: 1,
      label: "Qualificação de candidatos",
      value: "qualification_of_candidates_01",
    },
    {
      id: 2,
      label: "Avaliação de propostas",
      value: "evaluation_of_proposals_02",
    },
  ]);
  const [selectedTripTabs, setSelectedTripTabs] = useState([
    { id: 1, label: "Nova CI", value: "new_ci_01" },
    {
      id: 2,
      label: "Suporte da CI",
      value: "ci_support_02",
      status: "upcoming",
    },
  ]);
  const [selectedContestTabs, setSelectedContestTabs] = useState([]);
  const [selectedRatingCriteriaTabs, setSelectedRatingCriteriaTabs] = useState(
    []
  );
  const [isShowContest, setIsShowContest] = useState();

  const handleSubmit = async () => {
    try {
      let sendData = info;
      sendData = {
        ...info,
        trip_tabs: selectedTripTabs,
        contest_tabs: selectedContestTabs,
        rating_criteria_tabs: selectedRatingCriteriaTabs,
      };
      let includedFields = ["name", "status", "trip_tabs"];
      if (isShowContest) {
        includedFields = [
          "name",
          "status",
          "trip_tabs",
          "contest_tabs",
          "rating_criteria_tabs",
        ];
      }

      const validationResponse = validateForm(sendData, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        // let sendData = info;
        sendData = {
          ...info,
          trip_tabs: JSON.stringify(selectedTripTabs),
          contest_tabs: JSON.stringify(selectedContestTabs),
          rating_criteria_tabs: JSON.stringify(selectedRatingCriteriaTabs),
        };
        const insertResponse = await config.postAPI({
          url: "procedureType/update",
          params: { fields: sendData, id: id },
        });

        if (insertResponse?.response?.data?.error?.message) {
          return toast.error(insertResponse?.response?.data?.error?.message);
        }
        setTimeout(() => {
          return toast.success("Registo actualizado com sucesso");
        }, 200);
        history.push("/master/procedure_type");
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  useEffect(() => {
    const getDetails = async () => {
      const detailInfo = await config.getAPI({
        url: `procedureType/getdatabyId`,
        params: { id: id },
      });
      if (detailInfo?.payload) {
        setInfo({
          ...detailInfo?.payload,
        });
        setSelectedTripTabs(JSON.parse(detailInfo.payload.trip_tabs));
        setSelectedContestTabs(JSON.parse(detailInfo.payload.contest_tabs));
        setSelectedRatingCriteriaTabs(
          JSON.parse(detailInfo.payload.rating_criteria_tabs)
        );
        handleChangeTab(JSON.parse(detailInfo.payload.trip_tabs));
      }
      if (detailInfo?.error?.message) {
        history.push("/");
        // return toast.error(data?.error?.message)
      }
    };
    getDetails();
  }, [id]);

  const handleChange = (e, name) => {
    const setData = {
      ...info,
      [name]: e?.target?.value,
    };
    setInfo(setData);
  };

  const handleChangeTab = (e) => {
    setSelectedTripTabs(e);
    const data = e.find((element) => element.value === "rating_criteria_03");
    if (data) {
      setIsShowContest(true);
    } else {
      setSelectedContestTabs([]);
      setSelectedRatingCriteriaTabs([]);
      setIsShowContest(false);
    }
  };

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1 pb-8">
            <div className="mt-8">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="py-5 flex items-center justify-between">
                  <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
                    Editar tipo de procedimento
                  </h1>
                </div>
                <form className="space-y-6" action="#" method="POST">
                  <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                    <div className="md:grid md:gap-6">
                      <div className="mt-5 md:mt-0">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Nome <span className="mandatory">*</span>
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="name"
                              onChange={(e) => {
                                handleChange(e, "name");
                              }}
                              value={info?.name}
                              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                                error?.name && "border-red-600"
                              }`}
                            />
                            {error?.name && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.name}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="status"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Estado <span className="mandatory">*</span>
                            </label>
                            <select
                              id="status"
                              name="status"
                              autoComplete="status"
                              onChange={(e) => {
                                handleChange(e, "status");
                              }}
                              value={info?.status}
                              className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${
                                error?.status && "border-red-600"
                              }`}
                            >
                              <option>---Selecione Estado---</option>
                              <option value="Active">Activo</option>
                              <option value="Inactive">Inactivo</option>
                            </select>
                            {error?.status && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.status}
                              </span>
                            )}
                          </div>

                          <div className="col-span-12 sm:col-span-6">
                            <label
                              htmlFor="trip_tabs"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Solicitações - separadores{" "}
                              <span className="mandatory">*</span>
                            </label>

                            <div className="mt-1">
                              <MultiSelect
                                isLocked={(item) =>
                                  item.label === "Nova CI" ||
                                  item.label === "Suporte da CI"
                                }
                                items={tripTabList}
                                messages={{
                                  searchPlaceholder: "Procurar...",
                                  noItemsMessage: "Nenhum item...",
                                  noneSelectedMessage: "Nenhum seleccionado",
                                  selectedMessage: "selecionado",
                                  selectAllMessage: "Selecionar tudo",
                                  clearAllMessage: "Limpar tudo",
                                  disabledItemsTooltip:
                                    "Você só pode selecionar 1 arquivo",
                                }}
                                selectedItems={selectedTripTabs}
                                itemHeight={50}
                                onChange={(e) => handleChangeTab(e)}
                              />
                            </div>

                            {error?.trip_tabs && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.trip_tabs}
                              </span>
                            )}
                          </div>

                          {isShowContest ? (
                            <>
                              <div className="col-span-12 sm:col-span-6">
                                <label
                                  htmlFor="rating_criteria_tabs"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Solicitações (Critérios de avaliação) -
                                  separadores
                                </label>

                                <div className="mt-1">
                                  <MultiSelect
                                    items={ratingCriteriaTabList}
                                    messages={{
                                      searchPlaceholder: "Procurar...",
                                      noItemsMessage: "Nenhum item...",
                                      noneSelectedMessage:
                                        "Nenhum seleccionado",
                                      selectedMessage: "selecionado",
                                      selectAllMessage: "Selecionar tudo",
                                      clearAllMessage: "Limpar tudo",
                                      disabledItemsTooltip:
                                        "Você só pode selecionar 1 arquivo",
                                    }}
                                    selectedItems={selectedRatingCriteriaTabs}
                                    itemHeight={50}
                                    onChange={(e) =>
                                      setSelectedRatingCriteriaTabs(e)
                                    }
                                  />
                                </div>

                                {error?.rating_criteria_tabs && (
                                  <span className="text-xs text-red-600 capitalize">
                                    {error?.rating_criteria_tabs}
                                  </span>
                                )}
                              </div>
                              <div className="col-span-12 sm:col-span-6">
                                <label
                                  htmlFor="contest_tabs"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Concurso - separadores
                                </label>

                                <div className="mt-1">
                                  <MultiSelect
                                    items={contestTabList}
                                    messages={{
                                      searchPlaceholder: "Procurar...",
                                      noItemsMessage: "Nenhum item...",
                                      noneSelectedMessage:
                                        "Nenhum seleccionado",
                                      selectedMessage: "selecionado",
                                      selectAllMessage: "Selecionar tudo",
                                      clearAllMessage: "Limpar tudo",
                                      disabledItemsTooltip:
                                        "Você só pode selecionar 1 arquivo",
                                    }}
                                    selectedItems={selectedContestTabs}
                                    itemHeight={50}
                                    onChange={(e) => setSelectedContestTabs(e)}
                                  />
                                </div>

                                {error?.contest_tabs && (
                                  <span className="text-xs text-red-600 capitalize">
                                    {error?.contest_tabs}
                                  </span>
                                )}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <BtnRedirect
                      path={"/master/procedure_type"}
                      title={"Cancelar"}
                    />
                    <BtnSaveRecord
                      path={"/master/procedure_type"}
                      title={"Submeter"}
                      handleSubmit={handleSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
