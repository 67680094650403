import React, { Fragment, useState, useEffect, useCallback, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import LeftNavigation from "../../components/LeftNavigation/leftNavigation.js";
import Header from "../../components/Header/Header";
import ActionButton from "../../components/Other/ActionButton";
import { toast } from "react-toastify";
import { pluck } from "underscore";
import DeleteModel from "../../components/Other/DeleteModel";
import DataTable from "../../components/Table/MDataTable";
import config from "../../utils/config";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import {
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
  PencilIcon,
  PaperClipIcon,
  PlayCircleIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import Cookies from "js-cookie";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Roles() {
  const history = useHistory();
  const printInvoiceRef = useRef(null);
  const [ids, setIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const [contractData, setContractData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [buttonValue, setButtonValue] = useState("Todos");
  const [data, setData] = useState([]);
  const btnGroups = [
    {
      title: "Novo",
      type: "addNew",
      path: "/new-contract/NewContract",
    },
    { title: "Activar", type: "makeActive", path: "/new-contract" },
    { title: "Desactivar", type: "makeInactive", path: "/new-contract" },
    { title: "Eliminar", type: "delete", path: "/contracts/contracts_view" },
  ];

  useEffect(() => {
    // getDatas();
  }, []);


  function handleCancelDel() {
    setShowDelModal(false);
  }

  const changeinPortuguese = (status) => {
    if (status === "Active") {
      return (status = "Ativo");
    } else if (status === "Delayed") {
      return (status = "Atrasado");
    } else if (status === "Expired") {
      return (status = "Expirado");
    } else if (status === "Completed") {
      return (status = "Concluido");
    }
  };

  function handleAction(type) {
    if (ids.length < 1) {
      return toast.error("Selecione pelo menos um registro");
    } else if (type === "delete") {
      setShowModal(true);
      return;
    }
    setIds([]);
  }

  const handleDelete = async () => {
    const sendData = {
      ids,
      type: "delete",
    };
    const response = await config.deleteAPI({
      url: "contract/delete",
      params: { id: sendData?.ids, user_id: Cookies.get('user_id') },
    });

    if (!response?.payload?.success) {
      return toast.error("Falha na inserção do registro");
    }
    setShowModal(false);
    setIds([]);
    getList();
    return toast.success("Registo removido com sucesso");
  };

  function handleCancel() {
    setShowModal(false);
  }

  const onSelectionChange = (e) => {
    let idArr = pluck(e, "id");
    setIds(idArr);
  };

  const downloadPdf = async (id) => {
    try {
      config.getAPI({ url: "contract/getdatabyId", params: { id } }).then((data) => {
        if (data.payload) {
          setContractData(data.payload);
          setTimeout(() => {
            printInvoice();
          }, 100);
        }
        if (data?.error?.message) {
          return toast.error(data.error.message);
        }
      }).catch((error) => {
        return toast.error(error.message);
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  const printInvoice = useReactToPrint({
    content: () => printInvoiceRef.current,
  });

  const columns = [
    { title: "Código Físico", field: "physical_code" },
    {
      title: "Objecto do Contrato",
      field: "object_of_the_contract",
    },
    { title: "Nome do Fornecedor", field: "supplier.provider_name" },
    {
      title: "Data de início",
      field: "start_date",
      render: (rowData) => {
        return <span>{new Date(rowData?.start_date).toLocaleDateString()}</span>;
      },
    },
    {
      title: "Data de Expiração",
      field: "end_date",
      render: (rowData) => {
        return <span>{new Date(rowData?.end_date).toLocaleDateString()}</span>;
      },
    },
    { title: "Referência do Concurso", field: "contest_reference" },
    {
      title: "Estado",
      field: "status",
      render: (rowData) => <span className={getClassName(rowData.status)}> {changeinPortuguese(rowData.status)}</span>,
    },
    {
      title: "Acções",
      render: (rowData) => (
        <div className='flex'>
          <Link className='text-indigo-600 hover:text-indigo-900 mr-4 mt-1' to={`/new-contract/edit/${rowData.id}`}>
            <PencilIcon className='h-5 w-5 red-link' aria-hidden='true' />
          </Link>
          <button className='text-indigo-600 hover:text-indigo-900 focus:outline-none mr-4'
            onClick={() => downloadPdf(rowData.id)}
          >
            <PrinterIcon className='flex-shrink-0 h-5 w-5 red-link' aria-hidden='true' />
          </button>
          <Link className='text-indigo-600 hover:text-indigo-900 mr-4 mt-1' to={`/contracts/execution-contract/${rowData.id}`}>
            <PlayCircleIcon className='flex-shrink-0 h-5 w-5 red-link' aria-hidden='true' />
          </Link>
          <Link
            to={{ pathname: `/new-contract/edit/${rowData.id}`, state: { tab: 3, defaultIndex: 1 } }}
            className='text-indigo-600 hover:text-indigo-900 focus:outline-none mr-4'
          >
            <PaperClipIcon className='flex-shrink-0 h-5 w-5 red-link' />
          </Link>
        </div>
      ),
    },
  ];

  const getClassName = (status) => {
    if (status === "Active") {
      return "inline-flex items-center px-3 py-0.5 rounded-full text-base font-normal bg-indigo-200 text-indigo-800";
    } else if (status === "Delayed") {
      return "inline-flex items-center px-3 py-0.5 rounded-full text-base font-normal bg-red-200 text-red-800";
    } else if (status === "Completed") {
      return "inline-flex items-center px-3 py-0.5 rounded-full text-base font-normal bg-lime-200 text-lime-800";
    } else {
      return "inline-flex items-center px-3 py-0.5 rounded-full text-base font-normal bg-yellow-200 text-yellow-800";
    }
  };
  const [showModal2, setShowModal2] = React.useState(false);

  const getList = useCallback(
    (params) => {
      if (params === "all") {
        setButtonValue("Todos")
        config
          .getAPI({ url: "contract/list", params: {} })
          .then((data) => {
            if (data.payload) {
              setData(data.payload.rows);
              setShowLoader(false);
            }
            if (data?.error?.message) {
              history.push("/");
              // return toast.error(data?.error?.message)
            }
          })
          .catch((error) => {
            return toast.error(error.message);
          });
      } else if (params === "expired") {
        setButtonValue("expired")
        config
          .getAPI({ url: "contract/list", params: { status: "expired" } })
          .then((data) => {
            if (data.payload) {
              setData(data.payload.rows);
              setShowLoader(false);
            }
            if (data?.error?.message) {
              history.push("/");
              // return toast.error(data?.error?.message)
            }
          })
          .catch((error) => {
            return toast.error(error.message);
          });
      } else {
        setButtonValue(params)
        config
          .getAPI({ url: "contract/list", params: { status: params } })
          .then((data) => {
            if (data.payload) {
              setData(data.payload.rows);
              setShowLoader(false);
            }
            if (data?.error?.message) {
              history.push("/");
              // return toast.error(data?.error?.message)
            }
          })
          .catch((error) => {
            return toast.error(error.message);
          });
      }
    },
    [history]
  );

  useEffect(() => {
    setShowLoader(true);
    getList("all");
  }, [getList]);

  return (
    <>
      <div className='min-h-full'>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as='div' className='relative z-40 lg:hidden' onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>

            <div className='fixed inset-0 z-40 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute top-0 right-0 -mr-12 pt-2'>
                      <button
                        type='button'
                        className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex flex-shrink-0 items-center px-4'>
                    <img
                      className='h-8 w-auto'
                      src='https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300'
                      alt='Easywire logo'
                    />
                  </div>
                  <nav
                    className='mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto'
                    aria-label='Sidebar'
                  >
                    <div className='space-y-1 px-2'>
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current ? "bg-skin-primary text-white" : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon className='mr-4 h-6 w-6 flex-shrink-0 text-white' aria-hidden='true' />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className='mt-6 pt-6'>
                      <div className='space-y-1 px-2'>
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current ? "bg-skin-primary text-white" : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon className='mr-4 h-6 w-6 text-white' aria-hidden='true' />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className='w-14 flex-shrink-0' aria-hidden='true'>
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className='hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <LeftNavigation />
        </div>

        <div className='flex flex-1 flex-col lg:pl-64'>
          <Header />
          <main className='flex-1 pb-8'>
            <div className='mt-8'>
              <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
                <div className='dark:bg-black'>
                  <div className='py-5 flex items-center justify-between'>
                    <h1 className='text-3xl font-bold tracking-tight dark:text-[#fff] text-black'>Contratos</h1>
                    <div className='flex items-center gap-3'>
                      <Menu as='div' className='relative inline-block text-left'>
                        <div>
                          <Menu.Button className='flex items-center rounded-full border-2 py-2 px-2 dark:border-[#00FF00] border-[#EA042B] outline-none'>
                            <span className='sr-only'>Open options</span>
                            <EllipsisVerticalIcon className='h-5 w-5 dark:text-white red-text' aria-hidden='true' />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter='transition ease-out duration-100'
                          enterFrom='transform opacity-0 scale-95'
                          enterTo='transform opacity-100 scale-100'
                          leave='transition ease-in duration-75'
                          leaveFrom='transform opacity-100 scale-100'
                          leaveTo='transform opacity-0 scale-95'
                        >
                          <Menu.Items className='absolute right-0 z-30 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                            <div className='py-1'>
                              {btnGroups.map((btn, key) => {
                                return (
                                  <ActionButton
                                    key={key}
                                    btnTitle={btn.title}
                                    btnType={btn.type}
                                    btnPath={btn.path}
                                    handleAction={handleAction}
                                  />
                                );
                              })}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className='mx-auto py-2 mb-4'>
                    <button
                      type='button'
                      className={(buttonValue === "Todos") ? "bg-skin-primary  btn-link  mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn" : "mr-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn text-white"}
                      onClick={() => {
                        getList("all")
                        setShowLoader(true)
                      }}
                    >
                      Todos
                    </button>
                    <button
                      type='button'
                      className={(buttonValue === "Active") ? "bg-skin-primary btn-link text-white mr-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium   focus:outline-none xl-admin-btn" : "mr-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn "}
                      onClick={() => {
                        getList("Active")
                        setShowLoader(true);
                      }}
                    >
                      Activo
                    </button>
                    <button
                      type='button'
                      className={(buttonValue === "expired") ? "bg-skin-primary btn-link text-white mr-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium   focus:outline-none xl-admin-btn" : "mr-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn "}
                      onClick={() => {
                        getList("expired")
                        setShowLoader(true)
                      }}
                    >
                      Expirado
                    </button>
                    <button
                      type='button'
                      className={(buttonValue === "Delayed") ? "bg-skin-primary btn-link text-white mr-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium   focus:outline-none xl-admin-btn" : "mr-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn "}
                      onClick={() => {
                        getList("Delayed")
                        setShowLoader(true)
                      }}
                    >
                      Atrasado
                    </button>
                    <button
                      type='button'
                      className={(buttonValue === "Completed") ? "bg-skin-primary btn-link text-white mr-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium   focus:outline-none xl-admin-btn" : "mr-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn "}
                      onClick={() => {
                        getList("Completed")
                        setShowLoader(true)
                      }}
                    >
                      Concluídos
                    </button>
                  </div>
                  <DeleteModel mode={showModal} handleDelete={handleDelete} handleCancel={handleCancel} />
                  <div className='dark:bg-black'>
                    <div className='flex flex-col'>
                      <div className='datatable view_request_wrap'>
                        <DataTable
                          columns={columns}
                          data={data}
                          disabledCheckbox={false}
                          onSelectionChange={onSelectionChange}
                          showLoader={showLoader}
                        />
                        <DeleteModel mode={showModal} handleDelete={handleDelete} handleCancel={handleCancel} />
                        <DeleteModel mode={showDelModal} handleDelete={handleDelete} handleCancel={handleCancelDel} />
                      </div>

                      {showModal2 ? (
                        <>
                          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
                            <div className='relative my-6 mx-auto max-w-4xl w-96'>
                              {/*content*/}
                              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                                {/*header*/}
                                <div className='flex items-start justify-between pt-5 rounded-t px-3'>
                                  {/* <h3 className="text-xl font-semibold leading-6 font-medium text-gray-900">
                                            Número da CI
                                          </h3> */}
                                  <button
                                    className='p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-medium focus:outline-none'
                                    onClick={() => setShowModal2(false)}
                                  >
                                    <span className='bg-transparent text-gray-400 hover:text-gray-500 h-8 w-8 text-3xl block outline-none focus:outline-none'>
                                      ×
                                    </span>
                                  </button>
                                </div>
                                {/*body*/}
                                <div className='relative px-6 flex-auto'>
                                  <div className='sm:col-span-6'>
                                    <label htmlFor='about' className='block text-base font-bold text-gray-900'>
                                      Motivo
                                    </label>
                                    <div className='mt-1'>
                                      <textarea
                                        id='about'
                                        name='about'
                                        rows={5}
                                        className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                        defaultValue={""}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/*footer*/}
                                <div className='flex items-center justify-end p-6 rounded-b'>
                                  <button
                                    className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn'
                                    type='button'
                                    onClick={() => setShowModal2(false)}
                                  >
                                    Cancelar
                                  </button>
                                  <button
                                    className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white'
                                    type='button'
                                    onClick={() => setShowModal2(false)}
                                  >
                                    Submeter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setOpen}>
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6'>
                <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                  <button
                    type='button'
                    className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                    onClick={() => setOpen(false)}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div>
                  <div className='text-left'>
                    <Dialog.Title as='h3' className='text-xl font-semibold leading-6 font-medium text-gray-900 mb-5'>
                      Detalhes da solicitação
                    </Dialog.Title>
                    <div className='py-5'>
                      <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Código</dt>
                          <dd className='mt-1 text-base text-gray-900'>2022/0027</dd>
                        </div>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Área solicitante</dt>
                          <dd className='mt-1 text-base text-gray-900'>DAF</dd>
                        </div>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Destinatário</dt>
                          <dd className='mt-1 text-base text-gray-900'>GTI</dd>
                        </div>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Assunto</dt>
                          <dd className='mt-1 text-base text-gray-900'>Assunto 1</dd>
                        </div>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Data da solicitação</dt>
                          <dd className='mt-1 text-base text-gray-900'>4, Nov 2022</dd>
                        </div>
                        <div className='sm:col-span-2'>
                          <dt className='text-base font-bold text-gray-500'>Descrição da Solicitação</dt>
                          <dd className='mt-1 text-base text-gray-900'>
                            Lorem Ipsum é simplesmente um texto fictício da indústria tipográfica e de impressão.
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
                <div class='html2pdf__page-break'></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* pdf code start */}
      <div className="mt-5" style={{ display: "none" }}>
        <div ref={printInvoiceRef} style={{ padding: "50px" }}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <tr>
              <td>
                <table style={{ borderCollapse: "collapse", width: "100%", background: "#C79C8B", border: "1px solid #000000" }}>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", borderRight: "1px solid #000000", width: "30%", padding: "20px 10px" }}>
                      <img
                        src="/img/side-bot-logo.png"
                        alt=""
                      />
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", borderRight: "1px solid #000000", color: "#ffffff", textTransform: "uppercase", fontSize: "22px", textAlign: "center", fontWeight: "bold", width: "30%", padding: "20px 10px" }}>
                      RESUMO DO CONTRATO
                    </td>
                    <td style={{ width: "30%", padding: "20px 10px" }}>
                      <table style={{ borderCollapse: "collapse", width: "100%" }}>
                        <tr>
                          <td colSpan="2" style={{ fontFamily: "arial, sans-serif", color: "#ffffff", fontWeight: "bold", fontSize: "18px" }}>
                            Data de Emissão:
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" style={{ fontFamily: "arial, sans-serif", color: "#000000", fontWeight: "bold", fontSize: "18px" }}>
                            {moment(contractData?.createdAt).format('DD-MM-YY HH:MM.SS')}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                &nbsp;
              </td>
            </tr>
            <tr>
              <td>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    <td colSpan="2" style={{ fontFamily: "arial, sans-serif", background: "#B91F1B", border: "1px solid #000000", color: "#ffffff", textAlign: "center", fontWeight: "Bold", fontSize: "16px" }}>
                      INFORMAÇÃO DO CONTRATO
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Código Físico
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.physical_code}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Descrição do Objecto
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.object_of_the_contract}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Valor do Contrato
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.contract_value}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Moeda
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.currency?.currency_symbol}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Tipo de Procedimento
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.procedure_type?.name}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Modalidade do Contrato
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData.isConsortiumContract == "Yes" && contractData.isFrameworkAgreementContract == "Yes" ? "Consórcio | Acordo Quadro" : contractData.isConsortiumContract == "Yes" ? "Consórcio" :
                        contractData.isFrameworkAgreementContract == "Yes" ? "Acordo Quadro" : "No"
                      }
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Area Beneficiária
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.department?.name}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Modo de Pagamento
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.payment_mode?.name}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Data de início
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {moment(contractData?.start_date).format("DD-MM-YYYY")}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Data de conclusão
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {moment(contractData?.end_date).format("DD-MM-YYYY")}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Estado
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.status == "Active" ? "Activo" : contractData?.status == "Expired" ? "Expirado" : contractData?.status == "Competed" ? "Concluído" : ""}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" style={{ fontFamily: "arial, sans-serif", background: "#B91F1B", border: "1px solid #000000", color: "#ffffff", textAlign: "center", fontWeight: "Bold", fontSize: "16px" }}>
                      INFORMAÇÕES DO FORNECEDOR
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Nome do fornecedor
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.supplier?.provider_name}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      E-mail
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.supplier?.email}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Contacto Telefónico
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.supplier?.phone_number}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      NIF
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.supplier?.tax_number}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Endereço
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.supplier?.address}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" style={{ fontFamily: "arial, sans-serif", background: "#B91F1B", border: "1px solid #000000", color: "#ffffff", textAlign: "center", fontWeight: "Bold", fontSize: "16px" }}>
                      EXECUÇÃO DO CONTRATO
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Descrição da Execução
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.execution_description}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Execução Física (%)
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.execution_physical}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", borderRight: "none", BorderTop: "none", fontSize: "16px", textAlign: "right", padding: "10px", width: "30%" }}>
                      Execução Financeira {contractData?.currency?.currency_symbol}
                    </td>
                    <td style={{ fontFamily: "arial, sans-serif", border: "1px solid #000000", BorderTop: "none", fontSize: "16px", fontWeight: "bold", padding: "10px", width: "70%" }}>
                      {contractData?.calculated_amount}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </div>
      {/* pdf code end */}
    </>
  );
}