import { Fragment, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Dialog, Menu, Transition, Tab } from "@headlessui/react";
import LeftNavigation from "../../components/LeftNavigation/leftNavigation.js";
import Header from "../../components/Header/Header";
import ActionButton from "../../components/Other/ActionButton";
import { toast } from "react-toastify";
import { pluck, find } from "underscore";
import DeleteModel from "../../components/Other/DeleteModel";
import DataTable from "../../components/Table/MDataTable";
import { ClockIcon, CogIcon, CreditCardIcon, DocumentChartBarIcon, HomeIcon, QuestionMarkCircleIcon, ScaleIcon, ShieldCheckIcon, UserGroupIcon, XMarkIcon, PencilIcon, EyeIcon, XCircleIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import config from "../../utils/config.js";
import moment from "moment";
import { validateForm } from '../../utils/common.js';
import Cookies from "js-cookie";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];

const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ViewContest() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [contract_document, setFile] = useState({ preview: "", raw: "" });
  const [details, setDetails] = useState();
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);
  const [contestDocData, setContestDocData] = useState([]);
  const btnGroups = [
    { title: "Novo", type: "addNew", path: "/contest/NewContest" },
    { title: "Eliminar", type: "delete", path: "/contest" },
  ];
  const [open, setOpen] = useState(false);
  const [ids, setIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [contestId, setContestId] = useState("");
  const [cancellationData, setCancellationData] = useState({});
  const [fields, setFields] = useState({});
  const [selectedComID, setSelectedComID] = useState({});
  const [error, setError] = useState({});
  const [showPDF, setShowPDF] = useState("");
  const [tempStatus, setTempStatus] = useState("");
  const [framSrc, setFrameSrc] = useState("");

  const handleAction = async (type, id = "") => {
    if (ids.length < 1) {
      return toast.error("Selecione pelo menos um registro");
    } else if (type === "delete") {
      setShowModal(true);
      return;
    }

    const sendData = {
      id: ids,
      fields: { status: type === "makeActive" ? "publish" : type === "makeInactive" ? "published" : type },
    };

    const response = await config.postAPI({
      url: "contest/update",
      params: sendData,
    });

    if (!response?.payload?.success) {
      return toast.error("Falha na inserção do registro");
    } else {
      getDatas();
    }
    setIds([]);
    return toast.success("Registo actualizado com sucesso");
  };

  const handleDelete = async () => {
    const sendData = {
      ids,
      type: "delete",
    };
    const response = await config.deleteAPI({
      url: "contest/delete",
      params: { id: sendData?.ids },
    });

    if (!response?.payload?.success) {
      return toast.error("Falha na inserção do registro");
    }
    setShowModal(false);
    getDatas();
    setIds([]);
    return toast.success("Registo removido com sucesso");
  };

  function handleCancel() {
    setShowModal(false);
  }

  function getDatas() {
    config
      .getAPI({ url: "contest/list", params: {} })
      .then((data) => {
        if (data.payload) {
          setData(data.payload.rows);
          setShowLoader(false);
        }
        if (data?.error?.message) {
          history.push("/");
        }
      })
      .catch((error) => { });
  }

  function showPDF2() {
    setOpen3(true);
    setFrameSrc(config.staticPath + "contest/" + cancellationData?.contest_id + "/" + cancellationData?.file_name);
  }

  useEffect(() => {
    setShowLoader(true);
    getDatas();
  }, []);

  const info = async (id) => {
    try {
      const selectedData = find(data, function (num) {
        return num.id === id;
      });
      setDetails(selectedData);
      const response = await config.getAPI({
        url: "contestDocument/list",
        params: { contest_id: id }
      });
      setContestDocData(response.payload.rows);
      setOpen(true);
    } catch (error) {
      console.log("error", error);
    }
  }

  const showCancellationData = async (id) => {
    setContestId(id);
    setOpen2(true);
    const response = await config.getAPI({
      url: "contestCancellation/list",
      params: { contest_id: id }
    });
    if (response) {
      setCancellationData(response?.payload);
    }
  }

  const [data, setData] = useState([]);

  const onSelectionChange = (e) => {
    let idArr = pluck(e, "id");
    setIds(idArr);
  };

  const statusUpdate = async (contest_id) => {
    const response = await config.postAPI({
      url: "contest/update",
      params: {
        id: contest_id,
        fields: { status: "published" }
      },
    });

    if (!response?.payload?.success) {
      setShowLoader(false);
      return toast.error("Falha na inserção do registro");
    } else {
      getDatas();
      setShowLoader(false);
    }
    return toast.success("Registo actualizado com sucesso");
  }

  const checkQDData = async (ci_number) => {
    const response = await config.getAPI({
      url: "contest/list-qualification-candidate-docs",
      params: { ci_number },
    });
    const QDData = response?.payload?.tripQCIDs;
    if (QDData.length > 0) {
      const isNotApproved = find(QDData, function (ele) { return ele.status !== "Approved" })
      return isNotApproved
    }
  }

  const checkEVData = async (ci_number) => {
    const response = await config.getAPI({
      url: "contest/list-qualification-candidate-docs",
      params: { ci_number },
    });
    const response2 = await config.getAPI({
      url: "contest/list-evaluation-proposal-data",
      params: { trip_id: response.payload?.trip_id },
    });
    const evData = response2?.payload;
    if (evData.length > 0) {
      const isNotApproved = find(evData, function (ele) { return ele.status !== "Approved" })
      return isNotApproved
    }
  }

  function handleChange(e, field) {
    if (field == "competitor_id") {
      setFields({ ...fields, [field]: e.value });
      setSelectedComID(e)
    } else if (field == "complaint_date") {
      setFields({ ...fields, [field]: new Date(e) });
    } else if (field == "cancellation_pdf") {
      setFields({ ...fields, [field]: e.target.files[0] })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }

  const handleStatusUpdate = async (contest_id, procedure_type_id, ci_number) => {
    try {
      setShowLoader(true);
      if (ci_number) {
        const ptData = await config.getAPI({
          url: "procedureType/getdatabyId",
          params: { id: procedure_type_id }
        });
        const contestTab = (JSON.parse(ptData?.payload?.contest_tabs));
        if (contestTab.length > 0) {
          if (contestTab.length == 1) {
            if (contestTab[0]?.value == "qualification_of_competitors_01") {
              const responseQD = await checkQDData(ci_number);
              if (!responseQD) {
                await statusUpdate(contest_id);
              } else {
                setShowLoader(false)
                return toast.error("Verifique primeiramente e aprove os critérios de avaliação para prosseguir");
              }
            } else {
              const responseEV = await checkEVData(ci_number);
              if (!responseEV) {
                await statusUpdate(contest_id)
              } else {
                setShowLoader(false)
                return toast.error("Verifique primeiramente e aprove os critérios de avaliação para prosseguir");
              }
            }
          } else if (contestTab.length == 2) {
            const responseQD = await checkQDData(ci_number);
            const responseEV = await checkEVData(ci_number);
            if (!responseQD && !responseEV) {
              await statusUpdate(contest_id)
            } else if (responseQD || responseEV) {
              setShowLoader(false)
              return toast.error("Verifique primeiramente e aprove os critérios de avaliação para prosseguir");
            }
          }
        } else {
          setShowLoader(false);
          return toast.error("Verifique primeiramente e aprove os critérios de avaliação para prosseguir");
        }
      } else {
        setShowLoader(false);
        return toast.error("Verifique primeiramente e aprove os critérios de avaliação para prosseguir");
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const submitCancellation = async () => {
    try {
      const requiredFields = [
        "cancellation_pdf",
        "reason"
      ];
      const sendData = { cancellation_pdf: fields.cancellation_pdf, reason: fields.cancellation_reason };
      const validationResponse = validateForm(sendData, requiredFields);
      setError(validationResponse?.errors);
      if (validationResponse.isValid) {
        let formData = new FormData();
        formData.append("cancellation_pdf", fields.cancellation_pdf);
        formData.append("reason", fields.cancellation_reason);
        formData.append("contest_id", contestId);
        formData.append("user_id", parseInt(Cookies.get("user_id")));
        formData.append("table_name", "user");
        const response = await config.postFormDataAPI({
          url: "contestCancellation/create",
          params: formData
        });
        if (response) {
          setOpen2(false);
          setFields({ ...fields, cancellation_reason: "", cancellation_pdf: "" })
          setShowPDF("")
        }
        return toast.success("seu cancelamento adicionado");
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const columns = [
    { title: "Referência do Concurso", field: "contest_reference" },
    {
      title: "Descrição do objecto",
      field: "description_of_contest_type"
    },
    {
      title: "Tipo do Procedimento",
      field: "procedure_type",
      render: (rowData) => <> {rowData?.procedure_type?.name}</>,
    },
    {
      title: "Data de registo",
      field: "createdAt",
      render: (rowData) => (rowData = moment(rowData.createdAt).format("DD-MM-YYYY")),
    },
    {
      title: "Estado",
      field: "status",
      render: (rowData) => (
        <span
          className={
            rowData.status === "published"
              ? "inline-flex items-center px-4 py-1 rounded-full text-basefont-medium bg-yellow-200 text-yellow-800 capitalize"
              : rowData.status === "publish"
                ? "inline-flex items-center px-4 py-1 rounded-full text-basefont-medium bg-yellow-200 text-yellow-800 capitalize"
                : rowData.status === "in_evaluation_committee" ?
                  "inline-flex items-center px-4 py-1 rounded-full text-basefont-medium bg-orange-200 text-orange-800 capitalize"
                  : rowData.status === "in_dispute" ?
                    "inline-flex items-center px-4 py-1 rounded-full text-basefont-medium bg-orange-200 text-orange-800 capitalize"
                    : rowData.status === "finished" ? "inline-flex items-center px-4 py-1 rounded-full text-basefont-medium bg-indigo-200 text-indigo-800 capitalize"
                      :
                      "inline-flex items-center px-4 py-1 rounded-full text-basefont-medium bg-red-200 text-red-800 capitalize"
          }
        >
          {
            rowData.status === "published" ? ("Publicado") : rowData.status === "publish" ? ("Publicar") : rowData.status === "in_evaluation_committee" ? "Em CA" : rowData.status === "finished" ? "Finalizado" : rowData.status === "in_dispute" ? " Em impugnação" : rowData.status === "under_evaluation" ? "Em Avaliação" :
              rowData.status === "completed_ca" ? "Concluido CA" : rowData.status === "under_subscription" ? "Em Assinatura" : rowData.status === "in_court_of_auditors" ? "Em Tribunal de Contas" :
                rowData.status === "canceled" ? "Cancelado" :
                  (
                    <XCircleIcon
                      className="w-6 red-link cursor-pointer inline-block relative top-0"
                      aria-hidden="true"
                    />
                  )
          }
        </span >
      ),
    },
    {
      title: "Acções",
      render: (rowData) => (
        <div className="flex">
          {
            rowData.status === "publish" ?
              <Link
                className="text-indigo-600 hover:text-indigo-900 mr-4 mt-1"
                to={`/contest/EditContest/${rowData.id}`}
              >
                <PencilIcon
                  className="flex-shrink-0 h-5 w-5 red-link"
                  aria-hidden="true"
                />
              </Link> : ""}
          <button
            className="text-indigo-600 hover:text-indigo-900 focus:outline-none mr-4"
            onClick={() => info(rowData.id)}
          >
            <EyeIcon
              className="flex-shrink-0 h-5 w-5 red-link"
              aria-hidden="true"
            />
          </button>

          <button
            className="hover:text-indigo-900 focus:outline-none"
            // onClick={() => setOpen2(true)}
            onClick={() => showCancellationData(rowData.id)}
          >
            <XCircleIcon
              className="flex-shrink-0 h-6 w-6 red-link"
              aria-hidden="true"
            />
          </button>


          {/* {
            rowData.status !== "published" ?
              <div className="inline-block self-center relative top-1 ml-2">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon
                        className="h-6 w-6 red-link"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex px-4 py-2 text-sm"
                              )}
                              onClick={() =>
                                handleStatusUpdate(rowData?.id, rowData?.procedure_type_id, rowData?.ci_number)
                              }
                            >
                              <ShieldCheckIcon
                                className="mr-3 h-5 w-5 red-link"
                                aria-hidden="true"
                              />
                              <span>Publicado</span>
                            </div>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div> : ""} */}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          div
          className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col"
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1 pb-8">
            <div className="mt-8">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="dark:bg-black">
                  <div className="py-5 flex items-center justify-between">
                    <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
                      Concursos
                    </h1>
                    <div className="flex items-center gap-3">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="flex items-center rounded-full border-2 py-2 px-2 dark:border-[#00FF00] border-[#EA042B] outline-none">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon
                              className="h-5 w-5 dark:text-white red-text"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-30 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              {btnGroups.map((btn) => {
                                return (
                                  <ActionButton
                                    key={btn.id}
                                    btnTitle={btn.title}
                                    btnType={btn.type}
                                    btnPath={btn.path}
                                    handleAction={handleAction}
                                  />
                                );
                              })}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <DeleteModel
                    mode={showModal}
                    handleDelete={handleDelete}
                    handleCancel={handleCancel}
                  />
                  <div className="dark:bg-black">
                    <div className="flex flex-col">
                      <div className="datatable">
                        <DataTable
                          columns={columns}
                          data={data}
                          disabledCheckbox={false}
                          onSelectionChange={onSelectionChange}
                          showLoader={showLoader}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 font-medium text-gray-900 mb-5"
                    >
                      Detalhes Concursos
                    </Dialog.Title>
                    <div className="pb-3">
                      <Tab.Group as="div">
                        <div className="border-b border-gray-200">
                          <Tab.List className="-mb-px flex space-x-8">
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? "border-red-600 text-red-600"
                                    : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                  "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                )
                              }
                            >
                              Informação geral
                            </Tab>
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? "border-red-600 text-red-600"
                                    : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                  "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                )
                              }
                            >
                              Peças do Concurso
                            </Tab>
                          </Tab.List>
                        </div>
                        <Tab.Panels as={Fragment}>
                          <Tab.Panel className="mt-6">
                            <h3 className="sr-only">Informação geral</h3>
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  Referência do Concurso
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details && details.contest_reference}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  Descrição do objecto
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details && details.description_of_contest_type}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  Tipo de Procedimento
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details && details.procedure_type
                                    ? details.procedure_type.name
                                    : ""}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  Peças do Concurso
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  Anuncio
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-base font-bold text-gray-500">
                                  Data de submissão
                                </dt>
                                <dd className="mt-1 text-base text-gray-900">
                                  {details && details
                                    ? moment(details.createdAt).format(
                                      "DD-MM-YYYY"
                                    )
                                    : ""}
                                </dd>
                              </div>
                            </dl>
                          </Tab.Panel>

                          <Tab.Panel className="mt-6">
                            <h3 className="sr-only">Peças do Concurso</h3>
                            {
                              contestDocData.length > 0 ?
                                contestDocData.map((singleRecord) => {
                                  return (
                                    <div className="flex items-center space-x-6 border-b border-gray-200 py-10">
                                      <iframe
                                        style={{ height: "45vh", display: "block" }}
                                        src={config.staticPath + "contest/" + singleRecord.contest_id + "/" + singleRecord?.file_name}
                                        title="document"
                                        className="w-2/6 mx-auto mt-5"
                                      />
                                      <div className="flex flex-auto flex-col">
                                        <div>
                                          <h4 className="font-medium text-gray-900">
                                            <p>{singleRecord?.contract_doc?.name}</p>
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                                : ""
                            }
                          </Tab.Panel>
                        </Tab.Panels>
                      </Tab.Group>
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={open2} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen2}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={() => setOpen2(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 font-medium text-gray-900 mb-5"
                    >
                      Cancelamento do concurso
                    </Dialog.Title>
                    <div className="py-5">
                      {
                        cancellationData ?
                          <div className="gt_pdf_outer overflow-hidden shadow md:rounded-lg gt_borderbox">
                            <div className=''>
                              <div className="flex flex-auto flex-col gt_border_bt">
                                <h3 class="font-bold bg-gray-50 gt_pdfborder py-2">Motivo : <span className='py-2 gt_palign font-normal'>{cancellationData.reason}</span></h3>
                              </div>
                              <div className="flex flex-auto flex-col gt_border_bt">
                                <h3 class="font-bold bg-gray-50 gt_pdfborder py-2">Cancelado por : <span className='py-2 gt_palign font-normal'>{cancellationData?.user_name}</span></h3>
                              </div>
                            </div>
                            <div className='flex items-center gap-5'>
                              <div className='gt_pdfframe'>
                                <h3 class="font-bold bg-gray-50 gt_pdfborder py-2">Comprovativo :</h3>
                              </div>
                              <DocumentTextIcon className="flex-shrink-0 h-5 w-5 red-link cursor-pointer" aria-hidden="true" onClick={() => showPDF2()} />
                            </div>
                          </div> :
                          (tempStatus === "finished" || tempStatus === "canceled") ?
                            <table className="min-w-full divide-y divide-gray-300 border">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th scope="col" className="px-3 py-3.5 text-left text-base font-semibold text-gray-900">
                                    Colaborador
                                  </th>
                                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-6">
                                    Motivo
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                <tr>
                                  <td align='center' colSpan={2} className='py-8'>Nenhum registo encontrado</td>
                                </tr>
                              </tbody>
                            </table>
                            :
                            <>
                              <div className="space-y-8 divide-y divide-gray-200">
                                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                  <div className="sm:col-span-6">
                                    <label
                                      htmlFor="last-name"
                                      className="block text-base font-medium text-gray-700"
                                    >
                                      Motivo
                                    </label>
                                    <div className="mt-1">
                                      <textarea
                                        id="about"
                                        name="about"
                                        rows={3}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        defaultValue={""}
                                        onChange={(e) => handleChange(e, "cancellation_reason")}
                                      />
                                      {error?.reason && (
                                        <span className="text-xs text-red-600 capitalize">
                                          {error?.reason}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className='sm:col-span-6 mb-4'>
                                    {showPDF !== "" ? (
                                      <iframe
                                        style={{ height: "45vh", display: "block" }}
                                        src={showPDF}
                                        title="document"
                                        className="w-4/6 mx-auto mt-5"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md sm:col-span-6">
                                    <div className="space-y-1 text-center">
                                      {contract_document.preview ? (
                                        <img
                                          src={"/img/done.svg"}
                                          alt="Profile"
                                          className="mx-auto h-12 w-12 text-gray-400"
                                        />
                                      ) : (
                                        <img
                                          src={"/img/pdf.png"}
                                          alt="Profile"
                                          className="mx-auto h-12 w-12 text-gray-400"
                                        />
                                      )}
                                      <div className="flex text-sm text-gray-600">
                                        <label
                                          htmlFor="file-upload"
                                          className="relative cursor-pointer rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none"
                                        >
                                          <span>Submeta o comprovativo .PDF</span>
                                          <input
                                            accept="application/pdf"
                                            id="file-upload"
                                            name="file-upload"
                                            type="file"
                                            className="sr-only"
                                            onChange={(e) => (
                                              setShowPDF(URL.createObjectURL(e.target.files[0])),
                                              handleChange(e, "cancellation_pdf"))}
                                          />
                                        </label>
                                      </div>
                                      <p className="text-xs text-gray-500">
                                        PDF até 10MB
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {error?.cancellation_pdf && (
                                  <span className="text-xs text-red-600 capitalize">
                                    {error?.cancellation_pdf}
                                  </span>
                                )}
                              </div>
                              <div className="pt-5">
                                <div className="flex justify-end">
                                  <button
                                    type="button"
                                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
                                    onClick={() => setOpen2(false)}
                                  >
                                    Cancelar
                                  </button>
                                  <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none btn-link bg-skin-primary text-skin-white"
                                    onClick={() => submitCancellation()}
                                  >
                                    Submeter
                                  </button>
                                </div>
                              </div>
                            </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={open3} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen3}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpen3(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900 mb-5"
                    >
                      Comprovativo
                    </Dialog.Title>
                    <div className="py-5">
                      {framSrc !== "" && (
                        <iframe
                          style={{ height: "45vh", display: "block" }}
                          src={framSrc}
                          title="document"
                          className="w-4/6 mx-auto mt-5"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}