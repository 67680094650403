import React from "react";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import LeftNavigation from "../../components/LeftNavigation/leftNavigation.js";
import "@kenshooui/react-multi-select/dist/style.css";
import Header from "../../components/Header/Header";
import {
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
  CheckIcon,
  TrashIcon,
  PlusIcon,
  PencilIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import config from "../../../src/utils/config";
import { toast } from "react-toastify";
import { validateForm } from "../../utils/common.js";
import { pluck, filter, find } from "underscore";
import BtnRedirect from "../../components/Form/Button/BtnRedirect";
import DataTable from "../../components/MDataTable";
import moment from "moment/moment.js";
import DeleteModel from "../../components/Other/DeleteModel";
import Select from "react-select";
import Cookies from "js-cookie";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];
const ci = [{ name: "NÃO" }, { name: "SIM" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function UpdateSupplier({ history }) {
  const { id } = useParams();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tab, setTab] = useState(1);
  const steps1 = [
    { id: "01", name: "Informação Geral", href: "#", status: "current" },
    { id: "02", name: "Informação Bancária", href: "#", status: "upcoming" },
  ];
  const steps2 = [
    { id: "01", name: "Informação Geral", href: "#", status: "complete" },
    { id: "02", name: "Informação Bancária", href: "#", status: "current" },
  ];
  const [supplierTypeList, setSupplierTypeList] = useState();
  const [countryList, setCountryList] = useState();
  const [fields, setFields] = useState();
  const [error, setError] = useState({});
  const [bankList, setBankList] = useState();
  const [bankDetailsDiv, setBankDetailsDiv] = useState([
    {
      bank_id: "",
      swift: "",
      iban: "",
      routing_number: "",
    },
  ]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedBankID, setSelectedBankID] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(true)
    getSupplierTypeList();
    getCountryList();
    getBankList();
    getSupplierData();
    getSupplierBankData();
  }, []);

  function getSupplierTypeList() {
    config
      .getAPI({ url: "supplierType/list", params: { status: "Active" } })
      .then((data) => {
        if (data.payload) {
          setSupplierTypeList(data.payload.rows);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getCountryList() {
    config
      .getAPI({ url: "country/list", params: {} })
      .then((data) => {
        if (data?.payload) {
          let optionsArr = [];
          data?.payload?.rows.map(async (row) => {
            optionsArr.push({ value: row.id, label: row.name });
          });
          setCountryList(optionsArr);
          // setCountryList(data.payload.rows)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getBankList() {
    config.getAPI({ url: "bank/list", params: { status: "Active" } })
      .then((data) => {
        if (data.payload) {
          data.payload.rows.map((element) => {
            element.value = element.id;
            element.label = element.name;
          });
          setBankList(data.payload.rows);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  async function getSupplierData() {
    setShowLoader(true);
    await config
      .getAPI({ url: "supplier/getdatabyId", params: { id: id } })
      .then((data) => {
        if (data.payload) {
          setFields(data.payload);
          if (data?.payload?.country) {
            setSelectedOption({
              value: data?.payload?.country.id,
              label: data?.payload?.country.name,
            });
          }
          setShowLoader(false)
        }
        if (data?.error?.message) {
          history.push("/");
          // return toast.error(data?.error?.message)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  const handleGeneralInfoChange = (e, field) => {
    if (field === "country_id") {
      setSelectedOption(e);
      setFields({ ...fields, [field]: e.value });
    } else {
      setFields({ ...fields, [field]: e.target.value });
    }
    // setFields({ ...fields, [field]: e.target.value })
  };

  const handleSubmitGeneralInfo = async (type) => {
    const includedFields = [
      "supplier_type_id",
      "provider_name",
      "email",
      "phone_number",
      "country_id",
      // "address",
      "tax_number",
      "contact_person_name",
      "contact_person_email",
      "contact_person_phone_number",
      "status",
    ];
    const validationResponse = validateForm(fields, includedFields);
    setError(validationResponse?.errors);
    if (validationResponse?.isValid) {
      setShowLoader(true);
      config.getAPI({ url: 'supplier/check-tax-number', params: { provider_id: id, tax_number: fields?.tax_number } }).then(async (data1) => {
        if (data1.payload) {
          setShowLoader(false)
          return toast.error("Este registo já existe");
        } else {
          const insertResponse = await config.postAPI({
            url: "supplier/update",
            params: { id, fields, user_id: Cookies.get('user_id') },
          });
          setShowLoader(false)

          if (insertResponse?.response?.data?.error?.message) {
            return toast.error(insertResponse?.response?.data?.error?.message);
          }

          if (type === "next") {
            setTab(2);
            return toast.success("Registo criado com sucesso");
          } else {
            setTimeout(() => {
              return toast.success("Registo criado com sucesso");
            }, 200);
            history.push("/provider/ViewProvider");
          }
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  };

  async function getSupplierBankData() {
    await config
      .getAPI({ url: "supplierBank/getdatabyId", params: { supplier_id: id } })
      .then((data) => {
        if (data?.payload) {
          setData(data?.payload);
          // let tempData = [];
          // data.payload.map((single) => {
          //   let obj = {
          //     supplier_type_id: single.supplier_type_id,
          //     bank_id: single.bank_id,
          //     swift: single.swift,
          //     iban: single.iban,
          //     routing_number: single.routing_number,
          //     supplier_bank_id: single.id
          //   };
          //   tempData.push(obj);
          // });
          // setBankDetailsDiv(tempData)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  const addBankDetailsDiv = () => {
    setBankDetailsDiv([
      ...bankDetailsDiv,
      {
        bank_id: "",
        swift: "",
        iban: "",
        routing_number: "",
      },
    ]);
  };

  const removeBankDetailsDiv = (divIndex) => {
    const response = bankDetailsDiv?.filter(
      (element, index) => index !== divIndex
    );
    setBankDetailsDiv(response);
  };
  const [bankInfo, setBankInfo] = useState(false);
  const handleChangeBankDetails = (e, field) => {
    if (field === "bank_id") {
      const swiftData = find(bankList, function (singleBank) {
        return singleBank.id === e.value;
      });
      let swift = swiftData.swift;
      const setData = {
        ...bankInfo,
        swift,
        bank_id: e?.value,
      };
      setBankInfo(setData);
      setSelectedBankID(e);
    } else {
      const setData = {
        ...bankInfo,
        [field]: e?.target?.value,
      };
      setBankInfo(setData);
    }
  };

  // const handleChangeBankDetails = (e, field, divIndex) => {
  //   if (field === "bank_id") {
  //     const swiftData = find(bankList, function (singleBank) { return singleBank.id === e.target.value });
  //     bankDetailsDiv[divIndex]['swift'] = swiftData.swift
  //     setBankDetailsDiv([...bankDetailsDiv])
  //   }
  //   bankDetailsDiv[divIndex][field] = e.target.value
  //   setBankDetailsDiv([...bankDetailsDiv])
  // }

  const updateSupplierBankDetails = async () => {
    try {
      let bankId = bankInfo?.id;

      const sendData = {
        supplier_id: id,
        // bankDetailsDiv
        bankInfo,
      };
      const includedFields = ["bank_id", "swift", "iban"];
      const validationResponse = validateForm(bankInfo, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        const insertResponse = await config.postAPI({
          url: "supplierBank/update",
          params: sendData,
        });
        if (insertResponse?.response?.data?.error?.message) {
          return toast.error(insertResponse?.response?.data?.error?.message);
        }

        if (bankId) {
          // history.push("/provider/ViewProvider")
          setOpen(false);
          await getSupplierBankData();
        } else {
          setOpen(false);
          setBankInfo({
            id: "",
            bank_id: "",
            swift: "",
            iban: "",
            routing_number: "",
            supplier_bank_id: "",
          });
          await getSupplierBankData();
        }
        setTimeout(() => {
          return toast.success("Registo criado com sucesso");
        }, 200);
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  const [ids, setIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [viewRecord, setViewRecord] = useState("");

  const onSelectionChange = (e) => {
    let idArr = pluck(e, "id");
    setIds(idArr);
  };

  function handleCancel() {
    setShowModal(false);
  }

  const handleDelete = async () => {
    const sendData = {
      ids,
      type: "delete",
    };
    const response = await config.deleteAPI({
      url: "supplierBank/delete",
      params: { id: sendData?.ids },
    });

    if (!response?.payload?.success) {
      return toast.error("Falha na inserção do registro");
    }
    setShowModal(false);
    await getSupplierBankData();
    setIds([]);
    return toast.success("Registo removido com sucesso");
  };

  const handleAction = async (type, id = "") => {
    if (ids.length < 1 && id === "") {
      return toast.error("Selecione pelo menos um registro");
    } else if (type === "delete") {
      setShowModal(true);
      return;
    }
    const sendData = {
      id: ids,
      fields: {
        status:
          type === "makeActive"
            ? "Active"
            : type === "makeInactive"
              ? "Inactive"
              : type,
      },
    };
    const response = await config.postAPI({
      url: "supplierBank/update",
      params: sendData,
    });
    if (!response?.payload?.success) {
      return toast.error("Falha na inserção do registro");
    } else {
      await getSupplierBankData();
    }
    setIds([]);
    return toast.success("Registo actualizado com sucesso");
  };

  function getSingleRowData(data) {
    if (data) {
      setViewRecord(data);
    }
    setOpenView(true);
  }
  // useEffect(() => {
  // }, [setViewRecord])

  const columns = [
    {
      title: "Nome do Banco",
      field: "bank.name",
    },
    // { title: "SWIFT", field: "bank.swift" },
    {
      title: "IBAN",
      field: "iban",
    },
    // {
    //   title: "Routing Number",
    //   field: "routing_number",
    // },
    {
      title: "Acções",
      render: (rowData) => (
        <span className="flex">
          <button
            className="red-link focus:outline-none mr-3"
            onClick={() => {
              setOpen(true);
              setBankInfo({
                id: rowData?.id,
                bank_id: rowData.bank_id,
                swift: rowData.swift,
                iban: rowData.iban,
                routing_number: rowData.routing_number,
                supplier_bank_id: rowData.id,
              });
            }}
          >
            <PencilIcon
              className="flex-shrink-0 h-5 w-5"
              aria-hidden="true"
              onClick={() => setOpen(true)}
            />
          </button>

          <button
            className="red-link focus:outline-none mr-3"
            onClick={() => {
              setIds([rowData?.id]);
              handleAction("delete", rowData?.id);
            }}
          >
            <TrashIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          </button>
          <button
            className="red-link focus:outline-none"
            onClick={() => getSingleRowData(rowData)}
          >
            <EyeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
            {/* Edit  */}
          </button>
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1 pb-8">
            <div
              className="loader-img"
              style={{
                display: showLoader ? "block" : "none",
              }}
            ></div>
            <div className="mt-8">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="py-5 flex items-center justify-between">
                  <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
                    Editar Fornecedor
                  </h1>
                </div>

                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                  <div className="space-y-8">
                    {tab === 1 ? (
                      <div className="w-full">
                        <div className="mb-6">
                          <nav aria-label="Progress">
                            <ol
                              role="list"
                              className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
                            >
                              {steps1.map((step, stepIdx) => (
                                <li
                                  key={step.name}
                                  className="relative md:flex md:flex-1"
                                >
                                  {step.status === "complete" ? (
                                    <a
                                      href={step.href}
                                      className="group flex w-full items-center"
                                    >
                                      <span className="flex items-center px-6 py-4 text-base font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-600">
                                          <CheckIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                          />
                                        </span>
                                        <span className="ml-4 text-base font-medium text-gray-900">
                                          {step.name}
                                        </span>
                                      </span>
                                    </a>
                                  ) : step.status === "current" ? (
                                    <a
                                      href={step.href}
                                      className="flex items-center px-6 py-4 text-base font-medium"
                                      aria-current="step"
                                    >
                                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-red-600">
                                        <span className="text-red-600">
                                          {step.id}
                                        </span>
                                      </span>
                                      <span className="ml-4 text-base font-medium text-red-600">
                                        {step.name}
                                      </span>
                                    </a>
                                  ) : (
                                    <a
                                      href={step.href}
                                      className="group flex items-center"
                                    >
                                      <span className="flex items-center px-6 py-4 text-base font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                          <span className="text-gray-500 group-hover:text-gray-900">
                                            {step.id}
                                          </span>
                                        </span>
                                        <span className="ml-4 text-base font-medium text-gray-500 group-hover:text-gray-900">
                                          {step.name}
                                        </span>
                                      </span>
                                    </a>
                                  )}

                                  {stepIdx !== steps1.length - 1 ? (
                                    <>
                                      {/* Arrow separator for lg screens and up */}
                                      <div
                                        className="absolute top-0 right-0 hidden h-full w-5 md:block"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          className="h-full w-full text-gray-300"
                                          viewBox="0 0 22 80"
                                          fill="none"
                                          preserveAspectRatio="none"
                                        >
                                          <path
                                            d="M0 -2L20 40L0 82"
                                            vectorEffect="non-scaling-stroke"
                                            stroke="currentcolor"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </div>
                                    </>
                                  ) : null}
                                </li>
                              ))}
                            </ol>
                          </nav>
                        </div>
                        <form className="space-y-6" action="#" method="POST">
                          <div className="pt-1">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="last_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Tipo de Fornecedor{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <select
                                    id="country"
                                    name="country"
                                    autoComplete="country"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                    onChange={(e) =>
                                      handleGeneralInfoChange(
                                        e,
                                        "supplier_type_id"
                                      )
                                    }
                                    value={fields?.supplier_type_id}
                                  >
                                    <option>
                                      ---Seleccionar Fornecedor ---
                                    </option>
                                    {supplierTypeList?.map((singleST) => {
                                      return (
                                        <option value={singleST.id}>
                                          {singleST.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {error?.supplier_type_id && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.supplier_type_id}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Nome do Fornecedor{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="first_name"
                                    id="first_name"
                                    autoComplete="given-name"
                                    placeholder="Angocontas-Consul Financeira e informática, Lda"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                    onChange={(e) =>
                                      handleGeneralInfoChange(
                                        e,
                                        "provider_name"
                                      )
                                    }
                                    defaultValue={fields?.provider_name}
                                  />
                                  {error?.provider_name && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.provider_name}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  E-mail <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    autoComplete="given-email"
                                    placeholder="angocontas-Consul@gmail.com"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                    onChange={(e) =>
                                      handleGeneralInfoChange(e, "email")
                                    }
                                    defaultValue={fields?.email}
                                  />
                                  {error?.email && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.email}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="last_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Telefone | Telemóvel{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="number"
                                    name="phone"
                                    id="phone"
                                    autoComplete="given-number"
                                    placeholder="912246774"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                    onChange={(e) =>
                                      handleGeneralInfoChange(e, "phone_number")
                                    }
                                    defaultValue={fields?.phone_number}
                                  />
                                  {error?.phone_number && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.phone_number}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  País <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  {/* <select
                                    id="country"
                                    name="country"

                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                    onChange={(e) => handleGeneralInfoChange(e, "country_id")}
                                    value={fields?.country_id}
                                    isSearchable={true}
                                    autocomplete="on"
                                  >
                                    <option>---Seleccionar País---</option>
                                    {
                                      countryList?.map((singleCountry) => {
                                        return (
                                          <option value={singleCountry.id}>{singleCountry.name}</option>
                                        )
                                      })
                                    }
                                  </select> */}
                                  <Select
                                    value={selectedOption}
                                    placeholder="Seleccionar País"
                                    onChange={(e) =>
                                      handleGeneralInfoChange(e, "country_id")
                                    }
                                    options={countryList}
                                    isSearchable={true}
                                  />
                                  {error?.country_id && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.country_id}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-6">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Endereço
                                </label>
                                <div className="mt-1">
                                  <textarea
                                    id="address"
                                    name="address"
                                    rows={3}
                                    className="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                                    onChange={(e) =>
                                      handleGeneralInfoChange(e, "address")
                                    }
                                    defaultValue={fields?.address}
                                  />
                                  {error?.address && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.address}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  NIF <span className="mandatory">*</span>
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="nif"
                                    id="nif"
                                    autoComplete="given-nif"
                                    placeholder="5401137338"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                    onChange={(e) =>
                                      handleGeneralInfoChange(e, "tax_number")
                                    }
                                    defaultValue={fields?.tax_number}
                                  />
                                  {error?.tax_number && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.tax_number}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Nome da Pessoa de Contacto
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="contact_name"
                                    id="contact_name"
                                    autoComplete="given-contact-name"
                                    placeholder="917496314"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                    onChange={(e) =>
                                      handleGeneralInfoChange(
                                        e,
                                        "contact_person_name"
                                      )
                                    }
                                    defaultValue={fields?.contact_person_name}
                                  />
                                  {error?.contact_person_name && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.contact_person_name}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  E-mail da Pessoa de Contacto
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="contact_email"
                                    id="contact_email"
                                    autoComplete="given-contact-email"
                                    placeholder="angocontas-Consul@gmail.com"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                    onChange={(e) =>
                                      handleGeneralInfoChange(
                                        e,
                                        "contact_person_email"
                                      )
                                    }
                                    defaultValue={fields?.contact_person_email}
                                  />
                                  {error?.contact_person_email && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.contact_person_email}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="last_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Telefone | Telemóvel da Pessoa de Contacto
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="number"
                                    name="contact_person"
                                    id="contact_person"
                                    autoComplete="given-contact-person"
                                    placeholder="917496314"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                    onChange={(e) =>
                                      handleGeneralInfoChange(
                                        e,
                                        "contact_person_phone_number"
                                      )
                                    }
                                    defaultValue={
                                      fields?.contact_person_phone_number
                                    }
                                  />
                                  {error?.contact_person_phone_number && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.contact_person_phone_number}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-base font-medium text-gray-700"
                                >
                                  Estado
                                </label>
                                <div className="mt-1">
                                  <select
                                    id="state"
                                    name="state"
                                    autoComplete="state"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                    onChange={(e) =>
                                      handleGeneralInfoChange(e, "status")
                                    }
                                    value={fields?.status}
                                  >
                                    {/* <option>---Seleccionar estado ---</option> */}
                                    <option>Activo</option>
                                    <option>Inactivo</option>
                                    <option>Arquivar</option>
                                  </select>
                                  {error?.status && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.status}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pt-5">
                            <div className="flex justify-end">
                              <BtnRedirect
                                path={"/provider/ViewProvider"}
                                title={"Cancelar"}
                              />

                              <button
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                onClick={() => handleSubmitGeneralInfo("")}
                              >
                                Guardar e Sair
                              </button>
                              <button
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                onClick={() => handleSubmitGeneralInfo("next")}
                              >
                                Próximo
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    ) : null}

                    {tab === 2 ? (
                      <div className="w-full">
                        <div>
                          <nav aria-label="Progress">
                            <ol
                              role="list"
                              className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
                            >
                              {steps2.map((step, stepIdx) => (
                                <li
                                  key={step.name}
                                  className="relative md:flex md:flex-1"
                                >
                                  {step.status === "complete" ? (
                                    <a
                                      href={step.href}
                                      className="group flex w-full items-center"
                                    >
                                      <span className="flex items-center px-6 py-4 text-base font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-600">
                                          <CheckIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                          />
                                        </span>
                                        <span className="ml-4 text-base font-medium text-gray-900">
                                          {step.name}
                                        </span>
                                      </span>
                                    </a>
                                  ) : step.status === "current" ? (
                                    <a
                                      href={step.href}
                                      className="flex items-center px-6 py-4 text-base font-medium"
                                      aria-current="step"
                                    >
                                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-red-600">
                                        <span className="text-red-600">
                                          {step.id}
                                        </span>
                                      </span>
                                      <span className="ml-4 text-base font-medium text-red-600">
                                        {step.name}
                                      </span>
                                    </a>
                                  ) : (
                                    <a
                                      href={step.href}
                                      className="group flex items-center"
                                    >
                                      <span className="flex items-center px-6 py-4 text-base font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                          <span className="text-gray-500 group-hover:text-gray-900">
                                            {step.id}
                                          </span>
                                        </span>
                                        <span className="ml-4 text-base font-medium text-gray-500 group-hover:text-gray-900">
                                          {step.name}
                                        </span>
                                      </span>
                                    </a>
                                  )}

                                  {stepIdx !== steps2.length - 1 ? (
                                    <>
                                      {/* Arrow separator for lg screens and up */}
                                      <div
                                        className="absolute top-0 right-0 hidden h-full w-5 md:block"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          className="h-full w-full text-gray-300"
                                          viewBox="0 0 22 80"
                                          fill="none"
                                          preserveAspectRatio="none"
                                        >
                                          <path
                                            d="M0 -2L20 40L0 82"
                                            vectorEffect="non-scaling-stroke"
                                            stroke="currentcolor"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </div>
                                    </>
                                  ) : null}
                                </li>
                              ))}
                            </ol>
                          </nav>
                        </div>
                        <div className="pt-1">
                          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 py-4">
                            <div className="pb-5 border-b border-gray-200 col-span-6">
                              <div className="pt-1">
                                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 pb-3">
                                  <div className="sm:col-span-6 flex justify-end">
                                    {/* <button
                                        type="button"
                                        className="ml-3 mt-0 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-skin-primary hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 xl-admin-btn"
                                        onClick={() => addBankDetailsDiv()}
                                      >
                                        <PlusIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                      </button> */}
                                  </div>
                                </div>
                                {/* {
                                    bankDetailsDiv?.map((singleDiv, index) => {
                                      return (
                                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 rounded-lg border border-gray-200 bg-gray-50 p-6">
                                          <div className="sm:col-span-3">
                                            <label
                                              htmlFor="last_name"
                                              className="block text-base font-medium text-gray-700"
                                            >
                                              Nome do Banco
                                            </label>
                                            <div className="mt-1">
                                              <select
                                                id="country"
                                                name="country"
                                                autoComplete="country"
                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                                onChange={(e) => handleChangeBankDetails(e, 'bank_id', index)}
                                                value={singleDiv.bank_id}
                                              >
                                                <option>---Seleccionar Banco ---</option>
                                                {bankList?.map((singleBank) => {
                                                  return (
                                                    <option value={singleBank.id}>{singleBank.name}</option>
                                                  )
                                                })}
                                              </select>
                                            </div>
                                          </div>

                                          <div className="sm:col-span-3">
                                            <label
                                              htmlFor="last_name"
                                              className="block text-base font-medium text-gray-700"
                                            >
                                              SWIFT
                                            </label>
                                            <div className="mt-1">
                                              <input
                                                disabled
                                                type="text"
                                                name="first_name"
                                                id="first_name"
                                                autoComplete="given-name"
                                                placeholder='BAIPAOLUXXX'
                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                                defaultValue={singleDiv.swift}
                                              />
                                            </div>
                                          </div>

                                          <div className="sm:col-span-3">
                                            <label
                                              htmlFor="last_name"
                                              className="block text-base font-medium text-gray-700"
                                            >
                                              IBAN
                                            </label>
                                            <div className="mt-1">
                                              <input
                                                type="text"
                                                name="first_name"
                                                id="first_name"
                                                autoComplete="given-name"
                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                                onChange={(e) => handleChangeBankDetails(e, 'iban', index)}
                                                defaultValue={singleDiv.iban}
                                              />
                                            </div>
                                          </div>

                                          <div className="sm:col-span-3">
                                            <label
                                              htmlFor="last_name"
                                              className="block text-base font-medium text-gray-700"
                                            >
                                              Routing Number
                                            </label>
                                            <div className="mt-1">
                                              <input
                                                type="text"
                                                name="first_name"
                                                id="first_name"
                                                autoComplete="given-name"
                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                                onChange={(e) => handleChangeBankDetails(e, 'routing_number', index)}
                                                defaultValue={singleDiv.routing_number}
                                              />
                                            </div>
                                          </div>

                                          {
                                            index > 0 ?
                                              <div className="sm:col-span-6 flex justify-end">
                                                <button
                                                  type="button"
                                                  className="ml-3 mt-0 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-skin-primary hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 xl-admin-btn"
                                                  onClick={() => removeBankDetailsDiv(index)}
                                                >
                                                  <TrashIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                              </div> : ""
                                          }
                                        </div>
                                      )
                                    })
                                  } */}
                                <DeleteModel
                                  mode={showModal}
                                  handleDelete={handleDelete}
                                  handleCancel={handleCancel}
                                />
                                <div className="flex justify-end">
                                  <button
                                    type="button"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                    onClick={() => {
                                      setOpen(true);
                                    }}
                                  >
                                    Novo
                                  </button>
                                </div>
                                <div className="sm:col-span-6 mt-6">
                                  <DataTable
                                    columns={columns}
                                    data={data}
                                    disabledCheckbox={false}
                                    onSelectionChange={onSelectionChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              type="button"
                              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                              onClick={() => setTab(1)}
                            >
                              Retroceder
                            </button>
                            <button
                              type="button"
                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                              // onClick={() => updateSupplierBankDetails()}
                              onClick={() =>
                                history.push("/provider/ViewProvider")
                              }
                            >
                              Submeter
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900 mb-5"
                    >
                      Editar Fornecedor
                    </Dialog.Title>
                    <div className="pt-1">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 py-4">
                        <div className="pb-5 border-b border-gray-200 col-span-6">
                          <form className="space-y-6" action="#" method="POST">
                            <div className="helps">
                              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 rounded-lg border border-gray-200 bg-gray-50 p-6">
                                <div className="sm:col-span-3">
                                  <label
                                    htmlFor="last_name"
                                    className="block text-base font-medium text-gray-700"
                                  >
                                    Nome do Banco
                                  </label>
                                  <div className="mt-1">
                                    <Select
                                      value={selectedBankID}
                                      placeholder="Seleccionar Banco"
                                      onChange={(e) =>
                                        handleChangeBankDetails(e, "bank_id")
                                      }
                                      options={bankList}
                                      isSearchable={true}
                                    />
                                    {error?.bank_id && (
                                      <span className="text-xs text-red-600 capitalize">
                                        {error?.bank_id}
                                      </span>
                                    )}
                                  </div>
                                </div>

                                <div className="sm:col-span-3">
                                  <label
                                    htmlFor="last_name"
                                    className="block text-base font-medium text-gray-700"
                                  >
                                    SWIFT
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      disabled
                                      type="text"
                                      name="swift"
                                      id="swift"
                                      autoComplete="swift"
                                      placeholder="BAIPAOLUXXX"
                                      className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${error?.swift && "border-red-600"
                                        }`}
                                      defaultValue={bankInfo?.swift}
                                    />
                                    {error?.swift && (
                                      <span className="text-xs text-red-600 capitalize">
                                        {error?.swift}
                                      </span>
                                    )}
                                  </div>
                                </div>

                                <div className="sm:col-span-3">
                                  <label
                                    htmlFor="last_name"
                                    className="block text-base font-medium text-gray-700"
                                  >
                                    IBAN
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      type="text"
                                      name="iban"
                                      id="iban"
                                      autoComplete="iban"
                                      onChange={(e) =>
                                        handleChangeBankDetails(
                                          e,
                                          "iban",
                                          "index"
                                        )
                                      }
                                      defaultValue={bankInfo?.iban}
                                      className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${error?.iban && "border-red-600"
                                        }`}
                                    />
                                    {error?.iban && (
                                      <span className="text-xs text-red-600 capitalize">
                                        {error?.iban}
                                      </span>
                                    )}
                                  </div>
                                </div>

                                <div className="sm:col-span-3">
                                  <label
                                    htmlFor="last_name"
                                    className="block text-base font-medium text-gray-700"
                                  >
                                    Routing Number
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      type="text"
                                      name="routing_number"
                                      id="routing_number"
                                      autoComplete="routing_number"
                                      onChange={(e) =>
                                        handleChangeBankDetails(
                                          e,
                                          "routing_number"
                                        )
                                      }
                                      defaultValue={bankInfo?.routing_number}
                                      className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${error?.routing_number &&
                                        "border-red-600"
                                        }`}
                                    />
                                    {error?.routing_number && (
                                      <span className="text-xs text-red-600 capitalize">
                                        {error?.routing_number}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="pt-5">
                              <div className="flex justify-end">
                                <button
                                  type="button"
                                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                  onClick={() => {
                                    setOpen(false);
                                    setTab(1);
                                  }}
                                >
                                  Cancelar
                                </button>
                                <button
                                  type="button"
                                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                  onClick={() => updateSupplierBankDetails()}
                                >
                                  Submeter
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={openView} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpenView}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpenView(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900 mb-5"
                    >
                      Detalhes da Fornecedor
                    </Dialog.Title>
                    <div className="py-5">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Nome do Banco
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {viewRecord?.bank?.name}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            SWIFT
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {" "}
                            {viewRecord?.bank?.swift}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            IBAN
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {" "}
                            {viewRecord?.iban}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">
                            Routing Number
                          </dt>
                          <dd className="mt-1 text-base text-gray-900">
                            {" "}
                            {viewRecord?.routing_number}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
