import React, { useLayoutEffect } from "react";
import { Fragment, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Dialog, Transition, Tab, RadioGroup } from "@headlessui/react";
import LeftNavigation from "../../components/LeftNavigation/leftNavigation.js";
import Header from "../../components/Header/Header";
import DataTable from "../../components/MDataTable";
import DatePicker from "react-date-picker";
import config from "../../../src/utils/config";
import { classNames, logFormData, validateForm } from "../../utils/common.js";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
  TrashIcon,
  PlusIcon,
  CheckIcon,
  PencilIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import Tab1 from "./components/Tab1.jsx";
import { find, pluck } from "underscore";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];
const currency_type1 = [{ name: "NÃO" }, { name: "SIM" }];

const currency_type5 = [{ name: "AOA" }, { name: "USD" }, { name: "Euro" }, { name: "Libra" }, { name: "Real" }];
const currency_type6 = [{ name: "NÃO" }, { name: "SIM" }];
const recurring_service = [
  { id: 1, name: "NÃO" },
  { id: 2, name: "SIM" },
];

export default function EditContract({ history }) {
  const { tripID } = useParams();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tab, setTab] = useState(1);
  const [error, setError] = useState({});
  const [currency, setCurrency] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedradio, setSelectedRadio] = useState(currency_type1[0]);
  const [opentab2, setOpenTab2] = useState(false);
  const [opencontriumview, setOpencontriumview] = useState(false);
  const [selectedcontriumInfo, setSelectedcontriumInfo] = useState({
    provider_name: "",
    contract_value: "",
    currency_id: ""
  });
  const [ids, setIds] = useState([]);
  const [opentab2subtab2, setOpenTab2SubTab2] = useState(false);
  const [provider, setProvider] = useState([]);

  const [contriumcontractlist, setcontriumContractlist] = useState([]);
  const [frameworkcontractlist, setframeworkContractlist] = useState([]);
  const [selectedradio6, setSelectedRadio6] = useState(currency_type6[0]);

  const [selectedradio3, setSelectedRadio3] = useState();
  const [fields3, setFields3] = useState({
    parcel_name: "",
    expected_amount: "",
  });
  const [generateInstallment, setGenerateInstallment] = useState();

  const [frmSrc, setFrmsrc] = useState("");
  const [selectedinstallmentInfo, setSelectedinstallmentInfo] = useState({
    parcel_name: "",
    expected_amount: "",
    number_of_installments_to_generate: "",
    estimated_date: "",
  });
  const [selectedConditionInfo, setSelectedConditionInfo] = useState({
    parcel_name: "",
    expected_amount: "",
  });
  const [selectedDocInfo, setSelectedDocInfo] = useState({
    parts_of_the_contract: "",
    file: "",
  });
  const [documentInfo, setDocumentInfo] = useState({
    parts_of_the_contract: "",
    file: "",
  });
  const [AmendmentsdocumentInfo, setAmendmentsDocumentInfo] = useState({
    object_of_the_addendum: "",
    addendum_value: "",
    addendum_expiration_date: "",
    currency_type_id: "",
    file: "",
  });
  const [selectedAmendmentsdocumentInfo, setSelectedAmendmentsDocumentInfo] = useState({
    object_of_the_addendum: "",
    addendum_value: "",
    addendum_expiration_date: "",
    currency_type_id: "",
    file: "",
  });
  const [editDocFrmSrc, setEditDocFrmSrc] = useState("");
  const [deletedDocID, setDeletedDocID] = useState("");
  const [amendmentsDeletedDocID, setAmendmentsDeletedDocID] = useState("");
  const [contractConditionlist, setContractConditionlist] = useState([]);
  const [contractinstallmentlist, setContractinstallmentlist] = useState([]);
  const [contractdocumentlist, setContractdocumentlist] = useState([]);
  const [contractamendmentdsdocumentlist, setContractamendmentdsdocumentlist] = useState([]);
  const [openviewdoc, setopenviewdoc] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [opencondition, setOpenCondition] = useState(false);
  const [openconditionedit, setconditionedit] = useState(false);
  const [defaultIndex, setDefaultIndex] = useState(1);
  const [selectedContriumprovider, setSelectedContriumprovider] = useState(null);
  const [contriumfields, setContriumfieldsFields] = useState({});
  const [contriumprovider, setContriumProvider] = useState([]);
  const [selectedradio5, setSelectedRadio5] = useState();
  const [updatecc, setUpdatecc] = useState(false);
  const [editCCID, setEditCCID] = useState();
  const [selectedFrameworkprovider, setSelectedFrameworkprovider] = useState(null);
  const [frameworkfields, setFrameworkfieldsFields] = useState({});
  const [frameworkprovider, setFrameworkProvider] = useState([]);
  const [updateFP, setUpdateFP] = useState(false);
  const [editFPID, setEditFPID] = useState();

  const steps2 = [
    { id: "01", name: "Informação Geral", href: "#", status: "complete" },
    { id: "02", name: "Modalidade de Contrato", href: "#", status: "current" },
    { id: "03", name: "Contrato", href: "#", status: "upcoming" },
    { id: "04", name: "Condições de pagamento", href: "#", status: "upcoming" },
  ];

  const steps3 = [
    { id: "01", name: "Informação Geral", href: "#", status: "complete" },
    { id: "02", name: "Modalidade de Contrato", href: "#", status: "complete" },
    { id: "03", name: "Contrato", href: "#", status: "current" },
    { id: "04", name: "Condições de pagamento", href: "#", status: "upcoming" },
  ];

  const steps4 = [
    { id: "01", name: "Informação Geral", href: "#", status: "complete" },
    { id: "02", name: "Modalidade de Contrato", href: "#", status: "complete" },
    { id: "03", name: "Contrato", href: "#", status: "complete" },
    { id: "04", name: "Condições de pagamento", href: "#", status: "current" },
  ];

  const [contract_document, setFile] = useState({ preview: "", raw: "" });

  const [estimated_date, onChangeestimateddate] = useState(new Date());

  const [addendum_expiration_date, onChangeaddendumexpirationdate] = useState(new Date());


  const onSelectionChange = (e) => {
    let idArr = pluck(e, "contract_part");
    setIds(idArr);
  };

  const handleframeworkChange = (e, field) => {
    if (field === "provider_id") {
      setSelectedFrameworkprovider(e);
      setFrameworkfieldsFields({ ...frameworkfields, [field]: e.value });
    } else {
      setFrameworkfieldsFields({ ...frameworkfields, [field]: e.target.value });
    }
  };

  const submitcontrium = async (type) => {
    try {
      if (updatecc) {
        setShowLoader(true)
        let formData = new FormData();
        formData.append("provider_name", contriumfields?.provider_id);
        formData.append("contract_value", contriumfields?.contract_value);
        formData.append("currency_id", selectedradio5);
        formData.append("contractid", tripID);
        formData.append("id", editCCID);
        const updateResponse = await config.postFormDataAPI({
          url: "cosortiumcontract/update",
          params: formData,
        });
        setShowLoader(false)
        setOpenTab2(false)
        if (updateResponse?.response?.data?.error?.message) {
          return toast.error(updateResponse?.response?.data?.error?.message);
        }
        setSelectedContriumprovider(null)
        setContriumfieldsFields({
          provider_id: "",
          contract_value: "",
        });
        setSelectedRadio5()
        getcontriumcontractlist(tripID);
        setError({});
        return toast.success("Registro atualizado com sucesso");
      } else {
        const tempError = {};
        if (tripID !== "" || tripID !== undefined) {
          if (contriumfields?.provider_id === "") {
            tempError["provider_id"] = "Por favor, insira Nome da Parcela";
          }
          if (contriumfields?.contract_value === "") {
            tempError["contract_value"] = "Por favor, insira Montante Esperado";
          }
          if (Object.keys(tempError).length !== 0) {
            setError(tempError);
          } else {
            let formData = new FormData();
            formData.append("provider_id", contriumfields?.provider_id);
            formData.append("contract_value", contriumfields?.contract_value);
            formData.append("currency_id", selectedradio5);
            formData.append("contractid", tripID);
            logFormData(formData);
            setShowLoader(true)
            const insertResponse = await config.postFormDataAPI({
              url: "cosortiumcontract/create",
              params: formData,
            });
            setShowLoader(false)
            setOpenTab2(false)
            if (insertResponse?.response?.data?.error?.message) {
              return toast.error(insertResponse?.response?.data?.error?.message);
            }
            setSelectedContriumprovider(null)
            setContriumfieldsFields({
              provider_id: "",
              contract_value: "",
            });
            setSelectedRadio5()
            getcontriumcontractlist(tripID);
            setError({});
          }
        } else {
          console.log("failed to updalod");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const submitframework = async () => {
    try {
      if (updateFP) {
        setShowLoader(true)
        let formData = new FormData();
        formData.append("provider_id", selectedFrameworkprovider?.value);
        formData.append("contractid", tripID);
        formData.append("id", editFPID);
        logFormData(formData);
        const updateResponse = await config.postFormDataAPI({
          url: "frameworkagreementcontract/update",
          params: formData,
        });
        setShowLoader(false)
        setOpenTab2SubTab2(false)
        if (updateResponse?.response?.data?.error?.message) {
          return toast.error(updateResponse?.response?.data?.error?.message);
        }
        setDocumentInfo({
          parts_of_the_contract: "",
          file: "",
        });
        setFrmsrc("");
        getframeworkcontractlist(tripID);
        setError({});
        setSelectedFrameworkprovider(null)
        return toast.success("Registro atualizado com sucesso")
      } else {
        const tempError = {};
        if (tripID !== "" || tripID !== undefined) {
          if (Object.keys(tempError).length !== 0) {
            setError(tempError);
          } else {
            setShowLoader(true)
            let formData = new FormData();
            formData.append("provider_id", selectedFrameworkprovider?.value);
            formData.append("contractid", tripID);
            logFormData(formData);
            const insertResponse = await config.postFormDataAPI({
              url: "frameworkagreementcontract/create",
              params: formData,
            });
            setShowLoader(false)
            setOpenTab2SubTab2(false)
            if (insertResponse?.response?.data?.error?.message) {
              return toast.error(insertResponse?.response?.data?.error?.message);
            }
            setDocumentInfo({ parts_of_the_contract: "", file: "" });
            setFrmsrc("");
            getframeworkcontractlist(tripID);
            setError({});
            setSelectedFrameworkprovider(null)
          }
        } else {
          console.log("failed to updalod");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handlecontriumChange = (e, field) => {
    if (field === "provider_id") {
      setSelectedContriumprovider(e);
      setContriumfieldsFields({ ...contriumfields, [field]: e.value });
    } else {
      setContriumfieldsFields({ ...contriumfields, [field]: e.target.value });
    }
  };

  const editConsortiumContract = async (id) => {
    setEditCCID(id)
    setOpenTab2(true);
    config.getAPI({ url: "cosortiumcontract/getdatabyId", params: { id } }).then((data) => {
      if (data.payload) {
        const tempData = find(contriumprovider, function (singlePT) {
          return singlePT.value === data.payload?.provider_id;
        });
        setSelectedContriumprovider(tempData);
        setSelectedRadio5(data.payload?.currency_id)
        setContriumfieldsFields({ provider_id: data.payload?.provider_id, contract_value: data.payload?.contract_value })
      }
    }).catch((error) => {
      return toast.error(error.message);
    });
  }

  const editFP = async (id) => {
    setEditFPID(id);
    setOpenTab2SubTab2(true)
    config.getAPI({ url: "frameworkagreementcontract/getdatabyId", params: { id } }).then((data) => {
      if (data.payload) {
        const tempData = find(frameworkcontractlist, function (singlePT) {
          return singlePT.provider_id === data.payload?.provider_id;
        });
        setSelectedFrameworkprovider({ label: tempData?.supplier?.provider_name, value: tempData?.supplier.id })
      }
    }).catch((error) => {
      return toast.error(error.message);
    });
  }


  const columns1 = [
    {
      title: "Contrato",
      field: "parts_of_the_contract",
    },
    { title: "Ficheiro", field: "file_name" },
    {
      title: "Data de registo",
      field: "date",
      render: (rowData) => {
        return <span>{new Date(rowData?.createdAt).toLocaleDateString()}</span>;
      },
    },
    {
      title: "Acções",
      render: (rowData) => (
        <span className='flex'>
          <button className='red-link focus:outline-none mr-3'>
            <PencilIcon
              className='flex-shrink-0 h-5 w-5'
              aria-hidden='true'
              onClick={() => {
                setOpen(true);
                setSelectedDocInfo({
                  parts_of_the_contract: rowData?.parts_of_the_contract,
                  id: rowData?.id,
                  fileName: rowData?.file_name,
                });
                setDeletedDocID(rowData?.file_name);
                setEditDocFrmSrc(config.staticPath + "contract/" + tripID + "/" + rowData?.file_name);
              }}
            />
          </button>
          <button
            className='red-link focus:outline-none mr-3'
            onClick={() => {
              handleDeleteDocument({
                id: rowData?.id,
                fileName: rowData?.file_name,
              });
            }}
          >
            <TrashIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
          </button>
          <button
            className='red-link focus:outline-none'
            onClick={() => {
              setopenviewdoc(true);
              setSelectedDocInfo({
                parts_of_the_contract: rowData?.parts_of_the_contract,
                id: rowData?.id,
                fileName: rowData?.file_name,
              });
              setEditDocFrmSrc(config.staticPath + "contract/" + tripID + "/" + rowData?.file_name);
            }}
          >
            <EyeIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
            {/* Edit  */}
          </button>
        </span>
      ),
    },
  ];

  const columnscontrium = [
    {
      title: "Nome do Fornecedor",
      field: "supplier.provider_name",
    },
    { title: "Valor do Contrato", field: "contract_value" },
    { title: "Tipo de Moeda", field: "currency.currency_symbol" },
    {
      title: "Data de registo",
      field: "date",
      render: (rowData) => {
        return <span>{new Date(rowData?.createdAt).toLocaleDateString()}</span>;
      },
    },
    {
      title: "Acções",
      render: (rowData) => (
        <span className="flex">
          <button className="red-link focus:outline-none mr-3">
            <PencilIcon
              className="flex-shrink-0 h-5 w-5"
              aria-hidden="true"
              onClick={() => {
                setUpdatecc(true)
                editConsortiumContract(rowData?.id)
              }}
            />
          </button>
          <button
            className="red-link focus:outline-none mr-3"
            onClick={() => {
              handleDeletecontrium({
                id: rowData?.id,
              });
            }}
          >
            <TrashIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          </button>
          <button
            className="red-link focus:outline-none"
            onClick={() => {
              setOpencontriumview(true);
              setSelectedcontriumInfo({
                provider_name: rowData?.supplier.provider_name,
                contract_value: rowData?.contract_value,
                id: rowData?.id,
                currency: rowData?.currency.currency_symbol,
              });
            }}
          >
            <EyeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          </button>
        </span>
      ),
    },
  ];

  const columnsframework = [
    {
      title: "Nome do Fornecedor",
      field: "supplier.provider_name",
    },
    {
      title: "Acções",
      render: (rowData) => (
        <span className="flex">
          <button className="red-link focus:outline-none mr-3">
            <PencilIcon
              className="flex-shrink-0 h-5 w-5"
              aria-hidden="true"
              onClick={() => {
                setUpdateFP(true);
                editFP(rowData.id)
              }}
            />
          </button>
          <button
            className="red-link focus:outline-none mr-3"
            onClick={() => {
              handleDeleteframework({
                id: rowData?.id,
              });
            }}
          >
            <TrashIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          </button>
          {/* <button
            className="red-link focus:outline-none"
          >
            <EyeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          </button> */}
        </span>
      ),
    },
  ];

  const columnscondition = [
    {
      title: "Nome da Parcela",
      field: "parcel_name",
    },
    { title: "Montante Esperado(%)", field: "expected_amount" },
    {
      title: "Data de registo",
      field: "date",
      render: (rowData) => {
        return <span>{new Date(rowData?.createdAt).toLocaleDateString()}</span>;
      },
    },
    {
      title: "Acções",
      render: (rowData) => (
        <span className='flex'>
          {/* <Link
            className="red-link mr-3"
            to={"/"}
          >
            <PencilIcon
              className="flex-shrink-0 h-5 w-5"
              aria-hidden="true" onClick={() => setOpen(true)}
            />
          </Link> */}
          <button className='red-link focus:outline-none mr-3'>
            <PencilIcon
              className='flex-shrink-0 h-5 w-5'
              aria-hidden='true'
              onClick={() => {
                setconditionedit(true);
                setSelectedConditionInfo({
                  parcel_name: rowData?.parcel_name,
                  id: rowData?.id,
                  expected_amount: rowData?.expected_amount,
                });
              }}
            />
          </button>
          <button
            className='red-link focus:outline-none mr-3'
            onClick={() => {
              handleDeletecondition({ id: rowData?.id });
            }}
          >
            <TrashIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
          </button>
          <button
            className='red-link focus:outline-none'
            onClick={() => {
              setOpenCondition(true);
              setSelectedConditionInfo({
                parcel_name: rowData?.parcel_name,
                id: rowData?.id,
                expected_amount: rowData?.expected_amount,
              });
            }}
          >
            <EyeIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
            {/* Edit  */}
          </button>
        </span>
      ),
    },
  ];

  const columns2 = [
    {
      title: "Objecto da Adenda",
      field: "object_of_the_addendum",
    },
    { title: "Valor", field: "addendum_value" },
    { title: "Tipo de Moeda", field: "currency.currency_symbol" },
    { title: "Ficheiro", field: "file_name" },
    {
      title: "Data de Expiração",
      field: "date",
      render: (rowData) => {
        return <span>{new Date(rowData?.addendum_expiration_date).toLocaleDateString()}</span>;
      },
    },
    {
      title: "Acções",
      render: (rowData) => (
        <span className='flex'>
          {/* <Link
            className="red-link mr-3 3"
            to={"/ViewRequestEdit"}
          >
            <PencilIcon
              className="flex-shrink-0 h-5 w-5"
              aria-hidden="true"
            />            
          </Link> */}
          <button className='red-link focus:outline-none mr-3'>
            <PencilIcon
              className='flex-shrink-0 h-5 w-5'
              aria-hidden='true'
              onClick={() => {
                setOpen2(true);
                setSelectedAmendmentsDocumentInfo({
                  object_of_the_addendum: rowData?.object_of_the_addendum,
                  addendum_value: rowData?.addendum_value,
                  addendum_expiration_date: new Date(rowData?.addendum_expiration_date).toLocaleDateString(),
                  currency_type_id: rowData?.currency_type_id,
                  id: rowData?.id,
                  fileName: rowData?.file_name,
                });
                setSelectedRadio3(rowData?.currency_type_id);
                setAmendmentsDeletedDocID(rowData?.file_name);
                setEditDocFrmSrc(config.staticPath + "contract/" + tripID + "/" + rowData?.file_name);
              }}
            />
          </button>
          <button
            className='red-link mr-3'
            onClick={() => {
              handleamendmentdsDeleteDocument({
                id: rowData?.id,
                fileName: rowData?.file_name,
              });
            }}
          >
            <TrashIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
          </button>
          <button
            className='red-link'
            onClick={() => {
              setOpen3(true);
              setSelectedAmendmentsDocumentInfo({
                object_of_the_addendum: rowData?.object_of_the_addendum,
                addendum_value: rowData?.addendum_value,
                addendum_expiration_date: new Date(rowData?.addendum_expiration_date).toLocaleDateString(),
                currency_type_id: rowData?.currency.currency_symbol,
                id: rowData?.id,
                fileName: rowData?.file_name,
              });
              setEditDocFrmSrc(config.staticPath + "contract/" + tripID + "/" + rowData?.file_name);
            }}
          >
            <EyeIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
          </button>
        </span>
      ),
    },
  ];
  const columns3 = [
    {
      title: "Nome da Parcela",
      field: "parcel_name",
    },
    {
      title: "Data de Conclusão",
      field: "date",
      render: (rowData) => {
        return <span>{new Date(rowData?.estimated_date).toLocaleDateString()}</span>;
      },
    },
    // { title: "Estado", field: "state" },
    {
      title: "Execução Física Esperada (%)",
      field: "number_of_installments_to_generate",
    },
    { title: "Montante Esperado (%)", field: "expected_amount" },
    {
      title: "Acções",
      render: (rowData) => (
        <span className='flex'>
          {/* <Link
            className="red-link mr-3"
            to={"/ViewRequestEdit"}
          >
            <PencilIcon
              className="flex-shrink-0 h-5 w-5"
              aria-hidden="true"
            />            
          </Link> */}
          <button className='red-link focus:outline-none mr-3'>
            <PencilIcon
              className='flex-shrink-0 h-5 w-5'
              aria-hidden='true'
              onClick={() => {
                setOpen4(true);
                setSelectedinstallmentInfo({
                  parcel_name: rowData?.parcel_name,
                  number_of_installments_to_generate: rowData?.number_of_installments_to_generate,
                  estimated_date: rowData?.estimated_date,
                  id: rowData?.id,
                  expected_amount: rowData?.expected_amount,
                });
              }}
            />
          </button>
          <button
            className='red-link mr-3'
            onClick={() => {
              handleDeleteinstallment({ id: rowData?.id });
            }}
          >
            <TrashIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
          </button>
          <button
            className='red-link'
            onClick={() => {
              setOpen5(true);
              setSelectedinstallmentInfo({
                parcel_name: rowData?.parcel_name,
                number_of_installments_to_generate: rowData?.number_of_installments_to_generate,
                estimated_date: rowData?.estimated_date,
                id: rowData?.id,
                expected_amount: rowData?.expected_amount,
              });
            }}
          >
            <EyeIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
            {/* Edit  */}
          </button>
        </span>
      ),
    },
  ];

  function getcontriumcontractlist(contractid) {
    config
      .getAPI({ url: "cosortiumcontract/list", params: { contractid } })
      .then((data) => {
        if (data?.payload) {
          setcontriumContractlist(data?.payload?.rows);
          if (data?.payload?.rows.length > 0) {
            setSelectedRadio(currency_type1[1])
          }
        }
        if (data?.error?.message) {
          history.push("/");
          // return toast.error(data?.error?.message)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getframeworkcontractlist(contractid) {
    config
      .getAPI({ url: "frameworkagreementcontract/list", params: { contractid } })
      .then((data) => {
        if (data?.payload) {
          setframeworkContractlist(data?.payload?.rows);
          if (data?.payload?.rows.length > 0) {
            setSelectedRadio6(currency_type6[1])
          }
        }
        if (data?.error?.message) {
          history.push("/");
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getcurrencyList(params = "") {
    config
      .getAPI({ url: "currency/list", params: {} })
      .then((data) => {
        if (data.payload) {
          setCurrency(data.payload.rows);
        }
        if (data?.error?.message) {
          history.push("/");
          // return toast.error(data?.error?.message)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  const getEvaluationProvider = async () => {
    config
      .getAPI({ url: "supplier/list", params: { status: "Activo" } })
      .then((data) => {
        if (data.payload) {
          let optionsArr = [];
          data?.payload?.rows.map(async (row) => {
            optionsArr.push({ value: row.id, label: row.provider_name });
          });
          setProvider(optionsArr);
        }
        if (data?.error?.message) {
          history.push("/");
          // return toast.error(data?.error?.message)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const getcontriumProvider = async () => {
    config
      .getAPI({ url: "supplier/list", params: { status: "Activo" } })
      .then((data) => {
        if (data.payload) {
          let optionsArr = [];
          data?.payload?.rows.map(async (row) => {
            optionsArr.push({ value: row.id, label: row.provider_name });
          });
          setContriumProvider(optionsArr);
        }
        if (data?.error?.message) {
          history.push("/");
          // return toast.error(data?.error?.message)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const getframeworkProvider = async () => {
    config
      .getAPI({ url: "supplier/list", params: { status: "Activo" } })
      .then((data) => {
        if (data.payload) {
          let optionsArr = [];
          data?.payload?.rows.map(async (row) => {
            optionsArr.push({ value: row.id, label: row.provider_name });
          });
          setFrameworkProvider(optionsArr);
        }
        if (data?.error?.message) {
          history.push("/");
          // return toast.error(data?.error?.message)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };


  useEffect(() => {
    getcurrencyList();
    tripID && getframeworkcontractlist(tripID);
    tripID && getcontractosnewconditionlist(tripID);
    getEvaluationProvider();
    getcontriumProvider();
    getframeworkProvider();
    tripID && getcontriumcontractlist(tripID);
    tripID && getframeworkcontractlist(tripID);
    tripID && getcontractosamendmentdsdocumentlist(tripID);
    tripID && getcontractosinstallmentlist(tripID);
  }, []);


  useEffect(() => {
    if (tab === 4) {
      setShow(true);
    }
  }, [tab]);

  const handleDeletecontrium = async ({ id }) => {
    config
      .deleteAPI({
        url: "cosortiumcontract/delete",
        params: { id },
      })
      .then((data) => {
        if (data.payload) {
          getcontriumcontractlist(tripID);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const handleDeleteframework = async ({ id }) => {
    config
      .deleteAPI({
        url: "frameworkagreementcontract/delete",
        params: { id },
      })
      .then((data) => {
        if (data.payload) {
          getframeworkcontractlist(tripID);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };


  const handleDocumentChange = (e, name) => {
    if (name === "file") {
      const setData = {
        ...documentInfo,
        [name]: e?.target?.files?.[0],
      };
      setFrmsrc(URL.createObjectURL(e?.target?.files?.[0]));
      if (e?.target?.files?.[0].type !== "application/pdf") {
        setFile({ raw: e?.target?.files?.[0], preview: URL.createObjectURL(e?.target?.files?.[0]) });
      }
      setDocumentInfo(setData);
    } else {
      const setData = {
        ...documentInfo,
        [name]: e?.target?.value,
      };
      setDocumentInfo(setData);
    }
  };

  const handleAmendmentsDocumentChange = (e, name) => {
    if (name === "file") {
      const setData = {
        ...AmendmentsdocumentInfo,
        [name]: e?.target?.files?.[0],
      };
      setFrmsrc(URL.createObjectURL(e?.target?.files?.[0]));
      setAmendmentsDocumentInfo(setData);
    } else {
      const setData = {
        ...AmendmentsdocumentInfo,
        [name]: e?.target?.value,
      };
      setAmendmentsDocumentInfo(setData);
    }
  };

  const handleDeleteDocument = async ({ id, fileName }) => {
    config
      .deleteAPI({
        url: "contractosdocumentlist/delete",
        params: { id, fileName },
      })
      .then((data) => {
        if (data.payload) {
          getcontractosdocumentlist(tripID);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const handleDeletecondition = async ({ id }) => {
    config
      .deleteAPI({ url: "contractosnewcondition/delete", params: { id } })
      .then((data) => {
        if (data.payload) {
          getcontractosnewconditionlist(tripID);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const handleDeleteinstallment = async ({ id }) => {
    config
      .deleteAPI({
        url: "contractosgenerateinstallment/delete",
        params: { id },
      })
      .then((data) => {
        if (data.payload) {
          getcontractosinstallmentlist(tripID);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const handleamendmentdsDeleteDocument = async ({ id, fileName }) => {
    config
      .deleteAPI({
        url: "contractosamendmentds/delete",
        params: { id, fileName },
      })
      .then((data) => {
        if (data.payload) {
          getcontractosamendmentdsdocumentlist(tripID);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const handleDocumentUpdate = async () => {
    let formData = new FormData();
    formData.append("id", selectedDocInfo?.id);
    formData.append("contract_id", tripID);
    formData.append("parts_of_the_contract", selectedDocInfo?.parts_of_the_contract);
    if (deletedDocID !== selectedDocInfo?.file && selectedDocInfo?.file !== undefined) {
      formData.append("file", selectedDocInfo?.file);
      formData.append("deletedDoc", deletedDocID);
    }

    config
      .postFormDataAPI({
        url: "contractosdocumentlist/update",
        params: formData,
      })
      .then((data) => {
        if (data.payload) {
          getcontractosdocumentlist(tripID);
          setOpen(false);
          setSelectedDocInfo({
            file: "",
            id: "",
            parts_of_the_contract: "",
          });
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const handleAmendmentsDocumentUpdate = async () => {
    let formData = new FormData();
    formData.append("object_of_the_addendum", selectedAmendmentsdocumentInfo?.object_of_the_addendum);
    formData.append("addendum_value", selectedAmendmentsdocumentInfo?.addendum_value);
    formData.append("addendum_expiration_date", addendum_expiration_date);
    formData.append("currency_type_id", selectedradio3);
    formData.append("id", selectedAmendmentsdocumentInfo?.id);
    formData.append("contract_id", tripID);
    if (
      amendmentsDeletedDocID !== selectedAmendmentsdocumentInfo?.file &&
      selectedAmendmentsdocumentInfo?.file !== undefined
    ) {
      formData.append("file", selectedAmendmentsdocumentInfo?.file);
      formData.append("deletedDoc", amendmentsDeletedDocID);
    }
    config
      .postFormDataAPI({
        url: "contractosamendmentds/update",
        params: formData,
      })
      .then((data) => {
        if (data.payload) {
          getcontractosamendmentdsdocumentlist(tripID);
          setOpen2(false);
          setAmendmentsDocumentInfo({
            object_of_the_addendum: "",
            addendum_value: "",
            addendum_expiration_date: "",
            currency_type_id: "",
            file: "",
          });
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const handlenewconditionUpdate = async () => {
    let formData = new FormData();
    formData.append("parcel_name", selectedConditionInfo?.parcel_name);
    formData.append("expected_amount", selectedConditionInfo?.expected_amount);
    formData.append("id", selectedConditionInfo?.id);

    config
      .postFormDataAPI({
        url: "contractosnewcondition/update",
        params: formData,
      })
      .then((data) => {
        if (data.payload) {
          getcontractosnewconditionlist(tripID);
          setconditionedit(false);
          setSelectedConditionInfo({
            parcel_name: "",
            id: "",
            expected_amount: "",
          });
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const upadateinstallment = async () => {
    config
      .postAPI({
        url: "contractosgenerateinstallment/update",
        params: selectedinstallmentInfo,
      })
      .then((data) => {
        if (data.payload) {
          getcontractosinstallmentlist(tripID);
          setOpen4(false);
          setSelectedinstallmentInfo({
            parcel_name: "",
            number_of_installments_to_generate: "",
            estimated_date: "",
            id: "",
            expected_amount: "",
          });
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };

  const submitcondition = async () => {
    try {
      const tempError = {};
      if (tripID !== "" || tripID !== undefined) {
        if (fields3?.parcel_name === "") {
          tempError["parcel_name"] = "Por favor, insira Nome da Parcela";
        }
        if (fields3?.expected_amount === "") {
          tempError["expected_amount"] = "Por favor, insira Montante Esperado";
        }
        if (Object.keys(tempError).length !== 0) {
          setError(tempError);
        } else {
          let formData = new FormData();
          formData.append("parcel_name", fields3?.parcel_name);
          formData.append("expected_amount", fields3?.expected_amount);
          formData.append("contractid", tripID);
          logFormData(formData);
          setShowLoader(true)
          const insertResponse = await config.postFormDataAPI({
            url: "contractosnewcondition/create",
            params: formData,
          });
          setShowLoader(false)
          if (insertResponse?.response?.data?.error?.message) {
            return toast.error(insertResponse?.response?.data?.error?.message);
          }
          setFields3({
            parcel_name: "",
            expected_amount: "",
          });
          getcontractosnewconditionlist(tripID);
          setError({});
        }
      } else {
        console.error("failed to updalod");
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const submitinstallment = async () => {
    try {
      const tempError = {};
      if (tripID !== "" || tripID !== undefined) {
        if (generateInstallment?.parcel_name === "") {
          tempError["parcel_name"] = "por favor, selecione nome da Parcela";
        }
        if (generateInstallment?.expected_amount === "") {
          tempError["expected_amount"] = "por favor, selecione montante esperado";
        }
        if (generateInstallment?.number_of_installments_to_generate === "") {
          tempError["number_of_installments_to_generate"] = "por favor, selecione quantidade de parcelas a gerar";
        }
        if (Object.keys(tempError).length !== 0) {
          setError(tempError);
        } else {
          let formData = new FormData();
          formData.append("parcel_name", generateInstallment?.parcel_name);
          formData.append("expected_amount", generateInstallment?.expected_amount);
          formData.append(
            "number_of_installments_to_generate",
            generateInstallment?.number_of_installments_to_generate
          );
          formData.append("estimated_date", estimated_date);
          formData.append("contractid", tripID);
          logFormData(formData);
          setShowLoader(true)
          const insertResponse = await config.postFormDataAPI({
            url: "contractosgenerateinstallment/create",
            params: formData,
          });
          setShowLoader(false)
          if (insertResponse?.response?.data?.error?.message) {
            return toast.error(insertResponse?.response?.data?.error?.message);
          }
          setGenerateInstallment({
            parcel_name: "",
            expected_amount: "",
            number_of_installments_to_generate: "",
          });

          getcontractosinstallmentlist(tripID);
          setError({});
        }
      } else {
        console.error("failed to updalod");
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleDocumentSubmit = async () => {
    try {
      const tempError = {};
      if (tripID !== "" || tripID !== undefined) {
        if (documentInfo?.file === "") {
          tempError["file"] = "por favor, selecione file";
        }
        if (documentInfo?.parts_of_the_contract == "") {
          tempError["parts_of_the_contract"] = "por favor, selecione Contrato";
        }
        if (Object.keys(tempError).length !== 0) {
          setError(tempError);
        } else {
          let formData = new FormData();
          formData.append("file", documentInfo?.file);
          formData.append("parts_of_the_contract", documentInfo?.parts_of_the_contract);
          formData.append("contractid", tripID);
          logFormData(formData);
          setShowLoader(true)
          const insertResponse = await config.postFormDataAPI({
            url: "contractosdocumentlist/create",
            params: formData,
          });
          setShowLoader(false)
          if (insertResponse?.response?.data?.error?.message) {
            return toast.error(insertResponse?.response?.data?.error?.message);
          }
          setDocumentInfo({
            parts_of_the_contract: "",
            file: "",
          });
          setFrmsrc("");
          getcontractosdocumentlist(tripID);
          setError({});
        }
      } else {
        console.error("failed to updalod");
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleAmendmentsDocumentSubmit = async () => {
    try {
      const tempError = {};
      if (tripID !== "" || tripID !== undefined) {
        if (AmendmentsdocumentInfo?.file === "") {
          tempError["file"] = "por favor, selecione file";
        }
        if (AmendmentsdocumentInfo?.object_of_the_addendum === "") {
          tempError["object_of_the_addendum"] = "Por favor, insira objecto da adenda";
        }
        if (AmendmentsdocumentInfo?.addendum_value === "") {
          tempError["addendum_value"] = "Por favor, insira valor da adenda";
        }
        if (selectedradio3 === undefined) {
          tempError["selectedradio3"] = "por favor, selecione tipo de Moeda";
        }
        if (Object.keys(tempError).length !== 0) {
          setError(tempError);
        } else {
          let formData = new FormData();
          formData.append("file", AmendmentsdocumentInfo?.file);
          formData.append("object_of_the_addendum", AmendmentsdocumentInfo?.object_of_the_addendum);
          formData.append("addendum_value", AmendmentsdocumentInfo?.addendum_value);
          formData.append("addendum_expiration_date", addendum_expiration_date);
          formData.append("currency_type_id", selectedradio3);
          formData.append("contractid", tripID);
          logFormData(formData);
          setShowLoader(true)
          const insertResponse = await config.postFormDataAPI({
            url: "contractosamendmentds/create",
            params: formData,
          });
          setShowLoader(false)
          if (insertResponse?.response?.data?.error?.message) {
            return toast.error(insertResponse?.response?.data?.error?.message);
          }
          setAmendmentsDocumentInfo({
            object_of_the_addendum: "",
            addendum_value: "",
            addendum_expiration_date: "",
            currency_type_id: "",
            file: "",
          });
          setFrmsrc("");
          getcontractosamendmentdsdocumentlist(tripID);
          setError({});
        }
      } else {
        console.error("failed to updalod");
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const submit = async () => {
    history.push("/contracts/contracts_view");
  };

  function getcontractosdocumentlist(contractid) {
    config
      .getAPI({ url: "contractosdocumentlist/list", params: { contractid } })
      .then((data) => {
        if (data?.payload) {
          setContractdocumentlist(data?.payload?.rows);
        }
        if (data?.error?.message) {
          history.push("/");
          // return toast.error(data?.error?.message)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getcontractosnewconditionlist(contractid) {
    config
      .getAPI({ url: "contractosnewcondition/list", params: { contractid } })
      .then((data) => {
        if (data?.payload) {
          setContractConditionlist(data?.payload?.rows);
        }
        if (data?.error?.message) {
          history.push("/");
          // return toast.error(data?.error?.message)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getcontractosinstallmentlist(contractid) {
    config
      .getAPI({
        url: "contractosgenerateinstallment/list",
        params: { contractid },
      })
      .then((data) => {
        if (data?.payload) {
          setContractinstallmentlist(data?.payload?.rows);
        }
        if (data?.error?.message) {
          history.push("/");
          // return toast.error(data?.error?.message)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getcontractosamendmentdsdocumentlist(contractid) {
    config
      .getAPI({ url: "contractosamendmentds/list", params: { contractid } })
      .then((data) => {
        if (data?.payload) {
          setContractamendmentdsdocumentlist(data?.payload?.rows);
        }
        if (data?.error?.message) {
          history.push("/");
          // return toast.error(data?.error?.message)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  const handleconditionChange = (e, field) => {
    const setData = {
      ...fields3,
      [field]: e?.target?.value,
    };
    setFields3(setData);
  };

  const handlegenerateinstallmentChange = (e, field) => {
    setGenerateInstallment({ ...generateInstallment, [field]: e.target.value });
  };

  const handleeditgenerateinstallmentChange = (e, field) => {
    setSelectedinstallmentInfo({
      ...selectedinstallmentInfo,
      [field]: e.target.value,
    });
  };

  const handlerditconditionChange = (e, field) => {
    setSelectedConditionInfo({
      ...selectedConditionInfo,
      [field]: e.target.value,
    });
  };
  const location = useLocation();
  useLayoutEffect(() => {
    setTab(location?.state?.tab ?? 1);
    setDefaultIndex(location?.state?.defaultIndex ?? 0);
  }, [location?.state?.defaultIndex, location?.state?.tab]);

  return (
    <>
      <div className='min-h-full'>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as='div' className='relative z-40 lg:hidden' onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>

            <div className='fixed inset-0 z-40 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute top-0 right-0 -mr-12 pt-2'>
                      <button
                        type='button'
                        className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex flex-shrink-0 items-center px-4'>
                    <img
                      className='h-8 w-auto'
                      src='https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300'
                      alt='Easywire logo'
                    />
                  </div>
                  <nav
                    className='mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto'
                    aria-label='Sidebar'
                  >
                    <div className='space-y-1 px-2'>
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current ? "bg-skin-primary text-white" : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon className='mr-4 h-6 w-6 flex-shrink-0 text-white' aria-hidden='true' />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className='mt-6 pt-6'>
                      <div className='space-y-1 px-2'>
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current ? "bg-skin-primary text-white" : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon className='mr-4 h-6 w-6 text-white' aria-hidden='true' />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className='w-14 flex-shrink-0' aria-hidden='true'>
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className='hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <LeftNavigation />
        </div>

        <div className='flex flex-1 flex-col lg:pl-64'>
          <Header />
          <main className='flex-1 pb-8'>
            <div
              className="loader-img"
              style={{
                display: showLoader ? "block" : "none",
              }}
            ></div>
            <div className='mt-8'>
              <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
                <div className='py-5 flex items-center justify-between'>
                  <h1 className='text-3xl font-bold tracking-tight dark:text-[#fff] text-black'>Novo Contrato</h1>
                </div>
                <div className='space-y-6'>
                  <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                    <div className='space-y-8'>
                      {tab === 1 ? <Tab1 currency={currency} setTab={setTab} /> : null}

                      {tab === 2 ? (
                        <div className="w-full">
                          <div>
                            <nav aria-label="Progress">
                              <ol
                                role="list"
                                className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
                              >
                                {steps2.map((step, stepIdx) => (
                                  <li
                                    key={step.name}
                                    className="relative md:flex md:flex-1"
                                  >
                                    {step.status === "complete" ? (
                                      <a
                                        href={step.href}
                                        className="group flex w-full items-center"
                                      >
                                        <span className="flex items-center px-6 py-4 text-base font-medium">
                                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-600">
                                            <CheckIcon
                                              className="h-6 w-6 text-white"
                                              aria-hidden="true"
                                            />
                                          </span>
                                          <span className="ml-4 text-base font-medium text-gray-900">
                                            {step.name}
                                          </span>
                                        </span>
                                      </a>
                                    ) : step.status === "current" ? (
                                      <a
                                        href={step.href}
                                        className="flex items-center px-6 py-4 text-base font-medium"
                                        aria-current="step"
                                      >
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-red-600">
                                          <span className="text-red-600">
                                            {step.id}
                                          </span>
                                        </span>
                                        <span className="ml-4 text-base font-medium text-red-600">
                                          {step.name}
                                        </span>
                                      </a>
                                    ) : (
                                      <a
                                        href={step.href}
                                        className="group flex items-center"
                                      >
                                        <span className="flex items-center px-6 py-4 text-base font-medium">
                                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                            <span className="text-gray-500 group-hover:text-gray-900">
                                              {step.id}
                                            </span>
                                          </span>
                                          <span className="ml-4 text-base font-medium text-gray-500 group-hover:text-gray-900">
                                            {step.name}
                                          </span>
                                        </span>
                                      </a>
                                    )}

                                    {stepIdx !== steps2.length - 1 ? (
                                      <>
                                        {/* Arrow separator for lg screens and up */}
                                        <div
                                          className="absolute top-0 right-0 hidden h-full w-5 md:block"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            className="h-full w-full text-gray-300"
                                            viewBox="0 0 22 80"
                                            fill="none"
                                            preserveAspectRatio="none"
                                          >
                                            <path
                                              d="M0 -2L20 40L0 82"
                                              vectorEffect="non-scaling-stroke"
                                              stroke="currentcolor"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </div>
                                      </>
                                    ) : null}
                                  </li>
                                ))}
                              </ol>
                            </nav>
                          </div>
                          <div className="pt-1">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 bg-gray-100 px-4 py-4">
                              <div className="pb-5 border-b border-gray-200 col-span-6">
                                <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
                                  <Tab.Group as="div">
                                    <div className="border-b border-gray-200">
                                      <Tab.List className="-mb-px flex space-x-8">
                                        <Tab
                                          className={({ selected }) =>
                                            classNames(
                                              selected
                                                ? "border-red-600 text-red-600"
                                                : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                              "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                            )
                                          }
                                        >
                                          Contrato por consórcio
                                        </Tab>
                                        <Tab
                                          className={({ selected }) =>
                                            classNames(
                                              selected
                                                ? "border-red-600 text-red-600"
                                                : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                              "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                            )
                                          }
                                        >
                                          Contrato por Acordo Quadro
                                        </Tab>
                                      </Tab.List>
                                    </div>
                                    <Tab.Panels as={Fragment}>
                                      <Tab.Panel className="mt-6">
                                        <h3 className="sr-only">
                                          Contrato por consórcio?
                                        </h3>
                                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 bg-gray-100">
                                          <div className="sm:col-span-3">
                                            <label for="first_name" className="block text-base font-medium text-gray-700 mt-4"> Contrato por consórcio? <span className="mandatory">*</span></label>
                                          </div>
                                          <div className="sm:col-span-3 text-right">
                                          </div>
                                          <div className="sm:col-span-6 flex justify-between">
                                            <div className="sm:col-span-3">
                                              <RadioGroup
                                                value={selectedradio}
                                                onChange={setSelectedRadio}
                                              >
                                                <RadioGroup.Label className="sr-only">
                                                  {" "}
                                                  Privacy setting{" "}
                                                </RadioGroup.Label>
                                                <div className="-space-y-px sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                                  {currency_type1.map(
                                                    (setting, settingIdx) => (
                                                      <RadioGroup.Option
                                                        key={setting.name}
                                                        value={setting}
                                                        className={({ checked }) =>
                                                          classNames(
                                                            settingIdx === 0
                                                              ? "rounded-tl-md rounded-tr-md"
                                                              : "",
                                                            settingIdx ===
                                                              currency_type1.length - 1
                                                              ? "rounded-bl-md rounded-br-md"
                                                              : "",
                                                            checked
                                                              ? "bg-white border-white"
                                                              : "border-white",
                                                            "relative border flex cursor-pointer focus:outline-none"
                                                          )
                                                        }
                                                      >
                                                        {({ active, checked }) => (
                                                          <>
                                                            <span
                                                              className={classNames(
                                                                checked
                                                                  ? "bg-red-700 border-transparent"
                                                                  : "bg-white border-gray-300",
                                                                active
                                                                  ? "ring-2 ring-offset-2 ring-red-700"
                                                                  : "",
                                                                "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                                              )}
                                                              aria-hidden="true"
                                                            >
                                                              <span className="rounded-full bg-white w-1.5 h-1.5" />
                                                            </span>
                                                            <span className="ml-3 flex flex-col">
                                                              <RadioGroup.Label
                                                                as="span"
                                                                className={classNames(
                                                                  checked
                                                                    ? "text-red-700"
                                                                    : "text-gray-900",
                                                                  "block text-sm font-medium"
                                                                )}
                                                              >
                                                                {setting.name}
                                                              </RadioGroup.Label>
                                                            </span>
                                                          </>
                                                        )}
                                                      </RadioGroup.Option>
                                                    )
                                                  )}
                                                </div>
                                              </RadioGroup>
                                            </div>
                                            {selectedradio.name === "SIM" && <div className="sm:col-span-3"><button
                                              type="button"
                                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-skin-primary hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 xl-admin-btn"
                                            >
                                              <PlusIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                                onClick={() => setOpenTab2(true)}
                                              />
                                            </button></div>}
                                          </div>
                                          {selectedradio.name === "SIM" ? (
                                            <>
                                              <div className="sm:col-span-6 mt-6">
                                                <DataTable
                                                  columns={columnscontrium}
                                                  data={contriumcontractlist}
                                                  disabledCheckbox={false}
                                                  onSelectionChange={
                                                    onSelectionChange
                                                  }
                                                />
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Tab.Panel>

                                      <Tab.Panel className="text-sm text-gray-500 mt-6">
                                        <h3 className="sr-only">
                                          Contrato por Acordo Quadro
                                        </h3>
                                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 bg-gray-100">
                                          <div className="sm:col-span-3">
                                            <label for="first_name" className="block text-base font-medium text-gray-700 mt-4">Contrato por Acordo Quadro <span className="mandatory">*</span></label>
                                          </div>
                                          <div className="sm:col-span-3 text-right">
                                          </div>
                                          <div className="sm:col-span-6 flex justify-between">
                                            <div className="sm:col-span-3">
                                              <RadioGroup
                                                value={selectedradio6}
                                                onChange={setSelectedRadio6}
                                              >
                                                <RadioGroup.Label className="sr-only">
                                                  {" "}
                                                  Privacy setting{" "}
                                                </RadioGroup.Label>
                                                <div className="-space-y-px sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                                  {currency_type6.map(
                                                    (setting, settingIdx) => (
                                                      <RadioGroup.Option
                                                        key={setting.name}
                                                        value={setting}
                                                        className={({ checked }) =>
                                                          classNames(
                                                            settingIdx === 0
                                                              ? "rounded-tl-md rounded-tr-md"
                                                              : "",
                                                            settingIdx ===
                                                              currency_type6.length - 1
                                                              ? "rounded-bl-md rounded-br-md"
                                                              : "",
                                                            checked
                                                              ? "bg-white border-white"
                                                              : "border-white",
                                                            "relative border flex cursor-pointer focus:outline-none"
                                                          )
                                                        }
                                                      >
                                                        {({ active, checked }) => (
                                                          <>
                                                            <span
                                                              className={classNames(
                                                                checked
                                                                  ? "bg-red-700 border-transparent"
                                                                  : "bg-white border-gray-300",
                                                                active
                                                                  ? "ring-2 ring-offset-2 ring-red-700"
                                                                  : "",
                                                                "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                                              )}
                                                              aria-hidden="true"
                                                            >
                                                              <span className="rounded-full bg-white w-1.5 h-1.5" />
                                                            </span>
                                                            <span className="ml-3 flex flex-col">
                                                              <RadioGroup.Label
                                                                as="span"
                                                                className={classNames(
                                                                  checked
                                                                    ? "text-red-700"
                                                                    : "text-gray-900",
                                                                  "block text-sm font-medium"
                                                                )}
                                                              >
                                                                {setting.name}
                                                              </RadioGroup.Label>
                                                            </span>
                                                          </>
                                                        )}
                                                      </RadioGroup.Option>
                                                    )
                                                  )}
                                                </div>
                                              </RadioGroup>
                                            </div>
                                            {selectedradio6.name === "SIM" && <div className="sm:col-span-3"><button
                                              type="button"
                                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-skin-primary hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 xl-admin-btn"
                                            >
                                              <PlusIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                                onClick={() => setOpenTab2SubTab2(true)}
                                              />
                                            </button></div>}
                                          </div>

                                          {selectedradio6.name === "SIM" ? (
                                            <>
                                              <div className="sm:col-span-6">
                                                <DataTable
                                                  columns={columnsframework}
                                                  data={
                                                    frameworkcontractlist
                                                  }
                                                  disabledCheckbox={false}
                                                  onSelectionChange={
                                                    onSelectionChange
                                                  }
                                                />
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Tab.Panel>
                                    </Tab.Panels>
                                  </Tab.Group>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                                onClick={() => setTab(1)}
                              >
                                Retroceder
                              </button>

                              <button
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                onClick={() => submit()}
                              >
                                Guardar e Sair
                              </button>
                              <button
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                                onClick={() => setTab(3)}
                              >
                                Próximo
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {tab === 3 ? (
                        <div className='w-full'>
                          <div>
                            <nav aria-label='Progress'>
                              <ol className='divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0'>
                                {steps3.map((step, stepIdx) => (
                                  <li key={step.name} className='relative md:flex md:flex-1'>
                                    {step.status === "complete" ? (
                                      <a href={step.href} className='group flex w-full items-center'>
                                        <span className='flex items-center px-6 py-4 text-base font-medium'>
                                          <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-600'>
                                            <CheckIcon className='h-6 w-6 text-white' aria-hidden='true' />
                                          </span>
                                          <span className='ml-4 text-base font-medium text-gray-900'>{step.name}</span>
                                        </span>
                                      </a>
                                    ) : step.status === "current" ? (
                                      <a
                                        href={step.href}
                                        className='flex items-center px-6 py-4 text-base font-medium'
                                        aria-current='step'
                                      >
                                        <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-red-600'>
                                          <span className='text-red-600'>{step.id}</span>
                                        </span>
                                        <span className='ml-4 text-base font-medium text-red-600'>{step.name}</span>
                                      </a>
                                    ) : (
                                      <a href={step.href} className='group flex items-center'>
                                        <span className='flex items-center px-6 py-4 text-base font-medium'>
                                          <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400'>
                                            <span className='text-gray-500 group-hover:text-gray-900'>{step.id}</span>
                                          </span>
                                          <span className='ml-4 text-base font-medium text-gray-500 group-hover:text-gray-900'>
                                            {step.name}
                                          </span>
                                        </span>
                                      </a>
                                    )}

                                    {stepIdx !== steps3.length - 1 ? (
                                      <>
                                        {/* Arrow separator for lg screens and up */}
                                        <div
                                          className='absolute top-0 right-0 hidden h-full w-5 md:block'
                                          aria-hidden='true'
                                        >
                                          <svg
                                            className='h-full w-full text-gray-300'
                                            viewBox='0 0 22 80'
                                            fill='none'
                                            preserveAspectRatio='none'
                                          >
                                            <path
                                              d='M0 -2L20 40L0 82'
                                              vectorEffect='non-scaling-stroke'
                                              stroke='currentcolor'
                                              strokeLinejoin='round'
                                            />
                                          </svg>
                                        </div>
                                      </>
                                    ) : null}
                                  </li>
                                ))}
                              </ol>
                            </nav>
                          </div>
                          <div className='pt-1'>
                            <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 bg-gray-100 px-4 py-4'>
                              <div className='pb-5 border-b border-gray-200 col-span-6'>
                                <div className='mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none'>
                                  <Tab.Group as='div' defaultIndex={defaultIndex}>
                                    <div className='border-b border-gray-200'>
                                      <Tab.List className='-mb-px flex space-x-8'>
                                        <Tab
                                          className={({ selected }) =>
                                            classNames(
                                              selected
                                                ? "border-red-600 text-red-600"
                                                : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                              "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                            )
                                          }
                                        >
                                          Contrato
                                        </Tab>
                                        <Tab
                                          className={({ selected }) =>
                                            classNames(
                                              selected
                                                ? "border-red-600 text-red-600"
                                                : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                                              "whitespace-nowrap border-b-2 py-6 text-base font-bold"
                                            )
                                          }
                                        >
                                          Adendas do Contrato
                                        </Tab>
                                      </Tab.List>
                                    </div>
                                    <Tab.Panels as={Fragment}>
                                      <Tab.Panel className='mt-6'>
                                        <h3 className='sr-only'>Contrato</h3>
                                        <div className='sm:col-span-6'>
                                          <label
                                            htmlFor='first_name'
                                            className='block text-base font-medium text-gray-700'
                                          // onChange={(e) => {
                                          //   handleDocumentChange(e, "parts_of_the_contract")
                                          // }}
                                          // value={documentInfo?.parts_of_the_contract}
                                          >
                                            Tipo de documento <span className='mandatory'>*</span>
                                          </label>
                                          <div className='sm:col-span-3 mt-3'>
                                            <div className='mt-1'>
                                              <select
                                                autoComplete='country'
                                                className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md'
                                                onChange={(e) => {
                                                  handleDocumentChange(e, "parts_of_the_contract");
                                                }}
                                                value={documentInfo.parts_of_the_contract}
                                              >
                                                <option>---Selecione o Tipo de documento---</option>
                                                <option value={"contract"} key={"contract"}>
                                                  Contrato
                                                </option>
                                              </select>
                                              {error?.parts_of_the_contract && (
                                                <span className='text-xs text-red-600 capitalize'>
                                                  {error?.parts_of_the_contract}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                          {frmSrc !== "" ? (
                                            <iframe
                                              style={{ height: "45vh" }}
                                              src={frmSrc}
                                              title='document'
                                              className='w-4/6 mx-auto mt-5'
                                            />
                                          ) : (
                                            ""
                                          )}
                                          <div className='w-4/6 mx-auto mt-8 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                                            <div className='space-y-1 text-center'>
                                              {contract_document.preview ? (
                                                <img
                                                  src={"/img/done.svg"}
                                                  alt='Profile'
                                                  className='mx-auto h-12 w-12 text-gray-400'
                                                />
                                              ) : (
                                                <img
                                                  src={"/img/pdf.png"}
                                                  alt='Profile'
                                                  className='mx-auto h-12 w-12 text-gray-400'
                                                />
                                              )}
                                              <div className='flex text-sm text-gray-600'>
                                                <label
                                                  htmlFor='file-upload'
                                                  className={` ${error?.file && "border-red-600"
                                                    } relative cursor-pointer rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500`}
                                                >
                                                  <span>Seleccione o ficheiro</span>{" "}
                                                  <span className='mandatory'>*</span>
                                                  <input
                                                    accept='application/pdf'
                                                    id='file-upload'
                                                    name='file-upload'
                                                    type='file'
                                                    className='sr-only'
                                                    onChange={(e) => {
                                                      handleDocumentChange(e, "file");
                                                    }}
                                                    value={documentInfo?.parts_of_the_contract?.name || ""}
                                                  />
                                                </label>
                                              </div>
                                              <p className='text-xs text-gray-500'>PDF até 10MB</p>
                                            </div>
                                          </div>
                                            {error?.file && (
                                              <span className='text-xs text-red-600 capitalize'>{error?.file}</span>
                                            )}
                                          <div className='pt-5'>
                                            <div className='flex justify-end'>
                                              <button
                                                type='button'
                                                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white'
                                                onClick={handleDocumentSubmit}
                                              >
                                                Carregar
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='sm:col-span-6 mt-6'>
                                          <DataTable
                                            columns={columns1}
                                            data={contractdocumentlist}
                                            disabledCheckbox={false}
                                          />
                                        </div>
                                      </Tab.Panel>

                                      <Tab.Panel className='text-sm text-gray-500 mt-6'>
                                        <h3 className='sr-only'>Adendas do Contrato</h3>
                                        <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 bg-gray-100'>
                                          <div className='sm:col-span-3'>
                                            <label
                                              htmlFor='first_name'
                                              className='block text-base font-medium text-gray-700'
                                            >
                                              Objecto da adenda <span className='mandatory'>*</span>
                                            </label>
                                            <div className='mt-1'>
                                              <input
                                                type='text'
                                                name='object_of_the_addendum'
                                                id='object_of_the_addendum'
                                                autoComplete='given-name'
                                                className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md'
                                                onChange={(e) => {
                                                  handleAmendmentsDocumentChange(e, "object_of_the_addendum");
                                                }}
                                                value={AmendmentsdocumentInfo.object_of_the_addendum}
                                              />
                                              {error?.object_of_the_addendum && (
                                                <span className='text-xs text-red-600 capitalize'>
                                                  {error?.object_of_the_addendum}
                                                </span>
                                              )}
                                            </div>
                                          </div>

                                          <div className='sm:col-span-3'>
                                            <label
                                              htmlFor='first_name'
                                              className='block text-base font-medium text-gray-700'
                                            >
                                              Valor da adenda <span className='mandatory'>*</span>
                                            </label>
                                            <div className='mt-1'>
                                              <input
                                                type='number'
                                                name='addendum_value'
                                                id='addendum_value'
                                                autoComplete='given-name'
                                                className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md'
                                                onChange={(e) => {
                                                  handleAmendmentsDocumentChange(e, "addendum_value");
                                                }}
                                                value={AmendmentsdocumentInfo.addendum_value}
                                              />
                                              {error?.addendum_value && (
                                                <span className='text-xs text-red-600 capitalize'>
                                                  {error?.addendum_value}
                                                </span>
                                              )}
                                            </div>
                                          </div>

                                          <div className='sm:col-span-3'>
                                            <label
                                              htmlFor='first_name'
                                              className='block text-base font-medium text-gray-700'
                                            >
                                              Data de expiração da adenda
                                            </label>
                                            <div className='mt-1 relative'>
                                              <DatePicker
                                                className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-white'
                                                onChange={onChangeaddendumexpirationdate}
                                                value={addendum_expiration_date}
                                              />
                                            </div>
                                          </div>

                                          <div className='sm:col-span-3'>
                                            <label
                                              htmlFor='first_name'
                                              className='block text-base font-medium text-gray-700'
                                            >
                                              Tipo de Moeda <span className='mandatory'>*</span>
                                            </label>
                                            <div className='mt-1'>
                                              <RadioGroup value={selectedradio3} onChange={setSelectedRadio3}>
                                                <RadioGroup.Label className='sr-only'>
                                                  {" "}
                                                  Privacy setting{" "}
                                                </RadioGroup.Label>
                                                <div className='-space-y-px sm:flex sm:items-center sm:space-y-0 sm:space-x-10'>
                                                  {currency.map((setting, settingIdx) => (
                                                    <RadioGroup.Option
                                                      key={setting.currency_symbol}
                                                      value={setting.id}
                                                      className={({ checked }) =>
                                                        classNames(
                                                          settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                                                          settingIdx === currency.length - 1
                                                            ? "rounded-bl-md rounded-br-md"
                                                            : "",
                                                          checked
                                                            ? "bg-transparent border-transparent z-0"
                                                            : "border-transparent",
                                                          "relative border flex cursor-pointer focus:outline-none"
                                                        )
                                                      }
                                                    >
                                                      {({ active, checked }) => (
                                                        <>
                                                          <span
                                                            className={classNames(
                                                              checked
                                                                ? "bg-red-700 border-transparent"
                                                                : "bg-white border-gray-300",
                                                              active ? "ring-2 ring-offset-2 ring-red-700" : "",
                                                              "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                                            )}
                                                            aria-hidden='true'
                                                          >
                                                            <span className='rounded-full bg-white w-1.5 h-1.5' />
                                                          </span>
                                                          <span className='ml-3 flex flex-col'>
                                                            <RadioGroup.Label
                                                              as='span'
                                                              className={classNames(
                                                                checked ? "text-red-700" : "text-gray-900",
                                                                "block text-sm font-medium"
                                                              )}
                                                            >
                                                              {setting.currency_symbol}
                                                            </RadioGroup.Label>
                                                          </span>
                                                        </>
                                                      )}
                                                    </RadioGroup.Option>
                                                  ))}
                                                </div>
                                              </RadioGroup>
                                              {error?.selectedradio3 && (
                                                <span className='text-xs text-red-600 capitalize'>
                                                  {error?.selectedradio3}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                          {frmSrc !== "" ? (
                                            <iframe
                                              style={{ height: "45vh" }}
                                              src={frmSrc}
                                              title='document'
                                              class='w-4/6 mx-auto mt-5 sm:col-span-6'
                                            />
                                          ) : (
                                            ""
                                          )}
                                          <div className='sm:col-span-6'>
                                            <div className='mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                                              <div className='space-y-1 text-center'>
                                                {contract_document.preview ? (
                                                  <img
                                                    src={"/img/done.svg"}
                                                    alt='Profile'
                                                    className='mx-auto h-12 w-12 text-gray-400'
                                                  />
                                                ) : (
                                                  <img
                                                    src={"/img/pdf.png"}
                                                    alt='Profile'
                                                    className='mx-auto h-12 w-12 text-gray-400'
                                                  />
                                                )}
                                                <div className='flex text-sm text-gray-600'>
                                                  <label
                                                    htmlFor='file-upload'
                                                    className={` ${error?.file && "border-red-600"
                                                      } relative cursor-pointer rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500`}
                                                  >
                                                    <span>Seleccione o ficheiro</span>{" "}
                                                    <span className='mandatory'>*</span>
                                                    <input
                                                      accept='application/pdf'
                                                      id='file-upload'
                                                      name='file-upload'
                                                      type='file'
                                                      className='sr-only'
                                                      onChange={(e) => {
                                                        handleAmendmentsDocumentChange(e, "file");
                                                      }}
                                                      value={AmendmentsdocumentInfo?.object_of_the_addendum?.name || ""}
                                                    />
                                                  </label>
                                                </div>
                                                <p className='text-xs text-gray-500'>PDF até 10MB</p>
                                              </div>
                                            </div>

                                            <div className='pt-5'>
                                              <div className='flex justify-end'>
                                                <button
                                                  type='button'
                                                  className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white'
                                                  onClick={handleAmendmentsDocumentSubmit}
                                                >
                                                  Carregar
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='sm:col-span-6'>
                                            <DataTable
                                              columns={columns2}
                                              data={contractamendmentdsdocumentlist}
                                              disabledCheckbox={false}
                                            />
                                          </div>
                                        </div>
                                      </Tab.Panel>
                                    </Tab.Panels>
                                  </Tab.Group>
                                </div>
                              </div>
                            </div>
                          </div>
                          {error?.file && <span className='text-xs text-red-600 capitalize'>{error?.file}</span>}
                          <div className='pt-5'>
                            <div className='flex justify-end'>
                              <button
                                type='button'
                                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn'
                                onClick={() => setTab(1)}
                              >
                                Retroceder
                              </button>

                              <button
                                type='button'
                                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white'
                                onClick={() => submit()}
                              >
                                Guardar e Sair
                              </button>
                              <button
                                type='button'
                                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white'
                                onClick={() => setTab(4)}
                              >
                                Próximo
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {tab === 4 ? (
                        <div className='w-full'>
                          <div>
                            <nav aria-label='Progress'>
                              <ol className='divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0'>
                                {steps4.map((step, stepIdx) => (
                                  <li key={step.name} className='relative md:flex md:flex-1'>
                                    {step.status === "complete" ? (
                                      <a href={step.href} className='group flex w-full items-center'>
                                        <span className='flex items-center px-6 py-4 text-base font-medium'>
                                          <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-600'>
                                            <CheckIcon className='h-6 w-6 text-white' aria-hidden='true' />
                                          </span>
                                          <span className='ml-4 text-base font-medium text-gray-900'>{step.name}</span>
                                        </span>
                                      </a>
                                    ) : step.status === "current" ? (
                                      <a
                                        href={step.href}
                                        className='flex items-center px-6 py-4 text-base font-medium'
                                        aria-current='step'
                                      >
                                        <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-red-600'>
                                          <span className='text-red-600'>{step.id}</span>
                                        </span>
                                        <span className='ml-4 text-base font-medium text-red-600'>{step.name}</span>
                                      </a>
                                    ) : (
                                      <a href={step.href} className='group flex items-center'>
                                        <span className='flex items-center px-6 py-4 text-base font-medium'>
                                          <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400'>
                                            <span className='text-gray-500 group-hover:text-gray-900'>{step.id}</span>
                                          </span>
                                          <span className='ml-4 text-base font-medium text-gray-500 group-hover:text-gray-900'>
                                            {step.name}
                                          </span>
                                        </span>
                                      </a>
                                    )}

                                    {stepIdx !== steps4.length - 1 ? (
                                      <>
                                        {/* Arrow separator for lg screens and up */}
                                        <div
                                          className='absolute top-0 right-0 hidden h-full w-5 md:block'
                                          aria-hidden='true'
                                        >
                                          <svg
                                            className='h-full w-full text-gray-300'
                                            viewBox='0 0 22 80'
                                            fill='none'
                                            preserveAspectRatio='none'
                                          >
                                            <path
                                              d='M0 -2L20 40L0 82'
                                              vectorEffect='non-scaling-stroke'
                                              stroke='currentcolor'
                                              strokeLinejoin='round'
                                            />
                                          </svg>
                                        </div>
                                      </>
                                    ) : null}
                                  </li>
                                ))}
                              </ol>
                            </nav>
                          </div>
                          <div className='flex flex-col mt-6'>
                            <div className='text-sm sm:col-span-6 mb-3'>
                              <button
                                type='button'
                                onClick={() => {
                                  setShow(true);
                                  setShow1(false);
                                }}
                                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white'
                              >
                                Nova Condição
                              </button>
                              <button
                                type='button'
                                onClick={() => {
                                  setShow1(true);
                                  setShow(false);
                                }}
                                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white'
                              >
                                Gerar parcelas
                              </button>
                            </div>
                            <div className='App'>
                              {show && (
                                <div className='w-full border-b pb-6'>
                                  <div className='sm:col-span-6'>
                                    <div className='pt-1'>
                                      <h2 class='text-lg font-bold leading-6 text-gray-900 mt-6 mb-4'>Nova Condição</h2>
                                      <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                                        <div className='sm:col-span-3'>
                                          <label
                                            htmlFor='first_name'
                                            className='block text-base font-medium text-gray-700'
                                          >
                                            Nome da Parcela <span className='mandatory'>*</span>
                                          </label>
                                          <div className='mt-1'>
                                            <input
                                              type='text'
                                              name='first_name'
                                              id='first_name'
                                              autoComplete='given-name'
                                              className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md'
                                              onChange={(e) => handleconditionChange(e, "parcel_name")}
                                              value={fields3?.parcel_name}
                                            />
                                            {error?.parcel_name && (
                                              <span className='text-xs text-red-600 capitalize'>
                                                {error?.parcel_name}
                                              </span>
                                            )}
                                          </div>
                                        </div>

                                        <div className='sm:col-span-3'>
                                          <label
                                            htmlFor='first_name'
                                            className='block text-base font-medium text-gray-700'
                                          >
                                            Montante Esperado(%) <span className='mandatory'>*</span>
                                          </label>
                                          <div className='mt-1'>
                                            <input
                                              type='number'
                                              name='first_name'
                                              id='first_name'
                                              autoComplete='given-name'
                                              className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md'
                                              onChange={(e) => handleconditionChange(e, "expected_amount")}
                                              value={fields3?.expected_amount}
                                            />
                                            {error?.expected_amount && (
                                              <span className='text-xs text-red-600 capitalize'>
                                                {error?.expected_amount}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='pt-5'>
                                      <div className='flex justify-end'>
                                        {/* <button
                                          type='button'
                                          className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn'
                                          onClick={() => setShow(false)}
                                        >
                                          Cancelar
                                        </button> */}
                                        <button
                                          type='button'
                                          className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white'
                                          onClick={() => submitcondition()}
                                        >
                                          Registar
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='sm:col-span-6 mt-6'>
                                    <DataTable
                                      columns={columnscondition}
                                      data={contractConditionlist}
                                      disabledCheckbox={false}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className='App'>
                              {show1 && (
                                <div className='w-full border-b pb-6'>
                                  <div className='sm:col-span-6'>
                                    <div className='pt-1'>
                                      <h2 class='text-lg font-bold leading-6 text-gray-900 mt-6 mb-4'>
                                        Gerar parcelas
                                      </h2>
                                      <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                                        <div className='sm:col-span-3'>
                                          <label
                                            htmlFor='first_name'
                                            className='block text-base font-medium text-gray-700'
                                          >
                                            Nome da Parcela <span className='mandatory'>*</span>
                                          </label>
                                          <div className='mt-1'>
                                            <input
                                              type='text'
                                              name='first_name'
                                              id='first_name'
                                              autoComplete='given-name'
                                              className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md'
                                              onChange={(e) => handlegenerateinstallmentChange(e, "parcel_name")}
                                              value={generateInstallment?.parcel_name}
                                            />
                                            {error?.parcel_name && (
                                              <span className='text-xs text-red-600 capitalize'>
                                                {error?.parcel_name}
                                              </span>
                                            )}
                                          </div>
                                        </div>

                                        <div className='sm:col-span-3'>
                                          <label
                                            htmlFor='first_name'
                                            className='block text-base font-medium text-gray-700'
                                          >
                                            Quantidade de parcelas a gerar <span className='mandatory'>*</span>
                                          </label>
                                          <div className='mt-1'>
                                            <input
                                              type='number'
                                              name='first_name'
                                              id='first_name'
                                              autoComplete='given-name'
                                              className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md'
                                              onChange={(e) =>
                                                handlegenerateinstallmentChange(e, "number_of_installments_to_generate")
                                              }
                                              value={generateInstallment?.number_of_installments_to_generate}
                                            />
                                            {error?.number_of_installments_to_generate && (
                                              <span className='text-xs text-red-600 capitalize'>
                                                {error?.number_of_installments_to_generate}
                                              </span>
                                            )}
                                          </div>
                                        </div>

                                        <div className='sm:col-span-3'>
                                          <label
                                            htmlFor='first_name'
                                            className='block text-base font-medium text-gray-700'
                                          >
                                            Montante esperado (AOA) <span className='mandatory'>*</span>
                                          </label>
                                          <div className='mt-1'>
                                            <input
                                              type='number'
                                              name='first_name'
                                              id='first_name'
                                              autoComplete='given-name'
                                              className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md'
                                              onChange={(e) => handlegenerateinstallmentChange(e, "expected_amount")}
                                              value={generateInstallment?.expected_amount}
                                            />
                                            {error?.expected_amount && (
                                              <span className='text-xs text-red-600 capitalize'>
                                                {error?.expected_amount}
                                              </span>
                                            )}
                                          </div>
                                        </div>

                                        <div className='sm:col-span-3'>
                                          <label
                                            htmlFor='first_name'
                                            className='block text-base font-medium text-gray-700'
                                          >
                                            Data estimada
                                          </label>
                                          <div className='mt-1 relative'>
                                            <DatePicker
                                              className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                              onChange={onChangeestimateddate}
                                              value={estimated_date}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='pt-5'>
                                      <div className='flex justify-end'>
                                        {/* <button
                                          type='button'
                                          className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn'
                                          onClick={() => setShow1(false)}
                                        >
                                          Cancelar
                                        </button> */}
                                        <button
                                          type='button'
                                          className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white'
                                          onClick={() => submitinstallment()}
                                        >
                                          Gerar parcelas
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='mt-6 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                                    <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                                      <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                                        <table className='min-w-full divide-y divide-gray-200' />
                                        <DataTable
                                          selection={false}
                                          columns={columns3}
                                          data={contractinstallmentlist}
                                          className='min-w-full divide-y divide-gray-200'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='pt-5'>
                            <div className='flex justify-end'>
                              <button
                                type='button'
                                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn'
                                onClick={() => setTab(2)}
                              >
                                Retroceder
                              </button>
                              <button
                                type='button'
                                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white'
                                onClick={() => submit()}
                              >
                                Submeter
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* <div className="flex justify-end">
                    <button
                      type="button"
                      className="btn-link py-2 px-4 border border-gray-300 rounded-md shadow-sm text-[16px] font-medium shadow-sm bg-skin-white text-skin-black rounded-md"
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className="btn-link ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-[16px] font-medium shadow-sm bg-skin-primary text-skin-white rounded-md"
                    >
                      Submeter
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setOpen}>
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6'>
                <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                  <button
                    type='button'
                    className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600'
                    onClick={() => setOpen(false)}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div>
                  <div className='text-left'>
                    <Dialog.Title as='h3' className='text-xl font-semibold leading-6 text-gray-900 mb-5'>
                      Editar peça do procedimento
                    </Dialog.Title>
                    <div className='py-5'>
                      <div className='sm:col-span-6'>
                        <label htmlFor='street-address' className='block text-sm font-medium text-gray-700'>
                          Contrato <span className='mandatory'>*</span>
                        </label>
                        <div className='mt-1'>
                          <select
                            autoComplete='country'
                            className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md'
                            onChange={(e) => {
                              setSelectedDocInfo({
                                ...selectedDocInfo,
                                parts_of_the_contract: e?.target?.value,
                              });
                            }}
                            value={selectedDocInfo?.parts_of_the_contract}
                          >
                            <option>---Selecione o Contrato---</option>
                            <option value={"contract"} key={"contract"}>
                              Contrato
                            </option>
                          </select>
                          {error?.parts_of_the_contract && (
                            <span className='text-xs text-red-600 capitalize'>{error?.parts_of_the_contract}</span>
                          )}
                        </div>
                      </div>
                      {frmSrc !== "" ? (
                        <iframe
                          style={{ height: "45vh" }}
                          src={frmSrc}
                          title='document'
                          className='w-4/6 mx-auto mt-5'
                        />
                      ) : (
                        ""
                      )}
                      <div className='sm:col-span-6'>
                        <div className='mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                          <div className='space-y-1 text-center'>
                            {contract_document.preview ? (
                              <img src={"/img/done.svg"} alt='Profile' className='mx-auto h-12 w-12 text-gray-400' />
                            ) : (
                              <img src={"/img/pdf.png"} alt='Profile' className='mx-auto h-12 w-12 text-gray-400' />
                            )}
                            <div className='flex text-sm text-gray-600'>
                              <label
                                htmlFor='file-upload2'
                                className='relative cursor-pointer rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500'
                                value={selectedDocInfo?.file}
                                onChange={(e) => {
                                  setSelectedDocInfo({
                                    ...selectedDocInfo,
                                    file: e?.target?.files?.[0],
                                  });
                                  setEditDocFrmSrc(URL.createObjectURL(e?.target?.files?.[0]));
                                }}
                              >
                                <span>Seleccione o ficheiro</span> <span className='mandatory'>*</span>
                                <input accept='application/pdf' id='file-upload2' type='file' className='sr-only' />
                              </label>
                            </div>
                            <p className='text-xs text-gray-500'>PDF até 10MB</p>
                          </div>
                        </div>
                        {error?.file && <span className='text-xs text-red-600 capitalize'>{error?.file}</span>}
                      </div>
                      {editDocFrmSrc !== "" && (
                        <iframe
                          style={{ height: "45vh" }}
                          src={editDocFrmSrc}
                          title='document'
                          className='w-4/6 mx-auto mt-5'
                        />
                      )}
                      <div className='pt-5'>
                        <div className='flex justify-end'>
                          <button
                            type='button'
                            className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn'
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            Cancelar
                          </button>
                          <button
                            type='submit'
                            className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white'
                            onClick={handleDocumentUpdate}
                          >
                            Submeter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='html2pdf__page-break'></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={open2} as={Fragment}>
        <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setOpen2}>
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6'>
                <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                  <button
                    type='button'
                    className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600'
                    onClick={() => setOpen2(false)}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div>
                  <div className='text-left'>
                    <Dialog.Title as='h3' className='text-xl font-semibold leading-6 text-gray-900 mb-5'>
                      Editar Adendas do Contrato
                    </Dialog.Title>
                    <div className='py-5'>
                      <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                        <div className='sm:col-span-3'>
                          <label htmlFor='first_name' className='block text-base font-medium text-gray-700'>
                            Objecto da adenda <span className='mandatory'>*</span>
                          </label>
                          <div className='mt-1'>
                            <input
                              type='text'
                              name='object_of_the_addendum'
                              id='object_of_the_addendum'
                              autoComplete='given-name'
                              className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md'
                              onChange={(e) => {
                                setSelectedAmendmentsDocumentInfo({
                                  ...selectedAmendmentsdocumentInfo,
                                  object_of_the_addendum: e?.target?.value,
                                });
                              }}
                              value={selectedAmendmentsdocumentInfo?.object_of_the_addendum}
                            />
                          </div>
                        </div>

                        <div className='sm:col-span-3'>
                          <label htmlFor='first_name' className='block text-base font-medium text-gray-700'>
                            Valor da adenda <span className='mandatory'>*</span>
                          </label>
                          <div className='mt-1'>
                            <input
                              type='number'
                              name='addendum_value'
                              id='addendum_value'
                              autoComplete='given-name'
                              className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md'
                              onChange={(e) => {
                                setSelectedAmendmentsDocumentInfo({
                                  ...selectedAmendmentsdocumentInfo,
                                  addendum_value: e?.target?.value,
                                });
                              }}
                              value={selectedAmendmentsdocumentInfo?.addendum_value}
                            />
                          </div>
                        </div>

                        <div className='sm:col-span-3'>
                          <label htmlFor='first_name' className='block text-base font-medium text-gray-700'>
                            Data de expiração da adenda
                          </label>
                          <div className='mt-1 relative'>
                            <DatePicker
                              className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-white'
                              onChange={onChangeaddendumexpirationdate}
                              value={addendum_expiration_date}
                            />
                          </div>
                        </div>

                        <div className='sm:col-span-3'>
                          <label htmlFor='first_name' className='block text-base font-medium text-gray-700'>
                            Tipo de Moeda <span className='mandatory'>*</span>
                          </label>
                          <div className='mt-1'>
                            <RadioGroup value={selectedradio3} onChange={setSelectedRadio3}>
                              <RadioGroup.Label className='sr-only'> Privacy setting </RadioGroup.Label>
                              <div className='sm:flex sm:items-center flex-wrap'>
                                {currency.map((setting, settingIdx) => (
                                  <RadioGroup.Option
                                    key={setting.currency_symbol}
                                    value={setting.id}
                                    className={({ checked }) =>
                                      classNames(
                                        settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                                        settingIdx === currency.length - 1 ? "rounded-bl-md rounded-br-md" : "",
                                        checked
                                          ? "bg-transparent border-transparent z-0 mr-3 mb-3"
                                          : "border-transparent mr-3 mb-3",
                                        "relative border flex cursor-pointer focus:outline-none"
                                      )
                                    }
                                  >
                                    {({ active, checked }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            checked ? "bg-red-700 border-transparent" : "bg-white border-gray-300",
                                            active ? "ring-2 ring-offset-2 ring-red-700" : "",
                                            "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                          )}
                                          aria-hidden='true'
                                        >
                                          <span className='rounded-full bg-white w-1.5 h-1.5' />
                                        </span>
                                        <span className='ml-3 flex flex-col'>
                                          <RadioGroup.Label
                                            as='span'
                                            className={classNames(
                                              checked ? "text-red-700" : "text-gray-900",
                                              "block text-sm font-medium"
                                            )}
                                          >
                                            {setting.currency_symbol}
                                          </RadioGroup.Label>
                                        </span>
                                      </>
                                    )}
                                  </RadioGroup.Option>
                                ))}
                              </div>
                            </RadioGroup>
                          </div>
                        </div>
                        <div className='sm:col-span-6'>
                          <div className='mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                            <div className='space-y-1 text-center'>
                              {contract_document.preview ? (
                                <img src={"/img/done.svg"} alt='Profile' className='mx-auto h-12 w-12 text-gray-400' />
                              ) : (
                                <img src={"/img/pdf.png"} alt='Profile' className='mx-auto h-12 w-12 text-gray-400' />
                              )}
                              <div className='flex text-sm text-gray-600'>
                                <label
                                  htmlFor='file-upload2'
                                  className='relative cursor-pointer rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500'
                                  value={selectedAmendmentsdocumentInfo?.file}
                                  onChange={(e) => {
                                    setSelectedAmendmentsDocumentInfo({
                                      ...selectedAmendmentsdocumentInfo,
                                      file: e?.target?.files?.[0],
                                    });
                                    setEditDocFrmSrc(URL.createObjectURL(e?.target?.files?.[0]));
                                  }}
                                >
                                  <span>Seleccione o ficheiro</span> <span className='mandatory'>*</span>
                                  <input accept='application/pdf' id='file-upload2' type='file' className='sr-only' />
                                </label>
                              </div>
                              <p className='text-xs text-gray-500'>PDF até 10MB</p>
                            </div>
                          </div>
                          {editDocFrmSrc !== "" && (
                            <iframe
                              style={{ height: "45vh" }}
                              src={editDocFrmSrc}
                              title='document'
                              className='w-4/6 mx-auto mt-5'
                            />
                          )}
                        </div>
                      </div>
                      <div className='pt-5'>
                        <div className='flex justify-end'>
                          <button
                            type='button'
                            className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn'
                            onClick={() => {
                              setOpen2(false);
                            }}
                          >
                            Cancelar
                          </button>
                          <button
                            type='submit'
                            className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white'
                            onClick={() => {
                              handleAmendmentsDocumentUpdate();
                            }}
                          >
                            Submeter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='html2pdf__page-break'></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={open3} as={Fragment}>
        <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setOpen3}>
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6'>
                <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                  <button
                    type='button'
                    className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600'
                    onClick={() => setOpen3(false)}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div>
                  <div className='text-left'>
                    <Dialog.Title as='h3' className='text-xl font-semibold leading-6 text-gray-900 mb-5'>
                      Detalhes do Adendas do Contrato
                    </Dialog.Title>
                    <div className='py-5'>
                      <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Objecto da Adenda</dt>
                          <dd className='mt-1 text-base text-gray-900'>
                            {selectedAmendmentsdocumentInfo?.object_of_the_addendum}
                          </dd>
                        </div>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Valor</dt>
                          <dd className='mt-1 text-base text-gray-900'>
                            {selectedAmendmentsdocumentInfo?.addendum_value}%
                          </dd>
                        </div>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Tipo de Moeda</dt>
                          <dd className='mt-1 text-base text-gray-900'>
                            {selectedAmendmentsdocumentInfo?.currency_type_id}
                          </dd>
                        </div>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Data</dt>
                          <dd className='mt-1 text-base text-gray-900'>
                            {selectedAmendmentsdocumentInfo?.addendum_expiration_date}
                          </dd>
                        </div>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Ficheiro</dt>
                          <dd className='mt-1 text-base text-gray-900'>{selectedAmendmentsdocumentInfo?.fileName}</dd>
                        </div>
                      </dl>
                      {editDocFrmSrc !== "" && (
                        <iframe
                          style={{ height: "45vh" }}
                          src={editDocFrmSrc}
                          title='document'
                          className='w-4/6 mx-auto mt-5'
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div class='html2pdf__page-break'></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={open4} as={Fragment}>
        <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setOpen4}>
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6'>
                <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                  <button
                    type='button'
                    className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600'
                    onClick={() => setOpen4(false)}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div>
                  <div className='text-left'>
                    <Dialog.Title as='h3' className='text-xl font-semibold leading-6 text-gray-900 mb-5'>
                      Editar Condição
                    </Dialog.Title>
                    <div className='py-5'>
                      <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                        <div className='sm:col-span-3'>
                          <label htmlFor='street-address' className='block text-sm font-medium text-gray-700'>
                            Nome da Parcela <span className='mandatory'>*</span>
                          </label>
                          <div className='mt-1'>
                            <input
                              type='text'
                              name='username'
                              id='username'
                              autoComplete='username'
                              placeholder='Nome da Parcela 1'
                              className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                              onChange={(e) => handleeditgenerateinstallmentChange(e, "parcel_name")}
                              value={selectedinstallmentInfo?.parcel_name}
                            />
                          </div>
                        </div>

                        <div className='sm:col-span-3'>
                          <label htmlFor='street-address' className='block text-sm font-medium text-gray-700'>
                            Data de Conclusão
                          </label>
                          <div className='mt-1'>
                            <DatePicker
                              className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-white'
                              onChange={onChangeestimateddate}
                              value={estimated_date}
                            />
                          </div>
                        </div>

                        <div className='sm:col-span-3'>
                          <label htmlFor='street-address' className='block text-sm font-medium text-gray-700'>
                            Execução Física Esperada (%) <span className='mandatory'>*</span>
                          </label>
                          <div className='mt-1'>
                            <input
                              type='text'
                              name='username'
                              id='username'
                              autoComplete='username'
                              placeholder='92%'
                              className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                              onChange={(e) =>
                                handleeditgenerateinstallmentChange(e, "number_of_installments_to_generate")
                              }
                              value={selectedinstallmentInfo?.number_of_installments_to_generate}
                            />
                          </div>
                        </div>

                        <div className='sm:col-span-3'>
                          <label htmlFor='street-address' className='block text-sm font-medium text-gray-700'>
                            Montante Esperado (%) <span className='mandatory'>*</span>
                          </label>
                          <div className='mt-1'>
                            <input
                              type='text'
                              name='username'
                              id='username'
                              autoComplete='username'
                              placeholder='83%'
                              className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                              onChange={(e) => handleeditgenerateinstallmentChange(e, "expected_amount")}
                              value={selectedinstallmentInfo?.expected_amount}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='pt-5'>
                        <div className='flex justify-end'>
                          <button
                            type='button'
                            className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn'
                            onClick={() => setOpen4(false)}
                          >
                            Cancelar
                          </button>
                          <button
                            type='submit'
                            className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white'
                            onClick={() => upadateinstallment()}
                          >
                            Submeter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='html2pdf__page-break'></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={open5} as={Fragment}>
        <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setOpen5}>
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6'>
                <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                  <button
                    type='button'
                    className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600'
                    onClick={() => setOpen5(false)}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div>
                  <div className='text-left'>
                    <Dialog.Title as='h3' className='text-xl font-semibold leading-6 text-gray-900 mb-5'>
                      Detalhes do Condição
                    </Dialog.Title>
                    <div className='py-5'>
                      <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Nome da Parcela</dt>
                          <dd className='mt-1 text-base text-gray-900'>{selectedinstallmentInfo?.parcel_name}</dd>
                        </div>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Data de Conclusão</dt>
                          <dd className='mt-1 text-base text-gray-900'>{selectedinstallmentInfo?.estimated_date}</dd>
                        </div>
                        {/* <div className="sm:col-span-1">
                          <dt className="text-base font-bold text-gray-500">Estado</dt>
                          <dd className="mt-1 text-base text-gray-900">Bengo</dd>
                        </div> */}
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Execução Física Esperada (%)</dt>
                          <dd className='mt-1 text-base text-gray-900'>
                            {selectedinstallmentInfo?.number_of_installments_to_generate}%
                          </dd>
                        </div>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Montante Esperado (%)</dt>
                          <dd className='mt-1 text-base text-gray-900'>{selectedinstallmentInfo?.expected_amount}%</dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
                <div class='html2pdf__page-break'></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={opencondition} as={Fragment}>
        <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setOpenCondition}>
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6'>
                <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                  <button
                    type='button'
                    className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600'
                    onClick={() => setOpenCondition(false)}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div>
                  <div className='text-left'>
                    <Dialog.Title as='h3' className='text-xl font-semibold leading-6 text-gray-900 mb-5'>
                      Detalhes do Condição
                    </Dialog.Title>
                    <div className='py-5'>
                      <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Nome da Parcela</dt>
                          <dd className='mt-1 text-base text-gray-900'>{selectedConditionInfo?.parcel_name}</dd>
                        </div>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Montante Esperado (%)</dt>
                          <dd className='mt-1 text-base text-gray-900'>{selectedConditionInfo?.expected_amount}%</dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
                <div class='html2pdf__page-break'></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openconditionedit} as={Fragment}>
        <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setconditionedit}>
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6'>
                <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                  <button
                    type='button'
                    className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600'
                    onClick={() => setconditionedit(false)}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div>
                  <div className='text-left'>
                    <Dialog.Title as='h3' className='text-xl font-semibold leading-6 text-gray-900 mb-5'>
                      Editar Condição
                    </Dialog.Title>
                    <div className='py-5'>
                      <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                        <div className='sm:col-span-3'>
                          <label htmlFor='street-address' className='block text-sm font-medium text-gray-700'>
                            Nome da Parcela <span className='mandatory'>*</span>
                          </label>
                          <div className='mt-1'>
                            <input
                              type='text'
                              name='parcel_name'
                              id='parcel_name'
                              autoComplete='parcel_name'
                              placeholder='Nome da Parcela 1'
                              className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                              onChange={(e) => handlerditconditionChange(e, "parcel_name")}
                              defaultValue={selectedConditionInfo?.parcel_name}
                            />
                          </div>
                        </div>

                        <div className='sm:col-span-3'>
                          <label htmlFor='street-address' className='block text-sm font-medium text-gray-700'>
                            Montante Esperado (%) <span className='mandatory'>*</span>
                          </label>
                          <div className='mt-1'>
                            <input
                              type='text'
                              name='expected_amount'
                              id='expected_amount'
                              autoComplete='expected_amount'
                              placeholder='83%'
                              className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                              onChange={(e) => handlerditconditionChange(e, "expected_amount")}
                              defaultValue={selectedConditionInfo?.expected_amount}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='pt-5'>
                        <div className='flex justify-end'>
                          <button
                            type='button'
                            className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn'
                            onClick={() => setconditionedit(false)}
                          >
                            Cancelar
                          </button>
                          <button
                            type='submit'
                            className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white'
                            onClick={() => {
                              handlenewconditionUpdate();
                            }}
                          >
                            Submeter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='html2pdf__page-break'></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openviewdoc} as={Fragment}>
        <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setopenviewdoc}>
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6'>
                <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                  <button
                    type='button'
                    className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600'
                    onClick={() => setopenviewdoc(false)}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div>
                  <div className='text-left'>
                    <Dialog.Title as='h3' className='text-xl font-semibold leading-6 text-gray-900 mb-5'>
                      Detalhes do Contrato
                    </Dialog.Title>
                    <div className='py-5'>
                      <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Documento</dt>
                          <dd className='mt-1 text-base text-gray-900'>
                            Contrato
                          </dd>
                        </div>
                        <div className='sm:col-span-1'>
                          <dt className='text-base font-bold text-gray-500'>Ficheiro</dt>
                          <dd className='mt-1 text-base text-gray-900'>{selectedDocInfo?.fileName}</dd>
                        </div>
                      </dl>
                      {editDocFrmSrc !== "" && (
                        <iframe
                          style={{ height: "45vh" }}
                          src={editDocFrmSrc}
                          title='document'
                          className='w-4/6 mx-auto mt-5'
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div class='html2pdf__page-break'></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={opentab2} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpenTab2}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpenTab2(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="py-5">
                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="last_name"
                          className="block text-base font-medium text-gray-700"
                        >
                          Nome do Fornecedor
                        </label>
                        <div className="mt-1">
                          <Select
                            value={selectedContriumprovider}
                            placeholder="Selecionar Nome do Fornecedor"
                            onChange={(e) =>
                              handlecontriumChange(e, "provider_id")
                            }
                            options={contriumprovider}
                          />
                          {error?.provider_id && (
                            <span className="text-xs text-red-600 capitalize">
                              {error?.provider_id}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="last_name"
                          className="block text-base font-medium text-gray-700"
                        >
                          Valor do Contrato
                        </label>
                        <div className="mt-1">
                          <input
                            type="number"
                            name="first_name"
                            id="first_name"
                            autoComplete="given-name"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                            onChange={(e) =>
                              handlecontriumChange(
                                e,
                                "contract_value"
                              )
                            }
                            value={contriumfields?.contract_value}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="last_name"
                          className="block text-base font-medium text-gray-700 mb-2"
                        >
                          Tipo de Moeda
                        </label>
                        <div className="mt-1">
                          <RadioGroup
                            value={selectedradio5}
                            onChange={setSelectedRadio5}
                          >
                            <RadioGroup.Label className="sr-only">
                              {" "}
                              Privacy setting{" "}
                            </RadioGroup.Label>
                            <div className="-space-y-px sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                              {currency.map(
                                (setting, settingIdx) => (
                                  <RadioGroup.Option
                                    key={setting.currency_symbol}
                                    value={setting.id}
                                    className={({
                                      checked,
                                    }) =>
                                      classNames(
                                        settingIdx === 0
                                          ? "rounded-tl-md rounded-tr-md"
                                          : "",
                                        settingIdx ===
                                          currency.length -
                                          1
                                          ? "rounded-bl-md rounded-br-md"
                                          : "",
                                        checked
                                          ? "bg-transparent z-0"
                                          : "border-none",
                                        "relative flex cursor-pointer focus:outline-none"
                                      )
                                    }
                                  >
                                    {({
                                      active,
                                      checked,
                                    }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            checked
                                              ? "bg-red-700 border-transparent"
                                              : "bg-transparent border-gray-300",
                                            active
                                              ? "ring-2 ring-offset-2 ring-red-700"
                                              : "",
                                            "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                          )}
                                          aria-hidden="true"
                                        >
                                          <span className="rounded-full bg-white w-1.5 h-1.5" />
                                        </span>
                                        <span className="ml-3 flex flex-col">
                                          <RadioGroup.Label
                                            as="span"
                                            className={classNames(
                                              checked
                                                ? "text-red-700"
                                                : "text-gray-900",
                                              "block text-sm font-medium"
                                            )}
                                          >
                                            {setting.currency_symbol}
                                          </RadioGroup.Label>
                                        </span>
                                      </>
                                    )}
                                  </RadioGroup.Option>
                                )
                              )}
                            </div>
                          </RadioGroup>
                        </div>
                      </div>
                    </div>
                    <div className="pt-5">
                      <div className="flex justify-end">
                        <button
                          type="button"
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                          onClick={() => setOpenTab2(false)}
                        >
                          Cancelar
                        </button>
                        <button
                          type="submit"
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                          onClick={() => submitcontrium()}
                        >
                          Submeter
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>


      <Transition.Root show={opentab2subtab2} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpenTab2SubTab2}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                    onClick={() => setOpenTab2SubTab2(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="py-5">
                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-6">
                        <label
                          htmlFor="last_name"
                          className="block text-base font-medium text-gray-700"
                        >
                          Nome do Fornecedor
                        </label>
                        <div className="mt-1">
                          <Select
                            value={selectedFrameworkprovider}
                            placeholder="Selecionar Nome do Fornecedor"
                            onChange={(e) =>
                              handleframeworkChange(e, "provider_id")
                            }
                            options={frameworkprovider}
                          />
                          {error?.provider_id && (
                            <span className="text-xs text-red-600 capitalize">
                              {error?.provider_id}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="pt-5">
                      <div className="flex justify-end">
                        <button
                          type="button"
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none xl-admin-btn"
                          onClick={() => setOpenTab2SubTab2(false)}
                        >
                          Cancelar
                        </button>
                        <button
                          type="submit"
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none xl-admin-btn btn-link bg-skin-primary text-skin-white"
                          onClick={() => submitframework()}
                        >
                          Submeter
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="html2pdf__page-break"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
