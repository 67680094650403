// import { createDecipheriv } from 'crypto';
import Cookies from "js-cookie";
const baseURL = process.env.REACT_APP_BASE_URL;
// const algorithm = 'aes-256-cbc';
// import jwt_decode from "jwt-decode";

const config = {
  appName: process.env.REACT_APP_NAME,
  tinyAPIKey: process.env.REACT_APP_TINY_API_KEY,
  baseURL,
  staticPath: process.env.REACT_APP_STATIC_PATH_URL,
  USER_MODULE_PATH: process.env.REACT_APP_STATIC_PATH_URL,

  // decrypt (Securitykey, iv, data) {
  //   return new Promise((resolve) => {
  //       try{
  //         const decipher = createDecipheriv(algorithm, Securitykey, iv);
  //         let decryptedData = decipher.update(data, "hex", "utf-8");
  //         decryptedData += decipher.final("utf8");
  //         resolve(decryptedData)
  //         return;
  //       }
  //       catch(err){
  //         // console.log(err)
  //       }
  //   })
  // },

  getAPI(data) {
    return new Promise((resolve, reject) => {
      let url = new URL(`${baseURL}${data.url}`);
      // url.search = new URLSearchParams({...data.params, code:'EN'}).toString();
      url.search = new URLSearchParams({
        ...data.params,
        code: "EN",
      }).toString();
      fetch(url, { headers: getReqHeaders() })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          // console.log(data.payload)

          // const response = this.decrypt(Securitykey, data.payload.iv, data.payload.data);
          resolve(data);
          return;
        })
        .catch((error) => {
          // console.log(error)
          reject(error);
          return;
        });
    });
  },

  deleteAPI(data) {
    return new Promise((resolve, reject) => {
      let accessToken = Cookies.get("G-accessToken");
      fetch(`${baseURL}${data.url}`, {
        method: "delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },

        body: JSON.stringify(
          { ...data.params, code: "EN" },
          { headers: getReqHeaders() }
        ),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          // console.log(data.payload)
          // const response = this.decrypt(Securitykey, data.payload.iv, data.payload.data);
          resolve(data);
          return;
        })
        .catch((error) => {
          // console.log(error)
          reject(error);
          return;
        });
    });
  },

  postAPI(data) {
    return new Promise((resolve, reject) => {
      let accessToken = Cookies.get("G-accessToken");
      fetch(`${baseURL}${data.url}`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },

        // body:JSON.stringify({...data.params, code:'EN'})
        body: JSON.stringify({ ...data.params, code: "EN" }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          // console.log(data.payload)
          // const response = this.decrypt(Securitykey, data.payload.iv, data.payload.data);
          resolve(data);
          return;
        })
        .catch((error) => {
          // console.log(error)
          reject(error);
          return;
        });
    });
  },

  allAPI(data) {
    return new Promise((resolve, reject) => {
      let accessToken = Cookies.get("G-accessToken");
      fetch(`${baseURL}${data.url}`, {
        method: data.method ? data.method : "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        // body:JSON.stringify({...data.params, code:'EN'})
        body: JSON.stringify({ ...data.params, code: "EN" }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          // console.log(data.payload)
          // const response = this.decrypt(Securitykey, data.payload.iv, data.payload.data);
          resolve(data);
          return;
        })
        .catch((error) => {
          // console.log(error)
          reject(error);
          return;
        });
    });
  },

  postFormDataAPI(data) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}${data.url}`, {
        method: "post",

        body: data.params,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          // console.log(data.payload)
          // const response = this.decrypt(Securitykey, data.payload.iv, data.payload.data);
          resolve(data);
          return;
        })
        .catch((error) => {
          // console.log(error)
          reject(error);
          return;
        });
    });
  },

  patchAPI(data) {
    return new Promise((resolve, reject) => {
      let accessToken = Cookies.get("G-accessToken");
      fetch(`${baseURL}${data.url}`, {
        method: "patch",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ ...data.params, code: "EN" }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          resolve(data);
          return;
        })
        .catch((error) => {
          // console.log(error)
          reject(error);
          return;
        });
    });
  },
};

const getReqHeaders = () => {
  const accessToken = Cookies.get("G-accessToken");

  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export default config;
